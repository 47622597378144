import {
  addToCartSuccessSkuSelector,
  clearError,
  consentAddToCart,
  errorSelector,
  clearAddToCartSuccessSku,
  refreshCartCount,
  reNewServiceAndAddToCart,
  mergeStoreAndAddToCart,
} from '@/store/slices/addToCartSlice';
import { useAppDispatch } from '@/store/store';
import React, { ReactElement, ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  AddToCartErrorHandleOutput,
  errorMessageHandler,
  AddToCartHandleType,
} from '@/utils/addToCart';
import { useTranslation } from 'next-i18next';
import { openStockModal } from '@/store/slices/storeSlice';
import { useRouter } from 'next/router';
import * as ServerCookies from '@/services/client/cookieService';
import Cookies from 'js-cookie';
import * as cookieKey from '@/constants/cookieKey.constant';
import { customerIdSelector } from '@/store/slices/loginSlice';
import { currencyCode } from '@/constants/defaultValue.constant';
import { showMessageSuccessAutoHide } from '@/utils/showDialog';
import { StoreType } from '@/services/client/storeService';
import * as defaultValue from '@/constants/defaultValue.constant';

interface Props {
  children: ReactNode;
}

export default function AddToCartLayout({ children }: Props): ReactElement {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const error = useSelector(errorSelector);
  const addToCartSuccessSku = useSelector(addToCartSuccessSkuSelector);
  const customerId = useSelector(customerIdSelector);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (error) {
      errorMessageHandler({ ...error, lang: i18n.language }).then(
        async (handle: AddToCartErrorHandleOutput) => {
          if (handle.handleType === AddToCartHandleType.error) {
            dispatch(clearError());
          } else if (handle.handleType === AddToCartHandleType.consent) {
            dispatch(consentAddToCart({ sku: error.sku, qty: handle.qty }));
          } else if (handle.handleType === AddToCartHandleType.changeStore) {
            dispatch(clearError());
            if (error.sku) {
              dispatch(
                openStockModal({
                  sku: error.sku,
                  type: StoreType.stockvalid,
                  qty: error.qty ?? 1,
                }),
              );
            }
          } else if (handle.handleType === AddToCartHandleType.refresh) {
            dispatch(clearError());
          } else if (
            handle.handleType === AddToCartHandleType.clearCartAndAddToCart
          ) {
            Cookies.remove(cookieKey.cartCount);
            await ServerCookies.remove(cookieKey.ref);
            dispatch(refreshCartCount());
            dispatch(consentAddToCart({ sku: error.sku }));
            dispatch(clearError());
          } else if (
            handle.handleType === AddToCartHandleType.autoChangeStore
          ) {
            try {
              Cookies.set(cookieKey.stcode, defaultValue.defaultStoreCode);
              dispatch(mergeStoreAndAddToCart({ sku: error.sku }));
            } catch (_) {}
          } else if (
            handle.handleType ===
            AddToCartHandleType.clearCartAndGotoOrderhistory
          ) {
            Cookies.remove(cookieKey.cartCount);
            await ServerCookies.remove(cookieKey.ref);
            dispatch(refreshCartCount());
            router.push(`/${router.locale}/profile/orderhistory`);
            dispatch(clearError());
          } else if (handle.handleType === AddToCartHandleType.clearCart) {
            Cookies.remove(cookieKey.cartCount);
            await ServerCookies.remove(cookieKey.ref);
            dispatch(refreshCartCount());
            dispatch(clearError());
          } else if (
            handle.handleType === AddToCartHandleType.reNewServiceAndAddToCart
          ) {
            dispatch(reNewServiceAndAddToCart({ sku: error.sku }));
            dispatch(clearError());
          }
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, dispatch, i18n]);

  useEffect(() => {
    if (addToCartSuccessSku && addToCartSuccessSku.length > 0) {
      try {
        if ((window as any).dataLayer) {
          (window as any).dataLayer.push({ ecommerce: null });
          (window as any).dataLayer.push({
            event: 'addToCart',
            userId: customerId,
            ecommerce: {
              currencyCode: currencyCode, // Local currency is optional.
              value: addToCartSuccessSku[0].price
                ? addToCartSuccessSku[0].price.replace(/,/g, '')
                : '0',
              products: addToCartSuccessSku.map((e) => {
                return {
                  name: e.name,
                  id: e.sku,
                  price: e.price ? e.price.replace(/,/g, '') : '0',
                  brand: e.brand ?? '',
                  category: e.catName ?? '',
                  quantity: e.qty,
                };
              }),
            },
          });
        }
      } catch (_) {}
      try {
        const body = document.querySelector('#addtocart-main') as HTMLElement;
        const mainCart = document.querySelector('.main-cart') as HTMLElement;
        mainCart.classList.remove('shake');
        const originalImage = document.querySelector(
          `#product-image-${addToCartSuccessSku[0].sku}`,
        ) as HTMLElement;
        // Clone the originalImage to clonedImage
        const clonedImage = originalImage?.firstElementChild?.cloneNode(
          true,
        ) as HTMLElement;
        if (mainCart && body && clonedImage) {
          clonedImage?.classList.add('cloned-product-image');
          clonedImage.id = `cloned-product-image-${addToCartSuccessSku[0].sku}`;
          // Append the clonedImage(absolute) to body
          body.appendChild(clonedImage);
          // Calculate initial position of originalImage by parentElement in case because originalImage is absolute in relative parentElement for badge
          const initialX = originalImage.parentElement?.offsetLeft ?? 0;
          const initialY = originalImage.parentElement?.offsetTop ?? 0;

          // Set the initial position of clonedImage
          clonedImage.style.left = `${initialX}px`;
          clonedImage.style.top = `${initialY}px`;
          // Calculate the position difference to main-cart
          const xOffset =
            mainCart.offsetLeft -
            initialX -
            clonedImage.offsetWidth / 2 +
            mainCart.offsetWidth / 2;
          const yOffset =
            mainCart.offsetTop -
            initialY -
            clonedImage.offsetHeight / 2 +
            mainCart.offsetHeight / 2 +
            // Add scrollY to yOffset because the position of main-cart is relative to window
            window.scrollY;
          // Move the clonedImage to main-cart
          clonedImage.style.transition = 'transform 0.8s ease';
          clonedImage.style.transform = `translate(${xOffset}px, ${yOffset}px) scale(0)`;
          // Remove the clonedImage after transition
          clonedImage.addEventListener('transitionend', () => {
            body.removeChild(clonedImage);
            mainCart.classList.add('shake');
            dispatch(clearAddToCartSuccessSku());
          });
        } else {
          showMessageSuccessAutoHide({
            text: `${t('productaddedtocart')}`,
          });
        }
      } catch (_) {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToCartSuccessSku, dispatch]);

  return <div id="addtocart-main">{children}</div>;
}
