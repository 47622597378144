import {
  ErrorNextPay,
  ErrorShipment,
  ErrorShipmentType,
  SamedayChangeResponse,
  SelectedSlot,
  ShipmentResponse,
  ShippingType,
  Slot,
} from '@/models/Checkout.model';
import { samedayChange } from '@/services/client/checkoutService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import * as ServerCookies from '@/services/client/cookieService';
import Cookies from 'js-cookie';
import * as cookieKey from '@/constants/cookieKey.constant';
import { isEmail, isMobile } from '@/utils/validate';
import { numberWithCommas } from '@/utils/format';
import { CheckStockSkuResponse } from '@/models/Stock.model';

interface CheckoutState {
  ref: string;
  // undefined = not yet fetch
  // null = fetch but no data
  result?: ShipmentResponse | null;
  isLoadingShipment: boolean;
  errorShipment?: ErrorShipment;
  errorStock?: CheckStockSkuResponse;
  okTaxinvoice: boolean;
  receiveWith: 'S' | 'E';
  emailReceipt?: string;
  mobileReceipt?: string;
  currentPage: 'shipment' | 'payment';
  selectedSlot?: SelectedSlot[];
  // NextPay
  errorNextPay?: { type: ErrorNextPay; running?: number; message?: string };
  // NextPay
  // Express
  isSelectedExpress?: boolean;
  isLoadingChangeExpress: boolean;
  errorChangeExpress?: string;
  // Express
  // Call Service Slot
  isLoadingCallServiceSlot: boolean;
  // Call Service Slot
}

const initialState: CheckoutState = {
  ref: '',
  result: undefined,
  isLoadingShipment: false,
  errorShipment: undefined,
  errorStock: undefined,
  okTaxinvoice: true,
  receiveWith: 'E',
  currentPage: 'shipment',
  isLoadingChangeExpress: false,
  isLoadingCallServiceSlot: false,
};

export const changeExpress = createAsyncThunk(
  'shipment/changeExpress',
  async ({
    slotCode,
    lang,
    isExpress,
  }: {
    slotCode: string;
    lang?: string;
    isExpress: boolean;
  }): Promise<SamedayChangeResponse | null | undefined> => {
    Cookies.set(cookieKey.isExpress, (isExpress === true).toString());
    const response = await samedayChange({
      slotcode: slotCode,
      isExpress,
      lang,
    });
    if (response.data === null || response.status !== 200) {
      throw new Error(`${response?.status ?? '500.'}`);
    }
    return response.data;
  },
);

const shipmentSlice = createSlice({
  name: 'shipment',
  initialState: initialState,
  reducers: {
    nextPayment: (state) => {
      if (state.result?.checkStockResponse?.isError === true) {
        state.errorStock = state.result?.checkStockResponse;
        return;
      }
      if (
        state.result?.shippingType === ShippingType.delivery &&
        state.result?.addressList?.find((e) => e.isDefaultShip) === undefined
      ) {
        state.errorNextPay = { type: ErrorNextPay.deliveryAddress };
        return;
      }
      if (
        state.result?.shippingType === ShippingType.pickUp &&
        state.result?.addressList?.find((e) => e.isDefaultShip) === undefined &&
        (state.result?.skuWithServiceList ?? []).length > 0
      ) {
        state.errorNextPay = { type: ErrorNextPay.serviceAddress };
        return;
      }
      if (
        state.okTaxinvoice &&
        state.result?.addressList?.find((e) => e.isDefaultBill) === undefined
      ) {
        state.errorNextPay = { type: ErrorNextPay.taxinvoiceAddress };
        return;
      }
      if (state.result?.isServiceError) {
        state.errorShipment = {
          type: ErrorShipmentType.serviceBooking,
        };
        return;
      } else if (state.result?.isErrorSaveTrans) {
        state.errorShipment = {
          type: ErrorShipmentType.saveTransport,
        };
        return;
      } else if (state.result?.isErrorStoreBooking) {
        state.errorShipment = {
          type: ErrorShipmentType.storeBooking,
        };
        return;
      }
      if (state.receiveWith === 'S' && !isMobile(state.mobileReceipt ?? '')) {
        state.errorNextPay = { type: ErrorNextPay.mobile };
        return;
      } else if (
        state.receiveWith === 'E' &&
        !isEmail(state.emailReceipt ?? '')
      ) {
        state.errorNextPay = { type: ErrorNextPay.email };
        return;
      }

      const requiredSlots = [
        ...(state.result?.slots ?? []).filter(
          (e) =>
            (e.masterTimeSlots ?? []).length > 0 ||
            (e.serviceEmbed ?? []).length > 0,
        ),
      ];
      if (requiredSlots.length > 0) {
        for (let i = 0; i < requiredSlots.length; i++) {
          const requiredSlot = requiredSlots[i];
          if (
            requiredSlot.transportType !== 'KER' &&
            !requiredSlot.isLongtail &&
            !requiredSlot.isBackOrder
          ) {
            const slot = (state.selectedSlot ?? []).find(
              (item) => item.running === requiredSlot.slotrunning,
            );
            if (!slot) {
              state.errorNextPay = {
                type:
                  state.result?.shippingType === ShippingType.delivery
                    ? ErrorNextPay.deliverySlot
                    : ErrorNextPay.pickupSlot,
                running: requiredSlot.slotrunning,
              };
              return;
            }
          }
          if (requiredSlot.serviceEmbed && !requiredSlot.isBackOrder) {
            const embedSlot = (state.selectedSlot ?? []).find((item) =>
              requiredSlot.serviceEmbed
                ?.map((e) => e.slotrunning)
                .includes(item.running),
            );
            if (!embedSlot) {
              state.errorNextPay = {
                type: ErrorNextPay.serviceSlot,
                running: requiredSlot.slotrunning,
              };
              return;
            }
          }
          if (requiredSlot.serviceToCall && !requiredSlot.isBackOrder) {
            const toCallSlot = (state.selectedSlot ?? []).find((item) =>
              requiredSlot.serviceToCall
                ?.map((e) => e.slotrunning)
                .includes(item.running),
            );
            if (!toCallSlot) {
              state.errorNextPay = {
                type: ErrorNextPay.serviceSlot,
                running: requiredSlot.slotrunning,
              };
              return;
            }
          }
        }
      }
      state.currentPage = 'payment';
    },
    backToShipmentPage: (state) => {
      state.currentPage = 'shipment';
    },
    selectSlot: (state, action: { payload: SelectedSlot }) => {
      if (!state.selectedSlot) {
        state.selectedSlot = [];
      }
      const findIndex = state.selectedSlot.findIndex(
        (item) => item.running === action.payload.running,
      );
      if (findIndex > -1) {
        state.selectedSlot.splice(findIndex, 1);
      }
      state.selectedSlot.push(action.payload);
    },
    setMobileReceipt: (state, action: { payload: string }) => {
      state.mobileReceipt = action.payload;
    },
    setEmailReceipt: (state, action: { payload: string }) => {
      state.emailReceipt = action.payload;
    },
    setReceiveWith: (state, action: { payload: 'S' | 'E' }) => {
      state.receiveWith = action.payload;
    },
    setOkTaxinvoice: (state, action: { payload: boolean }) => {
      state.okTaxinvoice = action.payload;
    },
    clearError: (state) => {
      state.errorShipment = undefined;
      state.errorNextPay = undefined;
      state.errorStock = undefined;
    },
    // dispose() shipment
    clearResult: (state) => {
      state.result = undefined;
    },
    setInitialShipment: (
      state,
      action: {
        payload: {
          shipmentData?: ShipmentResponse | null;
        };
      },
    ) => {
      state.isLoadingShipment = false;
      state.selectedSlot = undefined;
      state.result = action.payload.shipmentData;
      if (action.payload.shipmentData?.orderStatus === '') {
        if ((action.payload.shipmentData?.products ?? []).length === 0) {
          state.errorShipment = {
            message: ``,
            redirect: '/cart',
          };
          state.result = undefined;
          return;
        }
        if (action.payload.shipmentData?.isServiceError) {
          state.errorShipment = {
            type: ErrorShipmentType.serviceBooking,
          };
        } else if (action.payload.shipmentData?.isErrorSaveTrans) {
          state.errorShipment = {
            type: ErrorShipmentType.saveTransport,
          };
        } else if (action.payload.shipmentData?.isErrorStoreBooking) {
          state.errorShipment = {
            type: ErrorShipmentType.storeBooking,
          };
        } else if (
          (action.payload.shipmentData?.voucherUsedList ?? []).find(
            (e) => e.isError,
          )
        ) {
          state.errorShipment = {
            type: ErrorShipmentType.reserveVoucher,
          };
        } else {
          state.errorShipment = undefined;
        }
        state.emailReceipt = action.payload.shipmentData?.info?.email;
        if ((action.payload.shipmentData?.info?.email ?? '-') === '-') {
          state.receiveWith = 'S';
        }
        state.mobileReceipt = action.payload.shipmentData?.info?.mobile;
        if ((action.payload.shipmentData?.info?.mobile ?? '-') === '-') {
          state.receiveWith = 'E';
        }
        state.ref = action.payload.shipmentData?.ref ?? '';
        try {
          const sameDaySlot = action.payload.shipmentData?.slots?.find(
            (e) =>
              e.sameDayObject?.status !== 0 && e.sameDayObject?.data !== null,
          );
          if (sameDaySlot) {
            state.isSelectedExpress = action.payload.shipmentData?.isExpress;
          } else {
            state.isSelectedExpress = false;
          }
        } catch (_) {}
        state.errorStock = action.payload.shipmentData?.checkStockResponse;
      } else if (action.payload.shipmentData?.orderStatus === 'W') {
        ServerCookies.remove(cookieKey.ref); // TODO: maybe be await
        Cookies.remove(cookieKey.cartCount);
        state.errorShipment = {
          message: 'pendingorder|',
          redirect: `/paymentfail?order=${state.ref}`,
        };
        state.result = undefined;
      } else if (
        action.payload.shipmentData?.checkStockResponse !== undefined
      ) {
        state.errorStock = action.payload.shipmentData?.checkStockResponse;
        state.result = undefined;
      } else if (action.payload !== null) {
        state.errorShipment = {
          message: ``,
          redirect: '/cart',
        };
        state.result = undefined;
      }
    },
    setLoadingCallServiceSlot: (
      state,
      action: {
        payload: { isLoading: boolean; mainRunningToClearChildren?: number };
      },
    ) => {
      state.isLoadingCallServiceSlot = action.payload.isLoading;
      if (action.payload.mainRunningToClearChildren !== undefined) {
        const mainSlot: Slot | undefined = state.result?.slots?.find(
          (e) => e.slotrunning === action.payload.mainRunningToClearChildren,
        );
        if (mainSlot) {
          const serviceSlotList = mainSlot.serviceToCall
            ?.map((e) => e.slotrunning)
            .filter((e) => e !== undefined);
          if (serviceSlotList && serviceSlotList.length > 0) {
            state.selectedSlot = state.selectedSlot?.filter(
              (e) => !serviceSlotList.includes(e.running),
            );
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changeExpress.pending, (state) => {
        state.errorChangeExpress = undefined;
        state.isLoadingChangeExpress = true;
        const sameDaySlot: Slot | undefined = state.result?.slots?.find(
          (e) =>
            e.sameDayObject?.status !== 0 && e.sameDayObject?.data !== null,
        );
        if (sameDaySlot) {
          const sameDayServiceSlotList = sameDaySlot.serviceEmbed
            ?.map((e) => e.slotrunning)
            .filter((e) => e !== undefined);
          if (sameDayServiceSlotList && sameDayServiceSlotList.length > 0) {
            state.selectedSlot = state.selectedSlot?.filter(
              (e) => !sameDayServiceSlotList.includes(e.running),
            );
          }
        }
      })
      .addCase(changeExpress.fulfilled, (state, action) => {
        state.errorChangeExpress = undefined;
        state.isLoadingChangeExpress = false;
        if (action.payload && state.result) {
          let mapResult = { ...state.result };
          if (action.payload.products) {
            mapResult.products = action.payload.products;
          }
          mapResult.shippingDiscountShow = action.payload.shippingDiscount
            ? numberWithCommas(action.payload.shippingDiscount)
            : undefined;
          mapResult.discountExpressFeeShow = action.payload.discountExpressFee
            ? numberWithCommas(action.payload.discountExpressFee)
            : undefined;
          mapResult.discountVoucherShow = action.payload.discountVoucher
            ? numberWithCommas(action.payload.discountVoucher)
            : undefined;
          mapResult.discountStaffShow = action.payload.discountStaff
            ? numberWithCommas(action.payload.discountStaff)
            : undefined;
          mapResult.discountT1PointShow = action.payload.discountT1Point
            ? numberWithCommas(action.payload.discountT1Point)
            : undefined;
          mapResult.sumTotalShow = action.payload.sumTotal
            ? numberWithCommas(action.payload.sumTotal)
            : '0';
          mapResult.totalAmountShow = action.payload.totalAmount
            ? numberWithCommas(action.payload.totalAmount)
            : '0';
          state.isSelectedExpress = action.meta.arg.isExpress;
          state.result = mapResult;
        } else {
          state.errorChangeExpress = '500';
        }
      })
      .addCase(changeExpress.rejected, (state, action) => {
        state.errorChangeExpress = action.error.message;
        state.isLoadingChangeExpress = false;
      });
  },
});

export const {
  nextPayment,
  backToShipmentPage,
  selectSlot,
  setMobileReceipt,
  setEmailReceipt,
  setReceiveWith,
  setOkTaxinvoice,
  clearError,
  clearResult,
  setInitialShipment,
  setLoadingCallServiceSlot,
} = shipmentSlice.actions;

export const shipmentResultSelector = (
  store: RootState,
): ShipmentResponse | undefined | null => store.shipment.result;

export const isLoadingShipmentSelector = (store: RootState): boolean =>
  store.shipment.isLoadingShipment;

export const errorShipmentSelector = (
  store: RootState,
): ErrorShipment | undefined => store.shipment.errorShipment;

// export const okTaxinvoiceSelector = (): boolean => true; //force true PWB-551
export const okTaxinvoiceSelector = (store: RootState): boolean =>
  store.shipment.okTaxinvoice;

export const receiveWithSelector = (store: RootState): 'S' | 'E' =>
  store.shipment.receiveWith;

export const emailReceiptSelector = (store: RootState): string | undefined =>
  store.shipment.emailReceipt;

export const mobileReceiptSelector = (store: RootState): string | undefined =>
  store.shipment.mobileReceipt;

export const currentPageSelector = (store: RootState): 'shipment' | 'payment' =>
  store.shipment.currentPage;

export const selectedSlotSelector = (
  store: RootState,
): SelectedSlot[] | undefined => store.shipment.selectedSlot;

export const errorNextPaySelector = (
  store: RootState,
): { type: ErrorNextPay; running?: number; message?: string } | undefined =>
  store.shipment.errorNextPay;

export const isSelectedExpressSelector = (
  store: RootState,
): boolean | undefined => store.shipment.isSelectedExpress;

export const isLoadingChangeExpressSelector = (store: RootState): boolean =>
  store.shipment.isLoadingChangeExpress;

export const errorChangeExpressSelector = (
  store: RootState,
): string | undefined => store.shipment.errorChangeExpress;

export const isLoadingCallServiceSlotSelector = (store: RootState): boolean =>
  store.shipment.isLoadingCallServiceSlot;

export const errorStockSelector = (
  store: RootState,
): CheckStockSkuResponse | undefined => store.shipment.errorStock;

export default shipmentSlice.reducer;
