import { currencyCode } from '@/constants/defaultValue.constant';
import { customerIdSelector } from '@/store/slices/loginSlice';
import {
  clearProductList,
  productListToSendSelector,
} from '@/store/slices/productViewSendSlice';
import { useAppDispatch } from '@/store/store';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function ProductListGtmWrapper() {
  const customerId = useSelector(customerIdSelector);
  const productListToSend = useSelector(productListToSendSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!(window as any).dataLayer || customerId === undefined) return;

    const sendProductListToGtm = () => {
      const groupedProducts: any = {};
      productListToSend.forEach((product) => {
        const listName = product.listName ?? '';
        if (!groupedProducts[listName]) {
          groupedProducts[listName] = [];
        }
        groupedProducts[listName].push({
          name: product.name,
          id: product.sku,
          price: product.price ? product.price.replace(/,/g, '') : '0',
          brand: product.brand ?? '',
          list: listName,
          category: product.catName ?? '',
          quantity: 1,
          position: product.position ?? 0,
        });
      });

      Object.keys(groupedProducts).forEach((listName) => {
        (window as any).dataLayer.push({ ecommerce: null });
        (window as any).dataLayer.push({
          event: 'productView',
          userId: customerId,
          ecommerce: {
            currencyCode: currencyCode,
            list: listName,
            products: groupedProducts[listName],
          },
        });
      });

      dispatch(clearProductList());
    };

    const sendTimeout = setTimeout(sendProductListToGtm, 1500);

    return () => {
      clearTimeout(sendTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productListToSend, customerId]);
  return <></>;
}
