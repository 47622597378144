import { MyCartResponse } from '@/models/MyCart.model';
import {
  EditCartType,
  editCartService,
  getClearCart,
  getMyCartData,
  getUsedVoucherList,
  editCartTradeinService,
  getRemobie,
} from '@/services/client/myCartService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import * as ServerCookies from '@/services/client/cookieService';
import Cookies from 'js-cookie';
import * as cookieKey from '@/constants/cookieKey.constant';
import { ProductModel } from '@/models/Product.model';
import { MyUsedVoucherListResponse } from '@/models/profile/MyVoucher.model';
import { skuCheckStock } from '@/services/client/checkoutService';
import { CheckStockSkuResponse } from '@/models/Stock.model';
import { MyRemobieModel } from '@/models/profile/MyRemobie.model';

interface MyCartState {
  ref: string;
  result?: MyCartResponse;
  isLoadingCart: boolean;
  errorCart?: string;
  isLoadingClearCart: boolean;
  errorClearCart?: string;
  isLoadingEditCart: boolean;
  errorEditCart?: string;
  editCartSuccessSku?: ProductModel[];
  voucherUsedResult?: MyUsedVoucherListResponse | null;
  isLoadingGoToCheckOut: boolean;
  errorGoToCheckOut?: CheckStockSkuResponse | null;
  remobieUsedResult?: MyRemobieModel | null;
}

const initialState: MyCartState = {
  ref: '',
  result: undefined,
  isLoadingCart: false,
  errorCart: undefined,
  isLoadingClearCart: false,
  errorClearCart: undefined,
  isLoadingEditCart: false,
  errorEditCart: undefined,
  voucherUsedResult: undefined,
  isLoadingGoToCheckOut: false,
  remobieUsedResult: undefined,
};

export const queryMyCartUsedVoucher = createAsyncThunk(
  'mycart/queryMyCartUsedVoucher',
  async ({ lang }: { lang?: string }) => {
    const voucherUsed = await getUsedVoucherList({ lang });
    return voucherUsed;
  },
);

export const queryMyRemobie = createAsyncThunk(
  'mycart/queryMyRemobie',
  async ({ lang }: { lang?: string }) => {
    const remobieUsed = await getRemobie({ lang });
    return remobieUsed;
  },
);

export const myCartQuery = createAsyncThunk(
  'mycart/query',
  async ({ lang }: { lang?: string }) => {
    const [response, voucherUsed] = await Promise.all([
      getMyCartData({ lang }),
      getUsedVoucherList({ lang }),
    ]);
    const [remobieUsed] = await Promise.all([getRemobie({ lang })]);
    return { response, voucherUsed, remobieUsed };
  },
);

export const clearCart = createAsyncThunk(
  'mycart/clearCart',
  async ({ dispatch, lang }: { dispatch: any; lang?: string }) => {
    const response = await getClearCart();
    await ServerCookies.remove(cookieKey.ref);
    Cookies.remove(cookieKey.cartCount);
    if (!response?.data) {
      throw new Error('Clear cart failed');
    }
    if (response?.data.dbCode != true) {
      throw new Error(response?.data.dbMessage);
    }
    dispatch(myCartQuery({ lang: lang }));
    return response;
  },
);

export const editCart = createAsyncThunk(
  'mycart/editCart',
  async ({
    product,
    qty,
    type,
    dispatch,
    lang,
  }: {
    product: ProductModel;
    qty: number;
    type: EditCartType;
    dispatch: any;
    lang?: string;
  }) => {
    const response = await editCartService({
      product,
      qty,
      type,
    });
    if (!response?.dbCode) {
      throw new Error(response?.dbMessage ? response?.dbMessage : 'Error.');
    }
    dispatch(myCartQuery({ lang: lang }));
    return response;
  },
);
export const editCartTradein = createAsyncThunk(
  'mycart/editCartTradein',
  async ({
    skutradein,
    qty,
    dispatch,
    lang,
  }: {
    skutradein: string;
    qty: number;
    dispatch: any;
    lang?: string;
  }) => {
    const response = await editCartTradeinService({
      skutradein,
      qty,
      lang: lang ? lang : 'th',
    });
    if (!response?.dbCode) {
      console.log('error');
      throw new Error(response?.dbMessage ? response?.dbMessage : 'Error.');
    }
    dispatch(myCartQuery({ lang: lang }));
    return response;
  },
);

export const goToCheckOut = createAsyncThunk(
  'mycart/goToCheckOut',
  async ({ lang }: { lang?: string }) => {
    const checkStockData = await skuCheckStock({ lang });
    return checkStockData.data;
  },
);

const myCartSlice = createSlice({
  name: 'mycart',
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.errorCart = undefined;
      state.errorClearCart = undefined;
      state.errorEditCart = undefined;
      state.errorGoToCheckOut = undefined;
    },
    clearResult: (state) => {
      state.result = undefined;
    },
  },
  extraReducers: (builder) => {
    // fulfilled, rejected
    builder
      .addCase(queryMyCartUsedVoucher.fulfilled, (state, action) => {
        state.voucherUsedResult = action.payload;
      })
      .addCase(queryMyCartUsedVoucher.rejected, (state) => {
        state.voucherUsedResult = undefined;
      });
    builder
      .addCase(queryMyRemobie.fulfilled, (state, action) => {
        state.remobieUsedResult = action.payload;
      })
      .addCase(queryMyRemobie.rejected, (state) => {
        state.remobieUsedResult = undefined;
      });
    // pending, fulfilled, rejected
    builder
      .addCase(myCartQuery.pending, (state) => {
        state.errorCart = undefined;
        state.isLoadingCart = true;
      })
      .addCase(myCartQuery.fulfilled, (state, action) => {
        if (action.payload.response?.diffStore) {
          state.errorCart = action.payload.response?.diffStore;
        } else {
          state.errorCart = undefined;
        }
        state.result = action.payload.response;
        state.voucherUsedResult = action.payload.voucherUsed;
        state.isLoadingCart = false;
        state.ref = action.payload.response?.ref ?? '';
        state.isLoadingEditCart = false;
        state.errorEditCart = undefined;
        state.remobieUsedResult = action.payload.remobieUsed;
      })
      .addCase(myCartQuery.rejected, (state, action) => {
        state.errorCart = action.error.message;
        state.result = undefined;
        state.isLoadingCart = false;
        Cookies.remove(cookieKey.cartCount);
      });
    // pending, fulfilled, rejected
    builder
      .addCase(clearCart.pending, (state) => {
        state.isLoadingClearCart = true;
        state.result = undefined;
        state.isLoadingCart = true;
        state.errorClearCart = undefined;
      })
      .addCase(clearCart.fulfilled, (state) => {
        state.isLoadingClearCart = false;
        state.errorClearCart = undefined;
        state.result = undefined;
        state.isLoadingCart = false;
      })
      .addCase(clearCart.rejected, (state) => {
        state.isLoadingClearCart = false;
        state.errorClearCart = undefined;
        state.result = undefined;
        state.isLoadingCart = false;
      });
    // pending, fulfilled, rejected
    builder
      .addCase(editCart.pending, (state) => {
        state.isLoadingEditCart = true;
        state.errorEditCart = undefined;
        state.editCartSuccessSku = undefined;
      })
      .addCase(editCart.fulfilled, (state, action) => {
        state.editCartSuccessSku = action.payload.dbItems.map((e) => {
          e.qty = action.payload.qty;
          return e;
        });
      })
      .addCase(editCart.rejected, (state, action) => {
        state.isLoadingEditCart = false;
        state.errorEditCart = action.error.message;
        state.editCartSuccessSku = undefined;
      });
    // pending, fulfilled, rejected
    builder
      .addCase(goToCheckOut.pending, (state) => {
        state.isLoadingGoToCheckOut = true;
        state.errorGoToCheckOut = undefined;
      })
      .addCase(goToCheckOut.fulfilled, (state, action) => {
        state.isLoadingGoToCheckOut = false;
        if (action.payload.isError === true) {
          state.errorGoToCheckOut = {
            message: action.payload.message,
            products: action.payload.products,
          };
        } else {
          state.errorGoToCheckOut = null;
        }
      })
      .addCase(goToCheckOut.rejected, (state, action) => {
        state.isLoadingGoToCheckOut = false;
        state.errorGoToCheckOut = {
          message: action.error.message,
        };
      });
    builder
      .addCase(editCartTradein.pending, (state) => {
        state.isLoadingEditCart = true;
        state.errorEditCart = undefined;
        state.editCartSuccessSku = undefined;
      })
      .addCase(editCartTradein.fulfilled, (state, action) => {
        state.editCartSuccessSku = action.payload.dbItems.map((e) => {
          e.qty = action.payload.qty;
          return e;
        });
      })
      .addCase(editCartTradein.rejected, (state, action) => {
        state.isLoadingEditCart = false;
        state.errorEditCart = action.error.message;
        state.editCartSuccessSku = undefined;
      });
  },
});

export const { clearError, clearResult } = myCartSlice.actions;

export const myCartResultSelector = (
  store: RootState,
): MyCartResponse | undefined => store.myCart.result;

export const isLoadingCartSelector = (store: RootState): boolean =>
  store.myCart.isLoadingCart;

export const errorCartSelector = (store: RootState): string | undefined =>
  store.myCart.errorCart;

export const isLoadingClearCartSelector = (store: RootState): boolean =>
  store.myCart.isLoadingClearCart;

export const errorClearCartSelector = (store: RootState): string | undefined =>
  store.myCart.errorClearCart;

export const isLoadingEditCartSelector = (store: RootState): boolean =>
  store.myCart.isLoadingEditCart;

export const errorEditCartSelector = (store: RootState): string | undefined =>
  store.myCart.errorEditCart;

export const editCartSuccessSkuSelector = (
  store: RootState,
): ProductModel[] | undefined => store.myCart.editCartSuccessSku;

export const voucherUsedResultSelector = (
  store: RootState,
): MyUsedVoucherListResponse | undefined | null =>
  store.myCart.voucherUsedResult;

export const isLoadingGoToCheckOutSelector = (store: RootState): boolean =>
  store.myCart.isLoadingGoToCheckOut;

export const errorGoToCheckOutSelector = (
  store: RootState,
): CheckStockSkuResponse | undefined | null => store.myCart.errorGoToCheckOut;

export const remobieUsedResultSelector = (
  store: RootState,
): MyRemobieModel | undefined | null => store.myCart.remobieUsedResult;

export default myCartSlice.reducer;
