import { modelValue } from '@/utils/modelValue';
import {
  CookieData,
  CookieDataConverter,
  CustomerInfo,
  CustomerInfoConverter,
} from '../Authen.model';

export interface MyWarrantyResponse {
  limit?: number;
  page?: number;
  totalwarranty?: number;
  alllistwarranty?: Alllistwarranty[] | [];
  info?: CustomerInfo | null;
  cookies?: CookieData[] | null;
}

export interface Alllistwarranty {
  custid?: string;
  ref?: string;
  receivedate?: string;
  skcode?: number;
  prname?: string;
  base64?: string;
  slugname?: string;
  warrantymonth?: string;
  warrantyexpired?: string;
  isexpired?: boolean;
  iswarrantyyear?: boolean;
  warrantynumber?: number;
}
export class MyWarrantyResponseConverter {
  public static fromJson(json: any): MyWarrantyResponse {
    const model: MyWarrantyResponse = {
      limit: Number(json['limit']),
      page: Number(json['page']),
      totalwarranty: Number(json['totalwarranty']),
      alllistwarranty: json['alllistwarranty']
        ? AlllistwarrantyConverter.fromJson(json['alllistwarranty'])
        : [],
      info: json['info'] ? CustomerInfoConverter.fromJson(json['info']) : null,
      cookies: json['cookies']
        ? json['cookies'].map((x: any) => CookieDataConverter.fromJson(x))
        : undefined,
    };
    return modelValue(model);
  }
}
export class AlllistwarrantyConverter {
  public static fromJson(json: any): Alllistwarranty[] | [] {
    if (Array.isArray(json)) {
      return json.map((item: any) => {
        return {
          custid: item['custid'],
          ref: item['ref'],
          receivedate: item['receivedate'],
          skcode: item['skcode'],
          prname: item['prname'],
          base64: item['base64'],
          slugname: item['slugname'],
          warrantymonth: item['warrantymonth'],
          warrantyexpired: item['warrantyexpired'],
          isexpired: item['isexpired'],
          iswarrantyyear: item['iswarrantyyear'],
          warrantynumber: item['warrantynumber'],
        };
      });
    }
    return [];
  }
}
