import {
  PropStatus,
  Property,
  PropsDataToUse,
  SpuDataToUse,
  Spubysku,
} from '@/models/ProductDetailPage.model';
import { RootState } from '@/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { subStringList } from '@/constants/defaultValue.constant';

// const exceptList: string[] = ['S', 'M', 'X', 'Q'];
// const subStringList: string[] = ['Z'];
// const exceptsubStringList: string[] = ['Style', 'Series'];

const exceptPropLowerList: string[] = ['', '-', 'all'];

interface SelectedSpuState {
  currentSku?: string;
  properties?: Property[];
  spuBySkus?: Spubysku[];
  selectedList?: PropsDataToUse[];
  propertyList: SpuDataToUse[];
  slugname?: string;
}

const initialState: SelectedSpuState = {
  currentSku: undefined,
  properties: undefined,
  spuBySkus: undefined,
  selectedList: undefined,
  propertyList: [],
  slugname: undefined,
};

const genPropertyList = ({
  currentSku,
  properties,
  spuBySkus,
  selectedList,
}: {
  currentSku?: string;
  properties?: Property[];
  spuBySkus?: Spubysku[];
  selectedList?: PropsDataToUse[];
}) => {
  const propertyList: SpuDataToUse[] = [];
  if (
    currentSku &&
    properties &&
    properties.length > 0 &&
    spuBySkus &&
    spuBySkus.length > 0
  ) {
    const conditionedProperties = properties?.filter(
      (property) =>
        property.name &&
        property.name.trim() !== '' &&
        property.propertyValueList &&
        property.propertyValueList.length > 0 &&
        subStringList.includes(property.name.substring(0, 1)),
      // (!exceptList.includes(property.name.substring(0, 1)) ||
      //   exceptsubStringList.includes(property.name)),
    );
    // .map((e) => {
    //   if (e.name && subStringList.includes(e.name.substring(0, 1))) {
    //     e.name = e.name.substring(1).trim();
    //   }
    //   return e;
    // });
    const thisSkuProperty = spuBySkus.find((e) => e.sku == currentSku);
    for (let i = 0; i < conditionedProperties.length; i++) {
      const property: Property = conditionedProperties[i];
      const propValueList: PropsDataToUse[] = [];
      for (let j = 0; j < property.propertyValueList!.length; j++) {
        const propertyValue: string = property.propertyValueList![j];
        if (
          !exceptPropLowerList.includes(propertyValue.toLowerCase()) &&
          spuBySkus.find(
            (e) =>
              e.prop &&
              e.prop.map((e) => e.toLowerCase().trim())[property.index ?? 0] ==
                propertyValue.toLowerCase(),
          )
        ) {
          let propStatus = PropStatus.notSelected;
          if (selectedList) {
            const findMatchProp = selectedList.find(
              (e) =>
                e.propertyIndex === property.index &&
                e.name.toLowerCase() === propertyValue.toLowerCase(),
            );
            if (findMatchProp) {
              propStatus = PropStatus.selected;
            }
          } else {
            if (thisSkuProperty) {
              const findMatchProp =
                thisSkuProperty.prop?.[property.index ?? 0]?.toLowerCase() ===
                propertyValue.toLowerCase();
              if (findMatchProp) {
                propStatus = PropStatus.selected;
              }
            }
          }
          propValueList.push({
            propertyIndex: property.index ?? 0,
            index: j,
            name: propertyValue,
            status: propStatus,
          });
        }
      }
      if (propValueList.length > 1) {
        if (propValueList.find((e) => e.status === PropStatus.selected)) {
          propValueList.forEach((e) => {
            if (e.status !== PropStatus.selected) {
              e.status = PropStatus.disabled;
            }
            return e;
          });
        }
        propertyList.push({
          index: property.index ?? 0,
          lable: property.name!,
          props: propValueList,
        });
      }
    }
    if (propertyList.length > 0 && selectedList) {
      const filteredSpuBySkus: Spubysku[] = [];
      for (let i = 0; i < spuBySkus.length; i++) {
        const spuBySku = spuBySkus[i];
        let isMatch = true;
        for (let j = 0; j < selectedList.length; j++) {
          const selectedProp = selectedList[j];
          if (
            spuBySku.prop?.[selectedProp.propertyIndex ?? 0]?.toLowerCase() !==
            selectedProp.name.toLowerCase()
          ) {
            isMatch = false;
            break;
          }
        }
        if (isMatch) {
          filteredSpuBySkus.push(spuBySku);
        }
      }
      if (filteredSpuBySkus.find((e) => e.sku === currentSku)) {
        propertyList.forEach((e) => {
          e.props.forEach((prop) => {
            const findMatchPropInFilteredSpuBySkus = filteredSpuBySkus.find(
              (f) => {
                return (
                  f.prop?.[prop.propertyIndex ?? 0]?.toLowerCase() ===
                  prop.name.toLowerCase()
                );
              },
            );
            if (prop.status !== PropStatus.selected) {
              if (findMatchPropInFilteredSpuBySkus) {
                prop.status = PropStatus.notSelected;
              } else {
                prop.status = PropStatus.disabled;
              }
            }
            return prop;
          });
          return e;
        });
      } else {
        if (filteredSpuBySkus.length > 0) {
          return {
            propertyList,
            slugname: filteredSpuBySkus[0].slugname,
            currentSku: filteredSpuBySkus[0].sku,
          };
        } else if (selectedList.length > 0) {
          //find spuBySkus is match selectedList except last object in selectedList[selectedList.length - 1]
          const lastSelected = selectedList[selectedList.length - 1];
          const findMatchSpuBySkus = spuBySkus.find((e) => {
            if (
              e.prop &&
              e.prop?.[lastSelected.propertyIndex ?? 0]?.toLowerCase() ===
                lastSelected.name.toLowerCase()
            ) {
              return true;
            } else {
              return false;
            }
          });
          return {
            propertyList,
            slugname: findMatchSpuBySkus?.slugname,
            currentSku: findMatchSpuBySkus?.sku,
          };
        }
      }
      // return { propertyList, currentSku };
    }
  }
  return { propertyList, slugname: undefined, currentSku };
};

const SelectedSpuSlice = createSlice({
  name: 'SelectedSpu',
  initialState,
  reducers: {
    select: (state, action: PayloadAction<PropsDataToUse>) => {
      if (state.propertyList.length > 0) {
        const findProperty = state.propertyList.find(
          (e) => e.index === action.payload.propertyIndex,
        );
        const findProp = findProperty?.props.find(
          (e) => e.index === action.payload.index,
        );
        let selectedList: PropsDataToUse[] = state.propertyList
          .map((e) => {
            return e.props.find((e) => e.status === PropStatus.selected);
          })
          .filter((e) => e !== undefined) as PropsDataToUse[];
        if (findProp) {
          switch (findProp.status) {
            case PropStatus.selected:
              selectedList = selectedList.filter(
                (e) => e.propertyIndex !== findProp.propertyIndex,
              );
              break;
            default:
              selectedList = selectedList.filter(
                (e) => e.propertyIndex !== findProp.propertyIndex,
              );
              selectedList.push(findProp);
              break;
          }
        }
        const { propertyList, slugname, currentSku } = genPropertyList({
          currentSku: state.currentSku,
          properties: state.properties,
          spuBySkus: state.spuBySkus,
          selectedList,
        });
        state.selectedList = selectedList;
        state.propertyList = propertyList;
        if (slugname) {
          state.slugname = slugname;
          state.currentSku = currentSku;
        }
      }
    },
    initPropertyList: (
      state,
      action: PayloadAction<{
        currentSku?: string;
        properties?: Property[];
        spuBySkus?: Spubysku[];
      }>,
    ) => {
      if (state.propertyList.length === 0) {
        const { currentSku, properties, spuBySkus } = action.payload;
        state.currentSku = currentSku;
        state.properties = properties;
        state.spuBySkus = spuBySkus;
        state.propertyList = genPropertyList({
          currentSku,
          properties,
          spuBySkus,
        }).propertyList;
      }
    },
    setToInitial: (state) => {
      state.currentSku = initialState.currentSku;
      state.selectedList = initialState.selectedList;
      state.propertyList = initialState.propertyList;
      state.properties = initialState.properties;
      state.spuBySkus = initialState.spuBySkus;
      state.slugname = initialState.slugname;
    },
    newSpuPropertyList: (
      state,
      action: PayloadAction<{
        currentSku?: string;
        properties?: Property[];
        spuBySkus?: Spubysku[];
      }>,
    ) => {
      state.slugname = undefined;
      const { currentSku, properties, spuBySkus } = action.payload;
      let selectedList: PropsDataToUse[] | undefined = [];
      if (state.selectedList?.find((e) => e.status === 2) === undefined) {
        selectedList = state.selectedList;
      } else if (state.selectedList && state.selectedList.length > 0) {
        selectedList = [state.selectedList![state.selectedList.length - 1]];
      }
      state.currentSku = currentSku;
      state.properties = properties;
      state.spuBySkus = spuBySkus;
      state.propertyList = genPropertyList({
        currentSku,
        properties,
        spuBySkus,
        selectedList: selectedList,
      }).propertyList;
    },
  },
});

export const { select, initPropertyList, setToInitial, newSpuPropertyList } =
  SelectedSpuSlice.actions;

export const spuSelectedSelector = (
  store: RootState,
): PropsDataToUse[] | undefined => store.selectedSpu.selectedList;

export const slugnameSelector = (store: RootState): string | undefined =>
  store.selectedSpu.slugname;

export const currentSkuSelector = (store: RootState): string | undefined =>
  store.selectedSpu.currentSku;

export const propertyListSelector = (store: RootState): SpuDataToUse[] =>
  store.selectedSpu.propertyList;

export default SelectedSpuSlice.reducer;
