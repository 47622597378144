import { modelValue } from '@/utils/modelValue';
import { ProductModel, ProductModelConverter } from './Product.model';

export interface CheckStockSkuResponse {
  isError?: boolean;
  message?: string;
  products?: ProductModel[];
  canDelivery?: boolean;
  canPickup?: boolean;
}

export class CheckStockSkuResponseConverter {
  public static fromJson(json: any): CheckStockSkuResponse {
    const model: CheckStockSkuResponse = {
      isError: json['isError'] == true ? true : false,
      message: json['message'],
      products: json['products']?.map((x: any) =>
        ProductModelConverter.fromJson(x),
      ),
      canDelivery: json['canDelivery'] == true ? true : false,
      canPickup: json['canPickup'] == true ? true : false,
    };
    return modelValue(model);
  }
}
