import '@/styles/globals.css';
import 'tailwindcss/tailwind.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../public/assets/css/smartbanner.css';
import SmartBanner from '../public/assets/js/smartbanner/smartbanner';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import { store } from '@/store/store';
import { Provider } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material';
import Layout from '@/components/Layout';
import AllCatContext from '@/contexts/AllCatContext';
import { SWRConfig } from 'swr';
import Script from 'next/script';
import Head from 'next/head';
import React, { useEffect, useState } from 'react';
import { AllCat, AllCatConverter } from '@/models/AllCat.model';
import { getAllCats } from '@/services/client/apiService';
import { useRouter } from 'next/router';
import * as ServerCookies from '@/services/client/cookieService';
import * as cookieKey from '@/constants/cookieKey.constant';
import InsiderUser from '@/components/insider/InsiderUser';
function App({ Component, pageProps }: AppProps) {
  const [allcats, setAllcats] = useState<AllCat[]>([]);
  // const [orgUrl, setOrgUrl] = useState('');
  const [fullUrl, setFullUrl] = useState('');
  const [fullUrlAlternateTH, setFullUrlAlternateTH] = useState('');
  const [fullUrlAlternateEN, setFullUrlAlternateEN] = useState('');
  const router = useRouter();
  const { query } = router;
  const { clickid } = router.query;
  useEffect(() => {
    const path =
      typeof window !== 'undefined'
        ? decodeURIComponent(window.location.pathname).replace(/\/+/g, '/')
        : '';
    const org =
      typeof window !== 'undefined'
        ? decodeURIComponent(window.location.origin)
        : '';
    let pathname_without_lang = path.replace(/\/th/, '');
    pathname_without_lang = pathname_without_lang.replace(/\/en/, '');
    // setOrgUrl(`${org}`);
    setFullUrl(`${org}${path}`);
    if (pathname_without_lang === '/') {
      pathname_without_lang = '';
    }
    setFullUrlAlternateTH(`${org}/th${pathname_without_lang}`);
    setFullUrlAlternateEN(`${org}/en${pathname_without_lang}`);

    //Start smartbanner
    let smartbanner;
    // const uriScheme = 'powerbuy://';
    smartbanner = new SmartBanner();
    // smartbanner.checkApp();
    smartbanner.publish();
    //End smartbanner
    async function initAndFetch() {
      const BranchSDK = (await import('branch-sdk')).default;
      BranchSDK.init(process.env.NEXT_PUBLIC_BRANCH_KEY!);
    }

    initAndFetch();
  }, [router]);
  useEffect(() => {
    if (clickid !== '' && clickid !== undefined) {
      const tune_id = clickid.toString();
      if (tune_id && tune_id != '') {
        const date_limit = Number(process.env.NEXT_PUBLIC_TUNE_ID_EXPIRED_DATE);
        ServerCookies.setMaxAge(
          cookieKey.tuneId,
          tune_id,
          date_limit ? date_limit : 30,
        );
      }
    }
  }, [clickid]);
  // useEffect(() => {
  //   const fetchTuneId = async () => {
  //     const id = await ServerCookies.get(cookieKey.tuneId);
  //     setTuneId(id ? String(id) : '');
  //   };

  //   fetchTuneId();
  // }, []);

  let hasOtherKey = Object.keys(query).some(
    (key) =>
      key !== 'slug' &&
      key !== 'page' &&
      key !== 'brand' &&
      key !== 'hotcat' &&
      key !== 'keyword',
  );
  let hasPageKey = Object.keys(query).some((key) => key === 'page');

  const theme = createTheme({
    components: {},
    breakpoints: {
      values: {
        xs: 0,
        sm: 480,
        md: 768,
        lg: 976,
        xl: 1360,
      },
    },
    typography: {
      fontFamily: 'helvethaicax',
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    spacing: 8,
    palette: {
      primary: {
        main: '#4E1F66',
      },
      secondary: {
        main: '#FFFFFF',
      },
      background: {
        default: '#FE5000',
      },
    },
  });

  // Global SWR Config
  const swrConfig = {
    // revalidateIfStale: false,
    validateOnFocus: false,
    revalidateOnFocus: false,
    // revalidateOnReconnect: false,
  };
  useEffect(() => {
    getAllCats().then((res) => {
      setAllcats((res ?? []).map((e) => AllCatConverter.fromJson(e)));
    });
  }, []);
  return (
    <>
      <Head>
        <title>
          #1 Powerbuy© - ช้อปแบรนด์ดัง! สินค้าของแท้ 100% รับประกันการจัดส่ง
        </title>
        <meta httpEquiv="Expires" content="-1" />
        <meta charSet="uft-8" />
        <link
          rel="manifest"
          href="/manifest.json"
          crossOrigin="use-credentials"
        />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#4E1F66" />
        <meta
          name="google-site-verification"
          content="Zx8ilUEpqBPafWIw0mBkNWLeEJajKJHRDHr-8EjjC7Q"
        />
        <meta name="smartbanner:title" content="Power Buy" />
        <meta name="smartbanner:author" content="ช้อปง่าย สบายเวอร์" />
        <meta name="smartbanner:price" content="FREE" />
        <meta
          name="smartbanner:price-suffix-apple"
          content=" - On the App Store"
        />
        <meta
          name="smartbanner:price-suffix-google"
          content=" - In Google Play"
        />
        <meta
          name="smartbanner:icon-apple"
          content="https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/c8/9b/84/c89b8477-c07a-f3ce-34fd-3332d7276a50/AppIcon-0-0-1x_U007emarketing-0-7-0-0-85-220.png/460x0w.webp"
        />
        <meta
          name="smartbanner:icon-google"
          content="https://play-lh.googleusercontent.com/tJ0-OWjn9Bx8wjJ4FLuKyRqcVX0jalFhCcS-MKZNVzvFp3S5vGz2P04iXqagd-Dc4Eam=w480-h960-rw"
        />
        <meta name="smartbanner:button" content="Open" />
        <meta
          name="smartbanner:button-url-apple"
          content="https://apps.apple.com/th/app/power-buy/id1559145849?l=th"
        />
        <meta
          name="smartbanner:button-url-google"
          content="https://play.google.com/store/apps/details?id=com.powerbuy.mobile"
        />
        <meta name="smartbanner:enabled-platforms" content="android,ios" />
        <meta name="smartbanner:close-label" content="Close this banner" />
        <meta name="smartbanner:api" content="true" />
        <meta
          name="apple-itunes-app"
          content="app-id=1559145849, affiliate-data=powerbuy, app-argument=https://apps.apple.com/th/app/power-buy/id1559145849?l=th"
        />
        <meta
          name="apple-itunes-app"
          content="app-id=1559145849, app-clip-bundle-id=1559145849.com.app.Powerbuy, app-clip-display=card"
        />
        <link
          rel="apple-touch-icon"
          href="https://dxdqngy5snf3t.cloudfront.net/icon.png"
        />
        <link
          rel="shortcut icon"
          href="https://dxdqngy5snf3t.cloudfront.net/favicon.ico"
          type="image/x-icon"
        />
        <link rel="canonical" href={fullUrl} />
        <meta property="og:locale" content="th_TH" />
        <meta property="og:locale:alternate" content="en_US" />
        <link rel="alternate" href={fullUrlAlternateEN} hrefLang="en-th" />
        <link rel="alternate" href={fullUrlAlternateTH} hrefLang="th-th" />
        <link rel="alternate" href={fullUrlAlternateTH} hrefLang="x-default" />
        {process.env.NEXT_PUBLIC_NODE_ENV == 'production' ? (
          hasPageKey ? (
            <meta name="robots" content="noindex,follow" />
          ) : (
            <>
              {hasOtherKey ? (
                <meta name="robots" content="noindex,nofollow" />
              ) : (
                <meta name="robots" content="index, follow" />
              )}
            </>
          )
        ) : (
          <meta name="robots" content="noindex" />
        )}
      </Head>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}');
        (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener banner closeBanner closeJourney data deepview deepviewCta first init link logout removeListener setBranchViewData setIdentity track trackCommerceEvent logEvent disableTracking getBrowserFingerprintId crossPlatformIds lastAttributedTouchData setAPIResponseCallback qrCode setRequestMetaData".split(" "), 0);
         branch.init('${process.env.NEXT_PUBLIC_BRANCH_KEY}');
      `}
      </Script>

      <Provider store={store}>
        <InsiderUser></InsiderUser>
        <ThemeProvider theme={theme}>
          <AllCatContext.Provider value={allcats}>
            <SWRConfig
              value={(validateConfig) => ({
                ...validateConfig,
                ...swrConfig,
              })}
            >
              <Layout>
                {/* <Script
                  rel="dns-prefetch"
                  src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}`}
                />
                <Script rel="dns-prefetch" id="google-analytics">
                  {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
        
                  gtag('config', '${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}');
                `}
                </Script> */}
                <Component {...pageProps} />
              </Layout>
            </SWRConfig>
          </AllCatContext.Provider>
        </ThemeProvider>
      </Provider>
    </>
  );
}
export default appWithTranslation(App);
