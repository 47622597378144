import { modelValue } from '@/utils/modelValue';

export interface GeoLocationResponse {
  zipCode?: string;
  district?: string;
  province?: string;
  lat?: string;
  lng?: string;
  amphur?: string;
  changwat?: string;
  subdistrict?: string;
  tambon?: string;
}
export class GeoLocationResponseConverter {
  public static fromJson(json: any): GeoLocationResponse {
    const model: GeoLocationResponse = {
      zipCode: json['ZIPCODE'],
      district: json['DISTRICT'],
      province: json['PROVINCE'],
      lat: json['LAT'],
      lng: json['LNG'],
      amphur: json['AMPHUR'],
      changwat: json['CHANGWAT'],
      subdistrict: json['SUBDISTRICT'],
      tambon: json['TAMBON'],
    };
    return modelValue(model);
  }
}
