import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PageReviewState {
  pageReviewValue: number;
}

const initialState: PageReviewState = {
  pageReviewValue: 1, // Initialize the value
};

const pageReviewSlice = createSlice({
  name: 'pageReview',
  initialState,
  reducers: {
    setpageReviewValue: (state, action: PayloadAction<number>) => {
      state.pageReviewValue = action.payload;
    },
  },
});

export const {
  // ... other actions
  setpageReviewValue,
} = pageReviewSlice.actions;

export default pageReviewSlice.reducer;
