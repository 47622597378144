import { signOut } from '@/store/slices/loginSlice';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import AppLink from '../../handler/AppLink';
import { useAppDispatch } from '@/store/store';
import { useRouter } from 'next/router';

interface Props {
  customerName: string;
}

export default function CustomerNameHover({ customerName }: Props) {
  const { t } = useTranslation();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const router = useRouter();

  const handleSignOut = () => {
    dispatch(signOut());
    if ((window as any).FB) {
      (window as any).FB.getLoginStatus(function (response: any) {
        if (response && response.authResponse) {
          (window as any).FB.logout(function () {});
        }
      });
    }
    router.push(`/${router.locale}`);
  };
  return (
    <Tooltip
      open={showTooltip}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
      leaveTouchDelay={0}
      placement="bottom"
      leaveDelay={150}
      componentsProps={{
        tooltip: {
          sx: {
            p: 0,
            backgroundColor: 'white',
            color: 'black',
            width: '100%',
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
          },
          style: {
            marginTop: 0,
          },
        },
      }}
      title={
        <Box
          className="shadow-md bg-transparent w-full"
          sx={{
            width: '100%',
            maxWidth: 360,
          }}
        >
          <nav aria-label="main mailbox folders">
            <List>
              <ListItem disablePadding>
                <AppLink className="w-full h-full" href="/profile/orderhistory">
                  <ListItemButton>
                    <ListItemText
                      disableTypography
                      primary={t('orderhistory')}
                    />
                  </ListItemButton>
                </AppLink>
              </ListItem>
              <ListItem disablePadding>
                <AppLink className="w-full h-full" href="/profile/wishlist">
                  <ListItemButton>
                    <ListItemText disableTypography primary={t('wishlist')} />
                  </ListItemButton>
                </AppLink>
              </ListItem>
              <ListItem disablePadding>
                <AppLink className="w-full h-full" href="/profile/myvoucher">
                  <ListItemButton>
                    <ListItemText disableTypography primary={t('mycoupon')} />
                  </ListItemButton>
                </AppLink>
              </ListItem>
              <ListItem disablePadding>
                <AppLink className="w-full h-full" href="/profile/myreview">
                  <ListItemButton>
                    <ListItemText disableTypography primary={t('myreview')} />
                  </ListItemButton>
                </AppLink>
              </ListItem>
              <ListItem disablePadding>
                <AppLink
                  className="w-full h-full"
                  href="/profile/accountsummary"
                >
                  <ListItemButton>
                    <ListItemText
                      disableTypography
                      primary={t('editmyaccount')}
                    />
                  </ListItemButton>
                </AppLink>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={handleSignOut}>
                  <ListItemText disableTypography primary={t('signout')} />
                </ListItemButton>
              </ListItem>
            </List>
          </nav>
        </Box>
      }
    >
      <div>{customerName}</div>
    </Tooltip>
  );
}
