import { modelValue } from '@/utils/modelValue';
import {
  CookieData,
  CookieDataConverter,
  CustomerInfo,
  CustomerInfoConverter,
} from '../Authen.model';
import { ProductModel, ProductModelConverter } from '../Product.model';

export interface WishlistResponse {
  title?: string;
  sub?: string;
  products?: ProductModel[];
  count?: number;
  limit?: number;
  page?: number;
  info?: CustomerInfo | null;
  cookies?: CookieData[] | null;
}

export class WishlistResponseConverter {
  public static fromJson(json: any): WishlistResponse {
    const model: WishlistResponse = {
      title: json['title'],
      sub: json['sub'],
      products: json['wishlist']
        ? json['wishlist'].map((x: any) => ProductModelConverter.fromJson(x))
        : [],
      count: Number(json['wishcount']),
      limit: Number(json['limitwish']),
      page: Number(json['page']),
      info: json['info']
        ? CustomerInfoConverter.fromJson(json['info'])
        : undefined,
      cookies: json['cookies']
        ? json['cookies'].map((x: any) => CookieDataConverter.fromJson(x))
        : undefined,
    };
    return modelValue(model);
  }
}
