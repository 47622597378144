import React from 'react';

interface Props {
  color?: string;
  size?: number;
  borderSize?: number;
}

export default function Spinner({ size, borderSize, color }: Props) {
  return (
    <div>
      <div
        className={`spinner`}
        style={{
          width: size ?? 24,
          height: size ?? 24,
          borderColor: color ?? 'white',
          borderTopColor: 'transparent',
          borderWidth: borderSize ?? 4,
          borderTopWidth: borderSize ?? 4,
        }}
      ></div>
    </div>
  );
}
