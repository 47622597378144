import {
  AddFreeGiftModel,
  AddFreeGiftModelConverter,
} from '@/models/AddFreeGift.model';
import { postDataFromAPI } from '@/utils/httpClient';
export const addFreeGift = async ({
  lang,
  skcode,
  ctlid,
  qnt,
  seq,
  usestock,
}: {
  lang?: string;
  skcode?: number;
  ctlid?: number;
  qnt?: number;
  seq?: number;
  usestock?: string;
}): Promise<any | undefined> => {
  const [getApiData] = await Promise.all([
    postDataFromAPI({
      path: '/addfreegift',
      lang,
      body: { skcode, ctlid, qnt, seq, usestock },
    }),
  ]);
  const apiData =
    getApiData.status == 200
      ? getApiData.data
        ? getApiData.data
        : undefined
      : undefined;
  let response: AddFreeGiftModel | undefined = apiData.products;
  try {
    response = AddFreeGiftModelConverter.fromJson(apiData);
  } catch (error) {
    console.log(error);
  }
  return response;
};
