import { modelValue } from '@/utils/modelValue';
import { ProductModel, ProductModelConverter } from './Product.model';
export interface HomePageEventResponseModel {
  products?: ProductModel[];
}

export class HomePageEventResponseModelConverter {
  public static fromJson(json: any): HomePageEventResponseModel {
    const model: HomePageEventResponseModel = {
      products: json['products']
        ? json['products'].map((x: any) => ProductModelConverter.fromJson(x))
        : undefined,
    };
    return modelValue(model);
  }
}
