import {
  MyReviewResponse,
  MyReviewResponseConverter,
} from '@/models/profile/MyReview.model';
import { saveClientCookieFromResponse } from '@/utils/cookie';
import { postDataFromAPI } from '@/utils/httpClient';

export const getProfileMyReview = async ({
  lang,
  page,
  mode,
}: {
  lang?: string;
  page?: number;
  mode?: number;
}): Promise<{
  data: MyReviewResponse | null | undefined;
  status: number | null;
}> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/profile/myreview',
      lang,
      body: {
        page,
        mode,
      },
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: MyReviewResponse | null | undefined;
    status: number | null;
  } = { data: data, status: getData.status };
  if (data) {
    try {
      response.data = MyReviewResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  // ถ้าอนาคตมีคนเรียกใช้แต่ไม่อยาก save cookie ให้ย้ายไปใน Slice
  await saveClientCookieFromResponse(response.data?.cookies);
  return response;
};
