import { modelValue } from '@/utils/modelValue';
import {
  CookieData,
  CookieDataConverter,
  CustomerInfo,
  CustomerInfoConverter,
} from './Authen.model';
import { ProductModel, ProductModelConverter } from './Product.model';
import { numberWithCommas } from '@/utils/format';

export interface MyCartResponse {
  info?: CustomerInfo | null;
  cookies?: CookieData[];
  ref: string;
  freegifts: ProductModel[];
  bundledetail: ProductModel[][];
  normalitem: ProductModel[];
  isFreeNotEnough: boolean;
  totalAmount: number;
  totalAmountShow: string;
  totalAmountThaiText: string;
  ontopDiscount: string;
  plppDetail?: PlppDetail[];
  sumTotalShow: string;
  sumPlppShow: string;
  totalVoucher: number;
  isBanned: boolean;
  diffStore?: string;
  checkStockProducts?: ProductModel[];
}

export class MyCartResponseConverter {
  public static fromJson(json: any): MyCartResponse {
    const model: MyCartResponse = {
      info: json['info']
        ? CustomerInfoConverter.fromJson(json['info'])
        : undefined,
      cookies: json['cookies']
        ? json['cookies'].map((x: any) => CookieDataConverter.fromJson(x))
        : undefined,
      ref: json['ref'] ?? '',
      freegifts: json['freegifts']
        ? json['freegifts'].map((x: any) => ProductModelConverter.fromJson(x))
        : [],
      bundledetail: json['bundledetail']
        ? json['bundledetail'].map((x: any) =>
            x.map((y: any) => ProductModelConverter.fromJson(y)),
          )
        : [],
      normalitem: json['normalitem']
        ? json['normalitem'].map((x: any) => ProductModelConverter.fromJson(x))
        : [],
      isFreeNotEnough: json['isfree'] == true ? true : false,
      totalAmount: json['totamt'] ?? 0,
      totalAmountShow: (json['totamtshow'] ?? '')
        .toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      totalAmountThaiText: json['totamttext'] ?? '',
      ontopDiscount: (json['ontopDiscount'] ?? '')
        .toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      plppDetail: json['plppdetail']
        ? json['plppdetail'].map((e: any) => PlppDetailConverter.fromJson(e))
        : undefined,
      sumTotalShow: (json['sumtottxt'] ?? '')
        .toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      sumPlppShow: (json['sumplpptxt'] ?? '')
        .toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      totalVoucher: json['totvoucher'] ?? 0,
      isBanned: json['qryisbanned'] == 'Y' ? true : false,
      diffStore: json['diffstore'],
      checkStockProducts: json['checkstockskus']
        ? json['checkstockskus'].map((e: any) =>
            ProductModelConverter.fromJson(e),
          )
        : undefined,
    };
    return modelValue(model);
  }
}

export interface ClearCartResponse {
  data?: ClearCartData;
  isErrorCancelRedeem: boolean;
}

export class ClearCartResponseConverter {
  public static fromJson(json: any): ClearCartResponse {
    const model: ClearCartResponse = {
      data: json['data']
        ? ClearCartDataConverter.fromJson(json['data'])
        : undefined,
      isErrorCancelRedeem: json['iserrcancelredeem'] == true ? true : false,
    };
    return modelValue(model);
  }
}

export interface ClearCartData {
  dbCode: boolean;
  dbMessage: string;
}

export class ClearCartDataConverter {
  public static fromJson(json: any): ClearCartData {
    const model: ClearCartData = {
      dbCode: json['dbcode'] ?? false,
      dbMessage: (json['dbmessage'] ?? '').toString().trim(),
    };
    return modelValue(model);
  }
}

export interface EditCartResponse {
  dbCode: boolean;
  dbItems: ProductModel[];
  dbMessage: string;
  qty?: number;
}

export class EditCartResponseConverter {
  public static fromJson(json: any): EditCartResponse {
    const model: EditCartResponse = {
      dbCode: json['dbcode'] == true ? true : false,
      dbItems: json['dbitems']
        ? json['dbitems'].map((e: any) => ProductModelConverter.fromJson(e))
        : [],
      dbMessage: (json['dbmessage'] ?? '').toString().trim(),
    };
    return modelValue(model);
  }
}

export enum PromotionFlag {
  // eslint-disable-next-line no-unused-vars
  product = 0,
  // eslint-disable-next-line no-unused-vars
  transport = 1,
  // eslint-disable-next-line no-unused-vars
  express = 2,
}

export interface PlppDetail {
  name: string;
  value: string;
  promotionFlag: PromotionFlag;
}

export class PlppDetailConverter {
  public static fromJson(json: any): PlppDetail {
    const model: PlppDetail = {
      name: json['EVNAME'] ?? '',
      value: numberWithCommas((json['bval'] ?? '').toString().trim()),
      promotionFlag: json['tspflag'],
    };
    return modelValue(model);
  }
}

export interface SpecialPurchaseResponse {
  canRedeem?: boolean;
  pathUrl?: string;
}

export class SpecialPurchaseResponseConverter {
  public static fromJson(json: any): SpecialPurchaseResponse {
    const model: SpecialPurchaseResponse = {
      canRedeem: json['canredeem'] == true ? true : false,
      pathUrl: json['pathurl'],
    };
    return modelValue(model);
  }
}
