import { Address } from '@/models/Address.model';
import {
  RequestEtaxConverter,
  RequestEtaxResponseModel,
  RequestEtaxResponseModelConverter,
} from '@/models/RequestEtax.model';
import { postDataFromAPI } from '@/utils/httpClient';

export const orderEtaxRequest = async ({
  address,
  orderRef,
  etaxType,
  etaxVal,
  lang,
}: {
  address: Address;
  orderRef: string;
  etaxType: string;
  etaxVal: string;
  lang?: string;
}): Promise<RequestEtaxResponseModel | undefined> => {
  const [getApiData] = await Promise.all([
    postDataFromAPI({
      path: '/orderetaxrequest',
      body: {
        orderref: orderRef,
        etaxtype: etaxType,
        etaxval: etaxVal,
        datatax: RequestEtaxConverter.toJson(address),
        lang,
      },
    }),
  ]);
  const apiData =
    getApiData.status == 200
      ? getApiData.data
        ? getApiData.data
        : undefined
      : undefined;
  let response: RequestEtaxResponseModel | undefined = apiData;
  if (apiData) {
    try {
      response = RequestEtaxResponseModelConverter.fromJson(apiData);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};
