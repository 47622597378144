import { modelValue } from '@/utils/modelValue';
import { Address } from './Address.model';

export interface RequestEtaxResponseModel {
  isError: boolean;
  errorMessage?: string;
}
export class RequestEtaxConverter {
  public static toJson(model: Address): any {
    const json: any = {
      customerType: model.isCompany ? 'C' : 'P',
      title: '',
      titleDesc: '',
      firstName: model.isCompany ? model.company : model.nameNotCompany,
      lastName: model.isCompany
        ? ''
        : model.lastNameNotCompany
        ? model.lastNameNotCompany
        : '',
      taxNo: model.idNumber,
      branchName: '',
      isHQ: model.isHq ? 'Y' : 'N',
      branchNumber: model.idBranch,
      mobileNo: model.phone,
      email: '',
      homeNo: model.addressNumber,
      buildingName: model.building,
      villageName: '',
      floorNo: model.floor,
      roomNo: model.room,
      mooNo: model.moo,
      alleyName: model.soi,
      roadName: model.road,
      subDistrictName: model.subDistrict,
      districtName: model.district,
      provinceName: model.province,
      postalCode: model.zipcode,
      fullAddress: '',
      company: model.company,
      namenocom: model.nameNotCompany,
      ulat: model.lat,
      ulong: model.lng,
    };
    return modelValue(json);
  }
}
export class RequestEtaxResponseModelConverter {
  public static fromJson(json: any): RequestEtaxResponseModel {
    const model: RequestEtaxResponseModel = {
      isError: json['iserr'] == true ? true : false,
      errorMessage: json['errmsg'],
    };
    return modelValue(model);
  }
}
