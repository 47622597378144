import { Grid } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React from 'react';
import footerClass from '@/styles/footer.module.css';
import AppLink from '@/components/Layout/handler/AppLink';
import Image from 'next/image';
import { IconLink } from '@/models/IconLink.model';

export default function CenterFooter() {
  const { t } = useTranslation();
  const socialLinks: IconLink[] = [
    {
      link: 'https://www.facebook.com/Powerbuythailand',
      logo: `https://${process.env.NEXT_PUBLIC_PROD_BASE_URL_WEB}/public/images/svg/footer/social/facebook.svg`,
      alt: 'facebook',
    },
    {
      link: 'https://www.instagram.com/powerbuy',
      logo: `https://${process.env.NEXT_PUBLIC_PROD_BASE_URL_WEB}/public/images/svg/footer/social/instagram.svg`,
      alt: 'instagram',
    },
    {
      link: 'https://www.youtube.com/user/PowerbuyTV',
      logo: `https://${process.env.NEXT_PUBLIC_PROD_BASE_URL_WEB}/public/images/svg/footer/social/youtube.svg`,
      alt: 'youtube',
    },
    {
      link: 'https://twitter.com/Power_Buy',
      logo: `https://${process.env.NEXT_PUBLIC_PROD_BASE_URL_WEB}/public/images/svg/footer/social/twitter.svg`,
      alt: 'twitter',
    },
    {
      link: 'https://line.me/R/ti/p/%40powerbuy',
      logo: `https://${process.env.NEXT_PUBLIC_PROD_BASE_URL_WEB}/public/images/svg/footer/social/line.svg`,
      alt: 'line',
    },
  ];
  const otherBrands: IconLink[] = [
    {
      link: 'https://www.central.co.th/th',
      logo: `https://${process.env.NEXT_PUBLIC_PROD_BASE_URL_WEB}/public/images/logo/footer/central.webp`,
      alt: 'central',
    },
    {
      link: 'https://www.robinson.co.th/th/',
      logo: `https://${process.env.NEXT_PUBLIC_PROD_BASE_URL_WEB}/public/images/logo/footer/robinson.webp`,
      alt: 'robinson',
    },
    {
      link: 'https://www.officemate.co.th/',
      logo: `https://${process.env.NEXT_PUBLIC_PROD_BASE_URL_WEB}/public/images/logo/footer/officemate.webp`,
      alt: 'officemate',
    },
    {
      link: 'https://www.b2s.co.th/',
      logo: `https://${process.env.NEXT_PUBLIC_PROD_BASE_URL_WEB}/public/images/logo/footer/b2s.webp`,
      alt: 'b2s',
    },
    {
      link: 'https://www.thaiwatsadu.com/',
      logo: `https://${process.env.NEXT_PUBLIC_PROD_BASE_URL_WEB}/public/images/logo/footer/twd.webp`,
      alt: 'thaiwatsadu',
    },
    {
      link: 'https://www.supersports.co.th/',
      logo: `https://${process.env.NEXT_PUBLIC_PROD_BASE_URL_WEB}/public/images/logo/footer/supersports.webp`,
      alt: 'supersports',
    },
    {
      link: 'https://www.tops.co.th/',
      logo: `https://${process.env.NEXT_PUBLIC_PROD_BASE_URL_WEB}/public/images/logo/footer/tops.webp`,
      alt: 'tops',
    },
    {
      link: 'https://www.mebmarket.com/',
      logo: `https://${process.env.NEXT_PUBLIC_PROD_BASE_URL_WEB}/public/images/logo/footer/meb.webp`,
      alt: 'mebmarket',
    },
    {
      link: 'https://www.petnme.co.th/',
      logo: `https://${process.env.NEXT_PUBLIC_PROD_BASE_URL_WEB}/public/images/logo/footer/petnme.webp`,
      alt: 'petnme',
    },
  ];
  const platformStore: IconLink[] = [
    {
      link: 'https://apps.apple.com/th/app/power-buy/id1559145849',
      logo: `https://${process.env.NEXT_PUBLIC_PROD_BASE_URL_WEB}/public/images/logo/footer/appstore.webp`,
      alt: 'App Store',
    },
    {
      link: 'https://play.google.com/store/apps/details?id=com.powerbuy.mobile',
      logo: `https://${process.env.NEXT_PUBLIC_PROD_BASE_URL_WEB}/public/images/logo/footer/googleplay.webp`,
      alt: 'Google play',
    },
  ];
  return (
    <div className={`${footerClass.centerFooterBg} px-2`}>
      <Grid
        container
        className={`${footerClass.centerFooter} border-t p-0 max-width-container`}
      >
        <Grid item xs={12} lg={8} className="pt-0 lg:pt-2 mt-2">
          <div className={`w-full h-full`}>
            <div className="columns-2 md:columns-4">
              <div className="break-inside-avoid-column pb-2">
                <div className="mb-1 text-primary">{t('powerbuy')}</div>
                <div className="text-sm text-grayLight3">
                  <AppLink className="block" href="/page/about-us">
                    {t('aboutus')}
                  </AppLink>
                  <a
                    href="https://drive.google.com/drive/folders/16_sz-h5PCogp3hoQIhyqUF7xUwtG_AgM"
                    rel="noopener"
                  >
                    {t('downloadcatalog')}
                  </a>
                </div>
              </div>

              <div className="break-inside-avoid-column">
                <div className="mb-1 text-primary">{t('customercare')}</div>
                <div className="text-sm text-grayLight3">
                  {/* <a
                    className="block"
                    href="mailto:powerbuycontactcenter@powerbuy.co.th"
                    target="_blank"
                  >
                    {t('contactus')}
                  </a> */}
                  <AppLink className="block" href="/page/contact-us">
                    {t('contactus')}
                  </AppLink>
                  <AppLink className="block" href="/page/business-to-business">
                    {t('businesstobusiness')}
                  </AppLink>
                </div>
              </div>

              <div className="break-inside-avoid-column">
                <div className="mb-1 text-primary">{t('policy')}</div>
                <div className="text-sm text-grayLight3">
                  <AppLink
                    className="block"
                    href="/page/terms-conditions"
                    target="_blank"
                  >
                    {t('termandconditions')}
                  </AppLink>
                  <AppLink
                    className="block"
                    href="/page/privacy-policy"
                    target="_blank"
                  >
                    {t('privacypolicy')}
                  </AppLink>
                  <AppLink
                    className="block"
                    href="/page/cookie-policy"
                    target="_blank"
                  >
                    {t('cookiepolicy')}
                  </AppLink>
                  <AppLink
                    className="block"
                    href="/page/price-match-guarantee-policy"
                    target="_blank"
                  >
                    {t('pricematchguarantee')}
                  </AppLink>
                  <AppLink
                    className="block"
                    href="/page/pwb-whistleblower"
                    target="_blank"
                  >
                    {t('whistleblowerchannel')}
                  </AppLink>
                </div>
              </div>
              <div className="break-inside-avoid-column">
                <div className="mb-1 text-primary">{t('the1')}</div>
                <div className="text-sm text-grayLight3">
                  <AppLink
                    className="block"
                    href="/page/the-1-exclusive"
                    target="_blank"
                  >
                    {t('the1exclusive')}
                  </AppLink>
                  <AppLink
                    className="block"
                    href="https://www.the1.co.th/th/privilege"
                    target="_blank"
                  >
                    {t('promotion')}
                  </AppLink>
                  <AppLink
                    className="block"
                    href="https://www.the1.co.th/th/redeem"
                    target="_blank"
                  >
                    {t('earnredeem')}
                  </AppLink>
                  {/* <AppLink
                    className="block"
                    href="https://www.the1.co.th/th/register"
                    target="_blank"
                  >
                    {t('register')}
                  </AppLink> */}

                  <AppLink className="block" href="/register" target="_blank">
                    {t('register')}
                  </AppLink>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={4} className="pt-0 lg:pt-2 mt-2 ps-0 lg:ps-10">
          <div className="mb-1 text-primary">{t('followus')}</div>
          <Grid container spacing={3}>
            {socialLinks.map((socialLink, i) => (
              <Grid key={i} item>
                <AppLink href={socialLink.link} target="_blank">
                  <Image
                    src={socialLink.logo}
                    alt={socialLink.alt ?? ''}
                    width={24}
                    height={24}
                    style={{ height: '1rem', width: '1rem' }}
                  />
                </AppLink>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12} className="mt-2">
          <Grid container>
            <Grid item xs={12} lg={2} className="my-auto">
              <div className="text-primary">
                {t('groupbusiness')}
                <span className="hidden md:inline ml-4 border-r-2 border-[#838383]"></span>
              </div>
            </Grid>
            <Grid item xs={12} lg={10}>
              <div className="w-full">
                <div className="flex flex-wrap md:justify-between">
                  {otherBrands.map((otherBrand, i) => (
                    <div key={i} className="py-1 md:py-0 my-auto">
                      <a href={otherBrand.link} target="_blank" rel="noopener">
                        <Image
                          src={otherBrand.logo}
                          alt={otherBrand.alt ?? ''}
                          width={110}
                          height={55}
                          className="w-[2.8rem] max-w-[4rem] lg:w-[4rem]"
                          style={
                            {
                              // width: 'auto',
                              // minHeight: '1rem',
                              // maxHeight: '3rem',
                            }
                          }
                        />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={12}>
          <div className="flex mt-2 justify-center">
            {platformStore.map((otherService, i) => (
              <div key={i} className="px-2">
                <AppLink href={otherService.link} target="_blank">
                  <Image
                    src={otherService.logo}
                    alt={otherService.alt ?? ''}
                    className="mx-auto lg:py-1"
                    width={132}
                    height={32}
                  />
                </AppLink>
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
