import { postDataFromAPI } from '@/utils/httpClient';

export const likeDislikeData = async ({
  sendData,
}: {
  sendData?: {
    skcode: string;
    mode: number;
    is_like: number;
    comment?: string | null;
  };
}): Promise<{
  dbCode: boolean;
  dbMessage?: string;
}> => {
  const [getApiData] = await Promise.all([
    postDataFromAPI({ path: '/postlikedislike', body: sendData }),
  ]);
  const apiData =
    getApiData.status == 200
      ? getApiData.data
        ? getApiData.data
        : undefined
      : undefined;

  let response = { dbCode: false, dbMessage: 'Error.' };
  if (apiData) {
    try {
      response = {
        dbCode: apiData.dbcode === true ? true : false,
        dbMessage: apiData.dbmessage,
      };
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};
