import { modelValue } from '@/utils/modelValue';

export interface SetupPinResponseModel {
  success?: boolean;
  isError: boolean;
  errorType?: number;
  errorMessage?: string;
}

export class SetupPinResponseModelConverter {
  public static fromJson(json: any): SetupPinResponseModel {
    const model: SetupPinResponseModel = {
      success: json['success'],
      isError: json['is_error'] == true ? true : false,
      errorType: json['type'],
      errorMessage: json['emsg'],
    };
    return modelValue(model);
  }
}
