import { modelValue } from '@/utils/modelValue';
import {
  CookieData,
  CookieDataConverter,
  CustomerInfo,
  CustomerInfoConverter,
} from '../Authen.model';
import { AddressConverter, Address } from '../Address.model';

export interface AccountSummaryResponse {
  title?: string;
  sub?: string;
  theOneDataCard?: string | null;
  theOneDataPoint?: string | null;
  theOneDataPointText?: string | null;
  theOneDataPointExpiry?: string | null;
  theOneDataUserId?: string | null;
  getAddress?: Address[];
  getShip?: Address;
  getBill?: Address;
  couponCount?: CouponCount;
  profileImage?: string;
  cookies?: CookieData[] | null;
  info?: CustomerInfo | null;
}
export interface CouponCount {
  total?: number;
  online?: number;
  store?: string | null;
  special?: string | null;
}

export class AccountSummaryResponseConverter {
  public static fromJson(json: any): AccountSummaryResponse {
    const model: AccountSummaryResponse = {
      title: json['title'],
      sub: json['sub'],
      theOneDataCard: json['theonedatacard'],
      theOneDataPoint: json['theonedatapoint'],
      theOneDataPointText: json['theonedatapointtext'],
      theOneDataPointExpiry: json['theonedatapointexpiry'],
      theOneDataUserId: json['theonedatauserid'],
      getAddress: json['getaddress']
        ? json['getaddress'].map((e: any) => AddressConverter.fromJson(e))
        : undefined,
      getShip: json['getship']
        ? AddressConverter.fromJson(json['getship'])
        : json['getall']
        ? AddressConverter.fromJson(json['getall'])
        : undefined,
      getBill: json['getbill']
        ? AddressConverter.fromJson(json['getbill'])
        : json['getall']
        ? AddressConverter.fromJson(json['getall'])
        : undefined,
      couponCount: json['couponcount']
        ? CouponCountConverter.fromJson(json['couponcount'])
        : undefined,
      profileImage: json['profileImage'],
      info: json['info']
        ? CustomerInfoConverter.fromJson(json['info'])
        : undefined,
      cookies: json['cookies']
        ? json['cookies'].map((x: any) => CookieDataConverter.fromJson(x))
        : undefined,
    };
    return modelValue(model);
  }
}
export class CouponCountConverter {
  public static fromJson(json: any): CouponCount {
    const model: CouponCount = {
      total: json['total'],
      online: json['online'],
      store: json['store'],
      special: json['special'],
    };
    return modelValue(model);
  }
}
export interface UpdateTheOneRequestModel {
  mode: number;
  t1c?: string;
}
