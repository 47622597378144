import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import AppLink from '../handler/AppLink';
import Image from 'next/image';
import { exceptStaticHeader } from '@/constants/defaultValue.constant';
import { useRouter } from 'next/router';
import headerClass from '@/styles/header.module.css';
import 'swiper/swiper-bundle.css'; // Import Swiper styles
import { Swiper, SwiperSlide } from 'swiper/react'; // Import Swiper React components

interface SubMenu {
  link: string;
  title: string;
  icon?: string;
}

export default function StaticPageHeader() {
  const { i18n, t } = useTranslation();
  const router = useRouter();
  const currentPage = router.pathname.split('/')[1];
  const [languageChanged, setLanguageChanged] = useState(false);

  const mockupSubMenus: SubMenu[] = [
    {
      link: '/page/express_delivery',
      title: t('express1hours'),
      icon: '/images/icons/header/pickup1hour.svg',
    },
    {
      link: '/page/promise-free-delivery',
      title: t('freedeliveryorpickup'),
      icon: '/images/icons/header/freedeliveryorpickup.svg',
    },
    {
      link: '/page/promise-free-installation',
      title: t('freeinstallation'),
      icon: '/images/icons/header/freeinstallation.svg',
    },
    {
      link: '/page/promise-easy-return-refund',
      title: t('easyrefund'),
      icon: '/images/icons/header/easyrefund.svg',
    },
    {
      link: '/page/100-authentic-warranty',
      title: t('authenticandwarranty'),
      icon: '/images/icons/header/authenticandwarranty.svg',
    },
  ];

  useEffect(() => {
    setLanguageChanged((prev) => !prev);
  }, [i18n.language]);

  return exceptStaticHeader.includes(`/${currentPage}`) ? (
    <></>
  ) : (
    <div className="max-width-container">
      <Swiper
        key={languageChanged ? 'yes' : 'no'}
        spaceBetween={20}
        slidesPerView={'auto'}
      >
        {mockupSubMenus.map((subMenu, i) => (
          <SwiperSlide
            key={`${i}`}
            style={{ width: 'auto' }}
            className={`py-2`}
          >
            <div
              className={`${headerClass.headswiper} xs:px-1 sm:px-2 md:px-4`}
            >
              <AppLink className="flex items-center" href={subMenu.link}>
                {subMenu.icon && (
                  <Image
                    src={subMenu.icon}
                    alt={subMenu.title}
                    width={40}
                    height={40}
                    className="me-1"
                    style={{ width: '40px', height: '40px' }}
                  />
                )}
                <span
                  className={
                    'overflow-hidden text-ellipsis text-primary text-sm'
                  }
                >
                  {subMenu.title}
                </span>
              </AppLink>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
