import {
  GetVoucherDetailModel,
  GetVoucherDetailModelConverter,
} from '@/models/profile/MyVoucherDetail.model';
// import { saveClientCookieFromResponse } from '@/utils/cookie';
import { postDataFromAPI } from '@/utils/httpClient';

export const getProfileMyVoucherDetail = async ({
  lang,
  voucherk,
}: {
  lang?: string;
  voucherk?: string;
}): Promise<{
  data: GetVoucherDetailModel | null | undefined;
  status: number | null;
}> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/getvoudetail',
      lang,
      body: {
        voucherk,
      },
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: GetVoucherDetailModel | null | undefined;
    status: number | null;
  } = { data: data, status: getData.status };
  if (data) {
    try {
      response.data = GetVoucherDetailModelConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};
