import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ServerCookies from '@/services/client/cookieService';
import * as cookieKey from '@/constants/cookieKey.constant';
import { RootState } from '@/store/store';
import {
  getRefund,
  sendRefundRequest,
} from '@/services/client/orderhistoryService';
import {
  RefundPageResponse,
  RefundRequestItem,
} from '@/models/profile/Refund.model';

interface OrderhistoryRefundState {
  result?: RefundPageResponse | null;
  isLoading: boolean;
  error?: string;
  requestRefundItem?: RefundRequestItem[] | null;
  isLoadingRequestRefund: boolean;
  saveRequestRefundSuccess?: boolean;
  errorSaveRequestRefund?: string;
}

const initialState: OrderhistoryRefundState = {
  isLoading: false,
  isLoadingRequestRefund: false,
};

export const profileOrderhistoryRefundQuery = createAsyncThunk(
  'orderhistory/refund/query',
  async ({ lang, order }: { lang?: string; order?: string }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await getRefund({
      lang,
      order,
    });
    if (!response || !response.data) {
      throw new Error(`${response.status ?? '500.'}`);
    }
    return response;
  },
);

export const submitRefundRequestItem = createAsyncThunk(
  'orderhistory/refund/submit',
  async ({
    order,
    selectedRefundList,
  }: {
    order: string;
    selectedRefundList: RefundRequestItem[];
  }) => {
    let form = new FormData();
    if (selectedRefundList && selectedRefundList.length > 0) {
      form.append('infoitem', JSON.stringify(selectedRefundList));
      for (let i = 0; i < selectedRefundList.length; i++) {
        let itmimg = selectedRefundList[i].images;
        for (let j = 0; j < (itmimg?.length ?? 0); j++) {
          if (itmimg && itmimg.length > 0) {
            let fileUrl = itmimg[j]?.imageUrl;
            if (fileUrl) {
              let name = fileUrl.name.substring(
                fileUrl.name.lastIndexOf('.') + 1,
              );
              form.append(
                'file-' + j,
                fileUrl,
                'img_' +
                  selectedRefundList[i].seqNo +
                  '_' +
                  itmimg[j].seqNo +
                  '.' +
                  name,
              );
            }
          }
        }
      }
      const response = await sendRefundRequest({ order, sendData: form });
      if (!response || response.isError) {
        throw new Error(response?.errorMessage ?? 'Error.');
      } else {
        return response;
      }
    }
  },
);

export const OrderhistoryRefundSlice = createSlice({
  name: 'orderhistoryRefund',
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.error = undefined;
      state.errorSaveRequestRefund = undefined;
    },
    clearResult: (state) => {
      state.result = undefined;
    },
    clearSuccess: (state) => {
      state.saveRequestRefundSuccess = undefined;
    },
  },
  extraReducers: (builder) => {
    // pending, fulfilled, rejected
    builder
      .addCase(profileOrderhistoryRefundQuery.pending, (state) => {
        state.isLoading = true;
        state.result = undefined;
      })
      .addCase(profileOrderhistoryRefundQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload === null) {
          state.result = {
            info: null,
          };
        } else {
          state.result = action.payload?.data ?? null;
        }
      })
      .addCase(profileOrderhistoryRefundQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.result = null;
        state.error = action.error.message;
      });
    builder
      .addCase(submitRefundRequestItem.pending, (state) => {
        state.isLoadingRequestRefund = true;
        state.saveRequestRefundSuccess = undefined;
        state.errorSaveRequestRefund = undefined;
      })
      .addCase(submitRefundRequestItem.fulfilled, (state) => {
        state.isLoadingRequestRefund = false;
        state.saveRequestRefundSuccess = true;
      })
      .addCase(submitRefundRequestItem.rejected, (state, action) => {
        state.isLoadingRequestRefund = false;
        state.saveRequestRefundSuccess = undefined;
        state.errorSaveRequestRefund = action.error.message;
      });
  },
});

export const { clearError, clearResult, clearSuccess } =
  OrderhistoryRefundSlice.actions;

export const orderhistoryRefundResultSelector = (
  store: RootState,
): RefundPageResponse | undefined | null => store.orderhistoryRefund.result;

export const isLoadingOrderhistoryRefundSelector = (
  store: RootState,
): boolean => store.orderhistoryRefund.isLoading;

export const errorOrderhistoryRefundSelector = (
  store: RootState,
): string | undefined => store.orderhistoryRefund.error;

export const refundRequestItemSelector = (
  store: RootState,
): RefundRequestItem[] | undefined | null =>
  store.orderhistoryRefund.requestRefundItem;

export const isLoadingRequestRefundSelector = (store: RootState): boolean =>
  store.orderhistoryRefund.isLoadingRequestRefund;

export const saveRequestRefundSuccessSelector = (
  store: RootState,
): boolean | undefined => store.orderhistoryRefund.saveRequestRefundSuccess;

export const errorRequestRefundSelector = (
  store: RootState,
): string | undefined => store.orderhistoryRefund.errorSaveRequestRefund;

export default OrderhistoryRefundSlice.reducer;
