import { modelValue } from '@/utils/modelValue';

export interface BundleModel {
  products?: ProductBundle[] | [];
  sku?: string;
  dfstore?: string;
  skuStockavail?: number;
  imgBundle?: string;
  promotionId?: string;
  isLine?: string;
  bundlesku?: ProductBundle[] | [];
  productskus?: ProductBundle[] | [];
  keyword?: string;
}

export interface ProductBundle {
  setid?: number;
  skc?: number;
  name?: string;
  qntBundle?: number;
  price?: string;
  orgPrice?: number;
  plppPrice?: number;
  ty?: string;
  amt?: number;
  ctlid?: number;
  base64?: string;
  pimImage?: string;
  slugname?: string;
  prBrand?: string;
  prUname?: string;
  priceshow?: string;
  orgPriceshow?: string;
  plppPriceshow?: string;
  disc?: string;
  per?: string;
  avgreview?: number;
  totalreview?: number;
  proEnddate?: string;
  allprice?: string;
  allorgprice?: string;
  allplppprice?: string;
  allpercent?: string;
  allpricesave?: string;
  flagShowORGPRICE?: number;
  freegiftmsg?: string;
  isBundleSoft?: boolean;
  reduce?: number;
  stockavail?: number;
  proname?: string;
  minqty?: number;
}

export class BundleModelConverter {
  public static fromJson(json: any): BundleModel {
    const model: BundleModel = {
      products:
        json['products'] && json['products'].length > 0
          ? json['products'].map((elements: any) =>
              elements.map((x: any) => ProductBundleConverter.fromJson(x)),
            )
          : undefined,
      bundlesku:
        json['bundlesku'] && json['bundlesku'].length > 0
          ? json['bundlesku'].map((elements: any) =>
              elements.map((x: any) => ProductBundleConverter.fromJson(x)),
            )
          : undefined,
      sku: json['sku'],
      dfstore: json['DFSTORE'],
      skuStockavail: json['sku_stockavail'],
      imgBundle: json['imgBundle'],
      promotionId: json['promotionId'],
      isLine: json['is_line'],
      keyword: json['keyword'],
    };
    return modelValue(model);
  }
}
export class ProductBundleConverter {
  public static fromJson(json: any): ProductBundle {
    const model: ProductBundle = {
      setid: json['SETID'],
      skc: json['SKC'],
      name: json['PRNAME'],
      qntBundle: json['QNT'],
      price: (
        json['PRICE'] ??
        json['price'] ??
        json['PRPRICE'] ??
        json['UPCSHOW'] ??
        json['UPC'] ??
        json['upc'] ??
        json['upcshow'] ??
        ''
      )
        .toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      orgPrice: json['ORG_PRICE'],
      plppPrice: json['PLPP_PRICE'],
      ty: json['TY'],
      amt: json['AMT'],
      ctlid: json['CTLID'],
      base64: json['Base64'],
      pimImage: json['pimImage'],
      slugname: json['slugname'],
      prBrand: json['PR_BRAND']!,
      prUname: json['PR_UNAME'],
      priceshow: json['PRICESHOW'],
      orgPriceshow: json['ORG_PRICESHOW'],
      plppPriceshow: json['PLPP_PRICESHOW'],
      disc: json['DISC'],
      per: json['PER'],
      avgreview: json['AVGREVIEW'],
      totalreview: json['TOTALREVIEW'],
      proEnddate: json['PRO_ENDDATE'],
      allprice: json['ALLPRICE'],
      allorgprice: json['ALLORGPRICE'],
      allplppprice: json['ALLPLPPPRICE'],
      allpercent: json['ALLPERCENT'],
      allpricesave: json['ALLPRICESAVE'],
      flagShowORGPRICE: json['FlagShow_ORGPRICE'],
      freegiftmsg: json['FREEGIFTMSG'],
      isBundleSoft: json['isBundleSoft'],
      reduce: json['REDUCE'],
      stockavail: json['STOCKAVAIL'],
      proname: json['PRONAME'],
      minqty: json['MINQTY'],
    };
    if (model.price) {
      try {
        const numericPrice = parseFloat(model.price);
        const formattedPrice = numericPrice.toLocaleString('en-US', {
          minimumFractionDigits: 2,
        });
        model.price = formattedPrice;
      } catch (error) {}
    }
    return modelValue(model);
  }
}
