import { CookieData } from '@/models/Authen.model';
import * as cookieKey from '@/constants/cookieKey.constant';
import * as ServerCookies from '@/services/client/cookieService';
import Cookies from 'js-cookie';

export const saveClientCookieFromResponse = async (
  cookies?: CookieData[] | null,
) => {
  if (cookies && cookies.length > 0) {
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      if (cookie.name && cookie.val != null && cookie.val != undefined) {
        if (cookieKey.serverCookieNameList.includes(cookie.name)) {
          await ServerCookies.set(cookie.name, cookie.val.toString().trim());
        } else {
          Cookies.set(cookie.name, cookie.val.toString().trim(), {
            secure: process.env.NEXT_PUBLIC_NODE_ENV !== 'development',
          });
        }
      } else if (
        cookie.name === cookieKey.ref &&
        (cookie.val === null || !cookie.val)
      ) {
        if (cookieKey.serverCookieNameList.includes(cookie.name)) {
          await ServerCookies.remove(cookie.name);
          await ServerCookies.remove(cookieKey.cartCount);
        }
      }
    }
  }
};
