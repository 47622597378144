import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import Head from 'next/head';
import {
  clearResult,
  clearError,
  editInfoProfileResultSelector,
  profileEditInfoQuery,
  isLoadingEditInfoProfileSelector,
} from '@/store/slices/profile/editInfoSlice';
import {
  customerIdSelector,
  isLoadingCustomerIdSelector,
} from '@/store/slices/loginSlice';
import { useAppDispatch } from '@/store/store';
import { useTranslation } from 'next-i18next';
import Cookies from 'js-cookie';
import * as cookieKey from '@/constants/cookieKey.constant';
import { useRouter } from 'next/router';
import aa from 'search-insights';

export default function InsiderUser() {
  const router = useRouter();
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const editInfoProfileResult = useSelector(editInfoProfileResultSelector);
  const isLoadingResult = useSelector(isLoadingEditInfoProfileSelector);
  const customerId = useSelector(customerIdSelector);
  const isLoadingCustomerId = useSelector(isLoadingCustomerIdSelector);
  const infoProfileCookie = Cookies.get(cookieKey.infoProfile);

  const [infoProfile, setInfoProfile] = useState<any | null | undefined>({
    info: null,
  });
  // const [insiderUserState, setInsiderUserState] = useState({
  //   gdpr_optin: true,
  //   email: '',
  //   email_optin: false,
  //   phone_number: '',
  //   name: '',
  //   surname: '',
  //   uuid: '',
  // });
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  useEffect(() => {
    if (infoProfileCookie) {
      try {
        const parsedInfoProfile = JSON.parse(infoProfileCookie);
        setInfoProfile(parsedInfoProfile);
        setIsLoadingData(false);
      } catch (error) {
        setInfoProfile(undefined);
        setIsLoadingData(false);
      }
    }
  }, [infoProfileCookie]);
  useEffect(() => {
    if (!isLoadingResult && editInfoProfileResult && !isLoadingCustomerId) {
      setInfoProfile(editInfoProfileResult);
      Cookies.set(cookieKey.infoProfile, JSON.stringify(editInfoProfileResult));
      setIsLoadingData(false);
    }
  }, [
    isLoadingResult,
    editInfoProfileResult,
    infoProfileCookie,
    isLoadingCustomerId,
    customerId,
  ]);

  useEffect(() => {
    if (!isLoadingCustomerId) {
      if (
        typeof window !== 'undefined' &&
        // infoProfile === undefined &&
        infoProfileCookie === undefined &&
        customerId !== undefined &&
        infoProfile?.info == null
      ) {
        dispatch(profileEditInfoQuery({ lang: i18n.language }));
      } else {
      }
      return () => {
        dispatch(clearResult());
        dispatch(clearError());
      };
    }
  }, [
    dispatch,
    i18n,
    infoProfile,
    infoProfileCookie,
    customerId,
    isLoadingCustomerId,
  ]);

  // useEffect(() => {
  //   if (infoProfile && !isLoadingData) {
  //     let name = '';
  //     let surname = '';
  //     try {
  //       const nameParts = infoProfile.info?.cname.split(' ');
  //       if (nameParts && nameParts.length > 1) {
  //         name = nameParts[0];
  //         surname = nameParts.slice(1).join(' ');
  //       }
  //     } catch (error) {}
  //     setInsiderUserState({
  //       gdpr_optin: true,
  //       email: `${infoProfile.info?.email}`,
  //       email_optin: infoProfile.csMarketing ? infoProfile.csMarketing : false,
  //       phone_number: `${infoProfile.info?.mobile}`,
  //       name: name,
  //       surname: surname,
  //       uuid: `${infoProfile.info?.custId}`,
  //     });
  //   }
  // }, [infoProfile, isLoadingData]);
  useEffect(() => {
    if (!isLoadingCustomerId) {
      if (!isLoadingData && customerId !== null && customerId !== undefined) {
        if (infoProfile?.info && customerId) {
          try {
            if ((window as any).dataLayer) {
              (window as any).dataLayer.push({ ecommerce: null });
              (window as any).dataLayer.push({
                event: 'Initialize',
                lang: i18n.language,
                login_status: 'Login',
                the1_card: infoProfile.info?.t1c,
                the1_id: infoProfile.info?.employeeID
                  ? infoProfile.info?.employeeID
                  : '',
                the1_phone: infoProfile.theoneMobile
                  ? infoProfile.theoneMobile
                  : infoProfile.info?.mobile,
                user_email: infoProfile.theoneEmail
                  ? infoProfile.theoneEmail
                  : infoProfile.info?.email,
                user_id: infoProfile.info?.custId,
              });
            }
          } catch (_) {}
        }
      }
    }
  }, [
    infoProfile,
    i18n.language,
    isLoadingData,
    customerId,
    infoProfileCookie,
    isLoadingCustomerId,
    router.asPath,
  ]);
  useEffect(() => {
    if (!isLoadingData && infoProfile.info === null && customerId === null) {
      try {
        if ((window as any).dataLayer) {
          (window as any).dataLayer.push({ ecommerce: null });
          (window as any).dataLayer.push({
            event: 'Initialize',
            lang: i18n.language,
            login_status: 'guest',
            the1_card: '',
            the1_id: '',
            the1_phone: '',
            user_email: '',
            user_id: '',
          });
        }
      } catch (_) {}
    }
  }, [
    infoProfile,
    i18n.language,
    isLoadingData,
    customerId,
    infoProfileCookie,
    isLoadingCustomerId,
    router.asPath,
  ]);

  useEffect(() => {
    aa('init', {
      appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
      apiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
      partial: true,
      useCookie: true,
    });

    if (!isLoadingCustomerId) {
      if (customerId && customerId !== '') {
        aa('setAuthenticatedUserToken', customerId);
        aa('setUserToken', customerId);
      } else {
        const algolia = Cookies.get(cookieKey.algolia);
        aa('setAuthenticatedUserToken', `${algolia}`);
        aa('setUserToken', `${algolia}`);
      }
    }
  }, [isLoadingCustomerId, customerId, router.asPath, i18n.language]);
  // useEffect(() => {
  //   if (customerNameStore && customerId) {
  //     console.log('login');
  //     console.log('customerId:', customerId);
  //   } else {
  //     console.log('logout');
  //   }
  // }, [customerNameStore, customerId]);

  // return insiderUserState ? (
  //   <Head>
  //     <script
  //       id="InsiderUser"
  //       dangerouslySetInnerHTML={{
  //         __html: `
  //           window.insider_object = window.insider_object || {};
  //           window.insider_object.user = {
  //             "gdpr_optin": true,
  //             "email": "${insiderUserState.email}",
  //             "email_optin": ${insiderUserState.email_optin},
  //             "phone_number":  "${insiderUserState.phone_number}",
  //             "name": "${insiderUserState.name}",
  //             "surname": "${insiderUserState.surname}",
  //             "uuid":"${insiderUserState.uuid}",
  //         };
  //       `,
  //       }}
  //     />
  //   </Head>
  // ) : (
  //   <></>
  // );
  return <></>;
}
