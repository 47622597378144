import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export enum AddressEtaxModalActionType {
  // eslint-disable-next-line no-unused-vars
  taxinvoice = 'taxinvoice',
}

interface AddressEtaxModalState {
  isOpen: boolean;
  isEdit: boolean;
}

const initialState: AddressEtaxModalState = {
  isOpen: false,
  isEdit: false,
};

export const addressEtaxModalSlice = createSlice({
  name: 'addressEtaxModal',
  initialState: initialState,
  reducers: {
    openAddressEtaxModal: (state) => {
      state.isOpen = true;
    },
    handleCloseAddressEtaxModal: (state) => {
      state.isOpen = false;
      state.isEdit = false;
    },
    editAddressEtaxModal: (state) => {
      state.isOpen = true;
      state.isEdit = true;
    },
  },
  extraReducers: () => {},
});

export const {
  openAddressEtaxModal,
  handleCloseAddressEtaxModal,
  editAddressEtaxModal,
} = addressEtaxModalSlice.actions;

export const isOpenAddressEtaxModalSelector = (store: RootState): boolean =>
  store.addressEtaxModal.isOpen;

export const isEditAddressEtaxModalSelector = (store: RootState): boolean =>
  store.addressEtaxModal.isEdit;

export default addressEtaxModalSlice.reducer;
