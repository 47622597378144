import { modelValue } from '@/utils/modelValue';
import {
  CookieData,
  CookieDataConverter,
  CustomerInfo,
  CustomerInfoConverter,
} from '../Authen.model';
import { fullMonth } from '@/constants/checkout.constant';

export enum VoucherSectionName {
  // eslint-disable-next-line no-unused-vars
  cart = 'cart',
  // eslint-disable-next-line no-unused-vars
  ship = 'ship',
}

export interface MyVoucherModel {
  title?: string;
  sub?: string;
  state?: string;
  voucherdetail?: VoucherDetail[];
  monththai?: string[];
  productpaging?: Productpaging;
  shiprewardid?: string;
  limit?: number;
  page?: number;
  srct?: null;
  sort?: null;
  fill?: null;
  info?: CustomerInfo | null;
  cookies?: CookieData[] | null;
}

export interface Productpaging {
  total?: number;
  perPage?: number;
  currentPage?: number;
  lastPage?: number;
  from?: number;
  to?: number;
  data?: VoucherDetail[];
  skcount?: number;
}

export interface VoucherDetail {
  voucherCode?: string;
  voucherKey?: string;
  state?: string;
  reservedExpiresAt?: string;
  voucherStartsAt?: string;
  voucherExpiresAtTH?: string;
  voucherExpiresAtEN?: string;
  issuedAt?: string;
  partnerId?: number;
  redeemDate?: string;
  reward?: Reward;
  expireBadge?: boolean;
  channeltag?: null | string;
  totalissued?: number;
  isShowButton?: boolean;
  voucherMessage?: string;
  voucherMessageBackgroungColor?: string;
}
export class VoucherDetailConverter {
  public static fromJson(json: any): VoucherDetail {
    const model: VoucherDetail = {
      voucherCode: json['voucher_code'],
      voucherKey: json['voucher_key'],
      state: json['state'],
      reservedExpiresAt: json['reserved_expires_at'],
      voucherStartsAt: json['voucher_starts_at'],
      voucherExpiresAtTH: json['voucher_expires_at'],
      voucherExpiresAtEN: json['voucher_expires_at'],
      issuedAt: json['issued_at'],
      partnerId: json['partner_id'],
      redeemDate: json['redeem_date'],
      reward: json['reward']
        ? RewardConverter.fromJson(json['reward'])
        : undefined,
      expireBadge: json['expireBadge'],
      channeltag: json['channeltag'],
      totalissued: json['totalissued'],
      isShowButton: json['showbutton'],
      voucherMessage: json['vouchermsg'],
      voucherMessageBackgroungColor: json['bgcolortag']?.toString().trim(),
    };
    if (model.voucherExpiresAtTH && model.voucherExpiresAtEN) {
      const voucherExpiresDate = new Date(model.voucherExpiresAtTH);
      const formattedDateTH = `${voucherExpiresDate.getDate()} ${
        fullMonth.th[voucherExpiresDate.getMonth()]
      } ${voucherExpiresDate.getFullYear()} ${voucherExpiresDate.getHours()}:${voucherExpiresDate.getMinutes()}`;
      model.voucherExpiresAtTH = formattedDateTH;
      const formattedDateEN = `${voucherExpiresDate.getDate()} ${
        fullMonth.en[voucherExpiresDate.getMonth()]
      } ${voucherExpiresDate.getFullYear()} ${voucherExpiresDate.getHours()}:${voucherExpiresDate.getMinutes()}`;
      model.voucherExpiresAtEN = formattedDateEN;
    }
    return modelValue(model);
  }
}

export interface Reward {
  id?: number;
  rewardType?: string;
  status?: string;
  name?: string;
  displayName?: string;
  description?: string;
  shortDescription?: null | string;
  termsCondition?: string;
  couponConditionDescription?: null | string;
  image?: string;
  tags?: string;
  category?: number[];
  voucherCodeType?: string;
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class MyVoucherModelConverter {
  public static fromJson(json: any): MyVoucherModel {
    const model: MyVoucherModel = {
      title: json['title'],
      sub: json['sub'],
      state: json['state'],
      voucherdetail: json['voucherdetail']
        ? json['voucherdetail'].map((x: any) =>
            VoucherDetailConverter.fromJson(x),
          )
        : [],
      monththai: json['monththai'],
      productpaging: json['productpaging']
        ? ProductPagingConverter.fromJson(json['productpaging'])
        : undefined,
      shiprewardid: json['shiprewardid'],
      limit: json['limit'],
      page: json['page'],
      srct: json['srct'],
      sort: json['sort'],
      fill: json['fill'],
      info: json['info']
        ? CustomerInfoConverter.fromJson(json['info'])
        : undefined,
      cookies: json['cookies']
        ? json['cookies'].map((x: any) => CookieDataConverter.fromJson(x))
        : undefined,
    };
    return modelValue(model);
  }
}
export class ProductPagingConverter {
  public static fromJson(json: any): Productpaging {
    const model: Productpaging = {
      total: json['total'],
      perPage: json['per_page'],
      currentPage: json['current_page'],
      lastPage: json['last_page'],
      from: json['from'],
      to: json['to'],
      skcount: json['skcount'],
    };
    return modelValue(model);
  }
}
export class RewardConverter {
  public static fromJson(json: any): Reward {
    const model: Reward = {
      id: json['id'],
      rewardType: json['reward_type'],
      status: json['status'],
      name: json['name'],
      displayName: json['display_name'],
      description: json['description'],
      shortDescription: json['short_description'],
      termsCondition: json['terms_condition'],
      couponConditionDescription: json['coupon_condition_description'],
      image: json['image'],
      tags: json['tags'],
      category: json['category'],
      voucherCodeType: json['voucher_code_type'],
    };
    return modelValue(model);
  }
}

export interface MyUsedVoucherListResponse {
  discountVoucher?: number;
  voucherUsedList?: VoucherUsed[];
}

export class MyUsedVoucherListResponseConverter {
  public static fromJson(json: any): MyUsedVoucherListResponse {
    const model: MyUsedVoucherListResponse = {
      discountVoucher: json['totaldisc']
        ? Number(
            json['totaldisc']
              .toString()
              .replaceAll(',', '')
              .replaceAll('฿', '')
              .trim(),
          )
        : undefined,
      voucherUsedList: json['itemlist']?.map((x: any) =>
        VoucherUsedConverter.fromJson(x),
      ),
    };
    return modelValue(model);
  }
}

export interface VoucherUsed {
  isError?: boolean;
  type?: string;
  key?: string;
  name?: string;
  voucherType?: string;
  discount?: string;
  maxDiscount?: string;
  isShippingVoucher?: boolean;
  voucherState?: string;
}

export class VoucherUsedConverter {
  public static fromJson(json: any): VoucherUsed {
    const model: VoucherUsed = {
      isError:
        json['is_error'] === true || json['is_error'] === 'true' ? true : false,
      type: json['type']?.toString().trim(),
      key: json['key']?.toString().trim(),
      name: json['name'],
      voucherType: json['voutype'],
      discount: json['discount'],
      maxDiscount: json['maxdisc']?.toString().trim(),
      isShippingVoucher:
        json['istransport'] == true || json['istransport'] === 'true'
          ? true
          : false,
      voucherState: json['state'],
    };
    return modelValue(model);
  }
}
