import { modelValue } from '@/utils/modelValue';
import {
  CookieData,
  CookieDataConverter,
  CustomerInfo,
  CustomerInfoConverter,
} from '../Authen.model';

export interface MyReviewResponse {
  title?: string;
  sub?: string;
  limit?: number;
  page?: number;
  mode?: string;
  productpaging?: Productpaging; // Now it's referencing the type
  allreview?: Allreview[]; // Now it's referencing the type
  info?: CustomerInfo | null;
  cookies?: CookieData[] | null;
}

export interface Allreview {
  custid?: string;
  orderdate?: string;
  sku?: string;
  name?: string;
  star?: number | null;
  status?: string;
  statusname?: string;
  base64?: string;
  hrecid?: number;
  slugname?: string;
  ref?: string;
  shadeCode?: string;
  shadename?: string;
  redCode?: number;
  greenCode?: number;
  blueCode?: number;
  showdate?: string;
}

export interface Productpaging {
  totalreview?: number;
  skcount?: number;
}

export class MyReviewResponseConverter {
  public static fromJson(json: any): MyReviewResponse {
    const model: MyReviewResponse = {
      title: json['title'],
      sub: json['sub'],
      limit: Number(json['limit']),
      page: Number(json['page']),
      mode: json['mode'],
      productpaging: MyReviewResponseConverter.fromJsonProductpaging(
        json['productpaging'],
      ),
      allreview: (json['allreview'] as any[]).map((x) =>
        MyReviewResponseConverter.fromJsonAllreview(x),
      ),
      info: json['info'] ? CustomerInfoConverter.fromJson(json['info']) : null,
      cookies: json['cookies']
        ? json['cookies'].map((x: any) => CookieDataConverter.fromJson(x))
        : undefined,
    };
    return modelValue(model);
  }

  private static fromJsonProductpaging(json: any): Productpaging {
    return {
      totalreview: json['totalreview'],
      skcount: json['skcount'],
    };
  }

  private static fromJsonAllreview(json: any): Allreview {
    return {
      custid: json['custid'],
      orderdate: json['orderdate'],
      sku: json['SKCODE'],
      name: json['PRNAME'],
      star: json['STAR'],
      status: json['STATUS'],
      statusname: json['STATUSNAME'],
      base64: json['Base64'],
      hrecid: json['HRECID'],
      slugname: json['slugname'],
      ref: json['REF'],
      shadeCode: json['ShadeCode'],
      shadename: json['Shadename'],
      redCode: json['RedCode'],
      greenCode: json['GreenCode'],
      blueCode: json['BlueCode'],
      showdate: json['showdate'],
    };
  }
}
