import {
  GetFreeGiftListModel,
  GetFreeGiftListModelConverter,
} from '@/models/GetFreeGiftList.model';
import { postDataFromAPI } from '@/utils/httpClient';

export const getFreeGiftList = async ({
  lang,
  ctlid,
  skcode,
  itmseq,
  qnt,
}: {
  lang?: string;
  ctlid?: number;
  skcode?: number;
  itmseq?: number;
  qnt?: number;
}): Promise<any | undefined> => {
  const [getApiData] = await Promise.all([
    postDataFromAPI({
      path: '/freegiftlist',
      lang,
      body: { ctlid, skcode, itmseq, qnt },
    }),
  ]);
  const apiData =
    getApiData.status == 200
      ? getApiData.data
        ? getApiData.data
        : undefined
      : undefined;
  let response: GetFreeGiftListModel | undefined = apiData.products;
  if (apiData && apiData.products && apiData.products.length > 0) {
    try {
      response = GetFreeGiftListModelConverter.fromJson(apiData);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};
