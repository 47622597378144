import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import LangHeader from '../../Layout/Header/LangHeader';
import CatMenuSideBar from '../CatMenuSideBar';
import { useSelector } from 'react-redux';
import { customerNameSelector } from '@/store/slices/loginSlice';
import AppLink from '@/components/Layout/handler/AppLink';
import ProfileLayoutMenu from '@/components/profile/ProfileLayout/ProfileLayoutMenu';

export default function MenuHeaderSideBar({
  onCloseDrawer,
}: {
  onCloseDrawer: () => void;
}) {
  const { t } = useTranslation();
  const [isProfileMenuOpen, setIsProfileMenuOpen] = React.useState(false);

  const customerNameStore = useSelector(customerNameSelector);
  const [customerName, setCustomerName] = useState<string | null | undefined>(
    undefined,
  );
  useEffect(() => {
    setCustomerName(customerNameStore);
  }, [customerNameStore]);
  return (
    <>
      <div className="bg-primary px-3 py-3 flex justify-between">
        <Image
          src="/images/svg/logo_mobile.svg"
          alt="logo_mobile"
          className="inline px-0 sm:px-2"
          width={53}
          height={48}
          style={{ maxHeight: 48 }}
        />
        <div onClick={onCloseDrawer}>
          <i className="bi bi-x-square text-white" />
        </div>
      </div>
      {!isProfileMenuOpen && customerName ? (
        <div className="flex items-center px-2 pt-2 font-bold">
          <div onClick={() => setIsProfileMenuOpen(true)}>
            <span>{t('account')}</span>
            <span className="ms-1">{customerName}</span>
          </div>
          <div className="ms-auto pt-0.5">
            <i className="bi bi-chevron-right text-sm" />
          </div>
        </div>
      ) : !customerName ? (
        <div className="flex px-3 py-2 font-bold">
          <AppLink className="hover:text-info" href="/signin">
            <span className="cursor-pointer">{t('signin')}</span>
          </AppLink>

          <span className="mx-2">|</span>
          <AppLink className="hover:text-info" href="/register">
            <span className="cursor-pointer">{t('register')}</span>
          </AppLink>
        </div>
      ) : (
        <></>
      )}
      <div className="overflow-x-hidden overflow-y-auto">
        {isProfileMenuOpen && customerName ? (
          <>
            <div
              className="flex px-2 pt-1 self-center cursor-pointer text-sm mt-2"
              onClick={() => {
                setIsProfileMenuOpen(false);
              }}
            >
              <div className="my-auto">
                <i
                  className="bi bi-chevron-left text-sm me-2"
                  style={{ verticalAlign: 'middle' }}
                />
                {t('backtomainmenu')}
              </div>
            </div>
            <ProfileLayoutMenu onlyList={true} />
          </>
        ) : (
          <CatMenuSideBar />
        )}
      </div>
      <div className="pb-2">
        <LangHeader />
      </div>
    </>
  );
}
