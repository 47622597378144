import { modelValue } from '@/utils/modelValue';
export interface CatbarModel {
  numrow?: number;
  catname?: string;
  catcode?: string;
  slugname?: string;
  catnameEN?: string;
  slugnameEN?: string;
}
export class CatbarModelConverter {
  public static fromJson(json: any): CatbarModel {
    const model: CatbarModel = {
      numrow: json['numrow']
        ? Number(json['numrow'].toString().trim())
        : undefined,
      catname: json['catname'] ? json['catname'].toString().trim() : undefined,
      catcode: json['catcode'] ? json['catcode'].toString().trim() : undefined,
      slugname: json['slugname']
        ? json['slugname'].toString().trim()
        : undefined,
      catnameEN: json['catnameEN']
        ? json['catnameEN'].toString().trim()
        : undefined,
      slugnameEN: json['slugnameEN']
        ? json['slugnameEN'].toString().trim()
        : undefined,
    };
    return modelValue(model);
  }
}
