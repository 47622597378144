import {
  CheckStockResponseConverter,
  StoreData,
  StoreDataArrayConverter,
} from '@/models/Store.model';
import {
  getDataFromAPI,
  getServerDataFromAPI,
  postDataFromAPI,
} from '@/utils/httpClient';
import Cookies from 'js-cookie';
import * as cookieKey from '@/constants/cookieKey.constant';

export enum StoreType {
  // eslint-disable-next-line no-unused-vars
  stockvalid = 'stockvalid',
  // eslint-disable-next-line no-unused-vars
  stockvalidchange = 'stockvalidchange',
  // eslint-disable-next-line no-unused-vars
  stockvalidbundle = 'stockvalidbundle',
}

export const checkStockService = async (
  sku: string,
  type: StoreType,
): Promise<StoreData[] | undefined> => {
  let path = `/stockvalid`;
  switch (type) {
    case StoreType.stockvalid:
      path = `/stockvalid`;
      break;
    case StoreType.stockvalidchange:
      path = `/stockvalidchange`;
      break;
    case StoreType.stockvalidbundle:
      path = `/stockvalidbundle`;
      break;
  }
  const [getDataAPI] = await Promise.all([
    postDataFromAPI({
      path,
      body: {
        skcode: sku,
      },
    }),
  ]);

  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : null;
  let storeList = undefined;
  try {
    if (apiData) {
      storeList = CheckStockResponseConverter.fromJson(apiData).stocks;
    }
  } catch (_) {}

  return storeList;
};

export const checkStockRefService = async (): Promise<
  StoreData[] | undefined
> => {
  let path = `/stockvalidcheckout`;
  const [getDataAPI] = await Promise.all([
    postDataFromAPI({
      path,
    }),
  ]);

  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : null;
  let storeList = undefined;
  try {
    if (apiData) {
      storeList = CheckStockResponseConverter.fromJson(apiData).stocks;
    }
  } catch (_) {}
  return storeList;
};

export const callForChangeStore = async (
  storeCode: string,
): Promise<boolean> => {
  const [dataAPI] = await Promise.all([
    postDataFromAPI({
      path: `/processcksto`,
      body: {
        folddefStore: Cookies.get(cookieKey.stcode),
        fdefStore: storeCode,
      },
    }),
  ]);

  const result = dataAPI.status === 200;
  if (result) {
    Cookies.set(cookieKey.stcode, storeCode, {
      secure: process.env.NEXT_PUBLIC_NODE_ENV !== 'development',
    });
  }

  return result;
};

export const getStoreData = async (): Promise<{
  storeData: StoreData[];
  pickupStoreData: StoreData[];
}> => {
  const [getDataAPI, getPickupStoreDataAPI] = await Promise.all([
    getDataFromAPI({
      path: `/getstoredata`,
    }),
    getDataFromAPI({
      path: `/getstorepickupdata`,
    }),
  ]);
  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : null;
  const storeList: StoreData[] = [];
  try {
    if (apiData && apiData.dbcode === true && apiData.dbitems.length > 0) {
      const listData = apiData.dbitems[0].listdata;
      const storeStringSets = listData.split('|');
      for (let i = 0; i < storeStringSets.length; i++) {
        const storeStringSet = storeStringSets[i];
        const storeItems = storeStringSet
          .split(',')
          .map((e: any) => e.toString().trim());
        if (storeItems.length >= 12) {
          storeList.push(StoreDataArrayConverter.fromJson(storeItems));
        }
      }
    }
  } catch (_) {}
  const pickupStoreData =
    getPickupStoreDataAPI.status == 200
      ? getPickupStoreDataAPI.data
        ? getPickupStoreDataAPI.data
        : null
      : null;
  const pickupStoreList: StoreData[] = [];
  try {
    if (
      pickupStoreData &&
      pickupStoreData.dbcode === true &&
      pickupStoreData.dbitems.length > 0
    ) {
      const listData = pickupStoreData.dbitems[0].listdata;
      const storeStringSets = listData.split('|');
      for (let i = 0; i < storeStringSets.length; i++) {
        const storeStringSet = storeStringSets[i];
        const storeItems = storeStringSet
          .split(',')
          .map((e: any) => e.toString().trim());
        if (storeItems.length >= 12) {
          pickupStoreList.push(StoreDataArrayConverter.fromJson(storeItems));
        }
      }
    }
  } catch (_) {}
  return {
    storeData: storeList,
    pickupStoreData: pickupStoreList,
  };
};

export const getAllStoreData = async (): Promise<StoreData[]> => {
  const [getDataAPI] = await Promise.all([
    getServerDataFromAPI({
      path: `/getstorealldata`,
    }),
  ]);
  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : null;
  const storeList: StoreData[] = [];
  try {
    if (apiData && apiData.dbcode === true && apiData.dbitems.length > 0) {
      const listData = apiData.dbitems[0].listdata;
      const storeStringSets = listData.split('|');
      for (let i = 0; i < storeStringSets.length; i++) {
        const storeStringSet = storeStringSets[i];
        const storeItems = storeStringSet
          .split(',')
          .map((e: any) => e.toString().trim());
        if (storeItems.length >= 12) {
          storeList.push(StoreDataArrayConverter.fromJson(storeItems));
        }
      }
    }
  } catch (_) {}
  return storeList;
};
