import { Button, Grid, IconButton, Drawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useEffect, useRef, useState } from 'react';
import headerClass from '@/styles/header.module.css';
import { useTranslation } from 'next-i18next';
import AppLink from '@/components/Layout/handler/AppLink';
import Image from 'next/image';
import TopHeader from '../TopHeader';
import MenuHeaderSideBar from '../../../homepage/HeroMenu/MenuHeaderSideBar';
import { useSelector } from 'react-redux';
import { cartCountSelector } from '@/store/slices/addToCartSlice';
import * as cookieKey from '@/constants/cookieKey.constant';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
// import * as showDialog from '@/utils/showDialog';
import SearchSuggestBox from './SearchSuggestBox';
import { useAppDispatch } from '@/store/store';
import {
  searchTextSelector,
  setHistoryResult,
  setSearchText,
} from '@/store/slices/searchSuggestSlice';
import { openModal } from '@/store/slices/storeSlice';
import DealFin from './DealFin';

export default function CenterHeader() {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [mobileSearch, setMobileSearch] = useState(false);
  const [cartCount, setCartCount] = useState<number>(0);
  const cartCountStore = useSelector(cartCountSelector);
  const searchRef = useRef<HTMLInputElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [isOpenSearchSuggest, setIsOpenSearchSuggest] =
    useState<boolean>(false);
  const searchText = useSelector(searchTextSelector);

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsOpenSearchSuggest(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setIsOpenSearchSuggest(false);
    if (mobileOpen) {
      setMobileOpen(false);
    }
    router.query.srct && dispatch(setSearchText(String(router.query.srct)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath, router.query.srct, dispatch]);

  useEffect(() => {
    if (cartCountStore) {
      setCartCount(cartCountStore);
    } else {
      setCartCount(Number(Cookies.get(cookieKey.cartCount) ?? 0));
    }
  }, [cartCountStore]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMobileSearchToggle = (e: any) => {
    e.preventDefault();
    setMobileSearch(!mobileSearch);
  };

  const handleSearchText = (e: any) => {
    e.preventDefault();
    dispatch(setSearchText(e.target.value));
  };

  const handleSearchSubmit = (e: any) => {
    e.preventDefault();
    if (`/search/${searchText}` !== decodeURIComponent(router.asPath)) {
      searchInputRef.current?.blur();
      setIsOpenSearchSuggest(false);
      // if (searchText.length < 2) {
      //   showDialog.showErrorWithOkButton({ text: t('searchempty') });
      //   return;
      // }
      dispatch(setHistoryResult(searchText));
      router.push(`/search/${searchText}`);
    }
  };

  const handleClickStore = () => dispatch(openModal({ canSelectStore: false }));

  return (
    <div className={`${headerClass.colorGradient}`}>
      <div className="h-10 lg:h-14 relative flex">
        <div className="absolute w-full top-0 z-20">
          <TopHeader />
        </div>
        <Grid
          container
          columnSpacing={1}
          className="self-center max-width-container"
        >
          <Grid item xs={2} sm={1} className="flex lg:hidden">
            <div className="self-center ps-1 md:ps-2">
              <IconButton
                sx={{ padding: 0 }}
                size="small"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
              >
                <MenuIcon color="secondary" />
              </IconButton>
              <Drawer
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                PaperProps={{
                  sx: { width: '100%' },
                }}
                className="block lg:hidden"
              >
                <MenuHeaderSideBar onCloseDrawer={handleDrawerToggle} />
              </Drawer>
            </div>
          </Grid>
          <Grid item xs={2} md={2} className="flex mt-0 lg:mt-2">
            <div className="ps-0 lg:ps-1 self-center">
              <a href={`/${i18n.language}/`} aria-label="homepage">
                <Image
                  src="/images/svg/logo.svg"
                  alt="logo"
                  className="hidden md:inline"
                  width={187}
                  height={54}
                  style={{ width: '100%' }}
                />
                <Image
                  src="/images/svg/logo_mobile.svg"
                  alt="logo_mobile"
                  className="inline md:hidden px-0 sm:px-2"
                  width={53}
                  height={48}
                  style={{ maxHeight: 48 }}
                />
              </a>
            </div>
          </Grid>
          <Grid
            item
            xs={mobileSearch ? 6 : 4}
            sm={mobileSearch ? 7 : 5}
            md={mobileSearch ? 8 : 6}
            lg={5}
            className="flex pt-0 lg:pt-4"
          >
            <div
              ref={searchRef}
              className={`w-full h-full ${
                mobileSearch ? 'block' : 'hidden'
              } lg:block self-center bg-white text-black max-h-6 static md:relative`}
            >
              <form
                className={`${headerClass.searchDesktop}`}
                onSubmit={handleSearchSubmit}
              >
                <input
                  ref={searchInputRef}
                  type="text"
                  value={searchText}
                  className="h-full w-full px-2 text-center focus:outline-none text-sm"
                  placeholder={`${t('search')}`}
                  id="search-input"
                  name="search-input"
                  aria-label="Search"
                  autoComplete="off"
                  onChange={handleSearchText}
                  onFocus={() => {
                    setIsOpenSearchSuggest(true);
                  }}
                />
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: 'white',
                    xs: {
                      display: 'none',
                    },
                    lg: {
                      display: 'flex',
                    },
                  }}
                  className="hidden lg:flex px-2 h-full cursor-pointer bg-white"
                >
                  <Image
                    src="/images/svg/header/search.svg"
                    alt="search"
                    width={22}
                    height={22}
                    className="self-center"
                    style={{
                      width: 'auto',
                      height: 'auto',
                    }}
                  />
                </Button>
              </form>
              {isOpenSearchSuggest && (
                <div className="absolute z-10 left-1/2 -translate-x-1/2 w-full h-auto">
                  <SearchSuggestBox />
                </div>
              )}
            </div>
            {!mobileSearch && (
              <div
                onClick={handleClickStore}
                className="mx-auto self-center lg:hidden flex cursor-pointer"
              >
                <Image
                  src="/images/svg/header/location_pin_white.svg"
                  alt="search"
                  width={24}
                  height={24}
                />
                <div className="ps-1 text-white text-sm sm:text-base">
                  <span className="flex sm:hidden">{t('branch')}</span>
                  <span className="hidden sm:flex">{t('storelocator')}</span>
                </div>
              </div>
            )}
          </Grid>
          <Grid
            item
            xs={mobileSearch ? 2 : 4}
            md={mobileSearch ? 1 : 3}
            lg={1}
            className="self-center inline-flex pt-0 lg:pt-4"
          >
            <div
              className="lg:hidden inline-flex h-8 w-full"
              onClick={handleMobileSearchToggle}
            >
              {mobileSearch ? (
                <Image
                  className="mx-auto my-auto"
                  src="/images/svg/header/close_white.svg"
                  alt="profile"
                  width={22}
                  height={22}
                />
              ) : (
                <Image
                  className="my-auto"
                  src="/images/svg/header/search_white.svg"
                  alt="profile"
                  width={22}
                  height={22}
                />
              )}
            </div>
            <AppLink
              href="/cart"
              className={`${mobileSearch ? 'hidden' : 'flex'} lg:flex`}
            >
              <div className="inline-flex ml-auto px-1 main-cart">
                <i className="bi bi-cart h-4 leading-4 text-white mr-1 my-auto"></i>
                <div
                  className="bg-secondaryOrange self-center flex rounded-full my-auto"
                  style={{
                    width: cartCount > 99 ? 28 : 25,
                    height: cartCount > 99 ? 28 : 25,
                  }}
                >
                  <div className="text-white m-auto font-bold text-xs">
                    {cartCount > 99 ? `99+` : cartCount}
                  </div>
                </div>
              </div>
            </AppLink>
          </Grid>
        </Grid>
        <DealFin />
      </div>
    </div>
  );
}
