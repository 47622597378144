export const ref = 'CUSTREF';
export const twdToken = 'TWACCESS_TOKEN';
export const the1Token = 'ACCESS_TOKEN';
export const toneToken = 'TONE_TOKEN';
// client (not http only)
export const pickupStcode = 'PICKSTORE';
export const stcode = 'DFSTORE';
export const customerName = 'SNAME';
export const cartCount = 'SHOPITEM';
export const shippingType = 'DTYPE';
export const isExpress = 'EXTYPE';
export const lang = 'lang';
export const tuneId = 'TUNE_ID';
export const infoProfile = 'infoProfile';
export const algolia = '_ALGOLIA';

export const serverCookieNameList = [
  ref,
  twdToken,
  the1Token,
  toneToken,
  tuneId,
  infoProfile,
  algolia,
];
