import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import Cookies from 'js-cookie';
import * as ServerCookies from '@/services/client/cookieService';
import * as cookieKey from '@/constants/cookieKey.constant';
import * as defaultValue from '@/constants/defaultValue.constant';
import CryptoJS from 'crypto-js';

/////CLIENT/////
function createClientAxiosInstance(): AxiosInstance {
  let retried = false;
  const instance: AxiosInstance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_BASE_URL_API,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    // config = { ...config, timeout: 30000 };
    return config;
  });
  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      if (response?.data.status === 401) {
        response.status = 401;
      }
      retried = false;
      return response;
    },
    async (error: AxiosError) => {
      if (
        !defaultValue.notRetryStatusCode.includes(
          error.response?.status ?? 504,
        ) &&
        error.message !== 'Unauthorized'
      ) {
        if (!retried && error.config) {
          retried = true;
          return instance(error.config);
        }
      }

      retried = false;
      return Promise.reject(error);
    },
  );

  return instance;
}

export const httpClientAPI = createClientAxiosInstance();

export const getDataFromAPI = async ({
  path,
  headers,
  checkInfo,
  timeout,
  // eslint-disable-next-line no-unused-vars
  lang, // อนาคตใช้
}: {
  path: string;
  headers?: any;
  checkInfo?: boolean;
  timeout?: number;
  lang?: string;
}): Promise<{ data: any; status: number | null }> => {
  try {
    const twdToken = await ServerCookies.get(cookieKey.twdToken);
    const the1Token = await ServerCookies.get(cookieKey.the1Token);
    const ref = await ServerCookies.get(cookieKey.ref);
    const response = await httpClientAPI.get(
      `${path}${lang ? `?lang=${lang}` : ''}`,
      {
        timeout: timeout ?? 30000,
        headers: {
          ...headers,
          authorization: twdToken ? `Bearer ${twdToken}` : undefined,
          accesstoken: the1Token,
          stcode:
            headers?.stcode ??
            Cookies.get(cookieKey.stcode) ??
            defaultValue.defaultStoreCode,
          ref: headers?.ref ?? ref,
        },
      },
    );
    let { data, status } = response;
    if (checkInfo == true && !data.info) {
      status = 401;
    }
    return { data, status };
  } catch (error: any) {
    return {
      data: error.response?.data,
      status: error.response?.status ?? null,
    };
  }
};

export const postDataFromAPI = async ({
  path,
  lang,
  body,
  form,
  headers,
  checkInfo,
  timeout,
}: {
  path: string;
  lang?: string;
  body?: any;
  form?: any;
  headers?: any;
  checkInfo?: boolean;
  timeout?: number;
}): Promise<{ data: any; status: number | null }> => {
  try {
    let langSend = lang;
    try {
      langSend = lang || Cookies.get(cookieKey.lang) || 'th';
    } catch (_) {}
    const twdToken = await ServerCookies.get(cookieKey.twdToken);
    const the1Token = await ServerCookies.get(cookieKey.the1Token);
    const ref = await ServerCookies.get(cookieKey.ref);
    let requestData: any;
    if (path == '/addtocart') {
      const key = `${process.env.NEXT_PUBLIC_SECRET_KEY}`;
      const encryptData = (data: string, key: string) => {
        return CryptoJS.AES.encrypt(data, key).toString();
      };
      const bodyEncrypt = encryptData(JSON.stringify(body), key);
      requestData = {
        lang: langSend,
        body: bodyEncrypt, // Add body as a separate key-value pair
      };
    } else {
      requestData = {
        lang: langSend,
        ...form, // Spread form properties if present
        ...(form ? {} : { ...body }), // If form is not present, spread body properties
      };
    }
    const response = await httpClientAPI.post(`${path}`, requestData, {
      timeout: timeout ?? 30000,
      headers: {
        ...headers,
        'Content-Type': form ? 'multipart/form-data' : 'application/json',
        authorization: twdToken ? `Bearer ${twdToken}` : undefined,
        accesstoken: the1Token,
        stcode:
          headers?.stcode ??
          Cookies.get(cookieKey.stcode) ??
          defaultValue.defaultStoreCode,
        ref: headers?.ref ?? ref,
      },
    });

    let { data, status } = response;
    if (checkInfo && !data.info) {
      status = 401;
    }
    return { data, status };
  } catch (error: any) {
    return {
      data: error.response?.data,
      status: error.response?.status ?? null,
    };
  }
};

/////SERVER/////

function createServerAxiosInstance(): AxiosInstance {
  let retried = false;
  const instance: AxiosInstance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_BASE_URL_SERVER_API,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      if (response?.data.status === 401) {
        response.status = 401;
      }
      retried = false;
      return response;
    },
    async (error: AxiosError) => {
      if (
        !defaultValue.notRetryStatusCode.includes(
          error.response?.status ?? 504,
        ) &&
        error.message !== 'Unauthorized'
      ) {
        if (!retried && error.config) {
          retried = true;
          return instance(error.config);
        }
      }

      retried = false;
      return Promise.reject(error);
    },
  );

  return instance;
}

export const httpServerAPI = createServerAxiosInstance();

export const getServerDataFromAPI = async ({
  path,
  headers,
  timeout,
}: {
  path: string;
  headers?: any;
  timeout?: number;
}): Promise<{ data: any; status: number | null }> => {
  try {
    const response = await httpServerAPI.get(`${path}`, {
      timeout: timeout ?? 30000,
      headers: { ...headers },
    });
    let { data, status } = response;
    return { data, status };
  } catch (error: any) {
    return {
      data: error.response?.data,
      status: error.response?.status ?? null,
    };
  }
};

export const postServerDataFromAPI = async ({
  path,
  lang,
  body,
  headers,
  timeout,
}: {
  path: string;
  lang?: string;
  body?: any;
  headers?: any;
  timeout?: number;
}): Promise<{ data: any; status: number | null }> => {
  try {
    let langSend = lang;
    try {
      langSend = lang || Cookies.get(cookieKey.lang) || 'th';
    } catch (_) {}
    const response = await httpServerAPI.post(
      `${path}`,
      {
        lang: langSend,
        ...body,
      },
      {
        timeout: timeout ?? 30000,
        headers: { ...headers },
      },
    );
    let { data, status } = response;
    return { data, status };
  } catch (error: any) {
    return {
      data: error.response?.data,
      status: error.response?.status ?? null,
    };
  }
};

///CMS SERVER////
export const httpClientServerCMS = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL_SERVER_CMS,
  headers: {
    authorization: `Bearer ${process.env.NEXT_PUBLIC_CMS_TOKEN}`,
    'Content-Type': 'application/json',
    'Cache-Control': 'public, max-age=28800',
  },
});
export const httpClientServerCMSNode = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL_SERVER_CMS_NODE,
  headers: {
    authorization: `Bearer ${process.env.NEXT_PUBLIC_CMS_TOKEN}`,
    'Content-Type': 'application/json',
    'Cache-Control': 'public, max-age=28800',
  },
});
export const getDataFromServerCMS = async ({
  path,
  lang,
  param,
}: {
  path: string;
  lang?: string;
  param?: any;
}): Promise<{ data: any; status: number | null }> => {
  try {
    let langSend = lang;
    try {
      langSend = lang || Cookies.get(cookieKey.lang) || 'th';
    } catch (_) {}
    // if (
    //   path.includes('/api/getstaticmenu') ||
    //   path.includes('/api/plpbanner')
    // ) {
    //   const response = await httpClientServerCMSNode.get(`${path}`, {
    //     headers: {
    //       authorization: `Bearer ${process.env.NEXT_PUBLIC_CMS_TOKEN}`,
    //     },
    //     params: {
    //       lang: langSend,
    //       ...param,
    //     },
    //   });
    //   const { data, status } = response;
    //   return {
    //     data,
    //     status,
    //   };
    // } else {
    //   const response = await httpClientServerCMS.get(`${path}`, {
    //     headers: {
    //       authorization: `Bearer ${process.env.NEXT_PUBLIC_CMS_TOKEN}`,
    //     },
    //     params: {
    //       lang: langSend,
    //       ...param,
    //     },
    //   });
    //   const { data, status } = response;
    //   return {
    //     data,
    //     status,
    //   };
    // }
    const response = await httpClientServerCMSNode.get(`${path}`, {
      headers: {
        authorization: `Bearer ${process.env.NEXT_PUBLIC_CMS_TOKEN}`,
      },
      params: {
        lang: langSend,
        ...param,
      },
    });
    const { data, status } = response;
    return {
      data,
      status,
    };
  } catch (error: any) {
    return { data: null, status: error.response?.status ?? null };
  }
};

////CMS/////
export const httpClientCMS = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL_CMS,
  headers: {
    authorization: `Bearer ${process.env.NEXT_PUBLIC_CMS_TOKEN}`,
    'Content-Type': 'application/json',
    'Cache-Control': 'public, max-age=28800',
  },
});

export const getDataFromCMS = async ({
  path,
  lang,
  param,
}: {
  path: string;
  lang?: string;
  param?: any;
}): Promise<{ data: any; status: number | null }> => {
  try {
    let langSend = lang;
    try {
      langSend = lang || Cookies.get(cookieKey.lang) || 'th';
    } catch (_) {}
    const response = await httpClientCMS.get(`${path}`, {
      headers: {
        authorization: `Bearer ${process.env.NEXT_PUBLIC_CMS_TOKEN}`,
      },
      params: {
        lang: langSend,
        ...param,
      },
    });
    const { data, status } = response;
    return {
      data,
      status,
    };
  } catch (error: any) {
    return { data: null, status: error.response?.status ?? null };
  }
};
