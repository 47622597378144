// To parse this data:
//
//   import { Convert, ProductDetailPageModel } from "./file";
//
//   const productDetailPageModel = Convert.toProductDetailPageModel(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

import { modelValue } from '@/utils/modelValue';
import {
  CookieData,
  CookieDataConverter,
  CustomerInfo,
  CustomerInfoConverter,
} from './Authen.model';
import { CatbarModel, CatbarModelConverter } from './Catbar.model';
import { AtbBadge, AtbBadgeConverter } from './Product.model';

export interface ProductDetailPageModel {
  areaPerItem?: number;
  pdfmanual?: null;
  title?: string;
  slug?: string;
  productdetail?: ProductDetailModel;
  priceset?: PricesetModel;
  istiles?: null;
  totalreview?: TotalreviewModel;
  imagelist360?: any;
  skucartstatus?: number;
  skucartservice?: string;
  pricewithser?: string;
  dfstore?: string;
  services?: ServiceModel[];
  cat3?: string;
  lv2?: string;
  atbBadges?: AtbBadge[];
  spucode?: number;
  catbars?: CatbarModel[];
  theonepromo?: TheonepromoModel;
  info?: CustomerInfo | null;
  cookies?: CookieData[] | null;
  spuByCat?: Spubycat;
  spuBySkus?: Spubysku[];
}

export interface PricesetModel {
  sku: string;
  prName?: string;
  price?: string;
  prUname?: string;
  stock?: number;
  disc?: string;
  per?: string;
  class?: string;
  location?: string;
  stname?: string;
  backORDflag?: boolean;
  preorder?: number;
  preOrderMsg?: string;
  whyshowleadtime?: string;
  abtflag?: string;
  tagnew?: number;
  hb?: string;
  dism?: string;
  isLongtailorDC?: string;
  hugthai?: number;
  linebt?: string;
  serSKCODE?: null;
  serPrice?: number;
  serOrgPrice?: number;
  serATB?: null;
  serDISM?: null;
  discwithser?: string;
  skUstatus?: string;
  assemble?: null;
  prname?: string;
  ldesp?: string;
  sdesp?: string;
  attrb?: string;
  pimg?: string;
  cat?: string;
  brand?: string;
  linkForVdo?: string;
  linkForVdo2?: null;
  prskustatus?: string;
  freeInstallBadge?: number;
  warranty?: string;
  warrantycode?: string;
  remarkMessage?: string;
  priceSqmeters?: null;
  persqmet?: null;
  prcode?: string;
  sparepartimg?: null;
  stockavail?: number;
  clearanceflag?: number;
  skuprice?: null;
  skudisc?: null;
  skuservicedisc?: null;
  badgeDiscNotInst?: null;
  badgeDiscInst?: null;
  saleflag?: number;
  freegiftBundle?: null;
  minqty?: number;
  boxWeight?: number;
  boxHeight?: number;
  boxWidth?: number;
  boxLength?: number;
  atbbadge?: null;
  isflagbranch?: number;
  catcode?: string;
  jsndoc?: null;
  packSize?: number;
  remark?: null;
  spu?: number;
  freegiftMessage?: string;
  canDelivery?: boolean;
  canPickup?: boolean;
  preorderSdate?: string;
  preorderAvail?: number;
  preorderAvailWithCart?: number;
  preorderRemark?: string;
  forceINST?: number;
  jsnLink?: string;
  isFreeGood?: number;
  isFreeService?: number;
}

export interface ProductDetailModel {
  prName?: string;
  ldesp?: string;
  sdesp?: string;
  attrb?: string;
  pimg?: string;
  cat?: string;
  sku?: string;
  brand?: string;
  linkForVdo?: string;
  linkForVdo2?: null;
  prskustatus?: string;
  assemble?: number;
  remarkMessage?: string;
  remarkMessageTH?: string;
  remarkMessageEN?: string;
}

export interface TheonepromoModel {
  point?: number;
  period?: string;
  theonepromo?: number;
}

export interface TotalreviewModel {
  avgreview?: number;
  totalreview?: number;
}

export interface ServiceModel {
  serSKCODE?: number;
  serPrice?: number;
  serOrgPrice?: number;
  serATB?: string;
  serDISM?: string;
  skuservicedisc?: number;
  skudisc?: number;
  priceAdd?: number;
}
export class ProductDetailPageModelConverter {
  public static fromJson(json: any): ProductDetailPageModel {
    let spuByCat = undefined;
    let spuBySkus = undefined;
    try {
      spuByCat = json['spubycat']
        ? SpubycatConverter.fromJson(
            JSON.parse(json['spubycat'].toString().trim()),
          )
        : undefined;
      spuBySkus = json['spubysku']
        ? JSON.parse(json['spubysku']).map((e: any) =>
            SpubyskuConverter.fromJson(e),
          )
        : undefined;
    } catch (_) {}

    const model: ProductDetailPageModel = {
      areaPerItem: json['areaPerItem'],
      pdfmanual: json['pdfmanual'],
      title: json['title'],
      slug: json['slug'] ? json['slug'] : json['slugName'],
      productdetail: json['productdetail']
        ? ProductDetailModelConverter.fromJson(json['productdetail'])
        : undefined,
      priceset: json['priceset']
        ? PricesetModelConverter.fromJson(json['priceset'])
        : undefined,
      istiles: json['istiles'],
      totalreview: json['totalreview']
        ? TotalreviewModelConverter.fromJson(json['totalreview'])
        : undefined,
      imagelist360: json['imagelist360'],
      skucartstatus: json['skucartstatus'],
      skucartservice: json['skucartservice'],
      pricewithser: json['pricewithser'],
      dfstore: json['DFSTORE'],
      services: json['services']
        ? json['services'].map((x: any) => ServiceModelConverter.fromJson(x))
        : undefined,
      cat3: json['cat3'],
      lv2: json['lv2'],
      atbBadges: json['atbBadge']
        ? json['atbBadge'].map((x: any) => AtbBadgeConverter.fromJson(x))
        : undefined,
      spucode: json['spucode'],
      catbars: json['catbars']
        ? json['catbars'].map((x: any) => CatbarModelConverter.fromJson(x))
        : undefined,
      theonepromo: json['theonepromo']
        ? TheonepromoModelConverter.fromJson(json['theonepromo'])
        : undefined,
      info: json['info']
        ? CustomerInfoConverter.fromJson(json['info'])
        : undefined,
      cookies: json['cookies']
        ? json['cookies'].map((x: any) => CookieDataConverter.fromJson(x))
        : undefined,
      spuByCat,
      spuBySkus,
    };
    return modelValue(model);
  }
}
export class PricesetModelConverter {
  public static fromJson(json: any): PricesetModel {
    let canDelivery = false;
    let canPickup = false;
    let stockAvail = json['STOCKAVAIL'];
    try {
      if (json['deliveryFlag']) {
        canDelivery = json['deliveryFlag'][0] === '1' ? true : false;
        canPickup = json['deliveryFlag'][1] === '1' ? true : false;
        if (!canDelivery && !canPickup) {
          stockAvail = 0;
        }
      }
    } catch (_) {}
    const model: PricesetModel = {
      sku: (json['SK_CODE'] ?? '').toString().trim(),
      prName: json['PR_NAME'],
      price: json['PRICE'],
      prUname: json['PR_UNAME'],
      stock: json['STOCK'],
      disc: json['disc'],
      per: json['per'],
      class: json['CLASS'],
      location: json['LOCATION'],
      stname: json['stname'],
      backORDflag: json['BackORDflag'],
      preorder: json['PREORDER'],
      preOrderMsg: json['PreOrderMsg'],
      whyshowleadtime: json['whyshowleadtime'],
      abtflag: json['ABTFLAG'],
      tagnew: json['TAGNEW'],
      hb: json['HB'],
      dism: json['DISM'],
      isLongtailorDC: json['isLongtailorDC'],
      hugthai: json['HUGTHAI'],
      linebt: json['Linebt'],
      serSKCODE: json['serSKCODE'],
      serPrice: json['serPrice'],
      serOrgPrice: json['serOrgPrice'],
      serATB: json['serATB'],
      serDISM: json['serDISM'],
      discwithser: json['discwithser'],
      skUstatus: json['SKUstatus'],
      assemble: json['assemble'],
      prname: json['prname'],
      ldesp: json['ldesp'],
      sdesp: json['sdesp'],
      attrb: json['attrb'],
      pimg: json['pimg'],
      cat: json['cat'],
      brand: json['brand'],
      linkForVdo: json['linkForVdo'],
      linkForVdo2: json['linkForVdo2'],
      prskustatus: json['prskustatus'],
      freeInstallBadge: json['FREEINSTALBADGE'],
      warranty: json['WARRANTY'],
      warrantycode: json['WARRANTYCODE'],
      remarkMessage: json['remark_message'],
      priceSqmeters: json['PRICE_SQMETERS'],
      persqmet: json['PERSQMET'],
      prcode: json['PRCODE'],
      sparepartimg: json['SPAREPARTIMG'],
      stockavail: stockAvail,
      clearanceflag: json['clearanceflag'],
      skuprice: json['SKUPRICE'],
      skudisc: json['SKUDISC'],
      skuservicedisc: json['SKUSERVICEDISC'],
      badgeDiscNotInst: json['BadgeDisc_notInst'],
      badgeDiscInst: json['BadgeDisc_Inst'],
      saleflag: json['saleflag'],
      freegiftBundle: json['FREEGIFTBUNDLE'],
      minqty: json['MINQTY'],
      boxWeight: json['BOX_WEIGHT'],
      boxHeight: json['BOX_HEIGHT'],
      boxWidth: json['BOX_WIDTH'],
      boxLength: json['BOX_LENGTH'],
      atbbadge: json['ATBBADGE'],
      isflagbranch: json['isflagbranch'],
      catcode: json['CATCODE'],
      jsndoc: json['Jsndoc'],
      packSize: json['packSize'],
      remark: json['Remark'],
      spu: json['SPU'],
      freegiftMessage: json['FREEGIFTMSG'],
      canDelivery,
      canPickup,
      preorderSdate: json['preorderSdate'],
      preorderAvail:
        json['preorderAvail'] !== undefined && json['preorderAvail'] !== null
          ? Number(json['preorderAvail'])
          : undefined,
      preorderAvailWithCart:
        (json['preorderAvailWithCart'] ?? json['preorderAvail']) !==
          undefined &&
        (json['preorderAvailWithCart'] ?? json['preorderAvail']) !== null
          ? Number(json['preorderAvailWithCart'] ?? json['preorderAvail'])
          : undefined,
      preorderRemark: json['preorderRemark'],
      forceINST: json['forceINST'],
      jsnLink: json['jsnLink'],
      isFreeGood: json['isfreegood'] ? json['isfreegood'] : 0,
      isFreeService: json['isfreeservice'] ? json['isfreeservice'] : 0,
    };
    return modelValue(model);
  }
}

export class ProductDetailModelConverter {
  public static fromJson(json: any): ProductDetailModel {
    const model: ProductDetailModel = {
      prName: json['prname'],
      ldesp: json['ldesp'],
      sdesp: json['sdesp'],
      attrb: json['attrb'],
      pimg: json['pimg'],
      cat: json['cat'],
      sku: json['skc'],
      brand: json['brand'],
      linkForVdo: json['linkForVdo'],
      linkForVdo2: json['linkForVdo2'],
      prskustatus: json['prskustatus'],
      assemble: json['assemble'],
      remarkMessage: json['RemarkMessage'],
      remarkMessageTH: json['RemarkMessage_TH'],
      remarkMessageEN: json['RemarkMessage_EN'],
    };
    return modelValue(model);
  }
}

export class TotalreviewModelConverter {
  public static fromJson(json: any): TotalreviewModel {
    const model: TotalreviewModel = {
      avgreview: json['AVGREVIEW'],
      totalreview: json['TOTALREVIEW'],
    };
    return modelValue(model);
  }
}

export class ServiceModelConverter {
  public static fromJson(json: any): ServiceModel {
    const model: ServiceModel = {
      serSKCODE: json['serSKCODE'],
      serPrice: json['serPrice'],
      serOrgPrice: json['serOrgPrice'],
      serATB: json['serATB'],
      serDISM: json['serDISM'],
      skuservicedisc: json['SKUSERVICEDISC'],
      skudisc: json['SKUDISC'],
      priceAdd: json['PriceAdd'],
    };
    return modelValue(model);
  }
}
export class TheonepromoModelConverter {
  public static fromJson(json: any): TheonepromoModel {
    const model: TheonepromoModel = {
      point: json['point'],
      period: json['period'],
      theonepromo: json['theonepromo'],
    };
    return modelValue(model);
  }
}

export interface Spubysku {
  sku?: string;
  slugname?: string;
  prop?: string[];
}

export class SpubyskuConverter {
  public static fromJson(json: any): Spubysku {
    const model: Spubysku = {
      sku: json['SKCODE']?.toString()?.trim(),
      slugname: json['slugname']?.toString()?.trim(),
      prop: json['PROP']
        ? Object.entries(JSON.parse(json['PROP'].toString().trim())).map(
            (e) => {
              return e[1]?.toString().trim() ?? '';
            },
          )
        : undefined,
    };
    return modelValue(model);
  }
}

export interface Spubycat {
  byCode?: string;
  cat?: string;
  properties?: Property[];
}

export class SpubycatConverter {
  public static fromJson(json: any): Spubycat {
    let properties = [];
    try {
      properties = json['PROPERTY']
        ? JSON.parse(json['PROPERTY']).map((x: any, i: number) =>
            PropertyConverter.fromJson(x, i),
          )
        : undefined;
    } catch (_) {}
    const model: Spubycat = {
      byCode: json['BYCODE'],
      cat: json['CLASS'],
      properties,
    };
    return modelValue(model);
  }
}

export interface Property {
  index?: number;
  name?: string;
  value?: string;
  propertyValueList?: string[];
}

export class PropertyConverter {
  public static fromJson(json: any, index: number): Property {
    let propertyValueList: string[] = [];
    try {
      if (json['VAL'].trim() != '') {
        propertyValueList = json['VAL']
          .trim()
          .split('|')
          .map((e: string) => e.trim());
      }
    } catch (_) {}
    const model: Property = {
      index,
      name: json['name'],
      value: json['VAL'],
      propertyValueList,
    };
    return modelValue(model);
  }
}

export interface SpuDataToUse {
  index: number;
  lable: string;
  props: PropsDataToUse[];
}

export interface PropsDataToUse {
  propertyIndex: number;
  index: number;
  name: string;
  status: PropStatus;
}

export enum PropStatus {
  // eslint-disable-next-line no-unused-vars
  notSelected = 0,
  // eslint-disable-next-line no-unused-vars
  selected = 1,
  // eslint-disable-next-line no-unused-vars
  disabled = 2,
}

export interface Spubysku {
  sku?: string;
  slugname?: string;
  prop?: string[];
}
