import { ProductModel } from '@/models/Product.model';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface ProductViewState {
  products: ProductModel[];
}

const initialState: ProductViewState = {
  products: [],
};

const productViewSendSlice = createSlice({
  name: 'productViewSendSlice',
  initialState: initialState,
  reducers: {
    addProductToList: (state, { payload }: { payload: ProductModel }) => {
      state.products = [...state.products, payload];
    },
    clearProductList: (state) => {
      state.products = [];
    },
  },
});

export const { addProductToList, clearProductList } =
  productViewSendSlice.actions;

export const productListToSendSelector = (store: RootState): ProductModel[] =>
  store.productViewSend.products;

export default productViewSendSlice.reducer;
