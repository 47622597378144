import { AddressPageResponse } from '@/models/profile/AddressList.model';
import {
  PostAddressStatus,
  getProfileAddressList,
  postAddress,
  geoLocation,
} from '@/services/client/addressService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ServerCookies from '@/services/client/cookieService';
import * as cookieKey from '@/constants/cookieKey.constant';
import { RootState } from '@/store/store';
import toast from 'react-hot-toast';
import thText from '@/locales/th/address.json';
import enText from '@/locales/en/address.json';
import { Address } from '@/models/Address.model';
import { GeoLocationResponse } from '@/models/geoLocation.model';

interface AddressState {
  result?: AddressPageResponse | null;
  isLoading: boolean;
  error?: string;
  deletingAddressName?: string;
  isLoadingEditAddress: boolean;
  errorEditAddress?: string;
  editAddressSucess?: boolean;

  //Edit and Add Address
  isShowAddressForm?: boolean;
  editingAddress?: Address;
  //Edit and Add Address

  //add address
  isLoadingSaveAddress: boolean;
  errorSaveAddress?: string;
  saveAddressSuccess?: boolean;
  //add address

  //query zipcode
  zipcodeResult?: GeoLocationResponse[] | null;
  isLoadingZipcodeResult: boolean;
  errorZipcodeResult?: string;
  provinceResult: string[] | null;
  districtResult: string[] | null;
  subdistrictResult: string[] | null;
  //query zipcode

  //get latlong
  latitudeAdress?: number;
  longtitudeAdress?: number;
  //get latlong
}

const initialState: AddressState = {
  isLoading: false,
  isLoadingEditAddress: false,
  isLoadingSaveAddress: false,
  isLoadingZipcodeResult: false,
  provinceResult: null,
  districtResult: null,
  subdistrictResult: null,
};
export const profileAddressListQuery = createAsyncThunk(
  'address/profile/query',
  async ({ lang }: { lang?: string; overideOnSuccess?: () => void }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await getProfileAddressList({ lang });
    if (!response.data) {
      throw new Error(`${response.status ?? '500.'}`);
    }
    return response;
  },
);

export const editAddress = createAsyncThunk(
  'address/editAddress',
  async ({ lang, address }: { lang?: string; address: Address }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await postAddress(address, PostAddressStatus.edit, lang);
    if (response) {
      throw new Error(`${response}`);
    }
    return response;
  },
);

export const deleteAddress = createAsyncThunk(
  'address/deleteAddress',
  async ({ lang, addressName }: { lang?: string; addressName: string }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await postAddress(
      { addressName },
      PostAddressStatus.delete,
      lang,
    );
    if (response) {
      throw new Error(`${response}`);
    }
    return response;
  },
);

export const saveAddress = createAsyncThunk(
  'address/saveAddress',
  async ({ lang, address }: { lang?: string; address: Address }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await postAddress(address, PostAddressStatus.add, lang);
    if (response) {
      throw new Error(`${response}`);
    }
    return response;
  },
);

export const getGeoLocation = createAsyncThunk(
  'address/getGeoLocation',
  async ({
    lang,
    zipCode,
  }: {
    lang?: string;
    zipCode: string;
    province?: string;
    district?: string;
    subDistrict?: string;
  }) => {
    if (!zipCode || zipCode.length < 5) {
      throw new Error(`invalid_zipcode`);
    }
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await geoLocation({ zipCode, lang });
    if (!response) {
      throw new Error(`${response ?? 'cannotfindzipcode'}`);
    }
    return response;
  },
);

export const addressSlice = createSlice({
  name: 'address',
  initialState: initialState,
  reducers: {
    openAddressForm: (state, action: { payload: Address | undefined }) => {
      state.isShowAddressForm = true;
      state.editingAddress = action.payload;
    },
    closeAddressForm: (state) => {
      state.isShowAddressForm = false;
      state.editingAddress = undefined;
      state.provinceResult = null;
      state.districtResult = null;
      state.subdistrictResult = null;
      state.error = undefined;
      state.errorEditAddress = undefined;
      state.errorSaveAddress = undefined;
      state.errorZipcodeResult = undefined;
      state.editAddressSucess = undefined;
      state.saveAddressSuccess = undefined;
      state.zipcodeResult = null;
    },
    clearError: (state) => {
      state.error = undefined;
      state.errorEditAddress = undefined;
      state.errorSaveAddress = undefined;
      state.errorZipcodeResult = undefined;
    },
    clearResult: (state) => {
      state.result = undefined;
    },
    clearSuccess: (state) => {
      state.editAddressSucess = undefined;
      state.saveAddressSuccess = undefined;
      state.zipcodeResult = null;
      state.provinceResult = null;
      state.districtResult = null;
      state.subdistrictResult = null;
    },
    clearZipCodeResult: (state) => {
      state.zipcodeResult = null;
      state.provinceResult = null;
      state.districtResult = null;
      state.subdistrictResult = null;
    },
    onChangeDistrict: (state, action) => {
      const result = state.zipcodeResult;
      if (result) {
        let tambonSelect: string[] = [];
        const getDistrict = result?.filter((e) => e.district == action.payload);
        state.subdistrictResult = null;
        for (let i = 0; i < getDistrict.length; i++) {
          const el = getDistrict[i];
          let tambon = el.tambon;
          if (tambon) {
            tambonSelect.push(tambon);
          }
        }
        state.subdistrictResult = tambonSelect;
      }
    },
    onChangeProvince: (state, action) => {
      const result = state.zipcodeResult;
      if (result) {
        let tambonSelect: string[] = [];
        let districtSelect: string[] = [];
        const getDistrict = result?.filter((e) => e.province == action.payload);
        state.subdistrictResult = null;
        let olddistict = '';
        for (let i = 0; i < getDistrict.length; i++) {
          const el = getDistrict[i];
          if (el.district && el.district != olddistict) {
            districtSelect.push(el.district);
            olddistict = el.district;
          }
          let tambon = el.tambon;
          if (tambon) {
            tambonSelect.push(tambon);
          }
        }
        state.districtResult = districtSelect;
        state.subdistrictResult = tambonSelect;
      }
    },
  },
  extraReducers: (builder) => {
    // pending, fulfilled, rejected
    builder
      .addCase(profileAddressListQuery.pending, (state, action) => {
        if (action.meta.arg.overideOnSuccess === undefined) {
          state.isLoading = true;
          state.result = undefined;
        }
      })
      .addCase(profileAddressListQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        action.meta.arg.overideOnSuccess?.();
        if (action.payload === null) {
          state.result = {
            info: null,
          };
        } else {
          state.result = action.payload?.data ?? null;
        }
      })
      .addCase(profileAddressListQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.result = null;
        state.error = action.error.message;
      });
    // pending, fulfilled, rejected
    builder
      .addCase(deleteAddress.pending, (state, action) => {
        state.isLoadingEditAddress = true;
        state.errorEditAddress = undefined;
        state.deletingAddressName = action.meta.arg.addressName;
        state.editAddressSucess = undefined;
      })
      .addCase(deleteAddress.fulfilled, (state, action) => {
        state.isLoadingEditAddress = false;
        state.deletingAddressName = undefined;
        state.editAddressSucess = true;
        if (state.result?.myAddress) {
          state.result = {
            ...state.result,
            myAddress: state.result?.myAddress.filter(
              (e) => e.addressName !== action.meta.arg.addressName,
            ),
          };
        }
        let text =
          action.meta.arg.lang === 'th'
            ? `${thText.deleteaddress} ${action.meta.arg.addressName} ${thText.finished}`
            : `${enText.deleteaddress} ${action.meta.arg.addressName} ${enText.finished}`;
        toast.success(text, {
          duration: 4000,
          position: 'bottom-right',
          iconTheme: {
            primary: '#000',
            secondary: '#fff',
          },
          ariaProps: {
            role: 'status',
            'aria-live': 'polite',
          },
        });
      })
      .addCase(deleteAddress.rejected, (state, action) => {
        state.isLoadingEditAddress = false;
        state.errorEditAddress = action.error.message;
        state.deletingAddressName = undefined;
        state.editAddressSucess = undefined;
      });
    // pending, fulfilled, rejected
    builder
      .addCase(editAddress.pending, (state) => {
        state.isLoadingEditAddress = true;
        state.errorEditAddress = undefined;
        state.editAddressSucess = undefined;
      })
      .addCase(editAddress.fulfilled, (state, action) => {
        state.isLoadingEditAddress = false;
        state.editAddressSucess = true;
        if (state.result && state.result?.myAddress) {
          const findEditAddressIndex = state.result?.myAddress.findIndex(
            (e) => e.addressName === action.meta.arg.address.addressName,
          );
          if (findEditAddressIndex !== undefined && findEditAddressIndex >= 0) {
            const newAddress = [...state.result?.myAddress];
            const inputAddress = action.meta.arg.address;
            newAddress[findEditAddressIndex] = {
              ...inputAddress,
              isDefaultShip: inputAddress.originalStt
                ? inputAddress.originalStt.toString().toLowerCase() ===
                    'ship' ||
                  inputAddress.originalStt.toString().toLowerCase() === 'all'
                  ? true
                  : false
                : false,
              isDefaultBill: inputAddress.originalStt
                ? inputAddress.originalStt.toString().toLowerCase() ===
                    'bill' ||
                  inputAddress.originalStt.toString().toLowerCase() === 'all'
                  ? true
                  : false
                : false,
            };
            if (newAddress[findEditAddressIndex].isDefaultShip === true) {
              const findAddressIndex = state.result?.myAddress?.findIndex(
                (e) => e.isDefaultShip === true,
              );
              if (
                findAddressIndex !== undefined &&
                findAddressIndex >= 0 &&
                findAddressIndex !== findEditAddressIndex
              ) {
                newAddress[findAddressIndex].isDefaultShip = false;
              }
            }
            if (newAddress[findEditAddressIndex].isDefaultBill === true) {
              const findAddressIndex = state.result?.myAddress?.findIndex(
                (e) => e.isDefaultBill === true,
              );
              if (
                findAddressIndex !== undefined &&
                findAddressIndex >= 0 &&
                findAddressIndex !== findEditAddressIndex
              ) {
                newAddress[findAddressIndex].isDefaultBill = false;
              }
            }
            state.result = {
              ...state.result,
              myAddress: newAddress,
            };
          }
        }
      })
      .addCase(editAddress.rejected, (state, action) => {
        state.isLoadingEditAddress = false;
        state.errorEditAddress = action.error.message;
        state.editAddressSucess = undefined;
      });
    // pending, fulfilled, rejected
    builder
      .addCase(saveAddress.pending, (state) => {
        state.isLoadingSaveAddress = true;
        state.errorSaveAddress = undefined;
        state.saveAddressSuccess = undefined;
      })
      .addCase(saveAddress.fulfilled, (state, action) => {
        state.isLoadingSaveAddress = false;
        state.saveAddressSuccess = true;
        const inputAddress = action.meta.arg.address;
        const currentAddress = [...(state.result?.myAddress ?? [])];
        const isDefaultShip = inputAddress.originalStt
          ? inputAddress.originalStt.toString().toLowerCase() === 'ship' ||
            inputAddress.originalStt.toString().toLowerCase() === 'all'
            ? true
            : false
          : false;
        const isDefaultBill = inputAddress.originalStt
          ? inputAddress.originalStt.toString().toLowerCase() === 'bill' ||
            inputAddress.originalStt.toString().toLowerCase() === 'all'
            ? true
            : false
          : false;
        if (isDefaultShip) {
          const findAddressIndex = state.result?.myAddress?.findIndex(
            (e) => e.isDefaultBill === isDefaultShip,
          );
          if (findAddressIndex !== undefined && findAddressIndex >= 0) {
            currentAddress[findAddressIndex].isDefaultShip = false;
          }
        }
        if (isDefaultBill) {
          const findAddressIndex = state.result?.myAddress?.findIndex(
            (e) => e.isDefaultBill === isDefaultBill,
          );
          if (findAddressIndex !== undefined && findAddressIndex >= 0) {
            currentAddress[findAddressIndex].isDefaultBill = false;
          }
        }
        const newAddress = {
          ...inputAddress,
          isDefaultShip,
          isDefaultBill,
        };
        state.result = {
          ...state.result,
          myAddress: [...currentAddress, newAddress],
        };
      })
      .addCase(saveAddress.rejected, (state, action) => {
        state.isLoadingSaveAddress = false;
        state.errorSaveAddress = action.error.message;
        state.saveAddressSuccess = undefined;
      });
    // pending, fulfilled, rejected
    builder
      .addCase(getGeoLocation.pending, (state) => {
        state.isLoadingZipcodeResult = true;
        state.errorZipcodeResult = undefined;
        state.zipcodeResult = undefined;
      })
      .addCase(getGeoLocation.fulfilled, (state, action) => {
        state.isLoadingZipcodeResult = false;
        state.zipcodeResult = action.payload;
        const objProvince = action.payload;
        const provinceSelect: string[] = [];
        const districtSelect: string[] = [];
        const tambonSelect: string[] = [];
        const provinceSelected: string | undefined = action.meta.arg.province;
        const districtSelected: string | undefined = action.meta.arg.district;
        if (action.payload && action.payload?.length > 0) {
          state.latitudeAdress = Number(action?.payload[0].lat);
          state.longtitudeAdress = Number(action?.payload[0].lng);
        }
        for (let i = 0; i < (objProvince ?? []).length; i++) {
          const element = objProvince![i];
          let province = element.province;
          if (province && !provinceSelect.find((e) => e === province)) {
            provinceSelect.push(province);
          }
          if (provinceSelect[0] == province || provinceSelected === province) {
            let district = element.district;
            if (district) {
              if (
                !districtSelect.find((e) => e === district) &&
                (provinceSelected?.includes(province ?? '') ||
                  (provinceSelected === undefined &&
                    provinceSelect[0] === province))
              ) {
                districtSelect.push(district);
              }
              let tambon = element.tambon;
              if (
                tambon &&
                !tambonSelect.find((e) => e === tambon) &&
                (districtSelected?.includes(district) ||
                  (districtSelected && district?.includes(districtSelected)) ||
                  (districtSelected === undefined &&
                    districtSelect[0] === district))
              ) {
                tambonSelect.push(tambon);
              }
            }
          }
        }
        state.provinceResult = provinceSelect;
        state.districtResult = districtSelect;
        state.subdistrictResult = tambonSelect;
      })
      .addCase(getGeoLocation.rejected, (state, action) => {
        state.isLoadingZipcodeResult = false;
        state.errorZipcodeResult =
          action.error.message === 'invalid_zipcode'
            ? undefined
            : 'cannotfindzipcode';
        state.provinceResult = null;
        state.districtResult = null;
        state.subdistrictResult = null;
      });
  },
});

export const {
  openAddressForm,
  closeAddressForm,
  clearSuccess,
  clearError,
  clearResult,
  onChangeDistrict,
  onChangeProvince,
  clearZipCodeResult,
} = addressSlice.actions;

export const addressListProfileResultSelector = (
  store: RootState,
): AddressPageResponse | undefined | null => store.profileAddress.result;

export const isLoadingAddressListProfileSelector = (
  store: RootState,
): boolean => store.profileAddress.isLoading;

export const errorAddressListProfileSelector = (
  store: RootState,
): string | undefined => store.profileAddress.error;

export const isLoadingEditAddressSelector = (store: RootState): boolean =>
  store.profileAddress.isLoadingEditAddress;

export const errorEditAddressSelector = (
  store: RootState,
): string | undefined => store.profileAddress.errorEditAddress;

export const deletingAddressNameSelector = (
  store: RootState,
): string | undefined => store.profileAddress.deletingAddressName;

export const isShowAddressFormSelector = (
  store: RootState,
): boolean | undefined => store.profileAddress.isShowAddressForm;

export const editingAddressSelector = (store: RootState): Address | undefined =>
  store.profileAddress.editingAddress;

export const editAddressSucessSelector = (
  store: RootState,
): boolean | undefined => store.profileAddress.editAddressSucess;

export const saveAddressSucessSelector = (
  store: RootState,
): boolean | undefined => store.profileAddress.saveAddressSuccess;

export const provinceOptionSelector = (
  store: RootState,
): string[] | null | undefined => store.profileAddress.provinceResult;

export const districtOptionSelector = (
  store: RootState,
): string[] | null | undefined => store.profileAddress.districtResult;

export const subDistrictOptionSelector = (
  store: RootState,
): string[] | null | undefined => store.profileAddress.subdistrictResult;

export const latitudeAdressSelector = (
  store: RootState,
): number | null | undefined => store.profileAddress.latitudeAdress;

export const longtitudeAdressSelector = (
  store: RootState,
): number | null | undefined => store.profileAddress.longtitudeAdress;

export const isLoadingSaveAddressSelector = (store: RootState): boolean =>
  store.profileAddress.isLoadingSaveAddress;

export const isLoadingZipcodeResultSelector = (store: RootState): boolean =>
  store.profileAddress.isLoadingZipcodeResult;

export const errorSaveAddressSelector = (
  store: RootState,
): string | undefined => store.profileAddress.errorSaveAddress;

export const errorZipcodeResultSelector = (
  store: RootState,
): string | undefined => store.profileAddress.errorZipcodeResult;

export default addressSlice.reducer;
