import { modelValue } from '@/utils/modelValue';
import { CookieData } from './Authen.model';

export interface SendLoginRequestModel {
  grant_type?: string;
  scope?: string;
  otp_request_id?: string;
  otp_code?: string;
  username?: string;
  pin?: string;
  password?: string;
  identity?: string;
  identity_code?: string;
}

export interface SendLoginResponseModel {
  isError: boolean;
  cookies?: CookieData[];
  isbanned: boolean;
  couponPopup?: CouponPopup[];
  errorType?: number;
  errorMessage?: string;
  t1token?: string;
  checkConsent?: ConsentData;
  info?: string;
  isSetpin?: boolean;
}

export class SendLoginResponseModelConverter {
  public static fromJson(json: any): SendLoginResponseModel {
    const model: SendLoginResponseModel = {
      isError: json['is_error'] == true ? true : false,
      cookies: json['cookies'],
      isbanned: json['isbanned'] == true ? true : false,
      couponPopup: json['couponPopup']
        ? json['couponPopup'].map((e: CouponPopup) => {
            try {
              e = CouponPopupConverter.fromJson(e);
            } catch (_) {}
            return e;
          })
        : undefined,
      errorType: json['type'],
      errorMessage: json['emsg'],
      t1token: json['t1token'],
      checkConsent: json['checkconsent']
        ? ConsentDataConverter.fromJson(json['checkconsent'])
        : undefined,
      info: json['info'],
      isSetpin: json['isSetpin'] ?? undefined,
    };
    return modelValue(model);
  }
}

export interface CouponPopup {
  image?: string | null;
  amount?: string;
  unit?: string;
  endsDate?: string;
}

export class CouponPopupConverter {
  public static fromJson(json: any): CouponPopup {
    const model: CouponPopup = {
      image: json['image'],
      amount: json['amount'],
      unit: json['unit'],
      endsDate: json['endsDate'],
    };
    return modelValue(model);
  }
}
export interface ConsentData {
  consentPrivacyVersion?: string;
  consentMarketingStatus: boolean;
}

export class ConsentDataConverter {
  public static fromJson(json: any): ConsentData {
    const model: ConsentData = {
      consentPrivacyVersion: json['version'],
      consentMarketingStatus: json['consent'],
    };
    return modelValue(model);
  }
}
