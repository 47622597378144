import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { getIssueFromCode } from '@/services/client/getIssueFromCodeService';
import { GetIssueFromCodeModel } from '@/models/GetIssueFromCode.model';
interface GetIssueFromCodeState {
  result: GetIssueFromCodeModel;
  isLoading: boolean;
}

const initialState: GetIssueFromCodeState = {
  result: {
    iserror: true,
    msg: 'Initial message',
    status: 500,
  },
  isLoading: false,
};

export const getIssueFromCodeQuery = createAsyncThunk(
  'getIssueFromCode/add',
  async ({ lang, code }: { lang: string; code: string }) => {
    const response = await getIssueFromCode({ lang, code });
    return response;
  },
);

const getIssueFromCodeSlice = createSlice({
  name: 'getIssueFromCode',
  initialState: initialState,
  reducers: {
    clearResult: (state) => {
      state.result = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIssueFromCodeQuery.pending, (state) => {
        state.result = {};
        state.isLoading = true;
      })
      .addCase(getIssueFromCodeQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.result = action.payload;
      })
      .addCase(getIssueFromCodeQuery.rejected, (state) => {
        state.isLoading = false;
        state.result = {};
      });
  },
});

export const { clearResult } = getIssueFromCodeSlice.actions;

export const getIssueFromCodeResultsSelector = (
  store: RootState,
): GetIssueFromCodeModel => store.getIssueFromCode.result;

export const isLoadingIssueFromCodeSelector = (store: RootState): boolean =>
  store.getIssueFromCode.isLoading;

export default getIssueFromCodeSlice.reducer;
