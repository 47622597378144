import React from 'react';
import Link from 'next/link';
import { styled } from '@mui/material/styles';
import { useRouter } from 'next/router';
import { Box, useTheme } from '@mui/material';

export default function LangHeader() {
  const theme = useTheme();
  const router = useRouter();
  const LangButton = styled(Link)(({ active }: { active: string }) => ({
    backgroundColor:
      active == 'true' ? theme.palette.primary.main : 'rgb(229, 231, 235)',
    color: active == 'true' ? 'white' : 'black',
  }));

  return (
    <Box className="ps-2 self-center flex cursor-pointer text-sm">
      <LangButton
        href={`/th/${router.asPath}`}
        locale="th"
        className="my-auto px-2 rounded-l-md"
        active={(router.locale === 'th').toString()}
      >
        ภาษาไทย
      </LangButton>
      <LangButton
        href={`/en/${router.asPath}`}
        locale="en"
        className="my-auto px-2 rounded-r-md"
        active={(router.locale === 'en').toString()}
      >
        English
      </LangButton>
    </Box>
  );
}
