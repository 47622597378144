import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Get1010CouponModel } from '@/models/Get1010Coupon.model';
import { get1010Coupon } from '@/services/client/get1010CouponService';
interface Get1010CouponState {
  result: Get1010CouponModel;
  isLoading: boolean;
}

const initialState: Get1010CouponState = {
  result: {
    iserror: true,
    msg: 'Initial message',
    status: 500,
  },
  isLoading: false,
};

export const get1010CouponQuery = createAsyncThunk(
  'get1010Coupon/add',
  async ({ lang, rewardid }: { lang: string; rewardid: string }) => {
    const response = await get1010Coupon({ lang, rewardid });
    return response;
  },
);

const get1010CouponSlice = createSlice({
  name: 'get1010Coupon',
  initialState: initialState,
  reducers: {
    clearResultGet1010: (state) => {
      state.result = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get1010CouponQuery.pending, (state) => {
        state.result = {};
        state.isLoading = true;
      })
      .addCase(get1010CouponQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.result = action.payload;
      })
      .addCase(get1010CouponQuery.rejected, (state) => {
        state.isLoading = false;
        state.result = {};
      });
  },
});

export const { clearResultGet1010 } = get1010CouponSlice.actions;

export const get1010CouponResultsSelector = (
  store: RootState,
): Get1010CouponModel => store.get1010Coupon.result;

export const isLoading1010CouponSelector = (store: RootState): boolean =>
  store.get1010Coupon.isLoading;

export default get1010CouponSlice.reducer;
