import { modelValue } from '@/utils/modelValue';

export interface AddFreeGiftModel {
  dbcode: boolean;
  dbitems: Dbitem[];
  dbmessage: string;
}
export class AddFreeGiftModelConverter {
  public static fromJson(json: any): AddFreeGiftModel {
    const model: AddFreeGiftModel = {
      dbcode: json['dbcode'],
      dbitems: json['dbitems']
        ? json['dbitems'].map((e: any) => DbitemConverter.fromJson(e))
        : undefined,
      dbmessage: json['dbmessage'],
    };
    return modelValue(model);
  }
}
export interface Dbitem {
  ref: string;
  stcode: string;
  seq: number;
  skcode: number;
  qnt: number;
  ctlid: number;
}

export class DbitemConverter {
  public static fromJson(json: any): Dbitem {
    const model: Dbitem = {
      ref: json['REF'],
      stcode: json['STCODE'],
      seq: json['SEQ'],
      skcode: json['SKCODE'],
      qnt: json['QNT'],
      ctlid: json['CTLID'],
    };
    return modelValue(model);
  }
}
