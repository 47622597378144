import footerClass from '@/styles/footer.module.css';
import React from 'react';
import { useTranslation } from 'next-i18next';

export default function BottomFooter() {
  const { t } = useTranslation();
  return (
    <div className={`${footerClass.bottomFooter} bg-grayLight`}>
      <div className="max-width-container text-black block lg:flex">
        <div style={{ flexGrow: 1 }}>{t('sidiary')}</div>
        <div className="ms-auto">{t('subsidiary')}</div>
      </div>
    </div>
  );
}
