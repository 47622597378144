import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// // import { UserData } from "@/models/user.model";
import { RootState } from '@/store/store';
// // import * as serverService from "@/services/serverService";
// // import httpClient from "@/utils/httpClient";
// import { AxiosRequestConfig } from "axios";
//import Router from 'next/router';
import { ProductModel } from '@/models/Product.model';
import { getEventData } from '@/services/client/getEventDataService';

interface homePageState {
  isLoading: boolean;
  error?: string;
  dealOfTheDayProducts?: ProductModel[];
  flashSaleProducts?: ProductModel[];
}

const initialState: homePageState = {
  isLoading: false,
  dealOfTheDayProducts: undefined,
  flashSaleProducts: undefined,
};

export const dealOfTheDay = createAsyncThunk(
  'event/dealOfTheDay',
  async ({
    lang,
    hotcat,
    maxshow,
  }: {
    lang?: string | undefined;
    hotcat?: string;
    maxshow?: number;
  }) => {
    const response = await getEventData({ lang, hotcat, maxshow });
    if (!response) {
      throw new Error('error');
    }
    return response;
  },
);
export const flashSale = createAsyncThunk(
  'event/flashSale',
  async ({
    lang,
    hotcat,
    maxshow,
  }: {
    lang?: string | undefined;
    hotcat?: string;
    maxshow?: number;
  }) => {
    const response = await getEventData({ lang, hotcat, maxshow });
    if (!response) {
      throw new Error('error');
    }
    return response;
  },
);

const homePageSlice = createSlice({
  name: 'homepage',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(dealOfTheDay.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(dealOfTheDay.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dealOfTheDayProducts = action.payload?.products;
      })
      .addCase(dealOfTheDay.rejected, (state) => {
        state.isLoading = false;
        state.dealOfTheDayProducts = undefined;
      });
    builder
      .addCase(flashSale.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(flashSale.fulfilled, (state, action) => {
        state.isLoading = false;
        state.flashSaleProducts = action.payload?.products;
      })
      .addCase(flashSale.rejected, (state) => {
        state.isLoading = false;
        state.flashSaleProducts = undefined;
      });
  },
});

// interface UserState {
//   username: string;
//   accessToken: string;
//   error?: string;
//   isAuthenticated: boolean;
//   isAuthenticating: boolean;
//   //   user?: UserData;
// }

// interface SingleProp {
//   data: string;
// }

// const initialState: UserState = {
//   username: "",
//   accessToken: "",
//   isAuthenticated: false,
//   isAuthenticating: true,
// //   user: undefined,
// };

// interface SignAction {
//   username: string;
//   password: string;
// }

// export const signUp = createAsyncThunk(
//   "user/signup",
//   async (credential: SignAction) => {
//     const response = await serverService.signUp(credential);
//     return response;
//   }
// );

// export const signIn = createAsyncThunk(
//   "user/signin",
//   async (credential: SignAction) => {
//     const response = await serverService.signIn(credential);
//     if (response.result != "ok") {
//       throw new Error("login failed");
//     }

//     // set access token
//     httpClient.interceptors.request.use((config?: AxiosRequestConfig) => {
//       if (config && config.headers) {
//         config.headers["Authorization"] = `Bearer ${response.token}`;
//       }

//       return config;
//     });
//     return response;
//   }
// );

// export const signOut = createAsyncThunk("user/signout", async () => {
//   await serverService.signOut();
//   Router.push("/login");
// });

// export const getSession = createAsyncThunk("user/fetchSession", async () => {
//   const response = await serverService.getSession();

//   // set access token
//   if (response) {
//     httpClient.interceptors.request.use((config?: AxiosRequestConfig) => {
//       if (config && config.headers && response.user) {
//         config.headers["Authorization"] = `Bearer ${response.user?.token}`;
//       }
//       return config;
//     });
//   }
//   return response;
// });

// const userSlice = createSlice({
//   name: "user",
//   initialState: initialState,
//   reducers: {
//     resetUsername: (state, action: PayloadAction<SingleProp>) => {
//       state.username = action.payload.data;
//     },
//   },
//   extraReducers: (builder) => {
//     builder.addCase(signUp.fulfilled, (state, action) => {
//       state.accessToken = "";
//       state.user = undefined;
//       state.isAuthenticated = false;
//     });
//     builder.addCase(signIn.fulfilled, (state, action) => {
//       state.accessToken = action.payload.token;
//       state.isAuthenticated = true;
//       state.isAuthenticating = false;
//       state.user = action.payload.user;
//     });
//     builder.addCase(signIn.rejected, (state, action) => {
//       state.accessToken = "";
//       state.isAuthenticated = false;
//       state.isAuthenticating = false;
//       state.user = undefined;
//     });
//     builder.addCase(signOut.fulfilled, (state, action) => {
//       state.accessToken = "";
//       state.isAuthenticated = false;
//       state.isAuthenticating = false;
//       state.user = undefined;
//     });
//     builder.addCase(getSession.fulfilled, (state, action) => {
//       state.isAuthenticating = false;
//       if (action.payload && action.payload.user && action.payload.user.token) {
//         state.accessToken = action.payload.user.token;
//         state.user = action.payload.user;
//         state.isAuthenticated = true;
//       }
//     });
//   },
// });

// export const { resetUsername } = userSlice.actions;

// // export common user selector
export const dealOfTheDayProductSelector = (
  store: RootState,
): ProductModel[] | undefined =>
  store.homePage.dealOfTheDayProducts ?? undefined;

export const flashSaleProductSelector = (
  store: RootState,
): ProductModel[] | undefined => store.homePage.flashSaleProducts ?? undefined;
// export const isAuthenticatedSelector = (store: RootState): boolean =>
//   store.user.isAuthenticated;
// export const isAuthenticatingSelector = (store: RootState): boolean =>
//   store.user.isAuthenticating;

// // // export reducer
export default homePageSlice.reducer;
