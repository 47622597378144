import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ServerCookies from '@/services/client/cookieService';
import * as cookieKey from '@/constants/cookieKey.constant';
import { RootState } from '@/store/store';
import { OrderhistoryListPageResponse } from '@/models/profile/Orderhistory.model';
import { getProfileOrderhistory } from '@/services/client/orderhistoryService';

interface OrderhistoryState {
  result?: OrderhistoryListPageResponse | null;
  isLoading: boolean;
  error?: string;
  currentPage: number;
  limit: number;
}

const initialState: OrderhistoryState = {
  currentPage: 1,
  isLoading: false,
  limit: 12,
};

export const profileOrderhistoryListQuery = createAsyncThunk(
  'orderhistory/profile/query',
  async ({
    lang,
    page,
    mode,
  }: {
    lang?: string;
    page?: number;
    mode?: number;
  }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await getProfileOrderhistory({
      lang,
      page,
      mode,
    });
    if (!response.data) {
      throw new Error(`${response.status ?? '500.'}`);
    }
    return response;
  },
);

export const OrderhistorySlice = createSlice({
  name: 'orderhistory',
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.error = undefined;
    },
    clearResult: (state) => {
      state.result = undefined;
    },
  },
  extraReducers: (builder) => {
    // pending, fulfilled, rejected
    builder
      .addCase(profileOrderhistoryListQuery.pending, (state) => {
        state.isLoading = true;
        state.result = undefined;
      })
      .addCase(profileOrderhistoryListQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload === null) {
          state.result = {
            info: null,
          };
        } else {
          state.result = action.payload?.data ?? null;
          state.limit = action.payload?.data?.limit ?? 12;
          state.currentPage = action.payload?.data?.page ?? 1;
        }
      })
      .addCase(profileOrderhistoryListQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.result = null;
        state.error = action.error.message;
        state.currentPage = 1;
      });
  },
});

export const { clearError, clearResult } = OrderhistorySlice.actions;

export const orderhistoryListProfileResultSelector = (
  store: RootState,
): OrderhistoryListPageResponse | undefined | null =>
  store.profileOrderhistory.result;

export const isLoadingOrderhistoryListProfileSelector = (
  store: RootState,
): boolean => store.profileOrderhistory.isLoading;

export const errorOrderhistoryListProfileSelector = (
  store: RootState,
): string | undefined => store.profileOrderhistory.error;

export const currentOrderHistoryPageSelector = (store: RootState): number =>
  store.profileOrderhistory.currentPage;

export const limitOrderHistorySelector = (store: RootState): number =>
  store.profileOrderhistory.limit;

export default OrderhistorySlice.reducer;
