import {
  AddToCartRequest,
  AddToCartRequestConverter,
  AddToCartResponse,
  AddToCartResponseConverter,
} from '@/models/AddToCart.model';
import { getDataFromAPI, postDataFromAPI } from '@/utils/httpClient';

export const reNewService = async ({
  lang,
  sku,
  skuService,
}: {
  lang?: string;
  sku: string;
  skuService?: string;
}): Promise<{
  dbCode: boolean;
  status?: number | null;
  dbMessage: string;
}> => {
  const getDataAPI = await postDataFromAPI({
    path: `/cartRenewService`,
    lang,
    body: { skcode: sku, skuservice: skuService },
  });
  if (getDataAPI.status === 200) {
    if (getDataAPI.data.dbcode === true) {
      return {
        dbCode: true,
        status: getDataAPI.status,
        dbMessage: '',
      };
    } else {
      return {
        dbCode: false,
        status: getDataAPI.status,
        dbMessage: getDataAPI.data.dbmessage,
      };
    }
  } else {
    return {
      dbCode: false,
      status: getDataAPI.status,
      dbMessage: 'Connection Error.',
    };
  }
};

export const addToCart = async (
  request: AddToCartRequest,
): Promise<AddToCartResponse> => {
  let path = `/addtocart`;
  if (
    request.setId !== undefined ||
    (request.skuQtyList && request.skuQtyList.length > 0)
  ) {
    path = `addmoretocart`;
  }

  const [getDataAPI] = await Promise.all([
    postDataFromAPI({
      path: path,
      body: AddToCartRequestConverter.toJson(request),
    }),
  ]);

  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : null;
  let response: AddToCartResponse = apiData
    ? apiData
    : { dbCode: false, dbMessage: 'Connection Error.' };
  try {
    response = AddToCartResponseConverter.fromJson(
      apiData ? apiData : { dbCode: false, dbMessage: 'Connection Error.' },
    );
  } catch (e) {
    console.log(e);
  }
  response.sku = request.sku;
  response.qty = request.qty;
  response.isSelectBundle = request.isSelectBundle;
  response.setId = request.setId;
  response.withService = request.withService;
  response.skuQtyList = request.skuQtyList;
  return response;
};

export const mergeStore = async (): Promise<boolean> => {
  const [getDataAPI] = await Promise.all([
    getDataFromAPI({
      path: '/mergestore',
    }),
  ]);

  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : null;
  try {
    if (apiData && apiData.dbcode === true) {
      return true;
    }
  } catch (e) {
    console.log(e);
  }
  return false;
};
