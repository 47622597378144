import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import loginReducer from './slices/loginSlice';
import wishListReducer from './slices/wishListSlice';
import compareReducer from './slices/compareSlice';
import addToCartReducer from './slices/addToCartSlice';
import TabReducer from './slices/clickOpenReviewSlice';
import pageReviewReducer from './slices/clickChangePageReviewSlice';
import categoryFilterReducer from './slices/categoryFilterSlice';
import clickOpenReviewReducer from './slices/clickOpenReviewSlice';
import myCartReducer from './slices/myCartSlice';
import shipmentReducer from './slices/checkout/shipmentSlice';
import paymentReducer from './slices/checkout/paymentSlice';
import rePaymentReducer from './slices/checkout/rePaymentSlice';
import storeReducer from './slices/storeSlice';
import searchSuggestReducer from './slices/searchSuggestSlice';
import profileReducer from './slices/profile/profileSlice';
import registerReducer from './slices/registerSlice';
import homepageReducer from './slices/homePageSlice';
import loadingReducer from './slices/loadingSlice';
import installationDetailReducer from './slices/clickInstallationDetailSlice';
import addressModalReducer from './slices/addressModalSlice';
import profileAddressReducer from './slices/profile/addressSlice';
import profileEditInfoReducer from './slices/profile/editInfoSlice';
import profileWishlistReducer from './slices/profile/wishlistSlice';
import profileOrderhistoryReducer from './slices/profile/orderhistory/orderhistorySlice';
import orderhistoryDetailReducer from './slices/profile/orderhistory/orderhistoryDetailSlice';
import profileMyReviewReducer from './slices/profile/myReviewSlice';
import profileMyVoucherReducer from './slices/profile/myVoucherSlice';
import useMyVoucherReducer from './slices/useVoucherSlice';
import profileAccountSummaryReducer from './slices/profile/accountSummarySlice';
import addressEtaxModalReducer from './slices/addressEtaxModalSlice';
import requestEtaxReducer from './slices/profile/requestEtaxSlice';
import getIssueFromCodeReducer from './slices/getIssueFromCodeSlice';
import get1010CouponReducer from './slices/get1010CouponSlice';
import getResetMailReducer from './slices/getResetMailSlice';
import getUpdatePasswordReducer from './slices/getUpdatePasswordSlice';
import profileMyWarrantyReducer from './slices/profile/myWarrantySlice';
import productViewSendReducer from './slices/productViewSendSlice';
import SelectedSpuReducer from './slices/pdp/spuSelectedSlice';
import getFreeGiftListReducer from './slices/getFreeGiftListSlice';
import addFreeGiftReducer from './slices/addFreeGiftSlice';
import orderhistoryRefundReducer from './slices/profile/orderhistory/orderhistoryRefundSlice';
import profileRefundReducer from './slices/profile/refundSlice';
import requestLikeDislikeReducer from './slices/pdp/requestLikeDislikeSlice';

const reducer = {
  user: loginReducer,
  wishList: wishListReducer,
  compare: compareReducer,
  categoryFilter: categoryFilterReducer,
  addToCart: addToCartReducer,
  tab: TabReducer,
  pageReview: pageReviewReducer,
  clickOpenReview: clickOpenReviewReducer,
  myCart: myCartReducer,
  shipment: shipmentReducer,
  store: storeReducer,
  searchSuggest: searchSuggestReducer,
  userRegister: registerReducer,
  homePage: homepageReducer,
  loading: loadingReducer,
  installationDetail: installationDetailReducer,
  addressModal: addressModalReducer,
  profile: profileReducer,
  profileAddress: profileAddressReducer,
  profileEditInfo: profileEditInfoReducer,
  profileWishlist: profileWishlistReducer,
  profileOrderhistory: profileOrderhistoryReducer,
  orderhistoryDetail: orderhistoryDetailReducer,
  profileMyReview: profileMyReviewReducer,
  profileMyVoucher: profileMyVoucherReducer,
  useMyVoucher: useMyVoucherReducer,
  profileAccountSummary: profileAccountSummaryReducer,
  addressEtaxModal: addressEtaxModalReducer,
  requestEtax: requestEtaxReducer,
  getIssueFromCode: getIssueFromCodeReducer,
  get1010Coupon: get1010CouponReducer,
  getResetMail: getResetMailReducer,
  getUpdatePassword: getUpdatePasswordReducer,
  profileMyWarranty: profileMyWarrantyReducer,
  productViewSend: productViewSendReducer,
  selectedSpu: SelectedSpuReducer,
  getFreeGiftList: getFreeGiftListReducer,
  addFreeGift: addFreeGiftReducer,
  profileRefund: profileRefundReducer,
  orderhistoryRefund: orderhistoryRefundReducer,
  requestLikeDislike: requestLikeDislikeReducer,
};

const reducerPayment = {
  payment: paymentReducer,
  rePayment: rePaymentReducer,
};

export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV === 'development',
});

export const storePayment = configureStore({
  reducer: reducerPayment,
  devTools: process.env.NODE_ENV === 'development',
});

// export type of root state from reducers
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

// export type of payment state from reducers
export type PaymentState = ReturnType<typeof storePayment.getState>;
export type PaymentDispatch = typeof storePayment.dispatch;
export const usePaymentDispatch = () => useDispatch<PaymentDispatch>();
