import { modelValue } from '@/utils/modelValue';

export interface MergeAccountData {
  dbcode?: boolean;
}

export class MergeAccountDataConverter {
  public static fromJson(json: any): MergeAccountData {
    const model: MergeAccountData = {
      dbcode: json['dbcode'],
    };
    return modelValue(model);
  }
}
