import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { GetResetMail } from '@/models/GetResetMail.model';
import { getResetMail } from '@/services/client/getResetMailService';
interface GetResetMailState {
  result: GetResetMail;
  isLoading: boolean;
}

const initialState: GetResetMailState = {
  result: {
    error: true,
    msg: 'Initial message',
    status: 500,
  },
  isLoading: false,
};

export const getResetMailQuery = createAsyncThunk(
  'getResetMail/add',
  async ({ lang, email }: { lang: string; email: string }) => {
    const response = await getResetMail({ lang, email });
    return response;
  },
);

const getResetMailSlice = createSlice({
  name: 'getResetMail',
  initialState: initialState,
  reducers: {
    clearResultGetResetMail: (state) => {
      state.result = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResetMailQuery.pending, (state) => {
        state.result = {};
        state.isLoading = true;
      })
      .addCase(getResetMailQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.result = action.payload;
      })
      .addCase(getResetMailQuery.rejected, (state) => {
        state.isLoading = false;
        state.result = {};
      });
  },
});

export const { clearResultGetResetMail } = getResetMailSlice.actions;

export const getResetMailResultsSelector = (store: RootState): GetResetMail =>
  store.getResetMail.result;

export const isLoadingResetMailSelector = (store: RootState): boolean =>
  store.getResetMail.isLoading;

export default getResetMailSlice.reducer;
