import { GetInstallationDetailModel } from '@/models/GetInstallationDetail.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface InstallationDetailState {
  data: GetInstallationDetailModel;
  serSKCODE?: string;
  isInstallation?: number;
}

const initialState: InstallationDetailState = {
  data: { prname: '', ldesp: '' },
  serSKCODE: '',
  isInstallation: 0,
};

const installationDetailSlice = createSlice({
  name: 'installationDetail',
  initialState,
  reducers: {
    setInstallationDetailValue: (
      state,
      action: PayloadAction<GetInstallationDetailModel>,
    ) => {
      state.data = action.payload;
    },
    setSerSKCODE: (state, action: PayloadAction<string>) => {
      state.serSKCODE = action.payload;
    },
    setIsInstallation: (state, action: PayloadAction<number>) => {
      state.isInstallation = action.payload;
    },
  },
});

export const {
  // ... other actions
  setInstallationDetailValue,
  setSerSKCODE,
  setIsInstallation,
} = installationDetailSlice.actions;

export const serSkcodeSelector = (state: RootState): string | undefined =>
  state.installationDetail.serSKCODE;

export const isInstallationSelector = (state: RootState): number | undefined =>
  state.installationDetail.isInstallation;

export default installationDetailSlice.reducer;
