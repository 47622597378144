export const modelValue = (obj: any) => {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === undefined) {
      if (key === 'cookies') {
        obj[key] = [];
      } else if (key === 'info') {
        obj[key] = null;
      } else {
        delete obj[key];
      }
    }
  }
  return obj;
};
