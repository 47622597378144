import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ServerCookies from '@/services/client/cookieService';
import * as cookieKey from '@/constants/cookieKey.constant';
import { RootState } from '@/store/store';
import {
  getProfileRefund,
  getReturnModal,
  submitRefundReturn,
} from '@/services/client/orderhistoryService';
import { RefundListPageResponse } from '@/models/profile/RefundList.model';
import {
  RefundReturnInfoResponse,
  RefundReturnSaveRequest,
} from '@/models/profile/RefundReturnInfo.model';

interface RefundState {
  result?: RefundListPageResponse | null;
  returnResult?: RefundReturnInfoResponse | null;
  isLoading: boolean;
  isLoadingReturn: boolean;
  error?: string;
  returnSaveResult?: RefundReturnSaveRequest;
  //save request return
  isLoadingReturnRequest: boolean;
  saveReturnRequestSuccess?: boolean;
  errorSaveReturnRequest?: string;
}

const initialState: RefundState = {
  isLoading: false,
  isLoadingReturn: false,
  isLoadingReturnRequest: false,
};

export const profileRefundListQuery = createAsyncThunk(
  'refund/profile/query',
  async ({ lang }: { lang?: string }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await getProfileRefund({
      lang,
    });
    if (!response.data) {
      throw new Error(`${response.status ?? '500.'}`);
    }
    return response;
  },
);

export const getRefundReturnModal = createAsyncThunk(
  'refund/profile/getReturnModal',
  async ({
    order,
    requestId,
    lang,
  }: {
    order: string;
    requestId: string;
    lang?: string;
  }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await getReturnModal({
      order,
      requestId,
      lang,
    });
    if (!response.data) {
      throw new Error(`${response.status ?? '500.'}`);
    }
    return response;
  },
);

export const submitRefundReturnRequest = createAsyncThunk(
  'refund/profile/refundReturnRequest',
  async ({
    requestId,
    order,
    requestData,
    lang,
  }: {
    requestId?: string;
    order?: string;
    requestData: RefundReturnSaveRequest;
    lang?: string;
  }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    if (!requestData) {
      throw new Error('type_is_not_match');
    }
    if (!order) {
      throw new Error('type_is_not_match');
    }
    if (requestData?.requestId === requestId) {
      const response = await submitRefundReturn({
        order: order,
        sendData: requestData,
        lang,
      });
      if (!response || response.isError) {
        throw new Error(
          `${response?.errorMessage ?? 'thesystemisfailpleasetryagain'}`,
        );
      }
      return response;
    } else {
      throw new Error('Error');
    }
  },
);

export const RefundSlice = createSlice({
  name: 'refund',
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.error = undefined;
      state.errorSaveReturnRequest = undefined;
    },
    clearResult: (state) => {
      state.result = undefined;
    },
    clearReturnResult: (state) => {
      state.returnResult = undefined;
    },
    clearReturnRequestSuccess: (state) => {
      state.saveReturnRequestSuccess = false;
    },
  },
  extraReducers: (builder) => {
    // pending, fulfilled, rejected
    builder
      .addCase(profileRefundListQuery.pending, (state) => {
        state.isLoading = true;
        state.result = undefined;
      })
      .addCase(profileRefundListQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload === null) {
          state.result = {
            info: null,
          };
        } else {
          state.result = action.payload?.data ?? null;
        }
      })
      .addCase(profileRefundListQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.result = null;
        state.error = action.error.message;
      });
    builder
      .addCase(getRefundReturnModal.pending, (state) => {
        state.isLoadingReturn = true;
        state.returnResult = undefined;
      })
      .addCase(getRefundReturnModal.fulfilled, (state, action) => {
        state.isLoadingReturn = false;
        console.log(action);
        state.returnResult = action.payload?.data ?? null;
        state.returnResult = {
          requestId: action.meta.arg.requestId,
          ...state.returnResult,
        };
      })
      .addCase(getRefundReturnModal.rejected, (state, action) => {
        state.isLoadingReturn = false;
        state.returnResult = null;
        state.error = action.error.message;
      });
    builder
      .addCase(submitRefundReturnRequest.pending, (state) => {
        state.isLoadingReturnRequest = true;
        state.saveReturnRequestSuccess = false;
      })
      .addCase(submitRefundReturnRequest.fulfilled, (state) => {
        state.isLoadingReturnRequest = false;
        state.saveReturnRequestSuccess = true;
      })
      .addCase(submitRefundReturnRequest.rejected, (state, action) => {
        state.isLoadingReturnRequest = false;
        state.errorSaveReturnRequest = action.error.message;
      });
  },
});

export const {
  clearError,
  clearResult,
  clearReturnResult,
  clearReturnRequestSuccess,
} = RefundSlice.actions;

export const refundListProfileResultSelector = (
  store: RootState,
): RefundListPageResponse | undefined | null => store.profileRefund.result;

export const isLoadingRefundListProfileSelector = (store: RootState): boolean =>
  store.profileRefund.isLoading;

export const errorRefundListProfileSelector = (
  store: RootState,
): string | undefined => store.profileRefund.error;

export const isLoadingRefundReturnSelector = (store: RootState): boolean =>
  store.profileRefund.isLoadingReturn;

export const refundReturnResultSelector = (
  store: RootState,
): RefundReturnInfoResponse | undefined | null =>
  store.profileRefund.returnResult;

export const isLoadingRefundReturnRequestSelector = (
  store: RootState,
): boolean => store.profileRefund.isLoadingReturnRequest;

export const saveReturnRequestSuccessSelector = (
  store: RootState,
): boolean | undefined => store.profileRefund.saveReturnRequestSuccess;

export const errorSaveReturnRequestSelector = (
  store: RootState,
): string | undefined => store.profileRefund.errorSaveReturnRequest;

export default RefundSlice.reducer;
