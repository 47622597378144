import {
  GetResetMail,
  GetResetMailConverter,
  GetUpdatePasswordModel,
  GetUpdatePasswordConverter,
} from '@/models/GetResetMail.model';
import { postDataFromAPI } from '@/utils/httpClient';

export const getResetMail = async ({
  lang,
  email,
}: {
  lang?: string;
  email: string;
}): Promise<GetResetMail> => {
  const [getApiData] = await Promise.all([
    postDataFromAPI({
      path: '/getresetmail',
      lang,
      body: { lang, eml: email },
    }),
  ]);
  const apiData =
    getApiData.status === 200
      ? getApiData.data
        ? getApiData.data
        : undefined
      : getApiData.status === 401
      ? getApiData.data
        ? getApiData.data
        : undefined
      : undefined;

  let response: GetResetMail = apiData;

  if (apiData) {
    try {
      response = GetResetMailConverter.fromJson(apiData);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};
export const getUpdatePassword = async ({
  lang,
  token,
  password,
}: {
  lang?: string;
  token: string;
  password: string;
}): Promise<GetUpdatePasswordModel> => {
  const [getApiData] = await Promise.all([
    postDataFromAPI({
      path: '/getupdatepassword',
      lang,
      body: { lang, token, password },
    }),
  ]);
  const apiData =
    getApiData.status === 200
      ? getApiData.data
        ? getApiData.data
        : undefined
      : getApiData.status === 401
      ? getApiData.data
        ? getApiData.data
        : undefined
      : undefined;

  let response: GetUpdatePasswordModel = apiData;

  if (apiData) {
    try {
      response = GetUpdatePasswordConverter.fromJson(apiData);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};
