import { modelValue } from '@/utils/modelValue';
import { CustomerInfo, CustomerInfoConverter } from '../Authen.model';
import { PostInfo, PostInfoConverter } from './RefundReturnInfo.model';

export enum RefundStatus {
  // eslint-disable-next-line no-unused-vars
  new = 'W', //คำร้องใหม่
  // eslint-disable-next-line no-unused-vars
  initialApprove = 'A', //อนุมัติเบื้องต้น
  // eslint-disable-next-line no-unused-vars
  partialInitialApprove = 'B', //อนุมัติเบื้องต้นบางส่วน
  // eslint-disable-next-line no-unused-vars
  initialDisApprove = 'C', //ไม่อนุมัติเบื้องต้น
  // eslint-disable-next-line no-unused-vars
  approve = 'D', //อนุมัติคืนสินค้า
  // eslint-disable-next-line no-unused-vars
  partialApprove = 'E', //อนุมัติคืนสินค้าบางส่วน
  // eslint-disable-next-line no-unused-vars
  reject = 'F', //ไม่อนุมัติคืนสินค้า
  // eslint-disable-next-line no-unused-vars
  receieve = 'R', //รับสินค้าแล้ว
}

export interface RefundListPageResponse {
  title?: string;
  sub?: string;
  pendingrefund?: OrderRefundDetail[];
  approverefund?: OrderRefundDetail[];
  noapproverefund?: OrderRefundDetail[];
  info?: CustomerInfo | null;
}

export class RefundListPageResponseConverter {
  public static fromJson(json: any): RefundListPageResponse {
    const model: RefundListPageResponse = {
      title: json['title'],
      sub: json['sub'],
      pendingrefund: json['pendingrefund']?.map((e: any) =>
        OrderRefundDetailConverter.fromJson(e),
      ),
      approverefund: json['approverefund']?.map((e: any) =>
        OrderRefundDetailConverter.fromJson(e),
      ),
      noapproverefund: json['noapproverefund']?.map((e: any) =>
        OrderRefundDetailConverter.fromJson(e),
      ),
      info: json['info']
        ? CustomerInfoConverter.fromJson(json['info'])
        : undefined,
    };
    return modelValue(model);
  }
}

export interface OrderRefundDetail {
  requestId: string;
  orderNo: string;
  stcode: string;
  requestDate: string;
  itemsResult?: boolean;
  bankInfo?: RefundBankInfo;
  returnAddress?: ReturnAddress;
  creditNoteAddress?: CreditNoteAddress;
  returnmethod?: string;
  returnPost?: PostInfo;
  paymentMethod?: string;
  storeaddress?: StoreAddress;
  totsum?: number;
  sklist: ProductRefundDetail[];
}

export class OrderRefundDetailConverter {
  public static fromJson(json: any): OrderRefundDetail {
    const model: OrderRefundDetail = {
      requestId: json['requestId'],
      orderNo: json['orderNo'],
      stcode: json['stcode'],
      requestDate: json['requestDate'],
      itemsResult: json['itemsResult'],
      bankInfo: json['bankinfo']
        ? RefundBankInfoConverter.fromJson(json['bankinfo'])
        : undefined,
      returnAddress: json['returnaddress']
        ? ReturnAddressConverter.fromJson(json['returnaddress'])
        : undefined,
      creditNoteAddress: json['creditnoteaddr']
        ? CreditNoteAddressConverter.fromJson(json['creditnoteaddr'])
        : undefined,
      returnmethod: json['returnmethod'],
      returnPost: json['returnPost']
        ? PostInfoConverter.fromJson(json['returnPost'])
        : undefined,
      paymentMethod: json['paymentMethod'],
      storeaddress: json['storeaddress'],
      totsum: json['totsum'],
      sklist: json['sklist']?.map((e: any) =>
        ProductRefundDetailConverter.fromJson(e),
      ),
    };
    return modelValue(model);
  }
}

export interface CreditNoteAddress {
  customerType?: string;
  title?: string;
  titleDesc?: string;
  firstName?: string;
  lastName?: string;
  taxNo?: string;
  branchName?: string;
  isHQ?: string;
  branchNumber?: string;
  mobileNo?: string;
  email?: string;
  homeNo?: string;
  buildingName?: string;
  villageName?: string;
  floorNo?: string;
  roomNo?: string;
  mooNo?: string;
  alleyName?: string;
  roadName?: string;
  subDistrictName?: string;
  districtName?: string;
  provinceName?: string;
  postalCode?: string;
  fullAddress?: string;
}

export class CreditNoteAddressConverter {
  public static fromJson(json: any): CreditNoteAddress {
    const model: CreditNoteAddress = {
      customerType: json['customerType'],
      title: json['title'],
      titleDesc: json['titleDesc'],
      firstName: json['firstName'],
      lastName: json['lastName'],
      taxNo: json['taxNo'],
      branchName: json['branchName'],
      isHQ: json['isHQ'],
      branchNumber: json['branchNumber'],
      mobileNo: json['mobileNo'],
      email: json['email'],
      homeNo: json['homeNo'],
      buildingName: json['buildingName'],
      villageName: json['villageName'],
      floorNo: json['floorNo'],
      roomNo: json['roomNo'],
      mooNo: json['mooNo'],
      alleyName: json['alleyName'],
      roadName: json['roadName'],
      subDistrictName: json['subDistrictName'],
      districtName: json['districtName'],
      provinceName: json['provinceName'],
      postalCode: json['postalCode'],
      fullAddress: json['fullAddress'],
    };
    return modelValue(model);
  }
}

export interface ReturnAddress {
  homeNo?: string;
  mooNo?: string;
  buildingName?: string;
  floorNo?: string;
  roomNo?: string;
  alleyName?: string;
  roadName?: string;
  postalCode?: string;
  subDistrictName?: string;
  districtName?: string;
  provinceName?: string;
  contactName?: string;
  contactMobileNo?: string;
}

export class ReturnAddressConverter {
  public static fromJson(json: any): ReturnAddress {
    const model: ReturnAddress = {
      homeNo: json['homeNo'],
      mooNo: json['mooNo'],
      buildingName: json['buildingName'],
      floorNo: json['floorNo'],
      roomNo: json['roomNo'],
      alleyName: json['alleyNo'],
      roadName: json['roadName'],
      postalCode: json['postalCode'],
      subDistrictName: json['subDistrictName'],
      districtName: json['districtName'],
      provinceName: json['provinceName'],
      contactName: json['contactName'],
      contactMobileNo: json['contactMobileNo'],
    };
    return modelValue(model);
  }
}
export interface RefundBankInfo {
  bankName: string;
  accountNo: string;
  accountName: string;
  imgname: string;
}
export class RefundBankInfoConverter {
  public static fromJson(json: any): RefundBankInfo {
    const model: RefundBankInfo = {
      bankName: json['bankName'],
      accountNo: json['accountNo'],
      accountName: json['accountName'],
      imgname: json['imgname'],
    };
    return modelValue(model);
  }
}
export interface RefundImage {
  seqNo: number;
  imageUrl: string;
  isImage?: boolean;
}

export class RefundImageConverter {
  public static fromJson(json: any): RefundImage {
    const model: RefundImage = {
      seqNo: json['seqNo'],
      imageUrl: json['imageUrl'],
      isImage: json['isImage'],
    };
    return modelValue(model);
  }
}

export interface ProductRefundDetail {
  seqNo: number;
  skuCode: string;
  returnQty: number;
  returnReason: string;
  returnRemark?: string;
  images?: RefundImage[];
  tot?: string;
  orderQty?: number;
  name?: string;
  pimImage?: string;
  base64?: string;
  slugname?: string;
  returnreason?: string;
  noapproveQty?: number;
  approveReturnQty: number;
  approveremark?: string;
  statustext: string;
  statuscode: string;
  statustrack: number;
}

export class ProductRefundDetailConverter {
  public static fromJson(json: any): ProductRefundDetail {
    const model: ProductRefundDetail = {
      seqNo: json['seqNo'],
      skuCode: json['skuCode'],
      returnQty: json['returnQty'],
      returnReason: json['returnReason'],
      returnRemark: json['returnRemark'],
      images: json['images']?.map((e: any) => RefundImageConverter.fromJson(e)),
      tot: json['tot'],
      orderQty: json['orderQty'],
      name: json['name'],
      base64: json['base64'],
      slugname: json['slugname'],
      returnreason: json['returnreason'],
      noapproveQty: json['noapproveQty'],
      approveReturnQty: json['approveReturnQty'],
      approveremark: json['approveremark'],
      statustext: json['statustext'],
      statuscode: json['statuscode'],
      statustrack: json['statustrack'],
    };
    return modelValue(model);
  }
}

export interface StoreAddress {
  stname: string;
  staddress: string;
  sttel: string;
  stopentime: string;
  stclosetime: string;
}
export class StoreAddressConverter {
  public static fromJson(json: any): StoreAddress {
    const model: StoreAddress = {
      stname: json['stname'],
      staddress: json['staddress'],
      sttel: json['sttel'],
      stopentime: json['stopentime'],
      stclosetime: json['stclosetime'],
    };
    return modelValue(model);
  }
}
