import { modelValue } from '@/utils/modelValue';

export interface DealFinModel {
  title?: string;
  colors?: string[];
  slugname?: string;
  textColor?: string;
}

export class DealFinModelConverter {
  public static fromJson(json: any): DealFinModel {
    const model: DealFinModel = {
      title: json['title'],
      colors: json['colors'],
      slugname: json['slugname'],
      textColor: json['textColor'],
    };
    return modelValue(model);
  }
}

export interface CMSHomePageModel {
  data?: Banner[];
  flashsale?: Flashsale;
  shopbyroom?: Shopbyroom;
  brand?: Brand[];
  brandBanner?: any[];
  loopbyroom?: string;
  articles?: Article[];
  beyondproject?: Beyondproject[];
  awPopups?: AwPopup;
  awPopupList?: AwPopup[];
  hotpromotions?: Dealoftheday[];
  events?: any[];
  promotionbanners?: Array<Banner[]>;
  promotionevents?: any[];
  dealofthedays?: Dealoftheday[];
  categories?: any[];
  newvfiximage?: string;
  promotionbannersslide?: Banner[];
}

export class CMSHomePageModelConverter {
  public static fromJson(json: any): CMSHomePageModel {
    const model: CMSHomePageModel = {
      data: json['data']
        ? json['data'].map((x: any) => BannerConverter.fromJson(x))
        : undefined,
      flashsale: json['flashsale']
        ? FlashsaleConverter.fromJson(json['flashsale'])
        : undefined,
      shopbyroom: json['shopbyroom']
        ? ShopbyroomConverter.fromJson(json['shopbyroom'])
        : undefined,
      brand: json['brand']
        ? json['brand'].map((x: any) => BrandConverter.fromJson(x))
        : undefined,
      brandBanner: json['brand_banner'] ? json['brand_banner'] : undefined,
      loopbyroom: json['loopbyroom'] ? json['loopbyroom'] : undefined,
      articles: json['articles']
        ? json['articles'].map((x: any) => ArticleConverter.fromJson(x))
        : undefined,
      beyondproject: json['beyondproject'] ? json['beyondproject'] : undefined,
      awPopups:
        json['aw_popups_web'] &&
        AwPopupConverter.fromJson(json['aw_popups_web']),
      awPopupList: json['aw_popup_list']
        ? json['aw_popup_list'].map((x: any) => AwPopupConverter.fromJson(x))
        : undefined,
      hotpromotions: json['hotpromotions'] ? json['hotpromotions'] : undefined,
      events: json['events'] ? json['events'] : undefined,
      promotionbanners: json['promotionbanners']
        ? json['promotionbanners']
        : undefined,
      promotionevents: json['promotionevents']
        ? json['promotionevents']
        : undefined,
      dealofthedays: json['dealofthedays'] ? json['dealofthedays'] : undefined,
      categories: json['categories'] ? json['categories'] : undefined,
      newvfiximage: json['newvfiximage'] ? json['newvfiximage'] : undefined,
      promotionbannersslide: json['promotionbannersslide']
        ? json['promotionbannersslide']
        : undefined,
    };
    return modelValue(model);
  }
}

export interface Article {
  tagSlug?: string;
  name?: string;
  img?: string;
  slug?: string;
  subject?: string;
  title?: string;
}

export class ArticleConverter {
  public static fromJson(json: any): Article {
    const model: Article = {
      tagSlug: json['tag_slug'],
      name: json['name'],
      img: json['img'],
      slug: json['slug'],
      subject: json['subject'],
      title: json['title'],
    };
    return modelValue(model);
  }
}

export interface AwPopup {
  content?: string;
  version?: number;
  platform?: number;
}

export class AwPopupConverter {
  public static fromJson(json: any): AwPopup {
    const model: AwPopup = {
      content: json['content'],
      version: json['version'],
      platform: json['platform'],
    };
    return modelValue(model);
  }
}

export interface Beyondproject {
  url?: string;
  subject?: string;
  title?: string;
  time?: string;
  length?: string;
}

export class BeyondprojectConverter {
  public static fromJson(json: any): Beyondproject {
    const model: Beyondproject = {
      url: json['url'],
      subject: json['subject'],
      title: json['title'],
      time: json['time'],
      length: json['length'],
    };
    return modelValue(model);
  }
}

export interface Brand {
  name: string;
  img: string;
  brand: string;
}

export class BrandConverter {
  public static fromJson(json: any): Brand {
    const model: Brand = {
      name: json['name'],
      img: json['img'],
      brand: json['brand'],
    };
    return modelValue(model);
  }
}

export interface Banner {
  topic: string;
  img: string;
  imgMobile?: string;
  url?: string;
  ahref?: boolean;
  col?: number;
  sequence?: number;
  types?: null;
  showmax?: number;
}

export class BannerConverter {
  public static fromJson(json: any): Banner {
    const model: Banner = {
      topic: json['topic'],
      img: json['img'] ? json['img'] : json['imageSec'],
      imgMobile: json['img_mobile'],
      url: json['url'],
      ahref: json['ahref'],
      col: json['col'],
      sequence: json['sequence'],
      types: json['types'],
      showmax: json['showmax'],
    };
    return modelValue(model);
  }
}

export interface Dealoftheday {
  img?: string;
  type?: string;
  showmax?: number;
  color?: string;
  name?: string;
  fontcolor?: string;
}

export class DealofthedayConverter {
  public static fromJson(json: any): Dealoftheday {
    const model: Dealoftheday = {
      img: json['img'],
      type: json['type'],
      showmax: json['showmax'],
      color: json['color'],
      name: json['name'],
      fontcolor: json['fontcolor'],
    };
    return modelValue(model);
  }
}

export interface Flashsale {
  img?: string;
  type?: string;
  showmax?: number;
}

export class FlashsaleConverter {
  public static fromJson(json: any): Flashsale {
    const model: Flashsale = {
      img: json['img'],
      type: json['type'] ? json['type'] : json['types'],
      showmax: Number(json['showmax'] ?? json['showmax'] ?? '0'),
    };
    return modelValue(model);
  }
}

export interface Shopbyroom {
  img?: string;
  subject?: string;
  description?: string;
  goto?: string;
  position?: Position[];
}

export class ShopbyroomConverter {
  public static fromJson(json: any): Shopbyroom {
    const model: Shopbyroom = {
      img: json['img'],
      subject: json['subject'],
      description: json['description'],
      goto: json['goto'],
      position: json['position']
        ? json['position'].map((x: any) => PositionConverter.fromJson(x))
        : undefined,
    };
    return modelValue(model);
  }
}

export interface Position {
  x: number;
  y: number;
  sku: string;
  roomData?: SkuRoomData;
}

export class PositionConverter {
  public static fromJson(json: any): Position {
    const model: Position = {
      x: json['x'],
      y: json['y'],
      sku: json['sku'],
    };
    return modelValue(model);
  }
}

export interface SkuRoomData {
  sku: string;
  slugname?: string;
  base64?: string;
  pimImage?: string;
  name?: string;
  price?: string;
}

export class SkuRoomDataConverter {
  public static fromJson(json: any): SkuRoomData {
    const model: SkuRoomData = {
      sku: json['sku']?.toString() ?? '',
      slugname: json['slugname'].toString(),
      base64: json['base64'],
      pimImage: json['pimImage'],
      name: json['name'].toString(),
      price: json['price'].toString(),
    };
    return modelValue(model);
  }
}
