import { modelValue } from '@/utils/modelValue';
import { ProductModel, ProductModelConverter } from './Product.model';

export interface SearchSuggestResponse {
  category: CatSuggestion[];
  products: ProductModel[];
}

export class SearchSuggestResponseConverter {
  public static fromJson(json: any): SearchSuggestResponse {
    const model: SearchSuggestResponse = {
      category: json['cat']
        ? json['cat'].map((e: any) => CatSuggestionConverter.fromJson(e))
        : [],
      products: json['src']
        ? SearchProductSuggestionConverter.fromJson(json['src']).products
        : [],
    };
    return modelValue(model);
  }
}

export interface CatSuggestion {
  catname: string;
  slugname: string;
}

export class CatSuggestionConverter {
  public static fromJson(json: any): CatSuggestion {
    const model: CatSuggestion = {
      catname: json['catname'] ? json['catname'] : '',
      slugname: json['slugname'] ? json['slugname'] : '',
    };
    return modelValue(model);
  }
}

export interface SearchProductSuggestion {
  dbcode: boolean;
  products: ProductModel[];
  dbmessage: string;
}

export class SearchProductSuggestionConverter {
  public static fromJson(json: any): SearchProductSuggestion {
    const model: SearchProductSuggestion = {
      dbcode: json['dbcode'] == true ? true : false,
      products: json['dbitems']
        ? json['dbitems'].map((e: any) => ProductModelConverter.fromJson(e))
        : [],
      dbmessage: json['dbmessage'] ? json['dbmessage'] : '',
    };
    return modelValue(model);
  }
}
