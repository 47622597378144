import React from 'react';

export default function Loading() {
  return (
    <div className="overlayload">
      <div
        style={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          width: '100%',
        }}
      >
        <div>
          <center>
            <div className="lds-facebook">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </center>
        </div>
      </div>
    </div>
  );
}
