import { modelValue } from '@/utils/modelValue';

export interface GetEditReviewModel {
  dbcode?: boolean;
  dbitems?: Dbitem[];
  dbmessage?: string;
}

export interface Dbitem {
  custid?: string;
  reviewdate?: string;
  sku?: string;
  prname?: string;
  star?: number;
  issecret?: boolean;
  comment?: string;
  status?: string;
  statusname?: string;
  reviewimg?: string;
  hrecid?: number;
  skuurl?: string;
}

export class GetEditReviewModelConverter {
  public static fromJson(json: any): GetEditReviewModel {
    const model: GetEditReviewModel = {
      dbcode: json['dbcode'],
      dbmessage: json['dbmessage'],
      dbitems:
        json['dbitems'] == null
          ? []
          : json['dbitems'].map((x: any) => DbitemConverter.fromJson(x)),
    };
    return modelValue(model);
  }
}
export class DbitemConverter {
  public static fromJson(json: any): Dbitem {
    const model: Dbitem = {
      custid: json['custid'],
      reviewdate: json['REVIEWDATE'],
      sku: json['SKCODE'],
      prname: json['PRNAME'],
      star: json['STAR'],
      issecret: json['ISSECRET'],
      comment: json['COMMENT'],
      status: json['STATUS'],
      statusname: json['STATUSNAME'],
      reviewimg: json['REVIEWIMG'],
      hrecid: json['HRECID'],
      skuurl: json['skcodeurl'],
    };
    return modelValue(model);
  }
}
