import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { getFreeGiftList } from '@/services/client/getFreeGiftListService';
import { GetFreeGiftListModel } from '@/models/GetFreeGiftList.model';
interface GetFreeGiftListState {
  result?: GetFreeGiftListModel | null;
  isLoading: boolean;
}

const initialState: GetFreeGiftListState = {
  isLoading: true,
};

export const getFreeGiftListQuery = createAsyncThunk(
  'getFreeGiftList/add',
  async ({
    lang,
    ctlid,
    skcode,
    itmseq,
    qnt,
  }: {
    lang: string;
    ctlid: number;
    skcode: number;
    itmseq: number;
    qnt: number;
  }) => {
    const response = await getFreeGiftList({
      lang,
      ctlid,
      skcode,
      itmseq,
      qnt,
    });
    return response;
  },
);

const getFreeGiftListSlice = createSlice({
  name: 'getFreeGiftList',
  initialState: initialState,
  reducers: {
    clearResultFreeGiftList: (state) => {
      state.result = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFreeGiftListQuery.pending, (state) => {
        state.result = {};
        state.isLoading = true;
      })
      .addCase(getFreeGiftListQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.result = action.payload;
      })
      .addCase(getFreeGiftListQuery.rejected, (state) => {
        state.isLoading = false;
        state.result = {};
      });
  },
});

export const { clearResultFreeGiftList } = getFreeGiftListSlice.actions;

export const getFreeGiftListResultsSelector = (
  store: RootState,
): GetFreeGiftListModel | undefined =>
  store.getFreeGiftList.result || { products: [] };

export const isLoadingFreeGiftListResultsSelector = (
  store: RootState,
): boolean => store.getFreeGiftList.isLoading;

export default getFreeGiftListSlice.reducer;
