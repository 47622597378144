import React, { useEffect, useState } from 'react';
import AppLink from '@/components/Layout/handler/AppLink';
import Image from 'next/image';
import { getDealFin } from '@/services/server/homePageService';
import { DealFinModel } from '@/models/HomePage.model';
import { useTranslation } from 'next-i18next';

export default function DealFin() {
  const { i18n } = useTranslation();
  const [dealFinData, setDealFinData] = useState<DealFinModel | undefined>();

  useEffect(() => {
    getDealFin({ lang: i18n.language }).then((res) => {
      setDealFinData(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return dealFinData ? (
    <div className="absolute w-2/6 h-full right-0 z-10 hidden lg:block">
      <div
        className={`w-full h-full flex`}
        style={{
          clipPath: 'polygon(0 100%, 10% 0, 100% 0, 100% 100%)',
          background:
            dealFinData.colors && dealFinData.colors.length == 2
              ? `radial-gradient(${dealFinData.colors[0]}, ${dealFinData.colors[1]})`
              : undefined,
        }}
      >
        <AppLink
          href={dealFinData.slugname ?? '/'}
          className="flex my-auto pt-4 ms-12"
        >
          <div
            className="self-center text-2xl font-bold"
            style={{
              color: dealFinData.textColor ?? 'white',
            }}
          >
            {dealFinData.title}
          </div>
          <Image
            src="/images/icons/header/save-badge.webp"
            alt="infinitydeal"
            width={100}
            height={48}
            style={{ width: 'auto', height: '.75rem' }}
            className="self-center ms-1"
          />
        </AppLink>
      </div>
    </div>
  ) : (
    <></>
  );
}
