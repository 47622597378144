import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { editWishList, getMyWishList } from '@/services/client/wishListService';

interface WishListState {
  result: string[];
  errorCode?: string;
}

const initialState: WishListState = {
  result: [],
};

export const wishListQuery = createAsyncThunk('wistList/query', async () => {
  const response = await getMyWishList();
  return response;
});

export const wishListAdd = createAsyncThunk(
  'wistList/add',
  async ({ sku }: { sku: string }) => {
    const response = await editWishList({ sku, type: 1 });
    if (response.status === 401) {
      throw new Error('401');
    } else if (response.status !== 200) {
      throw new Error(response.status?.toString() ?? 'error');
    }
    return response;
  },
);

export const wishListRemove = createAsyncThunk(
  'wistList/remove',
  async ({ sku }: { sku: string }) => {
    const response = await editWishList({ sku, type: 0 });
    if (response.status === 401) {
      throw new Error('401');
    } else if (response.status !== 200) {
      throw new Error(response.status?.toString() ?? 'error');
    }
    return response;
  },
);

const wishListSlice = createSlice({
  name: 'wishlist',
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.errorCode = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(wishListQuery.pending, (state) => {
        state.result = [];
      })
      .addCase(wishListQuery.fulfilled, (state, action) => {
        state.result = action.payload ?? [];
        state.errorCode = undefined;
      })
      .addCase(wishListQuery.rejected, (state) => {
        state.result = [];
        state.errorCode = undefined;
      });
    builder
      .addCase(wishListAdd.fulfilled, (state, action) => {
        state.result = action.payload?.data ?? [];
        state.errorCode = undefined;
      })
      .addCase(wishListAdd.rejected, (state, action) => {
        state.errorCode = action.error.message;
      });
    builder
      .addCase(wishListRemove.fulfilled, (state, action) => {
        state.result = action.payload?.data ?? [];
        state.errorCode = undefined;
      })
      .addCase(wishListRemove.rejected, (state, action) => {
        state.errorCode = action.error.message;
      });
  },
});

export const { clearError } = wishListSlice.actions;

export const wishListResultsSelector = (store: RootState): string[] =>
  store.wishList.result;

export const errorCodeSelector = (store: RootState): string | undefined =>
  store.wishList.errorCode;

export default wishListSlice.reducer;
