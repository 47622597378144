import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ServerCookies from '@/services/client/cookieService';
import * as cookieKey from '@/constants/cookieKey.constant';
import { RootState } from '@/store/store';
import { OrderhistoryDetailPageResponse } from '@/models/profile/Orderhistory.model';
import {
  callCancelOrder,
  getOrderhistoryDetail,
} from '@/services/client/orderhistoryService';

interface OrderhistoryDetailState {
  result?: OrderhistoryDetailPageResponse | null;
  isLoading: boolean;
  error?: string;

  isCanceled?: boolean;
  isVoidCoupon?: boolean;
  cancelOrderError?: string;
  isLoadingCancelOrder: boolean;
}

const initialState: OrderhistoryDetailState = {
  isLoading: false,
  isLoadingCancelOrder: false,
};

export const cancelOrder = createAsyncThunk(
  'orderhistory/cancel',
  async ({ order }: { order: string }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      throw new Error(`401`);
    }

    const response = await callCancelOrder({
      order,
    });

    if (!response.data || response.data.isError) {
      throw new Error(`${response.data?.message ?? response.status ?? '500.'}`);
    }
    return response;
  },
);

export const profileOrderhistoryDetailQuery = createAsyncThunk(
  'orderhistory/detail/query',
  async ({ lang, order }: { lang?: string; order?: string }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await getOrderhistoryDetail({
      lang,
      order,
    });
    if (!response.data) {
      throw new Error(`${response.status ?? '500.'}`);
    }
    return response;
  },
);

export const OrderhistoryDetailSlice = createSlice({
  name: 'orderhistoryDetail',
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.error = undefined;
      state.cancelOrderError = undefined;
      state.isVoidCoupon = undefined;
    },
    clearResult: (state) => {
      state.result = undefined;
      state.isCanceled = undefined;
    },
  },
  extraReducers: (builder) => {
    // pending, fulfilled, rejected
    builder
      .addCase(profileOrderhistoryDetailQuery.pending, (state) => {
        state.isLoading = true;
        state.result = undefined;
      })
      .addCase(profileOrderhistoryDetailQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload === null) {
          state.result = {
            info: null,
          };
        } else {
          state.result = action.payload?.data ?? null;
        }
      })
      .addCase(profileOrderhistoryDetailQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.result = null;
        state.error = action.error.message;
      });
    // pending, fulfilled, rejected
    builder
      .addCase(cancelOrder.pending, (state) => {
        state.isLoadingCancelOrder = true;
        state.cancelOrderError = undefined;
      })
      .addCase(cancelOrder.fulfilled, (state, action) => {
        state.isLoadingCancelOrder = false;
        state.cancelOrderError = undefined;
        state.isCanceled = true;
        state.isVoidCoupon = action.payload?.data?.isVoidCoupon;
      })
      .addCase(cancelOrder.rejected, (state, action) => {
        state.isLoadingCancelOrder = false;
        state.cancelOrderError = action.error.message;
      });
  },
});

export const { clearError, clearResult } = OrderhistoryDetailSlice.actions;

export const orderhistoryDetailResultSelector = (
  store: RootState,
): OrderhistoryDetailPageResponse | undefined | null =>
  store.orderhistoryDetail.result;

export const isLoadingOrderhistoryDetailSelector = (
  store: RootState,
): boolean => store.orderhistoryDetail.isLoading;

export const errorOrderhistoryDetailSelector = (
  store: RootState,
): string | undefined => store.orderhistoryDetail.error;

export const isVoidCouponSelector = (store: RootState): boolean | undefined =>
  store.orderhistoryDetail.isVoidCoupon;

export const isLoadingCancelOrderSelector = (store: RootState): boolean =>
  store.orderhistoryDetail.isLoadingCancelOrder;

export const cancelOrderErrorSelector = (
  store: RootState,
): string | undefined => store.orderhistoryDetail.cancelOrderError;

export const isCanceledSelector = (store: RootState): boolean | undefined =>
  store.orderhistoryDetail.isCanceled;

export default OrderhistoryDetailSlice.reducer;
