import { Grid } from '@mui/material';
import React from 'react';
import footerClass from '@/styles/footer.module.css';
import { IconLink } from '@/models/IconLink.model';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import AppLink from '../handler/AppLink';

export default function TopFooter() {
  const { t } = useTranslation();
  const helpLinks: IconLink[] = [
    {
      link: '/page/power-care-always-there',
      logo: `https://${process.env.NEXT_PUBLIC_PROD_BASE_URL_WEB}/public/images/svg/footer/service/help24.svg`,
      alt: 'powercare',
      title: '1324',
      subTitle: `${t('service24hours')}`,
    },
    {
      link: '/page/power-care-after-sale-service',
      logo: `https://${process.env.NEXT_PUBLIC_PROD_BASE_URL_WEB}/public/images/svg/footer/service/maintenance.svg`,
      alt: 'maintenance',
      title: `${t('service')}`,
      subTitle: `${t('fixandmaintenace')}`,
    },
    {
      link: '/storelocator',
      logo: `https://${process.env.NEXT_PUBLIC_PROD_BASE_URL_WEB}/public/images/svg/footer/service/allstore.svg`,
      alt: 'allstore',
      title: `${t('branch122')}`,
      subTitle: `${t('allprovince')}`,
    },
    // {
    //   link: 'mailto:powerbuycontactcenter@powerbuy.co.th',
    //   logo: `https://${process.env.NEXT_PUBLIC_PROD_BASE_URL_WEB}/public/images/svg/footer/service/message.svg`,
    //   alt: 'message',
    //   title: `${t('leavemessage')}`,
    //   subTitle: `${t('tous')}`,
    // },
    {
      link: '/page/contact-us',
      logo: `https://${process.env.NEXT_PUBLIC_PROD_BASE_URL_WEB}/public/images/svg/footer/service/message.svg`,
      alt: 'contactus',
      title: `${t('leavemessage')}`,
      subTitle: `${t('tous')}`,
    },
  ];
  return (
    <div className={`${footerClass.topFooter} flex px-2`}>
      <Grid container className="max-width-container self-center">
        {helpLinks.map((helpLink, i) => (
          <Grid item key={i} xs={3} md={3} className="py-0.5">
            <AppLink
              className="px-2 self-center"
              href={helpLink.link}
              isFullUrl={helpLink.link.startsWith('mailto') ? true : false}
              target="_blank"
            >
              <div
                className={`flex flex-col md:flex-row justify-center px-1 ${
                  helpLinks.length - 1 == i ? '' : 'border-r-2 border-[#838383]'
                }`}
              >
                <div className="my-auto">
                  <Image
                    src={helpLink.logo}
                    alt={helpLink.alt ?? ''}
                    width={34}
                    height={43}
                    className="w-auto md:w-full h-4 md:h-auto"
                  />
                </div>
                <div className="text-primary md:ms-5 leading-none mt-2 md:mt-0">
                  <div className="font-bold text-xs md:text-base leading-none text-">
                    {helpLink.title}
                  </div>
                  <div className="text-xs text-grayLight3">
                    {helpLink.subTitle}
                  </div>
                </div>
              </div>
            </AppLink>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
