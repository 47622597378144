import React, { useState } from 'react';
import Image, { ImageProps } from 'next/image';

const ImageWithFallback = (props: ImageProps) => {
  const { src, alt, ...rest } = props;
  const [imgSrc, setImgSrc] = useState(src);

  return (
    <Image
      unoptimized
      {...rest}
      src={imgSrc}
      alt={alt}
      onError={() => {
        setImgSrc('/images/svg/no-img.svg');
      }}
    />
  );
};

export default ImageWithFallback;
