import { modelValue } from '@/utils/modelValue';

export interface GetResetMail {
  error?: boolean;
  msg?: string;
  status?: number;
}
export class GetResetMailConverter {
  public static fromJson(json: any): GetResetMail {
    const model: GetResetMail = {
      error: json['error'],
      msg: json['msg'],
      status: json['status'],
    };
    return modelValue(model);
  }
}

export interface GetUpdatePasswordModel {
  error?: boolean;
  msg?: string;
  status?: number;
}
export class GetUpdatePasswordConverter {
  public static fromJson(json: any): GetUpdatePasswordModel {
    const model: GetUpdatePasswordModel = {
      error: json['error'],
      msg: json['msg'],
      status: json['status'],
    };
    return modelValue(model);
  }
}
