import { WishlistResponse } from '@/models/profile/Wishlist.model';
import {
  getProfileWishlist,
  getWishlistPage,
} from '@/services/client/wishListService';
import { RootState } from '@/store/store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ServerCookies from '@/services/client/cookieService';
import * as cookieKey from '@/constants/cookieKey.constant';

export interface ProfileWishlistState {
  result?: WishlistResponse | null;
  currentPage: number;
  limit: number;
  isLoading: boolean;
  error?: string;
}

const initialState: ProfileWishlistState = {
  currentPage: 1,
  isLoading: false,
  limit: 7,
};

export const profileWishlistQuery = createAsyncThunk(
  'profileWishlist/profile/query',
  async ({ lang }: { lang?: string }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await getProfileWishlist({ lang });
    if (!response.data) {
      throw new Error(`${response.status ?? '500.'}`);
    }
    return response;
  },
);

export const profileWishlistPage = createAsyncThunk(
  'profileWishlist/queryPage',
  async ({ lang, page }: { lang?: string; page?: number }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await getWishlistPage({ lang, page });
    if (response.status === 401) {
      return null;
    }
    if (!response.data) {
      throw new Error(`${response.status ?? '500.'}`);
    }
    return response;
  },
);

export const profileWishlistSlice = createSlice({
  name: 'profileWishlist',
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.error = undefined;
    },
    clearResult: (state) => {
      state.result = undefined;
    },
  },
  extraReducers: (builder) => {
    // pending, fulfilled, rejected
    builder
      .addCase(profileWishlistQuery.pending, (state) => {
        state.isLoading = true;
        state.result = undefined;
      })
      .addCase(profileWishlistQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload === null) {
          state.result = {
            info: null,
          };
        } else {
          state.result = action.payload?.data ?? null;
        }
        state.limit = action.payload?.data?.limit ?? 7;
        state.currentPage = 1;
      })
      .addCase(profileWishlistQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.result = null;
        state.error = action.error.message;
        state.currentPage = 1;
      });
    // fulfilled, rejected
    builder
      .addCase(profileWishlistPage.fulfilled, (state, action) => {
        if (action.payload === null) {
          state.result = {
            info: null,
          };
        } else {
          state.result = {
            ...state.result,
            count: action.payload?.data?.count,
            products: action.payload?.data?.products,
            page: action.payload?.data?.page,
            limit: action.payload?.data?.limit,
          };
        }
        state.limit = action.payload?.data?.limit ?? 7;
        state.currentPage = action.payload?.data?.page ?? 1;
      })
      .addCase(profileWishlistPage.rejected, (state, action) => {
        state.result = null;
        state.error = action.error.message;
        state.currentPage = 1;
      });
  },
});

export const { clearError, clearResult } = profileWishlistSlice.actions;

export const wishlistProfileResultSelector = (
  store: RootState,
): WishlistResponse | undefined | null => store.profileWishlist.result;

export const isLoadingWishlistProfileSelector = (store: RootState): boolean =>
  store.profileWishlist.isLoading;

export const errorWishlistProfileSelector = (
  store: RootState,
): string | undefined => store.profileWishlist.error;

export const currentWishlistProfilePageSelector = (store: RootState): number =>
  store.profileWishlist.currentPage;

export const limitWishlistProfileSelector = (store: RootState): number =>
  store.profileWishlist.limit;

export default profileWishlistSlice.reducer;
