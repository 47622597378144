import { modelValue } from '@/utils/modelValue';
import { CookieData, CookieDataConverter } from './Authen.model';
import { ProductModel, ProductModelConverter } from './Product.model';

export interface SkuWithQty {
  sku: string;
  qty: number;
  groupId?: number;
  shadeId?: number;
}

export class SkuWithQtyConverter {
  public static toJson(model: SkuWithQty): any {
    const json: any = {
      SKCODE: model.sku,
      QNT: model.qty,
      GroupID: model.groupId,
      ShadeID: model.shadeId,
    };
    return modelValue(json);
  }
}

export interface AddToCartRequest {
  sku: string;
  qty?: number;
  setId?: string;
  skuQtyList?: SkuWithQty[];
  type?: string;
  isOk?: boolean;
  isConfirm?: boolean;
  isSelectBundle?: boolean;
  isFreeBundle?: boolean;
  withService?: string;
  lang?: string;
  skutrade?: string;
  aglqueryid?: string;
}

export class AddToCartRequestConverter {
  public static toJson(model: AddToCartRequest): any {
    const json: any =
      model.skuQtyList && model.skuQtyList.length > 0
        ? {
            skcode: model.skuQtyList?.map((e) => SkuWithQtyConverter.toJson(e)),
            qty: model.qty,
            type: 'promopage',
            isok: model.isOk,
            lang: model.lang,
          }
        : model.setId !== undefined && model.setId !== null
        ? {
            skcode: model.sku,
            qty: model.qty,
            type: model.type,
            isok: model.isOk,
            setid: model.setId,
            lang: model.lang,
          }
        : {
            skcode: model.sku,
            qty: model.qty,
            setid: model.setId,
            isok: model.isOk,
            isconfirm: model.isConfirm,
            isfreebundle: model.isFreeBundle,
            isselectbundle: model.isSelectBundle,
            withser: model.withService,
            lang: model.lang,
            skutrade: model.skutrade,
            aglqueryid: model.aglqueryid,
          };
    return modelValue(json);
  }
}

export interface AddToCartResponse {
  dbCode: boolean;
  dbItems?: ProductModel[];
  dbMessage?: string;
  type?: string;
  svgImage?: string;
  messages?: string[];
  isNotRefundMessage?: boolean;
  serviceNotAvail?: boolean;
  productName?: string;
  skuService?: string;
  sku?: string;
  ref?: string;
  cookies?: CookieData[];
  // from request
  qty?: number;
  isSelectBundle?: boolean;
  setId?: string;
  withService?: string;
  skuQtyList?: SkuWithQty[];
  skutrade?: string;
}

export class AddToCartResponseConverter {
  public static fromJson(json: any): AddToCartResponse {
    const model: AddToCartResponse = {
      dbCode: json['dbcode'] == true ? true : false,
      dbItems: json['dbitems']?.map((e: any) =>
        ProductModelConverter.fromJson(e),
      ),
      dbMessage: json['dbmessage']?.toString().trim(),
      type: json['type']?.toString().trim(),
      svgImage: json['svgImage']?.toString().trim(),
      messages: json['messages']?.map((e: any) => e?.toString().trim()),
      isNotRefundMessage: json['isNotRefundMessage'] == true ? true : false,
      serviceNotAvail: json['servicenotavail'] == true ? true : false,
      productName: json['prname']?.toString().trim(),
      skuService: json['skuservice']?.toString().trim(),
      sku: json['skcode']?.toString().trim(),
      ref: json['ref']?.toString().trim(),
      cookies: json['cookies']
        ? json['cookies'].map((x: any) => CookieDataConverter.fromJson(x))
        : undefined,
    };
    return modelValue(model);
  }
}
