import {
  GetIssueFromCodeModel,
  GetIssueFromCodeModelConverter,
} from '@/models/GetIssueFromCode.model';
import { postDataFromAPI } from '@/utils/httpClient';

export const getIssueFromCode = async ({
  lang,
  code,
}: {
  lang?: string;
  code: string;
}): Promise<GetIssueFromCodeModel> => {
  const [getApiData] = await Promise.all([
    postDataFromAPI({
      path: '/getissuefromcode',
      lang,
      body: { lang, code },
    }),
  ]);
  const apiData =
    getApiData.status === 200
      ? getApiData.data
        ? getApiData.data
        : undefined
      : getApiData.status === 401
      ? getApiData.data
        ? getApiData.data
        : undefined
      : undefined;

  let response: GetIssueFromCodeModel = apiData;

  if (apiData) {
    try {
      response = GetIssueFromCodeModelConverter.fromJson(apiData);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};
