import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import headerClass from '@/styles/header.module.css';
import AppLink from '../handler/AppLink';
import { AllCat } from '@/models/AllCat.model';
import AllCatContext from '@/contexts/AllCatContext';

export default function BottomHeader() {
  const { t: t_common, i18n } = useTranslation();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [currentNode, setCurrentNode] = useState<string | undefined>(undefined);
  const menuRef = useRef<HTMLInputElement>(null);
  const timeoutIdRef = useRef<number | null>(null);

  const handleMouseEnter = (node: string) => {
    if (node !== '') {
      setCurrentNode(node);
      timeoutIdRef.current = window.setTimeout(
        () => {
          setShowMenu(true);
        },
        showMenu ? 0 : 100,
      );
    } else {
      setShowMenu(false);
    }
  };

  const handleMouseLeave = (e: any) => {
    e.preventDefault();
    if (timeoutIdRef.current !== null) {
      clearTimeout(timeoutIdRef.current);
    }
    setShowMenu(false);
  };

  useEffect(() => {
    const handleMouseClick = (event: any) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('click', handleMouseClick);

    return () => {
      document.removeEventListener('click', handleMouseClick);
      if (timeoutIdRef.current !== null) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, []);
  return (
    <>
      <div className="hidden lg:block h-6 bg-darkPurple">
        <div
          className="relative max-width-container"
          ref={menuRef}
          onMouseLeave={handleMouseLeave}
        >
          <div
            className={`${headerClass.bottomBarText} flex justify-between text-white leading-6 xl:leading-6 px-1.5 h-6`}
          >
            <AllCatContext.Consumer>
              {(allcats) => {
                if (!allcats) {
                  return;
                }
                return (
                  <>
                    <AppLink href={`/page/all-promotions`}>
                      <div
                        className={`cursor-pointer overflow-hidden line-clamp-1 relative pe-2`}
                        onMouseEnter={(event: any) => {
                          event.preventDefault();
                          handleMouseEnter('');
                        }}
                      >
                        <span
                          className="text-secondaryOrange"
                          style={{ fontFamily: 'helvethaicax' }}
                        >
                          {t_common('allpromotions')}
                        </span>
                      </div>
                    </AppLink>
                    {allcats.map((cat, i) => (
                      <AppLink
                        key={i}
                        href={cat.slugname && `/category/${cat.slugname}`}
                      >
                        <div
                          className={`cursor-pointer overflow-hidden line-clamp-1 relative ${
                            i === 8 ? 'ps-2' : 'px-2'
                          }`}
                          onMouseEnter={(event: any) => {
                            event.preventDefault();
                            handleMouseEnter(cat.node);
                          }}
                        >
                          {i18n.language === 'th'
                            ? cat.catname
                            : cat.catnameEN ?? cat.catname}
                          {currentNode === cat.node && showMenu && (
                            <i className="bi bi-caret-up-fill h-3 leading-6 text-xs absolute bottom-0 left-1/2 -translate-x-1/2"></i>
                          )}
                        </div>
                      </AppLink>
                    ))}
                    {showMenu && (
                      <div
                        className="absolute p-2 w-full bg-white shadow-lg overflow-y-scroll scroll-smooth"
                        style={{
                          maxHeight: '80vh',
                          top: '36px',
                        }}
                      >
                        {currentNode && (
                          <div className={`w-full h-full`}>
                            <div className="columns-4">
                              {allcats?.find((e) => e.node == currentNode) &&
                                (
                                  allcats?.find(
                                    (e: any) => e.node == currentNode,
                                  )?.items || []
                                ).map((e: any) => (
                                  <div
                                    key={e.node}
                                    className="break-inside-avoid-column pb-2"
                                  >
                                    <AppLink
                                      href={
                                        e.slugname && `/category/${e.slugname}`
                                      }
                                      className="text-xs font-bold text-primary hover:bg-grayLight1 px-1 py-0.5 block"
                                    >
                                      {i18n.language === 'th'
                                        ? e.catname
                                        : e.catnameEN ?? e.catname}
                                    </AppLink>
                                    {(e.items ?? [])
                                      // .slice(0, 7)
                                      .map((r: AllCat, i: number) => (
                                        <AppLink
                                          key={`allcat-${i}`}
                                          className="block text-xs text-black hover:bg-grayLight1 px-1 py-0.5"
                                          href={
                                            r.slugname &&
                                            `/category/${r.slugname}`
                                          }
                                        >
                                          {i18n.language === 'th'
                                            ? r.catname
                                            : r.catnameEN ?? r.catname}
                                        </AppLink>
                                      ))}
                                    {/* {(e.items ?? []).length > 7 && (
                            <AppLink
                              className={MenuClass.seeMore}
                              href={`/category/${e.slugname}`}
                            >
                              {t('seeall')}
                            </AppLink>
                          )} */}
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                );
              }}
            </AllCatContext.Consumer>
          </div>
        </div>
      </div>
      <AppLink href={`/page/all-promotions`}>
        <div
          className="sm:block lg:hidden h-7 text-center"
          style={{ background: '#EE7203' }}
        >
          <span
            className="text-white text-center text-lg"
            style={{ fontFamily: 'helvethaicax' }}
          >
            {t_common('allpromotions')}
          </span>
        </div>
      </AppLink>
    </>
    // <Grid
    //   container
    //   display={{ xs: 'none', lg: 'flex' }}
    //   className="max-width-container relative"
    //   height="1.5rem"
    // >
    //   <div className="absolute w-full top-5">
    //     {showMenu && <HeroMenu mouseLeave={handleMouseLeave} />}
    //   </div>
    //   <Grid item xs={2} className="self-center">
    //     <div
    //       display="flex"
    //       onClick={() => {
    //         if (!exceptPath.includes(pathname)) {
    //           setShowMenu(!showMenu);
    //         }
    //       }}
    //       onMouseLeave={() => {
    //         clearTimeout(timerIdRef.current);
    //       }}
    //       onMouseEnter={handleMouseEnter}
    //     >
    //       <MenuIcon
    //         className="self-center cursor-pointer"
    //         sx={{ width: '1rem', height: '1rem' }}
    //       />
    //       <div className="self-center font-bold select-none cursor-pointer text-sm ms-1">
    //         {t('allcategories')}
    //       </div>
    //     </div>
    //   </Grid>
    // </Grid>
  );
}
