export const defaultStoreCode = '026';
export const defaultPickupStoreCode = '934';
export const pageToShowCompareList = [
  '',
  'product',
  'category',
  'page',
  'search',
];
export const exceptRedirectList = ['/', '/signin', '/register'];
export const exceptStaticHeader = [
  '/brand',
  '/search',
  '/category',
  '/signin',
  '/register',
  '/cart',
  '/checkout',
  '/thank',
  '/profile',
  '/paymentfail',
  '/repayment',
];
export const serviceAtbFlags = ['V', 'XV'];
export const currencyCode = 'THB';
export const notRetryStatusCode = [203, 204, 401, 500];
export const notRefreshError = [
  'incorrectcardnumber',
  'incorrectcardholdername',
  'incorrectexpirydate',
  'incorrectexpirydate',
  'incorrectcvv',
  'qrwillavailableinfuture',
];

export const fcmServerKey =
  process.env.NEXT_PUBLIC_NODE_ENV === 'development'
    ? {
        type: 'service_account',
        project_id: 'powerbuy-uat',
        private_key_id: '258bb3ce9d98e8fa0d712c3076a02446d3ee1640',
        private_key:
          '-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDlr+4bRELvXeGm\nY8AWWUGugCEz/gATNqX4sfg9EjuycvYH4QVvGtY4jrkSvdZjk9VVIXMFUEkmvpPY\n7YOoZ3crN0dR0nH//I5InobOmkUY72Xb35iKtzOjIYSy5IeSw3wVHQjfSkamp7fB\nr1/SS6B2k6MCHupxlGM/5E+dZiCWCcqO4MZn093sY0JPWxOdy8VHjlHyOhZKZUo7\ndtwsE5C558dLIwoUMwEhZdTurRJuf5PxN6YZpmauVUQID4qnmUq8/jCHLBJI4UJ8\nNFOSjdOevqoA6SQ3GOYtXTOh+h3dJFWi4+YRJjaFSV3crGEw8SpQjYQUUq6jFajL\nvej20k41AgMBAAECggEACM956M+lnHMmwjasuhuI/CdL3sFjm4xEZPWsSekGo2UE\ng5XbZ83vbkwo1KUcJN9/vRwjQnUFCuu5iR/6cmQrO1wLkmhqDHTRuMuX7Gayt6IL\nPE1nfdhI+0nZ9YpaZvdOFBNA8V7y6dagiYxVtrl4hl19N1bo2eixJYf3XXH678/0\nUOscCa+wd4n+p3ec8nmpD+6d39/oV5CB2XWJ9jlMdA63N2CrfgewCEVeeGqSUtm3\nhV/ay4meGV6L+W4n0tSzFW0eiKq6j/sp+qeaSLyx2u45Qf9Zj6YjU6RNfqlAFRyF\nSjYu4nvUne+iMHVPS91wm7xa7TmiHOl91MN4RTtt+wKBgQD9Sqhu05GwGyDhtd93\nyMQYgDkC1kNQlNn5oztYBx/so0N4OA/WDl78d2aEPwJRfZNjDWo65wl9EIFV6IY/\n/aXrKQ8EeTKpkdaOiPqqDOiNO9QTyZT+GmMHEGjNVLmtInOkvb3SADFQfOf46a+B\nL8WEotg08yB5BgnRWSPF+2udWwKBgQDoJKjALukmg1cHqWm5qRPmvkMR5ddTBtp9\nO1ne5wU64NNo4T/HaCaZXbFwTAIXq7O6HzmUXBar6BSUezGgrJDBihOSUUevihb1\nJGcTfHuTJWkwEIJGDB8uKzK/rL8y2Uffkos9joQjTz3PnvRi/veaRpCCm9F72pLj\noa4FddDHrwKBgAUYCUchTh7CjOi9JZ9dFESB36Ok93mZZMDWfY0Jp6S0q9ixF/Bw\nHYzqQc+LDNrisaIeVPKWTuSK/m7h3EFRMpMGIl0uqswqOKxpKNFerRaaC2vSdAKg\nOTmdJN5sQ8etl7HKdxTQGC3MNUYWPQdWFJzUQZDfCtokOvJQ3p1Orx1dAoGBAL38\n4q8GtYHzk9KNcB2mpsb9h96qAKPOxBoh7Ndksv8Q6XS4hH4qWwzBhWqmij/dImZ9\nD57ADz353n32pRMeqAn9ey8Qnl6n4UoX1NQ52NkAkSnpGPVDDnG2oP4F3yU6dnwf\nbx2d+b78J1Y4fUJX8USt469A3x1CMV1jAQ0u8wO5AoGABHz9Ndk57IZhD8KLWo13\npzx8oYnovq5kKqtWSEV5E0WirDwUuxHO/O5MF+x7+juaUdYvMo0qaVCJW8Ooisvj\nPTVTgEVbM8yvYajjRzQe6y7EPQ4Djhxlkm+IhbkmdFjtyPSA1cq/sUXnRz+tDJr2\nrAYlF9pADLIpHPoOnehQjOs=\n-----END PRIVATE KEY-----\n',
        client_email:
          'firebase-adminsdk-ig39g@powerbuy-uat.iam.gserviceaccount.com',
        client_id: '109534917954013536961',
        auth_uri: 'https://accounts.google.com/o/oauth2/auth',
        token_uri: 'https://oauth2.googleapis.com/token',
        auth_provider_x509_cert_url:
          'https://www.googleapis.com/oauth2/v1/certs',
        client_x509_cert_url:
          'https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-ig39g%40powerbuy-uat.iam.gserviceaccount.com',
        universe_domain: 'googleapis.com',
      }
    : {
        type: 'service_account',
        project_id: 'powerbuy-uat',
        private_key_id: '258bb3ce9d98e8fa0d712c3076a02446d3ee1640',
        private_key:
          '-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDlr+4bRELvXeGm\nY8AWWUGugCEz/gATNqX4sfg9EjuycvYH4QVvGtY4jrkSvdZjk9VVIXMFUEkmvpPY\n7YOoZ3crN0dR0nH//I5InobOmkUY72Xb35iKtzOjIYSy5IeSw3wVHQjfSkamp7fB\nr1/SS6B2k6MCHupxlGM/5E+dZiCWCcqO4MZn093sY0JPWxOdy8VHjlHyOhZKZUo7\ndtwsE5C558dLIwoUMwEhZdTurRJuf5PxN6YZpmauVUQID4qnmUq8/jCHLBJI4UJ8\nNFOSjdOevqoA6SQ3GOYtXTOh+h3dJFWi4+YRJjaFSV3crGEw8SpQjYQUUq6jFajL\nvej20k41AgMBAAECggEACM956M+lnHMmwjasuhuI/CdL3sFjm4xEZPWsSekGo2UE\ng5XbZ83vbkwo1KUcJN9/vRwjQnUFCuu5iR/6cmQrO1wLkmhqDHTRuMuX7Gayt6IL\nPE1nfdhI+0nZ9YpaZvdOFBNA8V7y6dagiYxVtrl4hl19N1bo2eixJYf3XXH678/0\nUOscCa+wd4n+p3ec8nmpD+6d39/oV5CB2XWJ9jlMdA63N2CrfgewCEVeeGqSUtm3\nhV/ay4meGV6L+W4n0tSzFW0eiKq6j/sp+qeaSLyx2u45Qf9Zj6YjU6RNfqlAFRyF\nSjYu4nvUne+iMHVPS91wm7xa7TmiHOl91MN4RTtt+wKBgQD9Sqhu05GwGyDhtd93\nyMQYgDkC1kNQlNn5oztYBx/so0N4OA/WDl78d2aEPwJRfZNjDWo65wl9EIFV6IY/\n/aXrKQ8EeTKpkdaOiPqqDOiNO9QTyZT+GmMHEGjNVLmtInOkvb3SADFQfOf46a+B\nL8WEotg08yB5BgnRWSPF+2udWwKBgQDoJKjALukmg1cHqWm5qRPmvkMR5ddTBtp9\nO1ne5wU64NNo4T/HaCaZXbFwTAIXq7O6HzmUXBar6BSUezGgrJDBihOSUUevihb1\nJGcTfHuTJWkwEIJGDB8uKzK/rL8y2Uffkos9joQjTz3PnvRi/veaRpCCm9F72pLj\noa4FddDHrwKBgAUYCUchTh7CjOi9JZ9dFESB36Ok93mZZMDWfY0Jp6S0q9ixF/Bw\nHYzqQc+LDNrisaIeVPKWTuSK/m7h3EFRMpMGIl0uqswqOKxpKNFerRaaC2vSdAKg\nOTmdJN5sQ8etl7HKdxTQGC3MNUYWPQdWFJzUQZDfCtokOvJQ3p1Orx1dAoGBAL38\n4q8GtYHzk9KNcB2mpsb9h96qAKPOxBoh7Ndksv8Q6XS4hH4qWwzBhWqmij/dImZ9\nD57ADz353n32pRMeqAn9ey8Qnl6n4UoX1NQ52NkAkSnpGPVDDnG2oP4F3yU6dnwf\nbx2d+b78J1Y4fUJX8USt469A3x1CMV1jAQ0u8wO5AoGABHz9Ndk57IZhD8KLWo13\npzx8oYnovq5kKqtWSEV5E0WirDwUuxHO/O5MF+x7+juaUdYvMo0qaVCJW8Ooisvj\nPTVTgEVbM8yvYajjRzQe6y7EPQ4Djhxlkm+IhbkmdFjtyPSA1cq/sUXnRz+tDJr2\nrAYlF9pADLIpHPoOnehQjOs=\n-----END PRIVATE KEY-----\n',
        client_email:
          'firebase-adminsdk-ig39g@powerbuy-uat.iam.gserviceaccount.com',
        client_id: '109534917954013536961',
        auth_uri: 'https://accounts.google.com/o/oauth2/auth',
        token_uri: 'https://oauth2.googleapis.com/token',
        auth_provider_x509_cert_url:
          'https://www.googleapis.com/oauth2/v1/certs',
        client_x509_cert_url:
          'https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-ig39g%40powerbuy-uat.iam.gserviceaccount.com',
        universe_domain: 'googleapis.com',
      };

export const exceptLayoutList = ['/kbank/kborder', '/appcall2c2p'];

export const subStringList: string[] = ['Z'];
