import { modelValue } from '@/utils/modelValue';
import {
  CookieData,
  CookieDataConverter,
  CustomerInfo,
  CustomerInfoConverter,
} from '../Authen.model';
import { AddressConverter, Address } from '../Address.model';

export interface AddressPageResponse {
  title?: string;
  sub?: string;
  myAddress?: Address[];
  info?: CustomerInfo | null;
  cookies?: CookieData[] | null;
}

export class AddressPageResponseConverter {
  public static fromJson(json: any): AddressPageResponse {
    const model: AddressPageResponse = {
      title: json['title'],
      sub: json['sub'],
      myAddress: json['getaddress']
        ? json['getaddress'].map((e: any) => AddressConverter.fromJson(e))
        : undefined,
      info: json['info']
        ? CustomerInfoConverter.fromJson(json['info'])
        : undefined,
      cookies: json['cookies']
        ? json['cookies'].map((x: any) => CookieDataConverter.fromJson(x))
        : undefined,
    };
    return modelValue(model);
  }
}
