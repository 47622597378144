import { modelValue } from '@/utils/modelValue';
import { numberWithCommas } from '@/utils/format';

export interface ProductModel {
  seq: number;
  minPrice?: string;
  maxPrice?: string;
  prCode?: string;
  sku: string;
  name: string;
  nameEN?: string;
  slugname: string;
  brand: string;
  model?: string;
  unitName?: string;
  priceSort?: number;
  price: string;
  amount?: string;
  originalPrice?: string;
  total?: string;
  totalWithService?: string;
  originalTotal?: string;
  stock?: number;
  discount?: string;
  qty?: number;
  sumQty?: number;
  qtyAvail?: number;
  discountPercent?: number;
  base64?: string;
  pimImage?: string;
  spu?: number;
  avgReview?: number;
  totalReview?: number;
  atbFlag?: string;
  tagNew?: number;
  hugThai?: number;
  isLineButton: boolean;
  skuStatus?: string;
  assemble?: null;
  freegiftMessage?: string;
  freegiftBundle?: string;
  freeInstallBadge?: number;
  warranty?: string;
  warrantyCode?: string;
  perSqmeter?: null;
  minPriceSqmeter?: null;
  maxPriceSqmeter?: null;
  badgeBuyDisc?: null;
  stockAvail?: number;
  clearanceFlag?: number;
  saleFlag?: number;
  homeBranch?: string;
  minQty?: number;
  theOnePromotion?: number;
  backOrderFlag?: boolean;
  packSize?: number;
  remark?: null;
  atbBadges?: AtbBadge[];
  priceSaved?: string;
  skuService?: string;
  isInstall?: boolean;
  weight?: number;
  isFreegift?: boolean;
  canSelectFreegift?: boolean;
  isFreegiftNostock?: boolean;
  isPreOrder?: boolean;
  isShowOriginalPrice?: boolean;
  class?: string;
  skuServiceDiscount?: string;
  buyService?: boolean;
  leadtimeText?: string;
  onWeb?: boolean;
  qntBundle?: number;
  prUname?: string;
  isCrCharge?: boolean;
  serviceTracking?: ServiceTracking;
  bundleDiscount?: number;
  bundleAmount?: number;
  canDelivery?: boolean;
  canPickup?: boolean;
  catName?: string;
  position?: number;
  listName?: string;
  jsnLink?: string;
  skuCount?: number;
  ctlid?: number;
  usestock?: string;
  itemseq?: string;
  serSKCODE?: null;
  forceINST?: number;
  skutrade?: number;
  algposition?: number;
}

export class ProductModelConverter {
  public static fromJson(json: any): ProductModel {
    let canDelivery = false;
    let canPickup = false;
    let stockAvail = json['STOCKAVAIL'];
    try {
      if (json['deliveryFlag']) {
        canDelivery = json['deliveryFlag'][0] === '1' ? true : false;
        canPickup = json['deliveryFlag'][1] === '1' ? true : false;
        if (!canDelivery && !canPickup) {
          stockAvail = 0;
        }
      }
    } catch (_) {}
    const model: ProductModel = {
      seq: json['sqorder'] ?? json['SEQ'] ?? 9999,
      minPrice: json['min_price'],
      maxPrice: json['max_price'],
      prCode: json['prCode'] ?? json['PCD'],
      sku: (
        json['skcode'] ??
        json['SKCODE'] ??
        json['SKC'] ??
        json['skc'] ??
        json['sku'] ??
        ''
      )
        .toString()
        .trim(),
      name: (
        json['name'] ??
        json['PRN'] ??
        json['prn'] ??
        json['PRNAME'] ??
        json['PR_NAME'] ??
        ''
      ).trim(),
      nameEN: json['PRNE']?.trim(),
      slugname: json['slugname'] ?? '',
      brand: (
        json['brand'] ??
        json['BRN'] ??
        json['PRBRAND'] ??
        json['PR_BRAND'] ??
        ''
      ).trim(),
      model: json['model'],
      unitName: json['uname'] ?? json['UNIT'],
      priceSort: json['pricesort'],
      price: (
        json['price'] ??
        json['PRPRICE'] ??
        json['UPCSHOW'] ??
        json['UPC'] ??
        json['upc'] ??
        json['upcshow'] ??
        json['PR_PRICE'] ??
        ''
      )
        .toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      amount: json['amount'],
      originalPrice:
        json['PR_ORGPRICE'] || json['ORGPRICE']
          ? (json['PR_ORGPRICE'] ?? json['ORGPRICE'] ?? '')
              .toString()
              .trim()
              .replace('฿', '')
              .trim()
              .replaceAll('.00', '')
          : undefined,
      total:
        json['AMT'] ||
        json['TOT'] ||
        json['AMTSHOW'] ||
        json['amt'] ||
        json['amtshow']
          ? (
              json['AMT'] ??
              json['TOT'] ??
              json['AMTSHOW'] ??
              json['amt'] ??
              json['amtshow'] ??
              ''
            )
              .toString()
              .trim()
              .replace('฿', '')
              .trim()
              .replaceAll('.00', '')
          : undefined,
      totalWithService: json['TOTSHOW']
        ?.toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      originalTotal:
        json['AMT_ORGPRICE'] || json['AMTORGPRICE']
          ? (json['AMT_ORGPRICE'] ?? json['AMTORGPRICE'] ?? '')
              .toString()
              .trim()
              .replace('฿', '')
              .trim()
              .replaceAll('.00', '')
          : undefined,
      stock: json['STCK'] || json['STOCK'],
      discount: (json['disc'] ?? json['DISC'] ?? '')
        .toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      qty: json['QNT'] ?? json['qty'] ?? json['qnt'],
      sumQty: Number((json['SUMQNT'] ?? 0).toString().trim().replace(/,/g, '')),
      qtyAvail: json['QNTAVAIL'],
      discountPercent:
        json['per'] || json['PER']
          ? parseInt(
              (json['per'] ?? json['PER'] ?? '')
                .toLowerCase()
                .replace('off', '')
                .replace(/ /g, '')
                .replace('%', ''),
            )
          : undefined,
      base64: json['base64'] ?? json['Base64'] ?? json['img'] ?? json['BASE64'],
      pimImage: json['pimImage'],
      spu: json['SPU'],
      avgReview: json['AVGREVIEW'],
      totalReview: json['TOTALREVIEW'],
      atbFlag: json['ABTFLAG'] ?? json['ATBCODE'] ?? json['ATB'],
      tagNew: json['TAGNEW'],
      hugThai: json['HUGTHAI'],
      isLineButton:
        json['Linebt'] === true || json['Linebt'] === 'Y' ? true : false,
      skuStatus: json['SKUstatus'] ?? json['STATUS'],
      assemble: json['assemble'],
      freegiftMessage: json['FREEGIFTMSG'],
      freegiftBundle: json['FREEGIFTBUNDLE'],
      freeInstallBadge: json['FREEINSTALBADGE'],
      warranty: json['WARRANTY'],
      warrantyCode: json['WARRANTYCODE'],
      perSqmeter: json['PERSQMET'],
      minPriceSqmeter: json['MIN_PRICE_SQMETERS'],
      maxPriceSqmeter: json['MAX_PRICE_SQMETERS'],
      badgeBuyDisc: json['BadgeBuyDisc'],
      stockAvail,
      clearanceFlag: json['clearanceflag'],
      saleFlag: json['saleflag'],
      homeBranch: json['HOMExBRCH'],
      minQty: json['MINQTY'],
      theOnePromotion: json['theonepromo'],
      backOrderFlag: json['BackOrderFlag'],
      packSize: json['packSize'],
      remark: json['Remark'] ?? json['remark'],
      atbBadges: json['atbBadges']
        ? json['atbBadges'].map((x: any) => AtbBadgeConverter.fromJson(x))
        : undefined,
      priceSaved: calSavedPrice(
        json['price'] ? json['price'] : json['PR_PRICE'],
        json['disc'] ? json['disc'] : json['DISC'],
      ),
      skuService: json['SKUSERVICE']?.toString().trim(),
      isInstall: json['ISINSTALL'] == true ? true : false,
      weight: json['WEIGHT'],
      isFreegift:
        json['AUTOPLPP'] == 1 ||
        json['FREEGIFT'] ||
        (json['autoplpp'] === true) === true
          ? true
          : false,
      canSelectFreegift: json['FREESELECT'] == 1 ? true : false,
      isFreegiftNostock: json['FREENOSTOCK'] == 1 ? true : false,
      isPreOrder: json['PREORDER'] == 1 ? true : false,
      isShowOriginalPrice: json['FlagShow_ORGPRICE'] == 1 ? true : false,
      class: json['CLASS'],
      skuServiceDiscount: (json['SKUSERVICEDISC'] ?? '')
        .toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      buyService: json['BUYSERVICE'] == true ? true : false,
      leadtimeText: json['leadtimeText'],
      onWeb: json['onWeb'] !== null ? true : false,
      qntBundle: json['qntBundle'],
      prUname: json['prUname'] ?? json['PR_UNAME'],
      isCrCharge: json['isCrCharge'] == true ? true : false,
      serviceTracking: json['trackser']
        ? ServiceTrackingConverter.fromJson(json['trackser'])
        : undefined,
      bundleDiscount: json['BUNDLEDISC']
        ? Number(
            json['BUNDLEDISC']
              .toString()
              .trim()
              .replace('฿', '')
              .trim()
              .replaceAll('.00', '')
              .replaceAll(',', ''),
          )
        : undefined,
      bundleAmount: json['BUNDLEAMOUNT']
        ? Number(
            json['BUNDLEAMOUNT']
              .toString()
              .trim()
              .replace('฿', '')
              .trim()
              .replaceAll('.00', '')
              .replaceAll(',', ''),
          )
        : undefined,
      canDelivery,
      canPickup,
      catName: json['CATNAME'],
      jsnLink: json['jsnLink'],
      skuCount: json['skcount'],
      ctlid: json['CTLID'],
      usestock: json['USESTOCK'],
      itemseq: json['ITEMSEQ'],
      serSKCODE: json['serSKCODE'],
      forceINST: json['forceINST'],
      skutrade: json['SKUTRADE'],
      algposition: json['algposition'],
    };
    return modelValue(model);
  }
}

const calSavedPrice = (price1: string, discount1: string) => {
  let price = price1 ? price1.trim() : '';
  let discount = discount1 ? discount1.trim() : '';
  if (price && price !== '' && discount && discount !== '') {
    let savedPrice = 0;
    try {
      const priceNum = parseInt(
        price ? price.replace(',', '').replace('฿', '') : '',
      );
      const discountNum = parseInt(discount.replace(',', '').replace('฿', ''));
      savedPrice = discountNum - priceNum;
      return savedPrice > 0
        ? numberWithCommas(savedPrice.toFixed(2)).replaceAll('.00', '')
        : undefined;
    } catch (_) {
      return undefined;
    }
  }
};

export interface AtbBadge {
  base64?: string;
  idx: number;
}

export class AtbBadgeConverter {
  public static fromJson(json: any): AtbBadge {
    const model: AtbBadge = {
      base64: json['base64'] ?? json['IMGbase64'],
      idx: json['idx'] ?? 9,
    };
    return modelValue(model);
  }
}

export interface ServiceTracking {
  carrier?: string;
  trackingNumber?: string;
  deliveryDate?: string;
  statusCode?: string;
  closeJobDate?: string;
}

export class ServiceTrackingConverter {
  public static fromJson(json: any): ServiceTracking {
    const model: ServiceTracking = {
      carrier: json['carrier'],
      trackingNumber: json['trackingNumber'],
      deliveryDate: json['deliveryDate'],
      statusCode: json['statusCode'],
      closeJobDate: json['closeJobDate'],
    };
    return modelValue(model);
  }
}
