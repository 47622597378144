import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { GetUpdatePasswordModel } from '@/models/GetResetMail.model';
import { getUpdatePassword } from '@/services/client/getResetMailService';

interface GetUpdatePasswordState {
  result: GetUpdatePasswordModel;
  isLoading: boolean;
}

const initialState: GetUpdatePasswordState = {
  result: {
    error: true,
    msg: 'Initial message',
    status: 500,
  },
  isLoading: false,
};

export const getUpdatePasswordQuery = createAsyncThunk(
  'getupdatepassword/add',
  async ({
    lang,
    token,
    password,
  }: {
    lang: string;
    token: string;
    password: string;
  }) => {
    const response = await getUpdatePassword({ lang, token, password });
    return response;
  },
);

const getUpdatePasswordSlice = createSlice({
  name: 'getUpdatePassword',
  initialState: initialState,
  reducers: {
    clearResultGetUpdatePassword: (state) => {
      state.result = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUpdatePasswordQuery.pending, (state) => {
        state.result = {};
        state.isLoading = true;
      })
      .addCase(getUpdatePasswordQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.result = action.payload;
      })
      .addCase(getUpdatePasswordQuery.rejected, (state) => {
        state.isLoading = false;
        state.result = {};
      });
  },
});

export const { clearResultGetUpdatePassword } = getUpdatePasswordSlice.actions;

export const getUpdatePasswordResultsSelector = (
  store: RootState,
): GetUpdatePasswordModel => store.getUpdatePassword.result;

export const isLoadingUpdatePasswordSelector = (store: RootState): boolean =>
  store.getUpdatePassword.isLoading;

export default getUpdatePasswordSlice.reducer;
