import { AccountSummaryResponse } from '@/models/profile/AccountSummary.model';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as cookieKey from '@/constants/cookieKey.constant';
import {
  getProfileAccountSummary,
  postMemTheOne,
} from '@/services/client/accountSummaryService';
import { RootState } from '@/store/store';
import * as ServerCookies from '@/services/client/cookieService';

interface AccountSummaryState {
  result?: AccountSummaryResponse | null;
  isLoading: boolean;
  error?: string;
  isLoadingUpdateInfo: boolean;
  errorUpdateInfo?: string;
  updateInfoSuccess?: boolean;
  isUpdatedInfo?: boolean;
}

const initialState: AccountSummaryState = {
  isLoading: false,
  isLoadingUpdateInfo: false,
};

export const profileAccountSummaryQuery = createAsyncThunk(
  'accountSummary/profile/query',
  async ({ lang }: { lang?: string }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const [response] = await Promise.all([getProfileAccountSummary({ lang })]);
    if (!response.data) {
      throw new Error(`${response.status ?? '500.'}`);
    }
    return { response };
  },
);

export const updateInfo = createAsyncThunk(
  'accountSummary/profile/updateinfo',
  async ({ card }: { card?: string }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    if (!card) {
      throw new Error('Not found The1 number');
    }
    let sendData = {
      mode: 1,
      t1c: card,
    };
    const response = await postMemTheOne(sendData);
    if (response) {
      throw new Error(`${response}`);
    }
    return response;
  },
);
export const accountSummarySlice = createSlice({
  name: 'accountSummary',
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.error = undefined;
      state.errorUpdateInfo = undefined;
    },
    clearResult: (state) => {
      state.result = undefined;
    },
    clearSuccess: (state) => {
      state.updateInfoSuccess = undefined;
    },
  },
  extraReducers: (builder) => {
    // pending, fulfilled, rejected
    builder
      .addCase(profileAccountSummaryQuery.pending, (state) => {
        state.isLoading = true;
        state.result = undefined;
      })
      .addCase(profileAccountSummaryQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload === null) {
          state.result = {
            info: null,
          };
        } else {
          state.result = action.payload?.response.data ?? null;
        }
      })
      .addCase(profileAccountSummaryQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.result = null;
        state.error = action.error.message;
      });
    builder
      .addCase(updateInfo.pending, (state) => {
        state.isLoadingUpdateInfo = true;
        state.isUpdatedInfo = false;
        state.errorUpdateInfo = undefined;
        state.updateInfoSuccess = undefined;
      })
      .addCase(updateInfo.fulfilled, (state) => {
        state.isLoadingUpdateInfo = false;
        state.isUpdatedInfo = true;
        state.updateInfoSuccess = true;
      })
      .addCase(updateInfo.rejected, (state, action) => {
        state.isLoadingUpdateInfo = false;
        state.isUpdatedInfo = undefined;
        state.updateInfoSuccess = undefined;
        state.errorUpdateInfo = action.error.message;
      });
  },
});

export const { clearError, clearResult, clearSuccess } =
  accountSummarySlice.actions;

export const accountSummaryProfileResultSelector = (
  store: RootState,
): AccountSummaryResponse | undefined | null =>
  store.profileAccountSummary.result;

export const isLoadingAccountSummaryProfileSelector = (
  store: RootState,
): boolean => store.profileAccountSummary.isLoading;

export const errorAccountSummaryProfileSelector = (
  store: RootState,
): string | undefined => store.profileAccountSummary.error;

export const isLoadingUpdateInfoSelector = (store: RootState): boolean =>
  store.profileAccountSummary.isLoadingUpdateInfo;

export const errorUpdateInfoSelector = (store: RootState): string | undefined =>
  store.profileAccountSummary.errorUpdateInfo;

export const updateInfoSuccessSelector = (
  store: RootState,
): boolean | undefined => store.profileAccountSummary.updateInfoSuccess;

export const isUpdatedInfoSelector = (store: RootState): boolean | undefined =>
  store.profileAccountSummary.isUpdatedInfo;

export default accountSummarySlice.reducer;
