import {
  GetEditReviewModelConverter,
  GetEditReviewModel,
} from '@/models/GetEditReview.model';

import { postDataFromAPI } from '@/utils/httpClient';

export const getProfileMyReviewEdit = async ({
  lang,
  hrecid,
}: {
  lang: string;
  hrecid?: string;
}): Promise<{ data: GetEditReviewModel; status: number | null }> => {
  const [getApiData] = await Promise.all([
    postDataFromAPI({
      path: '/geteditreview',
      lang,
      body: { lang, id: hrecid },
    }),
  ]);
  const apiData =
    getApiData.status === 200
      ? getApiData.data
        ? getApiData.data
        : undefined
      : undefined;
  let response: {
    data: GetEditReviewModel;
    status: number | null;
  } = {
    data: apiData || {},
    status: getApiData.status,
  };

  if (apiData) {
    try {
      response.data = GetEditReviewModelConverter.fromJson(apiData);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};
