import { postDataFromAPI } from '@/utils/httpClient';
import Cookies from 'js-cookie';
import * as cookieKey from '@/constants/cookieKey.constant';
import * as defaultValue from '@/constants/defaultValue.constant';
import {
  HomePageEventResponseModel,
  HomePageEventResponseModelConverter,
} from '@/models/HomePageEvent.model';

export const getEventData = async ({
  lang,
  hotcat,
  maxshow,
}: {
  lang?: string;
  hotcat?: string;
  maxshow?: number;
}): Promise<HomePageEventResponseModel | undefined> => {
  const [getApiData] = await Promise.all([
    postDataFromAPI({
      path: '/eventdata',
      lang,
      body: {
        hotcat,
        maxshow: maxshow ?? '10',
        offset: '0',
        stcode: Cookies.get(cookieKey.stcode) ?? defaultValue.defaultStoreCode,
      },
    }),
  ]);
  const apiData =
    getApiData.status == 200
      ? getApiData.data
        ? getApiData.data
        : undefined
      : undefined;
  let response: HomePageEventResponseModel | undefined = apiData;
  if (apiData) {
    try {
      response = HomePageEventResponseModelConverter.fromJson(apiData);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};
