import React from 'react';
import Image from 'next/image';

interface Props {
  base64?: string;
  alt?: string;
  width?: number;
  maxHeight?: number;
  isBoreder?: boolean;
  noPadding?: boolean;
  className?: string;
  pimImage?: string;
}

export default function ProductImageBase64({
  base64,
  alt,
  width,
  maxHeight,
  isBoreder,
  noPadding,
  className,
  pimImage,
}: Props) {
  return (
    <div className={`${isBoreder ? `border ${!noPadding && 'p-1'}` : ''}`}>
      {base64 || pimImage ? (
        <Image
          src={
            pimImage
              ? `https://${process.env.NEXT_PUBLIC_BASE_URL_PIM_DOMAIN}/PWBPIM/web/Thumbnail/${pimImage}`
              : `data:image/jpeg;base64,${base64}`
          }
          alt={alt ?? ''}
          width={width ?? 319}
          height={width ?? 319}
          className={className}
          style={{
            objectFit: 'contain',
            width: width,
            height: width,
            maxHeight: maxHeight,
          }}
        />
      ) : (
        <Image
          src={`/images/svg/no-img.svg`}
          alt={alt ?? ''}
          width={width ?? 319}
          height={width ?? 319}
          style={{
            objectFit: 'contain',
          }}
        />
      )}
    </div>
  );
}
