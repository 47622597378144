import {
  compareResultsSelector,
  getImageToSetStore,
  isErrorSelector,
  isSetStoreSelector,
  mutateCompare,
  resetCompare,
  resetIsError,
} from '@/store/slices/compareSlice';
import Button from '@mui/material/Button';
import { useTranslation } from 'next-i18next';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import AppLink from './handler/AppLink';
import { useAppDispatch } from '@/store/store';
import { useRouter } from 'next/router';
import ProductImageBase64 from '../common/ProductImageBase64';
import Image from 'next/image';
import { pageToShowCompareList } from '../../constants/defaultValue.constant';

export default function CompareLayout() {
  const { t } = useTranslation('');
  const router = useRouter();
  const currentPage = router.pathname.split('/')[1];
  const compareResults = useSelector(compareResultsSelector);
  const isError = useSelector(isErrorSelector);
  const isSetstore = useSelector(isSetStoreSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isSetstore && pageToShowCompareList.includes(currentPage)) {
      dispatch(getImageToSetStore());
    }
  }, [isSetstore, dispatch, currentPage]);

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        dispatch(resetIsError());
      }, 3000);
    }
  }, [isError, dispatch]);

  const handleResetButton = () => {
    dispatch(resetCompare());
  };

  const handleRemoveButton = (sku: string) => {
    dispatch(mutateCompare({ sku, pimImage: '', slugname: '', urlimg: '' }));
  };

  return compareResults.length > 0 &&
    pageToShowCompareList.includes(currentPage) ? (
    <div className="fixed bottom-0 w-full" style={{ zIndex: 99 }}>
      {isError && (
        <div className="text-sm bg-secondaryOrange">
          <div className="max-width-container text-white text-center md:text-left px-2 xl:px-0">
            {t('comparelistisfull')}
          </div>
        </div>
      )}
      <div className="py-1 w-full bg-white shadow-[0px_0px_15px_0px_#00000024]">
        <div className="max-width-container text-sm px-2 xl:px-0 flex items-center w-full">
          <div className="hidden md:block md:w-1/5">
            {compareResults.length <= 1
              ? t('selectmorecompareproduct')
              : compareResults.length < 4
              ? `${t('compareproducts')} (${compareResults.length}/4)`
              : t('complete4items')}
          </div>
          <div className="hidden md:flex ms-2 w-3/6">
            {compareResults.map((item, i) => {
              return (
                <div key={i} className="w-1/4 flex">
                  <div className="w-8 h-8 border mx-auto relative">
                    <AppLink href={`/product/${item.slugname}`}>
                      {item.pimImage ? (
                        <ProductImageBase64
                          pimImage={item.pimImage}
                          width={48}
                        />
                      ) : item.urlimg ? (
                        <Image
                          src={`https://${process.env.NEXT_PUBLIC_BASE_URL_PIM_DOMAIN}/PWBPIM/web/Thumbnail/${item.urlimg}`}
                          alt={item.slugname}
                          width={100}
                          height={100}
                        />
                      ) : (
                        <ProductImageBase64
                          pimImage={item.pimImage}
                          width={48}
                        />
                      )}
                    </AppLink>
                    <div
                      className="absolute -top-1.5 -right-1.5 cursor-pointer"
                      onClick={() => {
                        handleRemoveButton(item.sku);
                      }}
                    >
                      <i className="bi bi-x-circle-fill text-redPrice"></i>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="ms-auto w-4/6 md:w-1/6">
            {compareResults.length > 1 ? (
              <AppLink href={`/compareproduct`}>
                <Button
                  className="bg-primary hover:bg-secondaryPurple"
                  sx={{
                    borderRadius: '0px',
                    border: '1px solid #4E1F66',
                    width: '100%',
                    height: '2rem',
                    color: 'white',
                    backgroundColor: '#4E1F66',
                    '&:hover': {
                      backgroundColor: '#854BA2',
                    },
                  }}
                >
                  <div className="hidden md:inline">{t('compare')}</div>
                  <div className="inline md:hidden">{`${t(
                    'compareproducts',
                  )} (${compareResults.length}/4)`}</div>
                </Button>
              </AppLink>
            ) : (
              <div className="block md:hidden text-center">
                {t('selectmorecompareproduct')}
              </div>
            )}
          </div>
          <div className="ps-2 w-2/6 md:w-1/6">
            <Button
              onClick={handleResetButton}
              sx={{
                borderRadius: '0px',
                border: '1px solid #4E1F66',
                width: '100%',
                height: '2rem',
                backgroundColor: 'white',
              }}
            >
              {t('reset')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
