import {
  WishlistResponse,
  WishlistResponseConverter,
} from '@/models/profile/Wishlist.model';
import { saveClientCookieFromResponse } from '@/utils/cookie';
import { getDataFromAPI, postDataFromAPI } from '@/utils/httpClient';

export const getProfileWishlist = async ({
  lang,
}: {
  lang?: string;
}): Promise<{
  data: WishlistResponse | null | undefined;
  status: number | null;
}> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/profile/wishlist',
      lang,
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: WishlistResponse | null | undefined;
    status: number | null;
  } = { data: data, status: getData.status };

  if (data) {
    try {
      response.data = WishlistResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  // ถ้าอนาคตมีคนเรียกใช้แต่ไม่อยาก save cookie ให้ย้ายไปใน Slice
  await saveClientCookieFromResponse(response.data?.cookies);
  return response;
};

export const getWishlistPage = async ({
  lang,
  page,
}: {
  lang?: string;
  page?: number;
}): Promise<{
  data: WishlistResponse | null | undefined;
  status: number | null;
}> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/getlistwistpage',
      lang,
      body: {
        page,
      },
    }),
  ]);

  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: WishlistResponse | null | undefined;
    status: number | null;
  } = { data: data, status: getData.status };

  if (data) {
    try {
      response.data = WishlistResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const getMyWishList = async (): Promise<string[] | null | undefined> => {
  const [getDataAPI] = await Promise.all([
    getDataFromAPI({
      path: '/getwishlist',
    }),
  ]);
  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : null;
  let response: string[] | null | undefined = apiData;
  return response;
};

export const editWishList = async ({
  sku,
  type,
}: {
  sku: string;
  type: 1 | 0; // 1: add, 0: remove
}): Promise<{
  data: string[] | null | undefined;
  status: number | null;
}> => {
  const [getDataAPI] = await Promise.all([
    postDataFromAPI({
      path: '/addwishlist',
      body: {
        skcode: sku,
        issku: 1,
        type,
      },
    }),
  ]);
  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : null;
  let response: string[] | null | undefined = apiData?.datas?.map((e: any) =>
    e.SKCODE.toString().trim(),
  );
  return {
    status: getDataAPI.status,
    data: response,
  };
};
