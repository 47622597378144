import React, { useEffect, useState } from 'react';
import headerClass from '@/styles/header.module.css';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import LangHeader from '../LangHeader';
import AppLink from '../../handler/AppLink';
import { useSelector } from 'react-redux';
import { customerNameSelector } from '@/store/slices/loginSlice';
import CustomerNameHover from './CustomerNameHover';
import { getRedirectQuery } from '@/utils/theone';

export default function TopHeader() {
  const { t } = useTranslation();
  const router = useRouter();
  const customerNameStore = useSelector(customerNameSelector);
  const [customerName, setCustomerName] = useState<string | null | undefined>(
    undefined,
  );

  useEffect(() => {
    setCustomerName(customerNameStore);
  }, [customerNameStore]);

  // const handleClickStore = () => {
  //   dispatch(openModal({ canSelectStore: false }));
  // };

  return (
    <div
      className={`hidden lg:block`}
      style={{
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2))`,
      }}
    >
      <div className={`${headerClass.topHeader} max-width-container`}>
        <div className="ms-auto text-sm me-5 self-center cursor-pointer hover:underline">
          <AppLink href="/storelocator">{t('storelocator')}</AppLink>
        </div>
        <div className="text-sm me-5 self-center cursor-pointer">
          {customerName ? (
            <CustomerNameHover customerName={customerName} />
          ) : (
            <div className="flex space-x-5">
              <div className="hover:underline">
                <AppLink href={`/signin${getRedirectQuery(router.asPath)}`}>
                  {t('signin')}
                </AppLink>
              </div>
              <div className="hover:underline">
                <AppLink href={`/register${getRedirectQuery(router.asPath)}`}>
                  {t('register')}
                </AppLink>
              </div>
            </div>
          )}
        </div>
        <LangHeader />
      </div>
    </div>
  );
}
