import {
  StoreType,
  checkStockRefService,
  checkStockService,
  getStoreData,
} from '@/services/client/storeService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import * as cookieKey from '@/constants/cookieKey.constant';
import * as defaultValue from '@/constants/defaultValue.constant';
import { RootState } from '../store';
import { getDistance } from '@/utils/store';
import { StoreData } from '@/models/Store.model';

interface StoreState {
  isOpenModal: boolean;
  canSelectStore: boolean;
  currentPickupStore?: StoreData;
  storeDataList?: StoreData[];
  pickupStoreDataList?: StoreData[];
  storeDataWithStockList?: StoreData[];
  addToCartQty?: number;
  canDelivery?: boolean;
  canPickup?: boolean;
  isPreorder?: boolean;
}

const initialState: StoreState = {
  isOpenModal: false,
  canSelectStore: false,
};

export const openStockModal = createAsyncThunk(
  'store/openStockModal',
  async ({
    sku,
    type,
    // eslint-disable-next-line no-unused-vars
    qty,
    // eslint-disable-next-line no-unused-vars
    canDelivery,
    // eslint-disable-next-line no-unused-vars
    canPickup,
    // eslint-disable-next-line no-unused-vars
    isPreorder,
  }: {
    sku: string;
    type: StoreType;
    qty?: number;
    canDelivery?: boolean;
    canPickup?: boolean;
    isPreorder?: boolean;
  }) => {
    const response = await checkStockService(sku, type);
    return response;
  },
);

export const openCheckoutStockModal = createAsyncThunk(
  'store/openCheckoutStockModal',
  async () => {
    const response = await checkStockRefService();
    return response;
  },
);

export const storeQuery = createAsyncThunk<
  {
    storeData: StoreData[];
    pickupStoreData: StoreData[];
  },
  { position?: GeolocationPosition | null },
  { state: RootState }
>(
  'store/query',
  async (
    // position: GeolocationPosition ใช้ใน action นะ อย่าลบ
    // eslint-disable-next-line no-unused-vars
    { position }: { position?: GeolocationPosition | null },
    { getState }: { getState: () => RootState },
  ) => {
    const oldStoreDataList = getState().store.storeDataList;
    const oldPickupStoreDataList = getState().store.pickupStoreDataList;
    let response: {
      storeData: StoreData[];
      pickupStoreData: StoreData[];
    } = {
      storeData: oldStoreDataList ?? [],
      pickupStoreData: oldPickupStoreDataList ?? [],
    };
    if (oldStoreDataList === undefined || oldStoreDataList.length === 0) {
      response = await getStoreData();
    }
    return response;
  },
);

export const storeSlice = createSlice({
  name: 'store',
  initialState: initialState,
  reducers: {
    saveStoreChanged: (state, action: { payload: string; type: string }) => {
      const findStore = state.pickupStoreDataList?.find(
        (e: any) => e.code === action.payload,
      );
      if (findStore) {
        state.currentPickupStore = findStore;
        Cookies.set(cookieKey.pickupStcode, action.payload, {
          secure: process.env.NEXT_PUBLIC_NODE_ENV !== 'development',
        });
      }
    },
    openModal: (
      state,
      action: { payload: { canSelectStore?: boolean }; type: string },
    ) => {
      state.storeDataWithStockList = undefined;
      state.addToCartQty = undefined;
      state.isOpenModal = true;
      state.canSelectStore = action.payload.canSelectStore === true;
    },
    closeModal: (state) => {
      state.storeDataWithStockList = undefined;
      state.addToCartQty = undefined;
      state.isOpenModal = false;
    },
  },
  extraReducers: (builder) => {
    // pending, fullfilled
    builder
      .addCase(openStockModal.pending, (state) => {
        state.addToCartQty = undefined;
        state.isOpenModal = true;
        state.storeDataWithStockList = [];
      })
      .addCase(openStockModal.fulfilled, (state, action) => {
        state.storeDataWithStockList =
          (action.payload ?? []).length > 0 ? action.payload : undefined;
        state.addToCartQty = action.meta.arg.qty;
        state.canDelivery = action.meta.arg.canDelivery;
        state.canPickup = action.meta.arg.canPickup;
        state.isPreorder = action.meta.arg.isPreorder;
      });
    // pending, fullfilled
    builder
      .addCase(openCheckoutStockModal.pending, (state) => {
        state.addToCartQty = undefined;
        state.isOpenModal = true;
        state.storeDataWithStockList = [];
      })
      .addCase(openCheckoutStockModal.fulfilled, (state, action) => {
        state.storeDataWithStockList =
          (action.payload ?? []).length > 0 ? action.payload : undefined;
        state.canSelectStore = true;
      });
    // fullfilled
    builder.addCase(storeQuery.fulfilled, (state, action) => {
      if (action.payload) {
        const position = action.meta.arg.position;
        let storeList: StoreData[] = action.payload.storeData;
        let pickupStoreList: StoreData[] = action.payload.pickupStoreData;
        if (position) {
          pickupStoreList = pickupStoreList
            .map((e) => {
              return {
                ...e,
                distance: getDistance(
                  position.coords.latitude,
                  position.coords.longitude,
                  e.latitude,
                  e.longitude,
                ),
              };
            })
            .sort((a, b) => a.distance - b.distance);
        }
        const pickupStoreCookie = Cookies.get(cookieKey.pickupStcode);
        const currentStoreCookie = Cookies.get(cookieKey.stcode);
        const currentStore = storeList[0];
        let currentPickupStore = null;
        if (pickupStoreCookie) {
          currentPickupStore = pickupStoreList.find(
            (e) => e.code === pickupStoreCookie,
          );
        } else {
          currentPickupStore =
            pickupStoreList.find(
              (e) => e.code === defaultValue.defaultPickupStoreCode,
            ) ?? pickupStoreList[0];
        }
        if (currentStore && currentStoreCookie !== currentStore.code) {
          Cookies.set(cookieKey.stcode, currentStore.code, {
            secure: process.env.NEXT_PUBLIC_NODE_ENV !== 'development',
          });
        }
        if (
          currentPickupStore &&
          pickupStoreCookie !== currentPickupStore?.code
        ) {
          state.currentPickupStore = currentPickupStore;
          Cookies.set(cookieKey.pickupStcode, currentPickupStore.code, {
            secure: process.env.NEXT_PUBLIC_NODE_ENV !== 'development',
          });
        }
        state.storeDataList = storeList;
        state.pickupStoreDataList = pickupStoreList;
      }
    });
  },
});

export const { openModal, closeModal, saveStoreChanged } = storeSlice.actions;

export const storeDataListSelector = (
  store: RootState,
): StoreData[] | undefined => store.store.storeDataList;

export const pickupStoreDataListSelector = (
  store: RootState,
): StoreData[] | undefined => store.store.pickupStoreDataList;

export const currentPickupStoreSelector = (
  store: RootState,
): StoreData | undefined =>
  store.store.currentPickupStore ??
  (store.store.pickupStoreDataList ?? []).find(
    (e: StoreData) =>
      e.code === Cookies.get(cookieKey.pickupStcode) ??
      defaultValue.defaultPickupStoreCode,
  );

export const addToCartQtySelector = (store: RootState): number | undefined =>
  store.store.addToCartQty;

export const isOpenModalSelector = (store: RootState): boolean =>
  store.store.isOpenModal;

export const canSelectStoreSelector = (store: RootState): boolean =>
  store.store.canSelectStore;

export const storeDataWithStockListSelector = (
  store: RootState,
): StoreData[] | undefined => store.store.storeDataWithStockList;

export const canDeliverySelector = (store: RootState): boolean | undefined =>
  store.store.canDelivery;

export const canPickupSelector = (store: RootState): boolean | undefined =>
  store.store.canPickup;

export const isPreorderSelector = (store: RootState): boolean | undefined =>
  store.store.isPreorder;

export default storeSlice.reducer;
