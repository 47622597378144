import { modelValue } from '@/utils/modelValue';

export interface MyRemobieModel {
  coupon_code: string;
  sku: string;
  value: number;
  trade_in: number;
  trade_up: number;
  status: string;
  created_at?: string;
  updated_at?: string;
  is_delete?: boolean;
}

export class MyRemobieConverter {
  public static fromJson(json: any): MyRemobieModel {
    const model: MyRemobieModel = {
      coupon_code: json['coupon_code'],
      sku: json['sku'],
      value: json['value'],
      trade_in: json['trade_in'],
      trade_up: json['trade_up'],
      status: json['status'],
      created_at: json['created_at'],
      updated_at: json['updated_at'],
      is_delete: json['is_delete'],
    };
    return modelValue(model);
  }
}
