import {
  AccountSummaryResponse,
  AccountSummaryResponseConverter,
  UpdateTheOneRequestModel,
} from '@/models/profile/AccountSummary.model';
import { saveClientCookieFromResponse } from '@/utils/cookie';
import { postDataFromAPI } from '@/utils/httpClient';

export const getProfileAccountSummary = async ({
  lang,
}: {
  lang?: string;
}): Promise<{
  data: AccountSummaryResponse | null | undefined;
  status: number | null;
}> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/profile/accountsummary',
      lang,
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: AccountSummaryResponse | null | undefined;
    status: number | null;
  } = { data: data, status: getData.status };

  if (data) {
    try {
      response.data = AccountSummaryResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  // ถ้าอนาคตมีคนเรียกใช้แต่ไม่อยาก save cookie ให้ย้ายไปใน Slice
  await saveClientCookieFromResponse(response.data?.cookies);
  return response;
};

export const postMemTheOne = async (
  sendData: UpdateTheOneRequestModel,
  lang?: string,
): Promise<string | undefined> => {
  const getData = await postDataFromAPI({
    path: '/postmemtheone',
    lang,
    body: { ...sendData, lang },
  });
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;
  if (data?.dbcode === true) {
    return undefined;
  } else {
    return data?.dbmessage && data?.dbmessage !== ''
      ? data?.dbmessage
      : 'updatefailed';
  }
};
