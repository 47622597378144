import axios from 'axios';

export const get = async (
  name: string,
): Promise<string | number | boolean | undefined> => {
  try {
    const data = await axios.get(`/api/v2/cookie?name=${name}`);
    return data?.data?.toString();
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const set = async (
  name: string,
  value: string | number | boolean,
): Promise<void> => {
  try {
    await axios.post(`/api/v2/cookie`, {
      name,
      value,
    });
  } catch (error) {
    console.log(error);
  }
};

export const setMaxAge = async (
  name: string,
  value: string | number | boolean,
  maxAgeInDays: number,
): Promise<void> => {
  try {
    await axios.post(`/api/v2/cookie`, {
      name,
      value,
      maxAgeInDays,
    });
  } catch (error) {
    console.log(error);
  }
};

export const remove = async (name: string): Promise<void> => {
  try {
    await axios.delete(`/api/v2/cookie?name=${name}`);
  } catch (error) {
    console.log(error);
  }
};
