import {
  getDataFromAPI,
  getDataFromCMS,
  postDataFromAPI,
} from '@/utils/httpClient';
import {
  CMSHomePageModel,
  CMSHomePageModelConverter,
  DealFinModel,
  DealFinModelConverter,
  SkuRoomData,
  SkuRoomDataConverter,
} from '@/models/HomePage.model';
import {
  CMSCategoryPageModel,
  CMSCategoryPageModelConverter,
  CmsType,
} from '@/models/CMS.model';

export const getDealFin = async ({
  lang,
}: {
  lang?: string;
}): Promise<DealFinModel | undefined> => {
  const [getDataCms] = await Promise.all([
    getDataFromAPI({ path: '/getdealfin', lang }),
  ]);
  const cmsData =
    getDataCms.status == 200
      ? getDataCms.data
        ? getDataCms.data
        : undefined
      : undefined;
  let response: DealFinModel | undefined = cmsData;
  if (cmsData) {
    try {
      response = DealFinModelConverter.fromJson(cmsData);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const getHomePageData = async ({
  lang,
}: {
  lang?: string;
}): Promise<CMSHomePageModel | undefined> => {
  const [getDataCms] = await Promise.all([
    getDataFromCMS({ path: '/api/banner', lang }),
  ]);
  const cmsData =
    getDataCms.status == 200
      ? getDataCms.data
        ? getDataCms.data
        : undefined
      : undefined;
  let response: CMSHomePageModel | undefined = cmsData;
  if (cmsData) {
    try {
      response = CMSHomePageModelConverter.fromJson(cmsData);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const getHomepageBlock = async ({
  lang,
  name,
}: {
  lang?: string;
  name: string;
}): Promise<CMSCategoryPageModel | undefined> => {
  const [getDataCms] = await Promise.all([
    getDataFromCMS({
      path: '/api/plpbanner',
      lang,
      param: {
        strqry: `homepage_${name}`,
      },
    }),
  ]);
  const cmsData =
    getDataCms.status == 200
      ? getDataCms.data
        ? getDataCms.data
        : undefined
      : undefined;
  if (cmsData && cmsData.contents && cmsData.contents.length > 0) {
    let hassku = 0;
    try {
      hassku = cmsData.contents.filter(
        (sk: any) =>
          sk.skus.length > 0 ||
          sk.type == CmsType.sku ||
          sk.type == CmsType.flashsale ||
          sk.type == CmsType.seo ||
          sk.type == CmsType.issueCoupon,
      ).length;
    } catch (e) {}
    if (hassku <= 0) {
      let responsCMSData: CMSCategoryPageModel | undefined = cmsData;
      try {
        responsCMSData = CMSCategoryPageModelConverter.fromJson(cmsData);
      } catch (error) {
        console.log(error);
      }
      return responsCMSData;
    } else {
      const [getProductDetailData] = await Promise.all([
        postDataFromAPI({
          path: `/page`,
          lang,
          body: {
            lang,
            cmsres: cmsData,
          },
        }),
      ]);
      const cmsDataPage =
        getProductDetailData.status == 200
          ? getProductDetailData.data
            ? getProductDetailData.data
            : undefined
          : undefined;
      let responsCMSData: CMSCategoryPageModel | undefined =
        cmsDataPage?.cmsres;
      if (cmsDataPage && cmsDataPage.cmsres) {
        try {
          responsCMSData = CMSCategoryPageModelConverter.fromJson(
            cmsDataPage.cmsres,
          );
        } catch (error) {
          console.log(error);
        }
      }
      return responsCMSData;
    }
  } else {
    return undefined;
  }
};

export const getShopByRoomSkuData = async ({
  lang,
  skus,
}: {
  lang?: string;
  skus: string[];
}): Promise<SkuRoomData[] | undefined> => {
  if (skus.length === 0) {
    return [];
  }
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/skuroomdata',
      lang,
      body: { skgroup: skus.join(',') },
    }),
  ]);
  const data =
    getData.status == 200
      ? getData.data
        ? getData.data
        : undefined
      : undefined;
  let response: SkuRoomData[] | undefined = data;
  if (data) {
    try {
      response = data.map((e: any) => SkuRoomDataConverter.fromJson(e));
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};
