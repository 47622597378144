import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ServerCookies from '@/services/client/cookieService';
import * as cookieKey from '@/constants/cookieKey.constant';
import { RootState } from '@/store/store';
import { orderEtaxRequest } from '@/services/client/requestEtaxService';
import { Address } from '@/models/Address.model';

interface RequestEtaxState {
  isLoading: boolean;
  isSuccess: boolean;
  error?: string;
}

const initialState: RequestEtaxState = {
  isLoading: false,
  isSuccess: false,
};

export const etaxRequest = createAsyncThunk(
  'requestEtax/orderEtaxRequest',
  async ({
    address,
    orderRef,
    etaxType,
    etaxVal,
    lang,
  }: {
    address: Address;
    orderRef: string;
    etaxType: string;
    etaxVal: string;
    lang?: string;
  }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await orderEtaxRequest({
      address,
      orderRef,
      etaxType,
      etaxVal,
      lang,
    });
    if (!response || response.isError) {
      throw new Error(response?.errorMessage ?? 'cannotrequestatthistime');
    }
    return response;
  },
);

export const requestEtaxSlice = createSlice({
  name: 'requestEtax',
  initialState: initialState,
  reducers: {
    clearSuccess: (state) => {
      state.isSuccess = false;
    },
    clearError: (state) => {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    // pending, fulfilled, rejected
    builder
      .addCase(etaxRequest.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(etaxRequest.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(etaxRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.error = action.error.message;
      });
  },
});

export const { clearSuccess, clearError } = requestEtaxSlice.actions;

export const isLoadingRequestEtaxSelector = (store: RootState): boolean =>
  store.requestEtax.isLoading;

export const isSuccessRequestEtaxSelector = (store: RootState): boolean =>
  store.requestEtax.isSuccess;

export const errorRequestEtaxSelector = (
  store: RootState,
): string | undefined => store.requestEtax.error;

export default requestEtaxSlice.reducer;
