import {
  addToCartQtySelector,
  canDeliverySelector,
  canPickupSelector,
  canSelectStoreSelector,
  closeModal,
  isOpenModalSelector,
  isPreorderSelector,
  pickupStoreDataListSelector,
  saveStoreChanged,
  storeDataWithStockListSelector,
  storeQuery,
} from '@/store/slices/storeSlice';
import { useAppDispatch } from '@/store/store';
import Cookies from 'js-cookie';
import React, { ReactElement, useEffect, useState } from 'react';
import * as cookieKey from '@/constants/cookieKey.constant';
import { useSelector } from 'react-redux';
import CustomModal from '../common/CustomModal';
import { useTranslation } from 'next-i18next';
import Button from '@mui/material/Button';
import * as showDialog from '@/utils/showDialog';
import Spinner from '../common/Spinner';
import { Skeleton } from '@mui/material';
import { useRouter } from 'next/router';
import TextMatching from '../common/TextMatching';
import Image from 'next/image';

export default function StoreLayout(): ReactElement {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const pickUpstoreDataList = useSelector(pickupStoreDataListSelector);
  const storeDataWithStockList = useSelector(storeDataWithStockListSelector);
  const addToCartQty = useSelector(addToCartQtySelector);
  const isOpenModal = useSelector(isOpenModalSelector);
  const canSelectStore = useSelector(canSelectStoreSelector);
  const handleClose = () => dispatch(closeModal());
  const [searchStoreText, setSearchStoreText] = useState<string>('');
  const [changingStore, setChangingStore] = useState<string | undefined>(
    undefined,
  );
  const [getCurrenting, setGetCurrenting] = useState<boolean>(false);
  const canDelivery = useSelector(canDeliverySelector);
  const canPickup = useSelector(canPickupSelector);
  const isPreorder = useSelector(isPreorderSelector);

  useEffect(() => {
    const handleStart = () => {
      dispatch(closeModal());
    };

    router.events.on('routeChangeStart', handleStart);

    return () => {
      router.events.off('routeChangeStart', handleStart);
    };
  });

  useEffect(() => {
    if (isOpenModal) {
      setGetCurrenting(true);
      navigator.geolocation.getCurrentPosition(
        (position?: GeolocationPosition | null) => {
          dispatch(storeQuery({ position })).then(() => {
            setGetCurrenting(false);
          });
        },
        () => {
          setGetCurrenting(false);
        },
      );
    } else {
      if (Cookies.get(cookieKey.pickupStcode)) {
        dispatch(storeQuery({ position: undefined }));
      } else {
        navigator.geolocation.getCurrentPosition(
          (position?: GeolocationPosition | null) => {
            dispatch(storeQuery({ position }));
          },
          () => {
            dispatch(storeQuery({ position: undefined }));
          },
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenModal]);

  const handleClickFindNearest = () => {
    setGetCurrenting(true);
    navigator.geolocation.getCurrentPosition(
      (position?: GeolocationPosition | null) => {
        dispatch(storeQuery({ position })).then(() => {
          setGetCurrenting(false);
        });
      },
      () => {
        setGetCurrenting(false);
        showDialog.showErrorWithOkButton({
          text: t('unfortunatelywewereunabletolocate'),
        });
      },
    );
  };

  const handleChangeStore = ({ stcode }: { stcode: string }) => {
    showDialog.showMessageWithButton({
      text: t('changebranchwarning'),
      primaryButton: {
        name: t('ok'),
        callBack: async () => {
          setChangingStore(stcode);
          setChangingStore(undefined);
          dispatch(saveStoreChanged(stcode));
          router.replace(`/${router.locale}${router.asPath}`);
          handleClose();
        },
      },
      secondaryButton: {
        name: t('cancel'),
      },
    });
  };

  // ไม่หา distance ใหม่เพราะว่ายังไง storeDataList ก็ต้องมีสาขา Cover อยู่แล้ว
  const storeDataWithStockListMap = storeDataWithStockList?.map((store) => {
    const findStoreData = pickUpstoreDataList?.find(
      (storeData) => storeData.code === store.code,
    );
    return { ...store, distance: findStoreData?.distance };
  });

  // .filter((e) => e.stock ?? 0 > 0);
  return (
    <CustomModal
      open={isOpenModal}
      onClose={handleClose}
      showCloseButton
      showScrollBar
    >
      <div className="min-w-[20vw] w-auto md:w-[75vw] lg:w-auto flex flex-col h-[80vh] sm:h-[92vh]">
        <div className="px-2 h-[8.5rem] md:h-[8.5rem] lg:h-[8.5rem] xl:h-[7.5rem]">
          <div className="text-xl text-primary">{t('selectstore')}</div>
          <div className="leading-3">{t('apply')}</div>
          <div className="mt-1 border-b">
            <input
              id="searchStore"
              type="text"
              value={searchStoreText}
              className="h-full w-full px-2 bg-grayLight focus:outline-none text-sm py-1.5"
              placeholder={`${t('searchstoreplaceholder')}`}
              onChange={(e) => setSearchStoreText(e.target.value)}
            />
            <div className="text-sm flex items-center my-0.5 h-5">
              {(storeDataWithStockListMap ?? pickUpstoreDataList ?? [])
                .length === 0 ? (
                <Skeleton variant="text" width="50%" />
              ) : (
                <>
                  <div>{t('or')}</div>
                  {getCurrenting ? (
                    <div className="ms-2">
                      <Spinner color="#000" borderSize={2} />
                    </div>
                  ) : (
                    <div
                      onClick={handleClickFindNearest}
                      className="flex items-center ms-1 cursor-pointer select-none text-secondaryPurple hover:text-primary"
                    >
                      <i className="bi bi-geo-alt leading-3"></i>
                      <div className="leading-3 text-xs sm:text-sm">
                        {t('clicktofindthestorenearest')}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="mt-1 leading-3">
            <span className="text-xs text-grayDark">{t('allstores')}</span>
            <span className="text-xs ps-1 text-redPrice">*</span>
            <span className="text-xs">{t('distancesareapproximated')}</span>
          </div>
        </div>
        <div className="overflow-y-scroll flex-grow px-2 show-scrollbar">
          {(storeDataWithStockListMap ?? pickUpstoreDataList ?? []).length ===
          0 ? (
            [...Array(8)].map((_, index) => (
              <div key={index} className="py-1.5 border-b flex">
                <div className="w-1/2">
                  <div className="h-6">
                    <Skeleton variant="text" width="40%" />
                  </div>
                  <div className="h-6 flex items-center">
                    <Skeleton variant="text" width="90%" />
                  </div>
                  <div className="h-6 flex items-center">
                    <Skeleton variant="text" width="70%" />
                  </div>
                </div>
                <div className="ms-auto text-xs">
                  <div>
                    <Skeleton variant="rounded" width={98} height="1.75rem" />
                  </div>
                  <div className="mt-1">
                    <Skeleton variant="text" width="100%" />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <>
              {isPreorder ? (
                <WarningStore
                  text={t('preorderproductnostockavailableatbranch')}
                />
              ) : canDelivery && !canPickup ? (
                <WarningStore text={t('checkstockwarningdelivery')} />
              ) : !canDelivery && canPickup ? (
                <WarningStore text={t('checkstockwarningpickup')} />
              ) : (
                <></>
              )}
              {[...(storeDataWithStockListMap ?? pickUpstoreDataList ?? [])]
                .sort((a, b) => {
                  if (searchStoreText.trim().length > 0) {
                    const aIndex = (i18n.language === 'th' ? a.name : a.nameEN)
                      .toLowerCase()
                      .indexOf(searchStoreText.toLowerCase());
                    const bIndex = (i18n.language === 'th' ? b.name : b.nameEN)
                      .toLowerCase()
                      .indexOf(searchStoreText.toLowerCase());
                    if (aIndex !== -1 && bIndex !== -1) {
                      return aIndex - bIndex;
                    } else if (aIndex !== -1) {
                      return -1;
                    } else if (bIndex !== -1) {
                      return 1;
                    }
                  }
                  // Sort by stock first, descending
                  if (a.stock !== b.stock) {
                    return (b.stock ?? 0) - (a.stock ?? 0);
                  }
                  // If stock is 0, sort by distance in ascending order
                  if (a.stock === 0) {
                    return (a.distance ?? 0) - (b.distance ?? 0);
                  }
                  // Default: no change in order
                  return 0;
                  // return (b.stock ?? 0) - (a.distance ?? 0);
                })
                .map((store, index) => (
                  <div key={index} className="py-1.5 border-b block sm:flex">
                    <div>
                      <div className="font-semibold">
                        <TextMatching
                          value={
                            i18n.language === 'th' ? store.name : store.nameEN
                          }
                          matcher={searchStoreText}
                        />
                      </div>
                      {storeDataWithStockListMap !== undefined && (
                        <div className="text-xs">
                          {(store.stock ?? 0) <=
                          (addToCartQty ? addToCartQty - 1 : 0) ? (
                            <span className="text-redPrice">
                              {t('notenoughproductsasordered')}
                            </span>
                          ) : (store.stock ?? 0) >=
                              (store.averageSalePerDay ?? 0) &&
                            (store.stock ?? 0) > 2 ? (
                            <div className="flex items-center space-x-2">
                              <div className="h-1.5 w-20 rounded bg-grayLight1">
                                <div className="h-full w-16 rounded bg-[#417505]"></div>
                              </div>
                              <div className="text-[#417505]">
                                {t('instock')}
                              </div>
                            </div>
                          ) : (
                            <span className="text-yellow">
                              <div className="flex items-center space-x-2">
                                <div className="h-1.5 w-20 rounded bg-grayLight1">
                                  <div className="h-full w-8 rounded bg-yellow"></div>
                                </div>
                                <div className="text-yellow">
                                  {t('limitedstock')}
                                </div>
                              </div>
                            </span>
                          )}
                        </div>
                      )}
                      <div>
                        <span>{t('operatinghourssince')}</span>
                        <span className="ps-1">
                          {store.openTime} - {store.closeTime} {t('n')}
                        </span>
                      </div>
                      <div>
                        <span>{t('phonenumber')}</span>
                        <span className="ps-1">{store.phoneNumber}</span>
                      </div>
                    </div>
                    <div className="ms-auto text-xs text-grayDark">
                      <div>
                        {Cookies.get(cookieKey.pickupStcode) !== store.code &&
                          canSelectStore &&
                          store.stock !== 0 &&
                          (store.stock ?? 0) >=
                            (addToCartQty ? addToCartQty : 0) && (
                            <Button
                              disabled={changingStore !== undefined}
                              size="small"
                              className="w-full bg-secondaryPurple hover:bg-primary text-white rounded min-w-[98px]"
                              sx={{
                                width: '100%',
                                minWidth: 98,
                                backgroundColor: '#854BA2',
                                color: 'white',
                                borderRadius: 0,
                                '&:hover': {
                                  backgroundColor: '#4E1F66',
                                },
                              }}
                              onClick={(e: any) => {
                                e.preventDefault();
                                if (!changingStore) {
                                  handleChangeStore({ stcode: store.code });
                                }
                              }}
                            >
                              {changingStore === store.code ? (
                                <div className="py-0.5">
                                  <Spinner borderSize={2} size={28} />
                                </div>
                              ) : (
                                t('selectstore')
                              )}
                            </Button>
                          )}
                      </div>
                      {store.distance && (
                        <div className="mt-1">
                          {t('withinaradius')}
                          <span className="px-1">
                            {store.distance?.toFixed(2)}
                          </span>
                          {t('km')}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
    </CustomModal>
  );
}

const WarningStore = ({ text }: { text: string }) => {
  return (
    <div className="w-full mt-2 p-2 rounded bg-grayLight1 flex items-center space-x-2">
      <div>
        <Image
          src="/images/svg/cart/warning_icon.svg"
          alt="warning_taxinvoice"
          width={24}
          height={24}
          style={{ width: '100%', height: '100%' }}
        />
      </div>
      <div className="leading-4">{text}</div>
    </div>
  );
};
