import { modelValue } from '@/utils/modelValue';

export interface GetIssueFromCodeModel {
  iserror?: boolean;
  msg?: string;
  status?: number;
}
export class GetIssueFromCodeModelConverter {
  public static fromJson(json: any): GetIssueFromCodeModel {
    const model: GetIssueFromCodeModel = {
      iserror: json['iserror'],
      msg: json['msg'],
      status: json['status'],
    };
    return modelValue(model);
  }
}
