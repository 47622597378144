import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TabState {
  aboutProductValue: number;
}

const initialState: TabState = {
  aboutProductValue: 0, // Initialize the value
};

const tabSlice = createSlice({
  name: 'tab',
  initialState,
  reducers: {
    setAboutProductValue: (state, action: PayloadAction<number>) => {
      state.aboutProductValue = action.payload;
    },
  },
});

export const {
  // ... other actions
  setAboutProductValue,
} = tabSlice.actions;

export default tabSlice.reducer;
