import {
  CheckOnTop,
  CheckOnTopResultSelector,
  clearError,
  clearResult,
  clearResultCheckOnTop,
  clearSuccess,
  clearSuccessCheckOnTop,
  errorSelector,
  isSuccessCheckOnTopSelector,
  successSelector,
} from '@/store/slices/useVoucherSlice';
import { useAppDispatch } from '@/store/store';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as showDialog from '@/utils/showDialog';
import {
  errorMessageHandler,
  UseVoucherErrorHandleOutput,
} from '@/utils/useVoucher';
import ModalCheckCode from '../profile/myvoucher/ModalCheckCode';
import { DataOutput, Info } from '@/models/GetVouFromCode.model';

function isDataOutputArray(array: any): array is DataOutput[] {
  return Array.isArray(array) && array.length > 0;
}

export default function UseVoucher() {
  const { i18n } = useTranslation();
  const { t: t_voucher } = useTranslation('usevoucher');
  const dispatch = useAppDispatch();
  const CheckOnTopResult = useSelector(CheckOnTopResultSelector);
  const isSuccessCheckOnTop = useSelector(isSuccessCheckOnTopSelector);
  const error = useSelector(errorSelector);
  const success = useSelector(successSelector);

  const [errMsg, setErrMsg] = useState('');
  const [errSubtitle, setErrSubtitle] = useState('');
  const [dialogOpenCheckCode, setdialogOpenCheckCode] = useState(false);
  const [products, setProducts] = useState<DataOutput[]>([]);

  useEffect(() => {
    if (error && !success) {
      errorMessageHandler({
        type: error.type,
        lang: i18n.language,
        secname: error.secname,
        fullaVaildate: error.fullaVaildate,
        infoRes: error.infoRes,
        dbMessage: error.dbMessage,
      }).then((handle: UseVoucherErrorHandleOutput) => {
        if (!handle.errMsg) {
          setErrMsg('');
          setErrSubtitle(`${handle.errSubtitle}`);
          const info: Info = handle.infoRes ? handle.infoRes : {};
          dispatch(
            CheckOnTop({
              lang: i18n.language,
              info: info,
            }),
          );
        } else {
          showDialog.showWarningWithOkButton({
            title: handle.errMsg,
            subtitle: handle.errSubtitle,
          });
          dispatch(clearError());
          dispatch(clearResult());
          dispatch(clearSuccess());
        }
      });
    } else if (success) {
      dispatch(clearError());
      dispatch(clearResult());
      dispatch(clearSuccess());
      showDialog.showMessageSuccessAutoHide({
        text: `${t_voucher('success')}`,
      });
    }
  }, [error, dispatch, success, i18n, t_voucher]);

  useEffect(() => {
    let title = `${t_voucher('minimumAmount')}`;
    const subtitle = errSubtitle
      ? errSubtitle
      : `${t_voucher('pleasEditCart')}`;
    if (isSuccessCheckOnTop) {
      if (CheckOnTopResult && Array.isArray(CheckOnTopResult)) {
        if (
          isSuccessCheckOnTop &&
          isDataOutputArray(CheckOnTopResult) &&
          CheckOnTopResult.length > 0
        ) {
          const products: any[] = CheckOnTopResult;
          title = products[0]?.output
            ? products[0].output
            : `${t_voucher('minimumAmount')}`;
          setErrSubtitle(subtitle);
          setErrMsg(title);
          setProducts(products);
          setdialogOpenCheckCode(true);
        } else {
          title = `${t_voucher('minimumAmount')}`;
          setErrSubtitle(subtitle);
          setErrMsg(title);
          showDialog.showWarningWithOkButton({
            title: title,
            subtitle: subtitle,
          });
          handleClear();
        }
      } else {
        showDialog.showWarningWithOkButton({
          title: title,
          subtitle: subtitle,
        });
        handleClear();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessCheckOnTop, CheckOnTopResult, errSubtitle]);

  const handleClear = () => {
    dispatch(clearError());
    dispatch(clearResult());
    dispatch(clearSuccess());
    dispatch(clearResultCheckOnTop());
    dispatch(clearSuccessCheckOnTop());
  };

  const handleCloseCheckCode = () => {
    setdialogOpenCheckCode(false);
    dispatch(clearError());
    dispatch(clearResult());
    dispatch(clearSuccess());
    setErrSubtitle('');
    dispatch(clearResultCheckOnTop());
    dispatch(clearSuccessCheckOnTop());
  };

  return (
    <ModalCheckCode
      open={dialogOpenCheckCode}
      onClose={handleCloseCheckCode}
      products={products}
      title={errMsg}
      subTitle={errSubtitle}
    />
  );
}
