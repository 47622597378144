import { ErrorShipment, ShipmentResponse } from '@/models/Checkout.model';
import { createSlice } from '@reduxjs/toolkit';
import { PaymentState } from '@/store/store';

interface RePaymentState {
  result?: ShipmentResponse | null;
  isLoading: boolean;
  error?: ErrorShipment;
}

const initialState: RePaymentState = { isLoading: false, error: undefined };

// export const rePaymentQuery = createAsyncThunk(
//   'rePayment/query',
//   async ({
//     lang,
//     ref,
//   }: {
//     lang?: string;
//     ref?: string;
//   }): Promise<ShipmentResponse | null | undefined> => {
//     const twdTokenCookie = Cookies.get(cookieKey.twdToken);
//     if (!twdTokenCookie) {
//       return null;
//     }
//     const response = await getShipmentData({ lang, isRepayment: true, ref });
//     if (response.data === null) {
//       throw new Error(`${response?.status ?? '500.'}`);
//     }
//     return response.data;
//   },
// );

export const rePaymentSlice = createSlice({
  name: 'rePayment',
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.error = undefined;
    },
    clearResult: (state) => {
      state.result = undefined;
    },
    setInitialRepayment: (
      state,
      action: {
        payload: {
          shipmentData?: ShipmentResponse | null;
        };
      },
    ) => {
      state.isLoading = false;
      if (action.payload?.shipmentData?.orderStatus === 'W') {
        state.result = action.payload?.shipmentData;
      } else {
        state.error = {
          message: `ขออภัย! ไม่สามารถสั่งซื้อสินค้าออนไลน์ได้ในขณะนี้`,
          redirect: '/profile/orderhistory',
        };
      }
    },
  },
  extraReducers: () => {
    // pending, fulfilled, rejected
    // builder
    //   .addCase(rePaymentQuery.pending, (state) => {
    //     state.isLoading = true;
    //     state.error = undefined;
    //   })
    //   .addCase(rePaymentQuery.fulfilled, (state, action) => {
    //     state.isLoading = false;
    //     if (action.payload?.orderStatus === 'W') {
    //       state.result = action.payload;
    //     } else {
    //       state.error = {
    //         message: `ขออภัย! ไม่สามารถสั่งซื้อสินค้าออนไลน์ได้ในขณะนี้`,
    //         redirect: '/profile/orderhistory',
    //       };
    //     }
    //   })
    //   .addCase(rePaymentQuery.rejected, (state, action) => {
    //     state.isLoading = false;
    //     state.error = {
    //       message: action.error.message ?? '500',
    //     };
    //     state.result = action.error.message === '401' ? null : undefined;
    //   });
  },
});

export const { clearError, clearResult, setInitialRepayment } =
  rePaymentSlice.actions;

export const rePaymentResultSelector = (
  store: PaymentState,
): ShipmentResponse | undefined | null => store.rePayment.result;

export const isLoadingRePaymentSelector = (store: PaymentState): boolean =>
  store.rePayment.isLoading;

export const errorRePaymentSelector = (
  store: PaymentState,
): ErrorShipment | undefined => store.rePayment.error;

export default rePaymentSlice.reducer;
