import { StoreData } from '@/models/Store.model';

export const getDistance = (
  currentLat: number,
  currentLng: number,
  destinationLat: number,
  destinationLng: number,
): number => {
  const radlat1 = (Math.PI * currentLat) / 180;
  const radlat2 = (Math.PI * destinationLat) / 180;
  const theta = currentLng - destinationLng;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (true) {
    dist = dist * 1.609344;
  }
  return dist;
};

export const branchNameLang = ({
  store,
  lang,
  storeName,
}: {
  store?: StoreData;
  lang?: string;
  storeName?: string;
}): string => {
  let result = store?.name ?? storeName ?? '';
  switch (lang) {
    case 'en':
      if (store?.nameEN || store?.name || storeName) {
        result = `${store?.nameEN ?? store?.name ?? storeName ?? ''} branch`;
      } else {
        result = `${'Power Buy'}`;
      }
      break;
    default:
      if (store?.name || storeName) {
        result = `สาขา ${store?.name ?? storeName ?? ''}`;
      } else {
        result = `${'เพาเวอร์บาย'}`;
      }
      break;
  }
  return result;
};
