import React from 'react';
import TopFooter from './TopFooter';
import CenterFooter from './CenterFooter';
import BottomFooter from './BottomFooter';

type Props = {};

export default function Footer({}: Props) {
  return (
    <>
      <TopFooter></TopFooter>
      <CenterFooter></CenterFooter>
      <BottomFooter></BottomFooter>
    </>
  );
}
