import { modelValue } from '@/utils/modelValue';

export interface RegisterInitiateResponseModel {
  mobile?: string;
  type?: string;
  requestID?: string;
  nextProcess?: string;
  data?: RegisterInitiateData;
  error?: RegisterInitiateError;
}

export class RegisterInitiateResponseModelConvert {
  public static fromJson(json: any): RegisterInitiateResponseModel {
    const model: RegisterInitiateResponseModel = {
      mobile: json['mobile'],
      type: json['type'],
      requestID: json['requestID'],
      nextProcess: json['nextProcess'],
      data: json['data']
        ? RegisterInitiateDataConverter.fromJson(json['data'])
        : undefined,
      error: json['error']
        ? RegisterInitiateErrorConverter.fromJson(json['error'])
        : undefined,
    };
    return modelValue(model);
  }
}

export interface RegisterInitiateData {
  preactivatedFlag?: boolean;
  otpRefText?: string;
  authenticate?: Authenticate;
}

export class RegisterInitiateDataConverter {
  public static fromJson(json: any): RegisterInitiateData {
    const model: RegisterInitiateData = {
      preactivatedFlag: json['preactivatedFlag'],
      otpRefText: json['otpRefText'],
      authenticate: json['authenticate']
        ? AuthenticateConverter.fromJson(json['authenticate'])
        : undefined,
    };
    return modelValue(model);
  }
}

export interface RegisterInitiateError {
  code?: string;
  description?: string;
  name?: string;
}

export class RegisterInitiateErrorConverter {
  public static fromJson(json: any): RegisterInitiateError {
    const model: RegisterInitiateError = {
      code: json['code'],
      description: json['description'],
      name: json['name'],
    };
    return modelValue(model);
  }
}

export interface Authenticate {
  otp?: boolean;
  password?: boolean;
  pin?: boolean;
}

export class AuthenticateConverter {
  public static fromJson(json: any): Authenticate {
    const model: Authenticate = {
      otp: json['otp'],
      password: json['password'],
      pin: json['pin'],
    };
    return modelValue(model);
  }
}
