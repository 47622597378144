import { ProductModel } from '@/models/Product.model';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as localStorageKey from '@/constants/localStorageKey.constant';
import { RootState } from '../store';
import { getSearchSuggestData } from '@/services/client/searchService';
import { CatSuggestion } from '@/models/SearchSuggest.model';
// import categoryList from '@/resources/searchlist.json';

interface searchSuggestState {
  searchText: string;
  searchTextCurrentResult?: string;
  historyResult?: string[];
  categoryResult?: { catname: string; slugname: string }[];
  productResult?: ProductModel[];
  resultLoading: boolean;
  error?: string;
}

const initialState: searchSuggestState = {
  searchText: '',
  resultLoading: false,
};

// export const searchCategory = createAsyncThunk(
//   'searchSuggest/searchCategory',
//   async ({ text }: { text: string }) => {
//     let categoryList: { catname: string; slugname: string }[] = [];
//     if (text) {
//       categoryList = (
//         await fetch('/resources/searchlist.json').then((res) => res.json())
//       ).list.filter((item: any) =>
//         item.catname.toLowerCase().includes(text.toLowerCase()),
//       );
//       categoryList = categoryList
//         .sort((a, b) => {
//           const aIndex = a.catname.toLowerCase().indexOf(text.toLowerCase());
//           const bIndex = b.catname.toLowerCase().indexOf(text.toLowerCase());
//           return aIndex - bIndex;
//         })
//         .slice(0, 10);
//     }
//     return categoryList;
//   },
// );

export const getSearchSuggest = createAsyncThunk(
  'searchSuggest/getSearchSuggest',
  async ({ text, lang }: { text: string; lang?: string }) => {
    if (!text) {
      throw new Error(``);
    }
    const response = await getSearchSuggestData({ text, lang });
    if (response.data) {
      let categories: CatSuggestion[] = [];
      if (response.data.category) {
        categories = response.data.category
          .sort((a, b) => {
            const aIndex = a.catname.toLowerCase().indexOf(text.toLowerCase());
            const bIndex = b.catname.toLowerCase().indexOf(text.toLowerCase());
            return aIndex - bIndex;
          })
          .slice(0, 12);
      }
      if (
        response.data.category.length === 0 &&
        response.data.products.length === 0
      ) {
        throw new Error(`${response?.status ?? '500.'}`);
      }
      return { categories, products: response.data.products };
    } else {
      throw new Error(`${response?.status ?? '500.'}`);
    }
  },
);

export const searchSuggestSlice = createSlice({
  name: 'searchSuggest',
  initialState: initialState,
  reducers: {
    setLoading: (state, actions) => {
      state.resultLoading = actions.payload;
      if (actions.payload === true) {
        state.error = undefined;
      }
    },
    setSearchText: (state, actions) => {
      state.searchText = actions.payload;
    },
    getHistoryResult: (state) => {
      const historyStorage = localStorage.getItem(
        localStorageKey.searchHistoryList,
      );
      if (historyStorage) {
        state.historyResult = JSON.parse(historyStorage);
      }
    },
    setHistoryResult: (
      state,
      actions: {
        payload: string;
        type: string;
      },
    ) => {
      const historyStorage = localStorage.getItem(
        localStorageKey.searchHistoryList,
      );
      let newHistoryResult: string[] = [];
      if (historyStorage) {
        newHistoryResult = JSON.parse(historyStorage);
      }
      if ((newHistoryResult ?? []).length >= 12) {
        newHistoryResult = newHistoryResult.slice(1, 12);
      }
      if (newHistoryResult.find((item) => item === actions.payload)) {
        newHistoryResult = newHistoryResult.filter(
          (item) => item !== actions.payload,
        );
      }
      newHistoryResult.push(actions.payload);
      localStorage.setItem(
        localStorageKey.searchHistoryList,
        JSON.stringify(newHistoryResult),
      );
      state.historyResult = newHistoryResult;
    },
    clearHistoryResult: (state) => {
      localStorage.removeItem(localStorageKey.searchHistoryList);
      state.historyResult = [];
    },
  },
  extraReducers: (builder) => {
    // pending, fulfilled, rejected
    builder.addCase(getSearchSuggest.pending, (state) => {
      state.resultLoading = true;
      state.error = undefined;
    });
    builder.addCase(getSearchSuggest.fulfilled, (state, action) => {
      state.searchTextCurrentResult = action.meta.arg.text;
      state.resultLoading = false;
      state.categoryResult = action.payload.categories;
      state.productResult = action.payload.products;
      state.error = undefined;
    });
    builder.addCase(getSearchSuggest.rejected, (state, action) => {
      state.searchTextCurrentResult = action.meta.arg.text;
      state.resultLoading = false;
      state.categoryResult = [];
      state.productResult = [];
      state.error = action.error.message;
    });
  },
});

export const {
  setLoading,
  setSearchText,
  getHistoryResult,
  setHistoryResult,
  clearHistoryResult,
} = searchSuggestSlice.actions;

export const searchTextSelector = (store: RootState): string =>
  store.searchSuggest.searchText;

export const searchTextCurrentResultSelector = (
  store: RootState,
): string | undefined => store.searchSuggest.searchTextCurrentResult;

export const searchHistoryResultSelector = (
  store: RootState,
): string[] | undefined => store.searchSuggest.historyResult;

export const searchCategoryResultSelector = (
  store: RootState,
): { catname: string; slugname: string }[] | undefined =>
  store.searchSuggest.categoryResult;

export const searchProductResultSelector = (
  store: RootState,
): ProductModel[] | undefined => store.searchSuggest.productResult;

export const resultLoadingSelector = (store: RootState): boolean =>
  store.searchSuggest.resultLoading;

export const errorSuggestSelector = (store: RootState): string | undefined =>
  store.searchSuggest.error;

export default searchSuggestSlice.reducer;
