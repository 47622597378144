import {
  MergeAccountData,
  MergeAccountDataConverter,
} from '@/models/MergeAccount.model';
import {
  UsernameLoginResponseModel,
  UsernameLoginResponseModelConverter,
} from '@/models/UsernameLogin.model';
import { postDataFromAPI } from '@/utils/httpClient';

export const getMergeFromT1 = async ({
  token,
}: {
  token: string;
}): Promise<MergeAccountData | undefined | null> => {
  const apiData = await postDataFromAPI({
    path: `/checkt1token`,
    headers: { mergeToken: token },
  });

  const data =
    apiData.status == 200
      ? apiData.data
        ? apiData.data
        : undefined
      : undefined;
  let response: MergeAccountData | null | undefined = data;
  if (data) {
    try {
      response = MergeAccountDataConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const getMergeFromFacebook = async ({
  facebookid,
}: {
  facebookid: string;
}): Promise<MergeAccountData | undefined | null> => {
  const apiData = await postDataFromAPI({
    path: `/checkfacebookaccount`,
    headers: { facebookid },
  });

  const data =
    apiData.status == 200
      ? apiData.data
        ? apiData.data
        : undefined
      : undefined;
  let response: MergeAccountData | null | undefined = data;
  if (data) {
    try {
      response = MergeAccountDataConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const mergeT1AccountVerify = async ({
  token,
  email,
  password,
  facebookid,
  checkconsent,
  checkversion,
}: {
  token: string;
  email: string;
  password?: string;
  facebookid?: string;
  checkconsent?: boolean;
  checkversion?: string;
}): Promise<UsernameLoginResponseModel | null | undefined> => {
  const [getDataAPI] = await Promise.all([
    postDataFromAPI({
      path: '/merget1accountverify',
      body: {
        email,
        password,
        facebookid,
        checkconsent,
        checkversion,
      },
      headers: {
        token,
      },
    }),
  ]);
  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : null;
  let response: UsernameLoginResponseModel | null | undefined = apiData;
  if (apiData) {
    try {
      response = UsernameLoginResponseModelConverter.fromJson(apiData);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const mergeFacebookAccountVerify = async ({
  email,
  password,
  facebookid,
  verifyemail,
}: {
  email: string;
  password?: string;
  facebookid?: string;
  verifyemail?: string;
}): Promise<UsernameLoginResponseModel | null | undefined> => {
  const [getDataAPI] = await Promise.all([
    postDataFromAPI({
      path: '/mergefacebookverify',
      body: {
        email,
        password,
        facebookid,
        verifyemail,
      },
    }),
  ]);
  const apiData =
    getDataAPI.status == 200
      ? getDataAPI.data
        ? getDataAPI.data
        : null
      : null;
  let response: UsernameLoginResponseModel | null | undefined = apiData;
  if (apiData) {
    try {
      response = UsernameLoginResponseModelConverter.fromJson(apiData);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};
