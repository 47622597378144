import { CustomerInfo } from '@/models/Authen.model';
import { RootState } from '@/store/store';
import { createSlice } from '@reduxjs/toolkit';

interface ProfileState {
  info?: CustomerInfo | null;
}

const initialState: ProfileState = {};

export const profileSlice = createSlice({
  name: 'profile',
  initialState: initialState,
  reducers: {
    setProfileInfo: (state, action) => {
      if (action.payload !== undefined && action.payload !== state.info) {
        state.info = action.payload;
      }
    },
    clearProfileInfo: (state) => {
      state.info = undefined;
    },
  },
});

export const { setProfileInfo, clearProfileInfo } = profileSlice.actions;

export const profileInfoSelector = (
  store: RootState,
): CustomerInfo | undefined | null => store.profile.info;

export default profileSlice.reducer;
