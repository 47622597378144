import { getDataFromAPI } from '@/utils/httpClient';

export const getSkuImage = async (sku: string): Promise<string> => {
  const [getDataAPI] = await Promise.all([
    getDataFromAPI({
      path: `/pimImage/${sku}`,
    }),
  ]);
  const apiData =
    getDataAPI.status == 200 ? (getDataAPI.data ? getDataAPI.data : '') : '';
  let response: string = apiData ?? '';
  return response;
};
