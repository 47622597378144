import React, { useState } from 'react';
import { Box } from '@mui/material';
import AppLink from '@/components/Layout/handler/AppLink';
import ImageWithFallback from '../Layout/handler/ImageWithFallback';
import MenuClass from '@/styles/menu.module.css';
import { useTranslation } from 'next-i18next';
import AllCatContext from '@/contexts/AllCatContext';
import { AllCat } from '@/models/AllCat.model';
import CatSubMenuSideBar from './CatSubMenuSideBar';

export default function CatMenuSideBar() {
  const { i18n } = useTranslation();
  const [currentNode, setCurrentNode] = useState<string | null>(null);
  const SetOpenSubMenu = (node: string | null) => {
    setCurrentNode(node);
  };
  return (
    <AllCatContext.Consumer>
      {(allcats) => {
        if (!allcats) {
          return;
        }
        return (
          <Box className="overflow-x-hidden overflow-y-auto">
            {allcats && (
              <>
                {!currentNode ? (
                  <>
                    {allcats.map((e: AllCat) => (
                      <Box key={e.node} className={MenuClass.mainCategoryItem}>
                        {!e.havenext ? (
                          <AppLink
                            href={e.slugname && `/category/${e.slugname}`}
                            className="flex px-2 py-2"
                          >
                            <Box className="self-center">
                              <ImageWithFallback
                                src={`https://${process.env.NEXT_PUBLIC_PROD_BASE_URL_WEB}/public/images/categorymenu/${e.node}.webp`}
                                alt={e.catname}
                                width={24}
                                height={24}
                              />
                            </Box>
                            <Box className="self-center ps-1 text-sm">
                              {i18n.language == 'th'
                                ? e.catname
                                : e.catnameEN ?? e.catname}
                            </Box>
                          </AppLink>
                        ) : (
                          <Box
                            className="flex px-2 py-1 cursor-pointer"
                            onClick={() => SetOpenSubMenu(e.node)}
                          >
                            <Box className="self-center">
                              <ImageWithFallback
                                src={`https://${process.env.NEXT_PUBLIC_PROD_BASE_URL_WEB}/public/images/categorymenu/${e.node}.webp`}
                                alt={e.catname}
                                width={24}
                                height={24}
                              />
                            </Box>
                            <Box className="self-center ps-1 text-sm">
                              {i18n.language == 'th'
                                ? e?.catname
                                : e?.catnameEN ?? e?.catname}
                            </Box>
                            <Box className="ml-auto">
                              <i className="bi bi-chevron-right text-sm my-auto" />
                            </Box>
                          </Box>
                        )}
                      </Box>
                    ))}
                  </>
                ) : (
                  <CatSubMenuSideBar
                    node={currentNode}
                    onCloseCatSubMenu={SetOpenSubMenu}
                  />
                )}
              </>
            )}
          </Box>
        );
      }}
    </AllCatContext.Consumer>
  );
}
