import { modelValue } from '@/utils/modelValue';
import {
  CookieData,
  CookieDataConverter,
  CustomerInfo,
  CustomerInfoConverter,
} from '../Authen.model';

export interface MyWarrantyDetailResponse {
  warrantydetail?: Warrantydetail;
  info?: CustomerInfo | null;
  cookies?: CookieData[] | null;
}

export interface Warrantydetail {
  ref?: string;
  custid?: string;
  receivedate: string;
  skcode: string;
  prname: string;
  base64: string;
  slugname: string;
  serialno: string;
  warrantymonth?: string;
  warrantyexpired?: string;
  isexpired?: boolean;
  iswarrantyyear?: boolean;
  warrantynumber?: number;
}

export class MyWarrantyDetailResponseConverter {
  public static fromJson(json: any): MyWarrantyDetailResponse {
    const model: MyWarrantyDetailResponse = {
      warrantydetail: json['warrantydetail']
        ? WarrantyDetailConverter.fromJson(json['warrantydetail'])
        : undefined,
      info: json['info'] ? CustomerInfoConverter.fromJson(json['info']) : null,
      cookies: json['cookies']
        ? json['cookies'].map((x: any) => CookieDataConverter.fromJson(x))
        : undefined,
    };
    return modelValue(model);
  }
}
export class WarrantyDetailConverter {
  public static fromJson(json: any): Warrantydetail {
    const model: Warrantydetail = {
      ref: json['ref'],
      custid: json['custid'],
      receivedate: json['receivedate'],
      skcode: json['skcode'],
      prname: json['prname'],
      base64: json['base64'],
      slugname: json['slugname'],
      serialno: json['serialno'],
      warrantymonth: json['warrantymonth'],
      warrantyexpired: json['warrantyexpired'],
      isexpired: json['isexpired'],
      iswarrantyyear: json['iswarrantyyear'],
      warrantynumber: json['warrantynumber'],
    };
    return modelValue(model);
  }
}
