import {
  MyVoucherModel,
  MyVoucherModelConverter,
} from '@/models/profile/MyVoucher.model';
import { saveClientCookieFromResponse } from '@/utils/cookie';
import { postDataFromAPI } from '@/utils/httpClient';

export const getMyVoucher = async ({
  lang,
  page,
  sectionName,
  rewardId,
}: {
  lang?: string;
  page?: number;
  sectionName?: string;
  rewardId?: string;
}): Promise<{
  data: MyVoucherModel | null | undefined;
  status: number | null;
}> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/getvoulist',
      lang,
      body: {
        page,
        secname: sectionName,
        rewardid: rewardId,
      },
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: MyVoucherModel | null | undefined;
    status: number | null;
  } = { data: data, status: getData.status };
  if (data) {
    try {
      response.data = MyVoucherModelConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }

  return response;
};

export const getProfileMyVoucher = async ({
  lang,
  page,
  state,
  sub,
}: {
  lang?: string;
  page?: number;
  state?: string;
  sub?: string;
}): Promise<{
  data: MyVoucherModel | null | undefined;
  status: number | null;
}> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/profile/myvoucher',
      lang,
      body: {
        page,
        state,
        sub,
        methodcall: 'POST',
      },
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: MyVoucherModel | null | undefined;
    status: number | null;
  } = { data: data, status: getData.status };
  if (data) {
    try {
      response.data = MyVoucherModelConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  // ถ้าอนาคตมีคนเรียกใช้แต่ไม่อยาก save cookie ให้ย้ายไปใน Slice
  await saveClientCookieFromResponse(response.data?.cookies);

  return response;
};
