import Swal, { SweetAlertIcon } from 'sweetalert2';
import Cookies from 'js-cookie';
import * as cookieKey from '@/constants/cookieKey.constant';

export interface ButtonNameWithCallBack {
  name: string;
  backgroundColor?: string;
  color?: string;
  callBack?: () => void;
}

export const showErrorWithOkButton = async ({
  text,
  allowOutsideClick,
}: {
  text: string;
  allowOutsideClick?: boolean;
}) => {
  await Swal.fire({
    width: 'auto',
    heightAuto: false,
    padding: '8',
    html: `<div class="text-center text-base">${text}</div>`,
    icon: 'error',
    confirmButtonColor: '#854BA2',
    confirmButtonText: `<div class="text-sm">${
      (Cookies.get(cookieKey.lang) ?? 'th') == 'th' ? 'ตกลง' : 'OK'
    }</div>`,
    allowOutsideClick: allowOutsideClick === false ? false : true,
  });
};

export const showMessageWithButton = async ({
  text,
  image,
  primaryButton,
  secondaryButton,
  showCloseButton,
  removeAnimation,
  allowOutsideClick,
  icon,
}: {
  text: string;
  image?: string;
  primaryButton?: ButtonNameWithCallBack;
  secondaryButton?: ButtonNameWithCallBack;
  showCloseButton?: boolean;
  removeAnimation?: boolean;
  allowOutsideClick?: boolean;
  icon?: SweetAlertIcon;
}) => {
  await Swal.fire({
    width: 'auto',
    allowOutsideClick: allowOutsideClick === false ? false : true,
    padding: '1rem',
    icon: icon,
    heightAuto: true,
    title: image
      ? `<div class="flex items-center w-full"><img class="mx-auto" src="${image}" /></div>`
      : '',
    html: `<div class="text-center text-black text-base">${text}</div>`,
    showConfirmButton: primaryButton ? true : false,
    confirmButtonColor: primaryButton?.backgroundColor ?? '#854BA2',
    confirmButtonText: `<div class="text-sm">${
      primaryButton?.name ??
      ((Cookies.get(cookieKey.lang) ?? 'th') == 'th' ? 'ตกลง' : 'OK')
    }</div>`,
    showCancelButton: secondaryButton ? true : false,
    cancelButtonColor: secondaryButton?.backgroundColor ?? '#B4B4B4',
    cancelButtonText: `<div class="text-sm">${secondaryButton?.name}</div>`,
    showCloseButton: showCloseButton ? true : false,
    showClass: {
      ...(removeAnimation ? { backdrop: 'swal2-noanimation', popup: '' } : {}),
    },
    hideClass: { ...(removeAnimation ? { popup: '' } : {}) },
  }).then((result) => {
    if (result.isConfirmed && primaryButton?.callBack != undefined) {
      primaryButton?.callBack();
    } else if (
      result.dismiss === Swal.DismissReason.cancel &&
      secondaryButton?.callBack != undefined
    ) {
      secondaryButton?.callBack();
    }
  });
};

export const showMessageSuccessAutoHide = async ({
  text,
}: {
  text: string;
}) => {
  await Swal.fire({
    width: 'auto',
    heightAuto: false,
    padding: '50px',
    icon: 'success',
    html: `<div class="text-base">${text}</div>`,
    timer: 1000,
    timerProgressBar: false,
    showConfirmButton: false,
  });
};

export const showWarningWithOkButton = async ({
  title,
  subtitle,
  allowOutsideClick,
}: {
  title: string;
  subtitle?: string;
  allowOutsideClick?: boolean;
}) => {
  await Swal.fire({
    width: 'auto',
    heightAuto: false,
    padding: '8',
    html: `<div class="text-center font-bold text-base">${title}</div><div class="text-center text-sm">${subtitle}</div>`,
    icon: 'warning',
    confirmButtonColor: '#854BA2',
    confirmButtonText: `<div class="text-sm">${
      (Cookies.get(cookieKey.lang) ?? 'th') == 'th' ? 'ตกลง' : 'OK'
    }</div>`,
    allowOutsideClick: allowOutsideClick === false ? false : true,
  });
};

export const showWarningWithOkButtonCallback = async ({
  title,
  subtitle,
  allowOutsideClick,
  confirmButton,
}: {
  title: string;
  subtitle?: string;
  allowOutsideClick?: boolean;
  confirmButton?: ButtonNameWithCallBack;
}) => {
  await Swal.fire({
    width: 'auto',
    heightAuto: false,
    padding: '8',
    html: `<div class="text-center font-bold text-base">${title}</div><div class="text-center text-sm">${subtitle}</div>`,
    icon: 'warning',
    confirmButtonColor: '#854BA2',
    confirmButtonText: `<div class="text-sm">${
      (Cookies.get(cookieKey.lang) ?? 'th') == 'th' ? 'ตกลง' : 'OK'
    }</div>`,
    allowOutsideClick: allowOutsideClick === false ? false : true,
  }).then((result) => {
    if (result.isConfirmed && confirmButton?.callBack != undefined) {
      confirmButton?.callBack();
    }
  });
};
