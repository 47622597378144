import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/store/store';

interface MiniFilterProduct {
  propMaster: number;
  propVal?: number[];
}
interface FilterState {
  fill?: MiniFilterProduct[];
  ship: boolean;
  sort?: number;
}

const initialState: FilterState = {
  fill: undefined,
  ship: false,
  sort: 0,
};

export const categoryFilterSlice = createSlice({
  name: 'filter',
  initialState: initialState,
  reducers: {
    resetFilter: (state) => {
      state.fill = undefined;
      state.ship = false;
      state.sort = 0;
    },
    setShipState: (state, action) => {
      state.ship = action.payload;
    },
    setSortState: (state, action) => {
      state.sort = action.payload;
    },
    setFillState: (state, action) => {
      if (action.payload && action.payload !== '') {
        try {
          const fillMap: MiniFilterProduct[] = [];
          const propAllSplit = action.payload.split('|');
          for (let i = 0; i < propAllSplit.length; i++) {
            const propAll = propAllSplit[i];
            const propSplit = propAll.split('-');
            const propMaster = Number(propSplit[0]);
            const propVal = propSplit[1].split('%').map((e: any) => Number(e));
            if (propVal.length > 0) {
              fillMap.push({ propMaster, propVal });
            }
          }
          state.fill = fillMap;
        } catch (_) {}
      } else {
        state.fill = undefined;
      }
    },
    mutateFill: (
      state,
      {
        payload,
      }: {
        payload: { master: number; val: number };
        type: string;
      },
    ) => {
      if (state.fill && state.fill.length > 0) {
        let tempFill = state.fill;
        const existPropMaster = tempFill.find(
          (e) => e.propMaster === payload.master,
        );
        if (existPropMaster !== null && existPropMaster !== undefined) {
          const existPropVal = (existPropMaster.propVal ?? []).find(
            (e) => e === payload.val,
          );
          if (existPropVal !== null && existPropVal !== undefined) {
            existPropMaster.propVal = existPropMaster.propVal?.filter(
              (e) => e !== payload.val,
            );
            if (existPropMaster.propVal?.length === 0) {
              tempFill = tempFill.filter(
                (e) => e.propMaster !== existPropMaster.propMaster,
              );
            }
          } else {
            existPropMaster.propVal?.push(payload.val);
          }
          state.fill = tempFill;
        } else {
          state.fill.push({
            propMaster: payload.master,
            propVal: [payload.val],
          });
        }
      } else {
        state.fill = [
          {
            propMaster: payload.master,
            propVal: [payload.val],
          },
        ];
      }
    },
  },
});

export const {
  resetFilter,
  mutateFill,
  setShipState,
  setSortState,
  setFillState,
} = categoryFilterSlice.actions;
export const fillSelector = (store: RootState): MiniFilterProduct[] =>
  store.categoryFilter?.fill ?? [];
export const fillQuerySelector = (store: RootState): string => {
  const response = store.categoryFilter.fill
    ?.map((e) => `${e.propMaster}-${e.propVal?.join('%')}`)
    .join('|');

  return response ?? '';
};
export const shipSelector = (store: RootState): boolean =>
  store.categoryFilter.ship;
export const sortSelector = (store: RootState): number =>
  store.categoryFilter?.sort ?? 0;

export default categoryFilterSlice.reducer;
