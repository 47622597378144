import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import TopBannerContext from '@/contexts/TopBannerContext';
import { Topbanner } from '@/models/VersionLoad.model';
import CompareLayout from './Compare';
import AddToCartLayout from './AddToCart';
import StoreLayout from './Store';
import { useAppDispatch } from '@/store/store';
import { clearProfileInfo } from '@/store/slices/profile/profileSlice';
import { useRouter } from 'next/router';
import { onlyAuth } from '@/services/client/authService';
import { useSelector } from 'react-redux';
import { customerIdSelector, setCustomerId } from '@/store/slices/loginSlice';
import Loading from './handler/Loading';
import { isLoadingPageSelector, setLoading } from '@/store/slices/loadingSlice';
import { Toaster } from 'react-hot-toast';
import { refreshCartCount } from '@/store/slices/addToCartSlice';
import UseVoucher from './UseVoucher';
import Cookies from 'js-cookie';
import * as cookieKey from '@/constants/cookieKey.constant';
import { exceptLayoutList } from '@/constants/defaultValue.constant';
import ProductListGtmWrapper from '../common/ProductListGtmWrapper';
import { useTranslation } from 'next-i18next';

interface Props {
  children: ReactNode;
}

export default function Layout({ children }: Props): ReactElement {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [topBanner, setTopBanner] = useState<Topbanner | undefined>(undefined);
  const customerIdStore = useSelector(customerIdSelector);
  const isLoadingPage = useSelector(isLoadingPageSelector);
  const { i18n } = useTranslation();

  useEffect(() => {
    const localLang = Cookies.get(cookieKey.lang) ?? 'th';
    if (localLang != router.locale) {
      Cookies.set(cookieKey.lang, router.locale ?? 'th');
    }
    const handleStart = (url: any) => {
      if (
        !url.includes('/profile/') ||
        !router.pathname.includes('/profile/')
      ) {
        dispatch(setLoading(true));
      }
    };
    const handleComplete = () =>
      setTimeout(() => dispatch(setLoading(false)), 0);
    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  });

  useEffect(() => {
    try {
      if (customerIdStore !== undefined) {
        (window as any).dataLayer.push({ ecommerce: null });
        (window as any).dataLayer.push(
          customerIdStore
            ? {
                event: 'page_metadata',
                userId: customerIdStore,
              }
            : undefined,
        );
      }
      if (!router.pathname.includes('/profile/')) {
        dispatch(clearProfileInfo());
      }
    } catch (_) {}
  }, [router.pathname, dispatch, customerIdStore, i18n.language]);

  useEffect(() => {
    if (customerIdStore !== null) {
      onlyAuth().then((res) => {
        dispatch(setCustomerId(res.info?.custId));
        dispatch(refreshCartCount());
      });
    }
  }, [dispatch, customerIdStore, i18n.language]);

  return exceptLayoutList.includes(router.pathname.toLocaleLowerCase()) ? (
    <>{children}</>
  ) : (
    <TopBannerContext.Provider value={{ topBanner, setTopBanner }}>
      {isLoadingPage && <Loading />}
      <Toaster
        toastOptions={{
          style: {
            width: 'auto',
            fontSize: '.75rem',
          },
        }}
      />
      <StoreLayout />
      <ProductListGtmWrapper />
      <AddToCartLayout>
        <Header />
        <main className="content-body">{children}</main>
        <CompareLayout />
        <Footer />
        <UseVoucher />
      </AddToCartLayout>
    </TopBannerContext.Provider>
  );
}
