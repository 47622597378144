import * as showDialog from '@/utils/showDialog';
import Cookies from 'js-cookie';
import * as cookieKey from '@/constants/cookieKey.constant';
import thText from '@/locales/th/addtocart.json';
import enText from '@/locales/en/addtocart.json';
import { SkuWithQty } from '@/models/AddToCart.model';

export enum AddToCartHandleType {
  // eslint-disable-next-line no-unused-vars
  consent = 'consent', // add to cart อีกรอบโดนใช้ state value
  // eslint-disable-next-line no-unused-vars
  error = 'error', // clear error state
  // eslint-disable-next-line no-unused-vars
  changeStore = 'changeStore', // แสดง stock เพื่อเปลี่ยนสาขา
  // eslint-disable-next-line no-unused-vars
  autoChangeStore = 'autoChangeStore', // เปลี่ยนสาขา auto แล้ว add to cart
  // eslint-disable-next-line no-unused-vars
  refresh = 'refresh', // refresh page
  // eslint-disable-next-line no-unused-vars
  clearCartAndAddToCart = 'clearCartAndAddToCart', // clearcart and add to cart
  // eslint-disable-next-line no-unused-vars
  clearCartAndGotoOrderhistory = 'clearCartAndGotoOrderhistory', // clearcart and go to orderhistory page
  // eslint-disable-next-line no-unused-vars
  clearCart = 'clearCart', // clearcart
  // eslint-disable-next-line no-unused-vars
  reNewServiceAndAddToCart = 'reNewServiceAndAddToCart', // reNewServiceAndAddToCart
}

export interface AddToCartErrorHandleInput {
  text?: string;
  type?: string;
  svgImage?: string;
  messages?: string[];
  isNotRefundMessage?: boolean;
  productName?: string;
  skuService?: string;
  ref?: string;
  serviceNotAvail?: boolean;
  sku?: string;
  qty?: number;
  lang?: string;
  isSelectBundle?: boolean;
  setId?: string;
  withService?: string;
  skuQtyList?: SkuWithQty[];
}

export interface AddToCartErrorHandleOutput {
  text?: string;
  handleType: AddToCartHandleType;
  storeCode?: string;
  qty?: number;
}

export const errorMessageHandler = async ({
  text,
  type,
  svgImage,
  messages,
  isNotRefundMessage,
  productName,
  qty,
  lang,
}: AddToCartErrorHandleInput): Promise<AddToCartErrorHandleOutput> => {
  const t = lang === 'th' ? thText : enText;
  const textSplit: string[] = (text ?? '').toString().split('|');
  let responseType: AddToCartHandleType = AddToCartHandleType.error;
  let responseQty: number | undefined = undefined;
  let storeCode = undefined;
  if (type === 'consent') {
    await showDialog.showMessageWithButton({
      image: svgImage,
      text: `${(messages ?? []).map((e) => `<div>${e}</div>`).join('')}${
        !isNotRefundMessage ? `<div>${t.iftheorderhasbeenpro}</div>` : ''
      }`,
      primaryButton: {
        name: t.cancel,
        backgroundColor: '#000000',
      },
      secondaryButton: {
        name: t.accept,
        backgroundColor: '#854BA2',
        callBack: () => {
          responseType = AddToCartHandleType.consent;
        },
      },
    });
  } else if (text === 'Stock Avail is Not Enough') {
    await showDialog.showMessageWithButton({
      image: '/images/svg/cart/warning_icon.svg',
      text: `<div>${t.sorry}</div><div>${t.thisbranchisoutofstore}</div>`,
      primaryButton: {
        name: t.changebranch,
        backgroundColor: '#854BA2',
        callBack: () => {
          responseType = AddToCartHandleType.changeStore;
        },
      },
    });
  } else if (text?.includes('Stock Avail is Not Enough|')) {
    await showDialog.showMessageWithButton({
      image: '/images/svg/cart/warning_icon.svg',
      text: `<div>${t.sorry}</div><div>${
        textSplit.length > 1 && (Number(textSplit[1]) ?? 0) >= 1
          ? `${t.thisbranchhasinventories} ${textSplit[1]} ${t.unit} ${t.tryanotherbranch}`
          : t.thisbranchisoutofstore
      }</div>`,
      primaryButton: {
        name: t.changebranch,
        backgroundColor: '#854BA2',
        callBack: () => {
          responseType = AddToCartHandleType.changeStore;
        },
      },
    });
  } else if (text?.includes('toomanystore|')) {
    try {
      storeCode = textSplit.length >= 3 ? textSplit[3] : undefined;
      responseType = AddToCartHandleType.autoChangeStore;
    } catch (_) {}
  } else if (text?.includes('ไม่พบสาขา|')) {
    const oldStoreName: string = textSplit.length > 1 ? textSplit[1] : '';
    await showDialog.showMessageWithButton({
      text: `<div>${t.branch} ${oldStoreName}</div><div>${t.temporarilyclosed}</div><div>${t.pleaseselectanewbranch}</div>`,
      primaryButton: {
        name: t.ok,
        backgroundColor: '#854BA2',
      },
    });
    responseType = AddToCartHandleType.changeStore;
  } else if (text?.includes('alreadypaid|')) {
    responseType = AddToCartHandleType.clearCartAndAddToCart;
  } else if (text?.includes('hasother|')) {
  } else if (text?.includes('haschristmas')) {
  } else if (text?.includes('hascurtain')) {
  } else if (text?.includes('longtailconsent|')) {
    await showDialog.showMessageWithButton({
      image: '/images/svg/cart/backconsent_icon.svg',
      text: `<div>${t.specialorder} ${
        textSplit.length > 1
          ? `${textSplit[1]} ${t.day}</div><div>${t.iftheorderhasbeenpro} <a href="/page/refundpolicy" target="_blank">${t.readterms}</a></div>`
          : ''
      }`,
      primaryButton: {
        name: t.cancel,
        backgroundColor: '#000000',
      },
      secondaryButton: {
        name: t.accept,
        backgroundColor: '#854BA2',
        callBack: () => {
          responseType = AddToCartHandleType.consent;
        },
      },
    });
  } else if (text?.includes('backorderconsent|')) {
    const dateshow: string = textSplit.length > 1 ? textSplit[1] : '';
    let stockShow: number = 0;
    try {
      stockShow = Number(textSplit[2]) ?? 0;
    } catch (_) {}
    await showDialog.showMessageWithButton({
      image: '/images/svg/cart/backconsent_icon.svg',
      text:
        stockShow > 0 && (qty ?? 1) > 0
          ? `${`<div class="text-base font-bold">${
              t.thisbranchhasproductreadyforshiping
            } ${stockShow.toString()} ${t.unit}</div>
            <div class="text-base font-bold">${
              t.ifyouwanttoordermorethanthiswewill
            }<div>
            <div class="text-sm">${t.wewill}${
              t.itwilltaketimetoprepare
            } ${dateshow} ${t.day}</div>`}`
          : `<div class="text-base font-bold">${t.temporarilyoutofstock} ${t.ifyouwanttoorder}</div>
            <div class="text-sm">${t.itwilltaketimetoprepare} ${dateshow} ${t.day}</div>`,
      primaryButton: {
        name: t.cancel,
        backgroundColor: '#000000',
      },
      secondaryButton: {
        name: t.accept,
        backgroundColor: '#854BA2',
        callBack: () => {
          responseType = AddToCartHandleType.consent;
        },
      },
    });
  } else if (text?.includes('Stock Avail is Disabled|')) {
    await showDialog.showMessageWithButton({
      image: '/images/svg/cart/thai_cry_ciecle.svg',
      text: `<div>${t.sorrythisproductiste}`,
      primaryButton: {
        name: t.ok,
        backgroundColor: '#000000',
      },
    });
  } else if (text?.includes('alreadyvoid|')) {
    await showDialog.showMessageWithButton({
      image: '/images/svg/cart/warning_icon.svg',
      text: `<div>${t.thiscarthasbeencancel}</div><div>${t.productsinthiscartcancel}</div>`,
      primaryButton: {
        name: t.addproduct,
        backgroundColor: '#854BA2',
        callBack: () => {
          responseType = AddToCartHandleType.clearCartAndAddToCart;
        },
      },
      secondaryButton: {
        name: t.gototheorderlistpage,
        backgroundColor: '#000000',
        callBack: () => {
          responseType = AddToCartHandleType.clearCartAndGotoOrderhistory;
        },
      },
    });
  } else if (text?.includes('pendingorder|')) {
    responseType = AddToCartHandleType.clearCart;
    await showDialog.showMessageWithButton({
      image: '/images/svg/cart/warning_icon.svg',
      text: `<div>${t.productsthatyouhavealready}</div>`,
      primaryButton: {
        name: t.gototheorderlistpage,
        backgroundColor: '#854BA2',
        callBack: () => {
          responseType = AddToCartHandleType.clearCartAndGotoOrderhistory;
        },
      },
    });
  } else if (text?.includes('hasselectbundle')) {
  } else if (text?.includes('quota|')) {
    let stockShow: number = 0;
    let stockRemain: number = 0;
    try {
      stockShow = Number(textSplit[1]) ?? 0;
    } catch (_) {}
    try {
      stockRemain = Number(textSplit[2]) ?? 0;
    } catch (_) {}
    if (stockShow < stockRemain) {
      stockShow = 0;
      stockRemain = 0;
    }
    // const stockIncart = stockShow - stockRemain;
    await showDialog.showMessageWithButton({
      image: '/images/svg/cart/backconsent_icon.svg',
      text:
        stockRemain > 0
          ? `${`<div class="text-base font-bold">${
              t.havestockavail
            } ${stockRemain.toString()} ${t.unit}</div>`}`
          : `${`<div class="text-base font-bold">${t.notenoughproductsasordered}</div>`}`,
      primaryButton:
        stockRemain > 0
          ? {
              name: t.cancel,
              backgroundColor: '#000000',
            }
          : {
              name: t.ok,
              backgroundColor: '#854BA2',
            },
      secondaryButton:
        stockRemain > 0
          ? {
              name: t.buyallavailable,
              backgroundColor: '#854BA2',
              callBack: () => {
                responseType = AddToCartHandleType.consent;
                responseQty = stockRemain;
              },
            }
          : undefined,
    });
  } else if (text?.includes('dcconsent|')) {
    const dateshow: string = textSplit.length > 1 ? textSplit[1] : '';
    let stockShow: number = 0;
    let flagbranch: number = 1;
    let btntext: string = t.buy;
    let isLongtail: boolean = false;
    try {
      stockShow = Number(textSplit[2]) ?? 0;
    } catch (_) {}
    try {
      flagbranch = Number(textSplit[3]) ?? 1;
      if (flagbranch === 2 || flagbranch === 5) {
        btntext = t.buyallavailable;
      } else if (flagbranch === 3) {
        btntext = t.buyallthatthisbranchhas;
      }
      if (textSplit[4] === 'ER') {
        isLongtail = true;
      }
    } catch (_) {}
    await showDialog.showMessageWithButton({
      image: '/images/svg/cart/backconsent_icon.svg',
      text:
        stockShow > 0 && (qty ?? 1) > 0
          ? `${`<div class="text-base font-bold">${
              [2, 4, 5].includes(flagbranch)
                ? t.productsareinstockandreadytosell
                : t.thisbranchhasproductreadyforshiping
            } ${stockShow.toString()} ${t.unit}</div>`}
            ${
              ![2, 3].includes(flagbranch)
                ? `${
                    flagbranch === 1
                      ? `<div class="text-sm">${t.ifyouwanttoordermorethanthiswewill}<div>`
                      : ``
                  }
                  <div class="text-sm">${flagbranch === 1 ? t.wewill : ``}${
                    t.itwilltaketimetoprepare
                  } ${dateshow} ${t.day}</div>`
                : ``
            }
            ${isLongtail ? `<div>${t.iftheorderhasbeenpro}</div>` : ''}`
          : `<div class="text-xl font-bold">${t.temporarilyoutofstock}</div>
            <div class="text-base">${t.itwilltaketimetoprepare} ${dateshow} ${
              t.day
            }</div>
            ${isLongtail ? `<div>${t.iftheorderhasbeenpro}</div>` : ''}`,
      primaryButton: {
        name: t.cancel,
        backgroundColor: '#000000',
      },
      secondaryButton: {
        name: btntext,
        backgroundColor: '#854BA2',
        callBack: () => {
          responseType = AddToCartHandleType.consent;
          if (![1, 4].includes(flagbranch)) {
            responseQty = stockShow;
          }
        },
      },
    });
  } else if (text?.includes('servicenotsame')) {
    await showDialog.showMessageWithButton({
      image: '/images/svg/cart/warning_icon.svg',
      text: `<div class="text-base font-bold text-black">${t.doyouwanttochangetheinstallationservicefor}</div><div class="text-sm">${productName}</div>`,
      primaryButton: {
        name: t.cancel,
        backgroundColor: '#000000',
      },
      secondaryButton: {
        name: t.ok,
        backgroundColor: '#854BA2',
        callBack: () => {
          responseType = AddToCartHandleType.reNewServiceAndAddToCart;
        },
      },
    });
  } else {
    const oldStcode: string | undefined = Cookies.get(cookieKey.stcode);
    if (!oldStcode) {
    } else {
      await showDialog.showMessageWithButton({
        text: text ?? '',
        primaryButton: {
          name: thText.ok,
        },
      });
    }
  }
  return { handleType: responseType, storeCode: storeCode, qty: responseQty };
};
