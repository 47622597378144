import { shortMonth } from '@/constants/checkout.constant';
import { SkuWithQty } from '@/models/AddToCart.model';

export const mapSkuAddingToCart = ({
  sku,
  skuQtyList,
  setId,
}: {
  sku?: string;
  skuQtyList?: SkuWithQty[];
  setId?: string;
}) => {
  return `${sku ?? (skuQtyList ?? []).map((e) => e.sku).join(',')}${
    setId !== undefined ? `|${setId}` : ''
  }`;
};

export const numberWithCommas = (x?: string | number | null) => {
  return x !== null || x !== undefined
    ? (x ?? '0')
        .toString()
        .replaceAll(',', '')
        .replaceAll('฿', '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : '';
};

export const stringToNumber = (x?: string | null) => {
  return x !== null || x !== undefined
    ? Number(
        (x ?? '0').toString().replaceAll(',', '').replaceAll('฿', '').trim(),
      )
    : undefined;
};

export const tranDate = ({
  leadTime,
  isShowYear,
  lang,
}: {
  leadTime?: number;
  isShowYear?: boolean;
  lang?: string;
}) => {
  let date = new Date();
  if (leadTime !== null && leadTime !== undefined) {
    date.setDate(date.getDate() + leadTime);
    let langUse: 'th' | 'en' = 'th';
    switch (lang) {
      case 'en':
        langUse = 'en';
        break;
      default:
        langUse = 'th';
        break;
    }
    return (
      date.getDate() +
      ' ' +
      shortMonth[langUse][date.getMonth()] +
      (isShowYear ? ` ${date.getFullYear() + 543}` : ``)
    );
  } else {
    return '';
  }
};

export const detectBrower = (): string => {
  let ua = navigator.userAgent;
  let tem;
  let M =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edg)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(' ');
};

export const convertToMarkPan = (pan: string) => {
  if (pan) {
    const panArr = pan.replaceAll('-', '').split('');
    const first6 = panArr.slice(0, 6);
    const last4 = panArr.slice(-4);
    const markPan = first6.concat('XXXXXX', last4).join('');
    return markPan;
  } else {
    return '';
  }
};
