import { modelValue } from '@/utils/modelValue';
export interface CustomerInfo {
  custId: string;
  email?: string;
  cname: string;
  t1c?: string;
  mobile: string;
  rtoken?: string;
  rapptwdtoken?: string;
  rbnbtoken?: string;
  rappbnbtoken?: string;
  cartRef?: string;
  sumqnt?: number;
  salesource?: string;
  isFullMember: boolean;
  employeeBUShortCode?: string;
  employeeID?: string;
  isStaff?: string;
}

export class CustomerInfoConverter {
  public static fromJson(json: any): CustomerInfo {
    const model: CustomerInfo = {
      custId: json['custid'] ?? '',
      email: json['email'],
      cname: json['cname'] ?? '',
      t1c: json['t1c'],
      mobile: json['mob'] ?? '',
      rtoken: json['RTOKEN'],
      rapptwdtoken: json['RAPPTWDTOKEN'],
      rbnbtoken: json['RBNBTOKEN'],
      rappbnbtoken: json['RAPPBNBTOKEN'],
      cartRef: json['CUSTREF'],
      sumqnt: json['SUMQNT'],
      salesource: json['salesource'],
      isFullMember: json['isFullMember'] == true ? true : false,
      employeeBUShortCode: json['employeeBUShortCode'],
      employeeID: json['employeeID'],
      isStaff: json['isStaff'],
    };
    return modelValue(model);
  }
}

export interface CookieData {
  name: string;
  val?: string;
  days?: number | null;
}

export class CookieDataConverter {
  public static fromJson(json: any): CookieData {
    const model: CookieData = {
      name: json['name'],
      val: json['val']?.toString().trim(),
      days: json['days'],
    };
    return modelValue(model);
  }
}
