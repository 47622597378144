import { MyVoucherModel } from '@/models/profile/MyVoucher.model';
import { getProfileMyVoucher } from '@/services/client/myVoucherService';
import { RootState } from '@/store/store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as cookieKey from '@/constants/cookieKey.constant';
import { getProfileMyVoucherDetail } from '@/services/client/myVoucherDetailService';
import { GetVoucherDetailModel } from '@/models/profile/MyVoucherDetail.model';
import * as ServerCookies from '@/services/client/cookieService';

export interface profileMyVoucherState {
  resultIssued?: MyVoucherModel | null;
  resultReservedRedeemed?: MyVoucherModel | null;
  resultExpired?: MyVoucherModel | null;
  currentPage: number;
  limit: number;
  state: string;
  isLoading: boolean;
  error?: string;
  isLoadingDetail: boolean;
  resultDetail?: GetVoucherDetailModel | null;
}

const initialState: profileMyVoucherState = {
  currentPage: 1,
  isLoading: false,
  limit: 12,
  state: 'issued',
  isLoadingDetail: false,
  resultDetail: undefined,
};

export const profileMyVoucherQuery = createAsyncThunk(
  'profileMyVoucher/profile/query',
  async ({
    lang,
    page,
    state,
    sub,
  }: {
    lang?: string;
    page?: number;
    state?: string;
    sub?: string;
  }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await getProfileMyVoucher({ lang, page, state, sub });
    if (!response.data) {
      throw new Error(`${response.status ?? '500.'}`);
    }
    return response;
  },
);

export const profileMyVoucherDetail = createAsyncThunk(
  'profileMyVoucher/profile/detail',
  async ({ lang, voucherk }: { lang?: string; voucherk?: string }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await getProfileMyVoucherDetail({ lang, voucherk });
    if (!response.data) {
      throw new Error(`${response.status ?? '500.'}`);
    }
    return response;
  },
);

export const profileMyVoucherSlice = createSlice({
  name: 'profileMyVoucher',
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.error = undefined;
    },
    clearResult: (state) => {
      state.resultIssued = undefined;
      state.resultReservedRedeemed = undefined;
      state.resultExpired = undefined;
    },
    clearResultDetail: (state) => {
      state.resultDetail = undefined;
    },
  },
  extraReducers: (builder) => {
    // pending, fulfilled, rejected
    builder
      .addCase(profileMyVoucherQuery.pending, (state) => {
        state.isLoading = true;
        // state.resultIssued = null;
        // state.resultReservedRedeemed = null;
        // state.resultExpired = null;
      })
      .addCase(profileMyVoucherQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload === null) {
          state.resultIssued = {
            info: null,
          };
          state.resultReservedRedeemed = {
            info: null,
          };
          state.resultExpired = {
            info: null,
          };
        } else {
          const stateRes = action.payload?.data?.state;
          switch (stateRes) {
            case 'issued':
              state.resultIssued = action.payload?.data ?? null;
              break;
            case 'reserved,redeemed':
              state.resultReservedRedeemed = action.payload?.data ?? null;
              break;
            case 'expired':
              state.resultExpired = action.payload?.data ?? null;
              break;
            default:
              state.resultIssued = null;
              state.resultReservedRedeemed = null;
              state.resultExpired = null;
          }
        }
        state.limit = action.payload?.data?.limit ?? 5;
        state.currentPage = 1;
      })
      .addCase(profileMyVoucherQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.resultIssued = null;
        state.resultReservedRedeemed = null;
        state.resultExpired = null;
        state.error = action.error.message;
        state.currentPage = 1;
      });
    builder
      .addCase(profileMyVoucherDetail.pending, (state) => {
        state.isLoadingDetail = true;
        state.resultDetail = undefined;
      })
      .addCase(profileMyVoucherDetail.fulfilled, (state, action) => {
        state.resultDetail = action.payload?.data ?? null;
        state.isLoadingDetail = false;
      })
      .addCase(profileMyVoucherDetail.rejected, (state) => {
        state.resultDetail = null;
        state.isLoadingDetail = false;
      });
  },
});

export const { clearError, clearResult, clearResultDetail } =
  profileMyVoucherSlice.actions;

export const MyVoucherProfileResultIssuedSelector = (
  store: RootState,
): MyVoucherModel | undefined | null => store.profileMyVoucher.resultIssued;
export const MyVoucherProfileResultReservedRedeemedSelector = (
  store: RootState,
): MyVoucherModel | undefined | null =>
  store.profileMyVoucher.resultReservedRedeemed;
export const MyVoucherProfileResultExpiredSelector = (
  store: RootState,
): MyVoucherModel | undefined | null => store.profileMyVoucher.resultExpired;

export const MyVoucherDetailProfileResultSelector = (
  store: RootState,
): GetVoucherDetailModel | undefined | null =>
  store.profileMyVoucher.resultDetail;

export const isLoadingMyVoucherProfileSelector = (store: RootState): boolean =>
  store.profileMyVoucher.isLoading;

export const errorMyVoucherProfileSelector = (
  store: RootState,
): string | undefined => store.profileMyVoucher.error;

export const currentMyVoucherProfilePageSelector = (store: RootState): number =>
  store.profileMyVoucher.currentPage;

export const limitMyVoucherProfileSelector = (store: RootState): number =>
  store.profileMyVoucher.limit;

export const isLoadingMyVoucherDetailProfileSelector = (
  store: RootState,
): boolean => store.profileMyVoucher.isLoadingDetail;

export default profileMyVoucherSlice.reducer;
