import {
  ClearCartResponse,
  ClearCartResponseConverter,
  EditCartResponse,
  EditCartResponseConverter,
  MyCartResponse,
  MyCartResponseConverter,
  SpecialPurchaseResponse,
  SpecialPurchaseResponseConverter,
} from '@/models/MyCart.model';
import { ProductModel } from '@/models/Product.model';
import {
  MyRemobieConverter,
  MyRemobieModel,
} from '@/models/profile/MyRemobie.model';
import {
  MyUsedVoucherListResponse,
  MyUsedVoucherListResponseConverter,
} from '@/models/profile/MyVoucher.model';
import { saveClientCookieFromResponse } from '@/utils/cookie';
import { getDataFromAPI, postDataFromAPI } from '@/utils/httpClient';

export const getMyCartData = async ({
  lang,
}: {
  lang?: string;
}): Promise<MyCartResponse | undefined> => {
  const [getData] = await Promise.all([
    postDataFromAPI({ path: '/mycart', lang }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;
  let response: MyCartResponse | undefined = data;
  if (data) {
    try {
      response = MyCartResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  // ถ้าอนาคตมีคนเรียกใช้แต่ไม่อยาก save cookie ให้ย้ายไปใน Slice
  await saveClientCookieFromResponse(response?.cookies);
  return response;
};

export const getClearCart = async (): Promise<
  ClearCartResponse | undefined
> => {
  const [getData] = await Promise.all([getDataFromAPI({ path: '/clearcart' })]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;
  let response: ClearCartResponse | undefined = data;
  if (data) {
    try {
      response = ClearCartResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export enum EditCartType {
  // eslint-disable-next-line no-unused-vars
  normal = 'normal',
  // eslint-disable-next-line no-unused-vars
  free = 'free',
  // eslint-disable-next-line no-unused-vars
  bundle = 'bundle',
}

export const editCartService = async ({
  product,
  qty,
  type,
}: {
  product: ProductModel;
  qty: number;
  type: EditCartType;
}): Promise<EditCartResponse | undefined> => {
  if (type === EditCartType.normal) {
  } else if (type === EditCartType.bundle) {
  }
  let body = {
    seq: product.seq,
    qnt: qty,
  };
  const [getData] = await Promise.all([
    postDataFromAPI({ path: '/editscntrn', body }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;
  let response: EditCartResponse | undefined = data;
  if (data) {
    try {
      response = EditCartResponseConverter.fromJson(data);
      response.qty = qty;
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const checkSpecialPurchase = async (
  amount: number,
): Promise<SpecialPurchaseResponse | undefined> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/specialpurchase',
      body: {
        amount,
      },
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;
  let response: SpecialPurchaseResponse | undefined = data;
  if (data) {
    try {
      response = SpecialPurchaseResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const getUsedVoucherList = async ({
  lang,
}: {
  lang?: string;
}): Promise<MyUsedVoucherListResponse | null> => {
  const getData = await getDataFromAPI({
    path: '/getvouvalidatelist',
    lang,
  });
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: MyUsedVoucherListResponse | null = data;
  if (data) {
    try {
      response = MyUsedVoucherListResponseConverter.fromJson(data);
      response.voucherUsedList = response?.voucherUsedList?.filter(
        (e) => e.isError || (e.maxDiscount && e.maxDiscount !== ''), // เอา Error ออกมาด้วย เพราะ Erro ไม่มี maxDiscount
      );
      // isError จะถูกเคลียค่าก่อนส่ง Ereor มา (หมายถึง getvouvalidatelist เคลียเลย แล้วส่งมาแค่ Error เพราะฉะนั้นไม่ต้อง reload page)
      if (response.voucherUsedList?.length === 0) {
        response = null;
      }
    } catch (error) {
      console.log(error);
    }
  }

  return response;
};

export const editCartTradeinService = async ({
  skutradein,
  qty,
  lang,
}: {
  skutradein: string;
  qty: number;
  lang: string;
}): Promise<EditCartResponse | undefined> => {
  let body = {
    skutradein,
    qty,
    lang,
  };
  const [getData] = await Promise.all([
    postDataFromAPI({ path: '/editscntrntradein', body }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;
  let response: EditCartResponse | undefined = data;
  if (data) {
    try {
      response = EditCartResponseConverter.fromJson(data);
      response.qty = qty;
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const getRemobie = async ({
  lang,
}: {
  lang?: string;
}): Promise<MyRemobieModel | null> => {
  const getData = await getDataFromAPI({
    path: '/getremobie',
    lang,
  });
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;
  let response: MyRemobieModel | null = data;
  if (data) {
    try {
      response = MyRemobieConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }

  return response;
};
export const checkremobie = async ({
  code,
}: {
  code?: string;
}): Promise<MyRemobieModel | null> => {
  const getData = await postDataFromAPI({
    path: '/checkremobie',
    body: { code },
  });
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;
  let response: MyRemobieModel | null = data;
  if (data) {
    try {
      response = MyRemobieConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }

  return response;
};
