import React, { useState } from 'react';
import { Box } from '@mui/material';
import AppLink from '@/components/Layout/handler/AppLink';
import MenuClass from '@/styles/menu.module.css';
import { useTranslation } from 'next-i18next';
import AllCatContext from '@/contexts/AllCatContext';
import { AllCat } from '@/models/AllCat.model';
import CatSubMenuLvSideBar from './CatSubMenuLvSideBar';

export default function CatSubMenuSideBar({
  node,
  onCloseCatSubMenu,
}: {
  node: string | null;
  // eslint-disable-next-line no-unused-vars
  onCloseCatSubMenu: (node: string | null) => void;
}) {
  const { t, i18n } = useTranslation();
  const [currentNode, setCurrentNode] = useState<string | null>(null);
  const SetOpenSubMenuLv = (node: string | null) => {
    setCurrentNode(node);
  };
  return (
    <AllCatContext.Consumer>
      {(allcats) => {
        if (!node) {
          return;
        }
        allcats = allcats?.filter((e: AllCat) => e.node === node) ?? [];
        return (
          <Box className="overflow-x-hidden overflow-y-auto">
            {!currentNode ? (
              <>
                <Box
                  className="flex px-2 py-1 self-center cursor-pointer text-sm"
                  onClick={() => onCloseCatSubMenu(null)}
                >
                  <div className="my-auto">
                    <i
                      className="bi bi-chevron-left text-sm me-2"
                      style={{ verticalAlign: 'middle' }}
                    />
                    {t('allcategories')}
                  </div>
                </Box>
                <AppLink
                  href={
                    allcats[0]?.slugname &&
                    `/category/${allcats[0]?.slugname ?? ''}`
                  }
                  className="flex px-2 py-2 font-bold"
                >
                  <Box className="self-center ps-1 text-sm">
                    {i18n.language == 'th'
                      ? allcats[0]?.catname
                      : allcats[0]?.catnameEN ?? allcats[0]?.catname}
                  </Box>
                  <Box className="ml-auto underline text-sm">{t('seeall')}</Box>
                </AppLink>
                {(allcats[0]?.items ?? []).map((e: AllCat) => (
                  <Box key={e.node} className={MenuClass.mainCategoryItem}>
                    {!e.havenext ? (
                      <AppLink
                        href={e.slugname && `/category/${e.slugname}`}
                        className="flex px-2 py-2"
                      >
                        <Box className="self-center ps-1 text-sm">
                          {i18n.language == 'th'
                            ? e.catname
                            : e.catnameEN ?? e.catname}
                        </Box>
                      </AppLink>
                    ) : (
                      <Box
                        className="flex px-2 py-1 cursor-pointer"
                        onClick={() => SetOpenSubMenuLv(e.node)}
                      >
                        <Box className="self-center ps-1 text-sm">
                          {i18n.language == 'th'
                            ? e.catname
                            : e.catnameEN ?? e.catname}
                        </Box>
                        <Box className="ml-auto">
                          <i className="bi bi-chevron-right text-sm my-auto" />
                        </Box>
                      </Box>
                    )}
                  </Box>
                ))}
              </>
            ) : (
              <CatSubMenuLvSideBar
                mainCatName={
                  i18n.language == 'th'
                    ? allcats[0]?.catname
                    : allcats[0]?.catnameEN ?? allcats[0]?.catname
                }
                allSubCats={allcats[0]?.items ?? []}
                node={currentNode}
                onCloseCatSubMenuLv={SetOpenSubMenuLv}
              />
            )}
          </Box>
        );
      }}
    </AllCatContext.Consumer>
  );
}
