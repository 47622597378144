import Modal from '@mui/material/Modal';
import React, { ReactNode } from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  showCloseButton?: boolean;
  closeButtonColor?: string;
  bgColor?: string;
  canClickOutside?: boolean;
  isFullHeight?: boolean;
  showScrollBar?: boolean;
  isWidthAlwayAuto?: boolean;
  isNoShadow?: boolean;
  styleClass?: string;
}

export default function CustomModal({
  open,
  onClose,
  children,
  showCloseButton,
  closeButtonColor,
  bgColor,
  canClickOutside,
  isFullHeight,
  showScrollBar,
  isWidthAlwayAuto,
  isNoShadow,
  styleClass,
}: Props) {
  return (
    <Modal
      open={open}
      onClose={canClickOutside === false ? undefined : onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={styleClass}
    >
      <div
        className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ${
          bgColor ? `bg-${bgColor}` : 'bg-white'
        } ${isWidthAlwayAuto ? 'w-auto' : 'w-full'} md:w-auto rounded ${
          isNoShadow ? '' : 'shadow-md'
        } outline-none max-h-[95%] max-w-[95%] overflow-auto`}
      >
        <div className={`relative ${isFullHeight ? 'h-[95vh]' : ''}`}>
          {showCloseButton && (
            <div
              className="absolute top-0 right-0 cursor-pointer"
              onClick={onClose}
            >
              <i
                className={`bi bi-x font-extrabold text-2xl ${
                  closeButtonColor ? `text-${closeButtonColor}` : 'text-primary'
                }`}
              ></i>
            </div>
          )}
          <div
            className={`py-2 px-1 h-full ${
              showScrollBar ? 'show-scrollbar' : ''
            }`}
          >
            {children}
          </div>
        </div>
      </div>
    </Modal>
  );
}
