import { modelValue } from '@/utils/modelValue';
import { StoreAddress, StoreAddressConverter } from './RefundList.model';
import { Address, AddressConverter } from '../Address.model';

export interface RefundReturnInfoResponse {
  requestId?: string;
  paymenttype?: string;
  billAddress?: Address;
  deliveryAddress?: Address;
  pickupmethod?: string;
  reasoncode?: string;
  banklist?: BankList[];
  storeaddress?: StoreAddress;
}
export class RefundReturnInfoResponseConverter {
  public static fromJson(json: any): RefundReturnInfoResponse {
    const model: RefundReturnInfoResponse = {
      requestId: json['requestId'],
      paymenttype: json['paymenttype'],
      billAddress: json['billaddr']
        ? AddressConverter.fromJson(json['billaddr'])
        : undefined,
      deliveryAddress: json['deliveraddr']
        ? AddressConverter.fromJson(json['deliveraddr'])
        : undefined,
      pickupmethod: json['pickupmethod'],
      reasoncode: json['reasoncode'],
      banklist: json['banklist']?.map((e: any) =>
        BankListConverter.fromJson(e),
      ),
      storeaddress: json['storeaddress']
        ? StoreAddressConverter.fromJson(json['storeaddress'])
        : undefined,
    };
    return modelValue(model);
  }
}

export interface BankList {
  name: number;
  imgname: string;
}
export class BankListConverter {
  public static fromJson(json: any): BankList {
    const model: BankList = {
      name: json['NAME'],
      imgname: json['IMGNAME'],
    };
    return modelValue(model);
  }
}

export interface RefundReturnSaveRequest {
  requestId?: string;
  creditNote?: number;
  paymentInfo?: ReturnPaymentInfo;
  creditNoteAddress?: Address;
  returnProductMethod?: number;
  returnHome?: Address;
  returnPost?: PostInfo;
  images?: File[];
}

export interface ReturnPaymentInfo {
  bankName: string;
  accountNo: string;
  accountName: string;
}

export interface PostInfo {
  returnProviderName: string;
  returnTrackingNo: string;
}

export class PostInfoConverter {
  public static fromJson(json: any): PostInfo {
    const model: PostInfo = {
      returnProviderName: json['returnProviderName'],
      returnTrackingNo: json['returnTrackingNo'],
    };
    return modelValue(model);
  }
}

export class ReturnHomePickupConverter {
  public static toJson(model: Address): any {
    const json: any = {
      homeNo: model.addressNumber,
      mooNo: model.moo,
      buildingName: model.building,
      floorNo: model.floor,
      roomNo: model.room,
      alleyNo: model.soi,
      roadName: model.road,
      subDistrictName: model.subDistrict,
      districtName: model.district,
      provinceName: model.province,
      postalCode: model.zipcode,
      contactName: model.nameReturnSender,
      contactMobileNo: model.contactReturnSender,
    };
    return modelValue(json);
  }
}

export interface RefundReturnRequestResponse {
  isError: boolean;
  errorMessage?: string;
}
export class RefundReturnRequestResponseConverter {
  public static fromJson(json: any): RefundReturnRequestResponse {
    const model: RefundReturnRequestResponse = {
      isError: json['iserr'] == true ? true : false,
      errorMessage: json['msg'],
    };
    return modelValue(model);
  }
}
