import { modelValue } from '@/utils/modelValue';
import {
  CookieData,
  CookieDataConverter,
  CustomerInfo,
  CustomerInfoConverter,
} from '../Authen.model';
import { Address } from '../Address.model';
import {
  OrderDetail,
  OrderDetailConverter,
  Tracking,
  TrackingConverter,
} from './Orderhistory.model';

export interface RefundPageResponse {
  title?: string;
  isError?: boolean;
  canRefund?: boolean;
  the1Point?: string;
  ref?: string;
  isAllowRefund?: boolean;
  stcode?: string;
  allowRefundSku?: RefundSku[];
  noAllowRefundSku?: RefundSku[];
  orderDetail?: OrderDetail;
  notAllowWarningMessage?: string;
  addressList?: Address[];
  paymentMethod?: { key: string; value?: string }[];
  info?: CustomerInfo | null;
  cookies?: CookieData[] | null;
}
export class RefundPageResponseConverter {
  public static fromJson(json: any): RefundPageResponse {
    const model: RefundPageResponse = {
      title: json['title'],
      isError: json['iserror'],
      canRefund: json['canRefund'],
      the1Point: json['theonedatapoint'],
      ref: json['refshow'],
      isAllowRefund: json['isallowrefund'],
      stcode: json['stlocation'],
      allowRefundSku: json['allowrefundsku']?.map((e: any) =>
        RefundSkuConverter.fromJson(e),
      ),
      noAllowRefundSku: json['noallowrefundsku']?.map((e: any) =>
        RefundSkuConverter.fromJson(e),
      ),
      orderDetail: json['orderdetail']
        ? OrderDetailConverter.fromJson(json['orderdetail'])
        : undefined,
      notAllowWarningMessage: json['notAllowWarningMessage'],
      paymentMethod: json['paymentmethod'],
      info: json['info']
        ? CustomerInfoConverter.fromJson(json['info'])
        : undefined,
      cookies: json['cookies']
        ? json['cookies'].map((x: any) => CookieDataConverter.fromJson(x))
        : undefined,
    };
    return modelValue(model);
  }
}
export interface RefundSku {
  tracking?: Tracking;
  nItem?: number;
  seq?: number;
  products?: RefundProductModel[];
  trackingCode?: number;
  shippingName?: string;
}

export class RefundSkuConverter {
  public static fromJson(json: any): RefundSku {
    const model: RefundSku = {
      tracking: json['trackingNo']
        ? TrackingConverter.fromJson(json['trackingNo'])
        : undefined,
      nItem: json['nitems'],
      seq: json['seq'],
      products:
        json['sklist'] || json['skulist']
          ? (json['sklist'] ?? json['skulist'])?.map((e: any) =>
              RefundProductModelConverter.fromJson(e),
            )
          : undefined,
      trackingCode: json['trackingCode'],
      shippingName: json['shipname'],
    };
    return modelValue(model);
  }
}

export interface RefundProductModel {
  name: string; //prn
  price: number; //upc
  qty: number; //qnt
  total?: number; //amt
  sku: string;
  isFreegift?: boolean; //FREEGIFT
  atbFlag?: string; //ATB
  groupID?: number;
  shadeID?: number;
  seqSkuBase?: number;
  shadeCode?: string;
  shadename?: string;
  redCode?: number;
  greenCode?: number;
  blueCode?: number;
  slugname: string;
  pimImage?: string;
  base64?: string; //img
  refundUpc?: number;
  refundAmt?: number;
  refundDsc?: number;
  rqntAvail?: number;
  refundTot?: string | null;
  errMessage?: string | null;
  reasonList: ReasonRefund[];
  trackSer?: TrackService;
}

export class RefundProductModelConverter {
  public static fromJson(json: any): RefundProductModel {
    const model: RefundProductModel = {
      name: json['PRN'] ?? '',
      price: json['UPC'] ?? 0,
      qty: json['QNT'] ?? 0,
      total: json['AMT'] ?? 0,
      sku: json['SKC'] ?? '',
      isFreegift: json['FREEGIFT'],
      atbFlag: json['ATB'],
      groupID: json['GroupID'] ?? 0,
      shadeID: json['ShadeID'] ?? 0,
      seqSkuBase: json['SEQSKUBASE'] ?? 0,
      shadeCode: json['ShadeCode'] ?? '',
      shadename: json['Shadename'] ?? '',
      redCode: json['RedCode'] ?? 0,
      greenCode: json['GreenCode'] ?? 0,
      blueCode: json['BlueCode'] ?? 0,
      slugname: json['slugname'] ?? '',
      pimImage: json['pimImage'],
      base64: json['img'],
      refundUpc: json['REFUNDUPC'] ?? 0,
      refundAmt: json['REFUNDAMT'] ?? 0,
      refundDsc: json['REFUNDDSC'] ?? 0,
      rqntAvail: json['RQNTAVAIL'] ?? 0,
      refundTot: json['REFUNDTOT'],
      errMessage: json['errMessage'],
      reasonList: json['REASONLIST']?.map((e: any) =>
        ReasonRefundConverter.fromJson(e),
      ),
      trackSer: json['trackser']
        ? TrackServiceConverter.fromJson(json['trackser'])
        : undefined,
    };
    return modelValue(model);
  }
}

export interface TrackService {
  deliveryDate?: string;
}

export class TrackServiceConverter {
  public static fromJson(json: any): TrackService {
    const model: TrackService = {
      deliveryDate: json['deliveryDate'],
    };
    return modelValue(model);
  }
}

export interface ReasonRefund {
  rsCode: string;
  rsName: string;
  warningMessage?: string | null;
}

export class ReasonRefundConverter {
  public static fromJson(json: any): ReasonRefund {
    const model: ReasonRefund = {
      rsCode: json['RSCODE'],
      rsName: json['RSNAME'],
      warningMessage: json['warningMessage'] ?? null,
    };
    return modelValue(model);
  }
}

export interface RefundRequestItem {
  seqNo: number;
  skuCode?: string;
  returnQty?: number;
  returnReason?: string;
  returnRemark?: string;
  images?: RefundRequestItemImage[];
}

export interface RefundRequestItemImage {
  seqNo: number;
  imageUrl?: File;
}

export interface RefundRequestResponse {
  isError: boolean;
  errorMessage?: string;
}
export class RefundRequestResponseConverter {
  public static fromJson(json: any): RefundRequestResponse {
    const model: RefundRequestResponse = {
      isError: json['iserr'] == true ? true : false,
      errorMessage: json['msg'],
    };
    return modelValue(model);
  }
}
