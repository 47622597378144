import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface LoadingState {
  isLoadingPage: boolean;
}

const initialState: LoadingState = {
  isLoadingPage: false,
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoadingPage = action.payload;
    },
  },
});

export const { setLoading } = loadingSlice.actions;

export const isLoadingPageSelector = (store: RootState): boolean =>
  store.loading.isLoadingPage;

export default loadingSlice.reducer;
