import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as authService from '@/services/client/authService';
import * as authMergeVerify from '@/services/client/authMergeVerifyService';
import {
  RegisterInitiateData,
  RegisterInitiateResponseModel,
} from '@/models/RegisterInitiate.model';
import {
  SendRegisterRequestModel,
  SendRegisterResponseModel,
} from '@/models/SendRegister.model';
import { RootState } from '../store';
import { isMobile } from '@/utils/validate';
import * as cookieKey from '@/constants/cookieKey.constant';
import { CookieData } from '@/models/Authen.model';
import * as ServerCookies from '@/services/client/cookieService';
import Cookies from 'js-cookie';
import { UsernameLoginResponseModel } from '@/models/UsernameLogin.model';
import { ConsentData } from '@/models/SendLogin.model';

export interface RegisterState {
  twdAccessToken?: string;
  the1AccessToken?: string;
  isLoading: boolean;
  isLoadingMerge: boolean;
  isAuthenticated: boolean;
  error?: string;
  registerInitiateData?: RegisterInitiateData;
  customerName?: string;
  registerRequestID?: string;
  nextProcess?: string;
  registerInfo?: RegisterInfoModel;
  mobile?: string;
  type?: string;
  mergeInfo?: string;
  tokenMergeFromT1?: string;
  canMergeAccount?: boolean;
  checkConsent?: ConsentData;
}
export interface RegisterInfoModel {
  firstName?: string;
  lastName?: string;
  email?: string;
  pin?: string;
  isCheckMarket?: boolean;
  consentFlag?: boolean;
  isCheckTC?: boolean;
  version?: string;
}

const initialState: RegisterState = {
  isLoading: false,
  isLoadingMerge: false,
  isAuthenticated: false,
  registerInitiateData: undefined,
  registerRequestID: undefined,
  nextProcess: undefined,
  registerInfo: undefined,
  canMergeAccount: false,
  checkConsent: undefined,
};
export const authenInfoRegister = createAsyncThunk(
  'user/authenInfoRegister',
  async (username: string) => {
    let type: string = isMobile(username) ? 'mobile' : 'email';
    const response = await authService.authenRegisterInitiate({
      username,
      type,
    });
    if (!response || response.error) {
      throw new Error(`${response?.error?.name ?? 'Error'}`);
    }
    return response;
  },
);

export const registerInitiate = createAsyncThunk<
  RegisterInitiateResponseModel,
  undefined,
  { state: RootState }
>(
  'user/registerInitiate',
  async (_, { getState }: { getState: () => RootState }) => {
    let username = getState().userRegister.mobile;
    let type = getState().userRegister.type;
    if (!username) {
      throw new Error('username_not_found');
    }
    if (!type) {
      throw new Error('type_not_found');
    }
    const response = await authService.registerInitiate({
      username,
      type,
    });
    if (!response || response.error) {
      throw new Error('Error');
    }
    return response;
  },
);

export const otpVerify = createAsyncThunk<
  RegisterInitiateResponseModel,
  {
    input: string;
    lang?: string | undefined;
  },
  { state: RootState }
>(
  'user/otpVerify',
  async (
    { input, lang }: { input: string; lang?: string },
    { getState }: { getState: () => RootState },
  ) => {
    let requestId = getState().userRegister.registerRequestID;
    if (!requestId) {
      throw new Error('requestId_not_found');
    }
    const response = await authService.otpVerify({ requestId, input, lang });

    if (!response || response.error) {
      throw new Error(
        `${
          response?.error?.code === '210001'
            ? 'signin:invalidotppleasetryagain'
            : response?.error?.description
        }`,
      );
    }
    return response;
  },
);

export const sendRegister = createAsyncThunk<
  SendRegisterResponseModel,
  {
    memberType: string;
    nationality?: string;
    country?: string;
    identity?: string;
    birthday?: string;
  },
  { state: RootState }
>(
  'user/sendRegister',
  async (
    {
      memberType,
      nationality,
      country,
      identity,
      birthday,
      lang,
    }: {
      memberType: string;
      nationality?: string;
      country?: string;
      identity?: string;
      birthday?: string;
      lang?: string;
    },
    { getState }: { getState: () => RootState },
  ) => {
    let requestId = getState().userRegister.registerRequestID;
    let userInfo = getState().userRegister.registerInfo;
    let checkFirstNameTh = /^[ก-๙]+$/gi.test(userInfo?.firstName ?? '');
    let checkLastnameTh = /^[ก-๙]+$/gi.test(userInfo?.lastName ?? '');
    let firstNameLang = !checkFirstNameTh ? 'en' : 'th';
    let lastNameLang = !checkLastnameTh ? 'en' : 'th';
    if (!requestId) {
      throw new Error('requestId_not_found');
    }
    let sendData: SendRegisterRequestModel | undefined;
    birthday = birthday?.split('-').reverse().join('-');
    sendData = {
      requestID: requestId,
      firstName: { th: userInfo?.firstName, en: userInfo?.firstName },
      lastName: { th: userInfo?.lastName, en: userInfo?.lastName },
      mobile: getState().userRegister.mobile,
      mobileCountry: '66',
      email: userInfo?.email,
      pin: userInfo?.pin,
      tc: userInfo?.isCheckTC ? 'Y' : 'N',
      consentFlag: userInfo?.consentFlag ? 'Y' : 'N',
      consentVersion: '1.0',
      transactionChannel: '001',
      partnerCode: 'TWD',
      branchCode: '',
      staffID: '',
      languagePreference: 'EN',
      firstnamelang: firstNameLang,
      lastnamelang: lastNameLang,
      version: userInfo?.version,
      ischkmarket: userInfo?.isCheckMarket,
      member: 0,
      mode: '0',
    };
    if (memberType === '1') {
      sendData = {
        ...sendData,
        ...(nationality === 'THA'
          ? { citizenID: identity, passportNo: '' }
          : {
              citizenID: '',
              passportNo: identity,
              documentCountryCode: country,
            }),
        nationalityID: nationality,
        dateOfBirth: birthday,
        member: 1,
      };
    }
    if (!sendData) {
      throw new Error('type_is_not_match');
    }
    const response = await authService.sendRegister(sendData, lang);
    if (!response || response.isError) {
      throw new Error(
        `${response?.errorType ?? '9'}|${response?.errorMessage ?? 'Error.'}|${
          response?.t1token
        }|${response?.info}|${
          response?.checkConsent?.consentMarketingStatus === true ? '1' : '0'
        }|${response?.checkConsent?.consentPrivacyVersion}`,
      );
    }
    return response;
  },
);

export const checkT1Token = createAsyncThunk(
  'user/checkt1token',
  async (token: string) => {
    if (!token) {
      throw new Error('token_not_found');
    }
    const response = await authMergeVerify.getMergeFromT1({ token });
    if (!response) {
      throw new Error(`${response}`);
    }
    return response;
  },
);

export const mergeAccountVerify = createAsyncThunk<
  UsernameLoginResponseModel,
  {
    token?: string;
    email: string;
    password?: string;
    facebookid?: string;
  },
  { state: RootState }
>(
  'user/mergeT1AccountVerify',
  async (
    {
      token,
      email,
      password,
      facebookid,
    }: {
      token?: string;
      email: string;
      password?: string;
      facebookid?: string;
    },
    { getState }: { getState: () => RootState },
  ) => {
    if (!token) {
      throw new Error('token_not_found');
    }
    if (!email) {
      throw new Error('email_not_found');
    }
    if (!password && !facebookid) {
      throw new Error('authen_not_found');
    }
    let checkconsent =
      getState().userRegister.checkConsent?.consentMarketingStatus;
    let checkversion =
      getState().userRegister.checkConsent?.consentPrivacyVersion;
    const response = await authMergeVerify.mergeT1AccountVerify({
      token,
      email,
      password,
      facebookid,
      checkconsent,
      checkversion,
    });
    if (!response || response.isError) {
      throw new Error(`${response?.errorType ?? '9'}`);
    }
    return response;
  },
);

const setAPIToken = async ({
  twdAccessToken,
  the1AccessToken,
  customerName,
  ref,
}: {
  twdAccessToken: CookieData;
  the1AccessToken: CookieData;
  customerName?: CookieData | null;
  ref?: CookieData | null;
}) => {
  try {
    if (customerName) {
      Cookies.set(
        cookieKey.customerName,
        (customerName?.val ?? 'unknown').toString(),
        {
          expires: customerName.days ?? 7,
          secure: process.env.NEXT_PUBLIC_NODE_ENV !== 'development',
        },
      );
    }
    if (twdAccessToken.val) {
      await ServerCookies.setMaxAge(
        cookieKey.twdToken,
        twdAccessToken.val.toString(),
        twdAccessToken.days ?? 7,
      );
    }
    if (the1AccessToken.val) {
      await ServerCookies.setMaxAge(
        cookieKey.the1Token,
        the1AccessToken.val.toString(),
        the1AccessToken.days ?? 7,
      );
    }
    if (ref?.val) {
      await ServerCookies.set(cookieKey.ref, ref.val.toString());
    }
  } catch (e) {
    console.log(e);
  }
};

const registerSlice = createSlice({
  name: 'register',
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.error = undefined;
    },
    clearIsAuthenticated: (state) => {
      state.isAuthenticated = false;
    },
    initialRegisterForm: (state) => {
      state.isLoading = false;
      state.error = undefined;
      state.registerInitiateData = undefined;
      state.registerRequestID = undefined;
      state.nextProcess = undefined;
      state.registerInfo = undefined;
      state.mergeInfo = undefined;
      state.tokenMergeFromT1 = undefined;
    },
    setRegisterInfoRequest: (state, action) => {
      if (action.payload) {
        state.nextProcess = 'set_pin';
        state.registerInfo = {
          firstName: action.payload.firstName ?? state.registerInfo?.firstName,
          lastName: action.payload.lastName ?? state.registerInfo?.lastName,
          email: action.payload.email ?? state.registerInfo?.email,
          isCheckMarket: action.payload.isCheckMarket,
          consentFlag: action.payload.consentFlag ?? false,
          isCheckTC: action.payload.isCheckTC ?? false,
          version: action.payload.version,
        };
      }
    },
    setRegisterPinRequest: (state, action) => {
      if (action.payload) {
        state.nextProcess = 'set_member_type';
        state.registerInfo = {
          ...state.registerInfo,
          pin: action.payload.pin,
        };
      }
    },
    setPreviousProcess: (state, action) => {
      state.nextProcess = action.payload;
    },
    clearRegisterInitiateRequest: (state) => {
      state.registerRequestID = undefined;
    },
    clearTokenMergeFromT1: (state) => {
      state.tokenMergeFromT1 = undefined;
    },
    clearMergeAccount: (state) => {
      state.canMergeAccount = false;
      state.mergeInfo = undefined;
      state.tokenMergeFromT1 = undefined;
      state.checkConsent = undefined;
    },
  },
  extraReducers: (builder) => {
    // fullfiled, pending, rejected
    builder
      .addCase(authenInfoRegister.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(authenInfoRegister.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        state.mobile = action.payload.mobile;
        state.type = action.payload.type;
        state.registerInitiateData = action.payload?.data;
        state.registerRequestID = action.payload.requestID;
        state.nextProcess = action.payload.nextProcess;
      })
      .addCase(authenInfoRegister.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(registerInitiate.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(registerInitiate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        state.registerInitiateData = action.payload?.data;
        state.registerRequestID = action.payload.requestID;
        state.nextProcess = action.payload.nextProcess;
      })
      .addCase(registerInitiate.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
    // fullfiled, pending, rejected
    builder
      .addCase(otpVerify.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(otpVerify.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        state.registerRequestID = action.payload.requestID;
        state.nextProcess = action.payload.nextProcess;
      })
      .addCase(otpVerify.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(sendRegister.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(sendRegister.fulfilled, (state, action) => {
        state.isLoading = false;
        const cookies = action.payload.cookies;
        if (cookies && cookies.length > 0) {
          const the1AccessToken = cookies.find(
            (e) => e.name == cookieKey.the1Token,
          );
          const twdAccessToken = cookies.find(
            (e) => e.name == cookieKey.twdToken,
          );
          if (the1AccessToken && twdAccessToken) {
            const customerName = cookies.find(
              (e) => e.name == cookieKey.customerName,
            );
            state.customerName = (customerName?.val ?? 'unknown').toString();
            const ref = cookies.find((e) => e.name == cookieKey.ref);
            setAPIToken({
              twdAccessToken: twdAccessToken,
              the1AccessToken: the1AccessToken,
              customerName: customerName,
              ref: ref,
            });
            state.the1AccessToken = (the1AccessToken.val ?? '').toString();
            state.twdAccessToken = (twdAccessToken.val ?? '').toString();
            state.customerName = (customerName?.val ?? 'unknown').toString();
            state.isAuthenticated = true;
            state.registerRequestID = undefined;
            state.nextProcess = undefined;
            state.registerInitiateData = undefined;
            state.registerInfo = undefined;
          } else {
            state.error = action.payload.errorMessage;
          }
        } else {
          state.error = action.payload.errorMessage;
        }
      })
      .addCase(sendRegister.rejected, (state, action) => {
        let errorType: string = '9';
        let errorMessage: string = 'systemerror';
        try {
          errorType = action.error.message?.split('|')[0] ?? '9';
          errorMessage = action.error.message?.split('|')[1] ?? 'Error.';
        } catch (ex) {}
        switch (errorType) {
          case '0':
            state.error = 'thisemailhasalreadyused';
            state.nextProcess = 'register';
            break;
          case '1':
            state.error = 'pleasecheckinfo';
            state.nextProcess = 'register';
            break;
          case '2':
            state.error = 'thisidnumberalreadyused';
            break;
          case '12':
            let tokenMergeFromT1 = undefined;
            let mergeInfo = undefined;
            let consent = undefined;
            let privacyVersion = undefined;
            try {
              tokenMergeFromT1 = action.error.message?.split('|')[2];
              mergeInfo = action.error.message?.split('|')[3];
              privacyVersion = action.error.message?.split('|')[5] ?? undefined;
              consent = action.error.message?.split('|')[4]
                ? action.error.message?.split('|')[4] === '1'
                  ? true
                  : false
                : undefined;
            } catch (ex) {}
            state.error = 'merge_account_email';
            state.mergeInfo = mergeInfo;
            state.tokenMergeFromT1 = tokenMergeFromT1 ?? undefined;
            state.checkConsent = {
              consentMarketingStatus: consent ?? false,
              consentPrivacyVersion: privacyVersion,
            };
            break;
          default:
            state.error = errorMessage;
        }
        state.isLoading = false;
      });
    builder
      .addCase(checkT1Token.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(checkT1Token.fulfilled, (state) => {
        state.isLoading = false;
        state.error = undefined;
        state.canMergeAccount = true;
      })
      .addCase(checkT1Token.rejected, (state) => {
        state.isLoading = false;
        state.canMergeAccount = false;
        state.tokenMergeFromT1 = undefined;
        state.checkConsent = undefined;
        state.error = 'not_allowed_merge';
      });
    builder
      .addCase(mergeAccountVerify.pending, (state) => {
        state.isLoadingMerge = true;
        state.error = undefined;
      })
      .addCase(mergeAccountVerify.fulfilled, (state, action) => {
        state.isLoadingMerge = false;
        state.error = undefined;
        state.canMergeAccount = false;
        state.checkConsent = undefined;
        state.tokenMergeFromT1 = undefined;
        const cookies = action.payload.cookies;
        if (cookies && cookies.length > 0) {
          const the1AccessToken = cookies.find(
            (e) => e.name == cookieKey.the1Token,
          );
          const twdAccessToken = cookies.find(
            (e: any) => e.name == cookieKey.twdToken,
          );
          if (the1AccessToken && twdAccessToken) {
            const customerName = cookies.find(
              (e: any) => e.name == cookieKey.customerName,
            );
            const ref = cookies.find((e: any) => e.name == cookieKey.ref);
            setAPIToken({
              twdAccessToken: twdAccessToken,
              the1AccessToken: the1AccessToken,
              customerName: customerName,
              ref: ref,
            });
            state.the1AccessToken = (the1AccessToken.val ?? '').toString();
            state.twdAccessToken = (twdAccessToken.val ?? '').toString();
            state.customerName = (customerName?.val ?? 'unknown').toString();
            state.isAuthenticated = true;
            state.registerRequestID = undefined;
            state.nextProcess = undefined;
            state.registerInitiateData = undefined;
            state.registerInfo = undefined;
          } else {
            state.error = 'systemloginerror';
          }
        } else {
          state.error = 'systemloginerror';
        }
      })
      .addCase(mergeAccountVerify.rejected, (state, action) => {
        let errorType: string = '9';
        let errorMessage: string = 'not_allowed_merge';
        try {
          errorType = action.error.message ?? '9';
        } catch (_) {}
        switch (errorType) {
          case '1':
          case '3':
            state.error = 'user_account_not_found';
            break;
          case '2':
            state.error = 'not_allowed_merge';
            break;
          case '4':
            state.error = 'wrongpassword';
            break;
          default:
            state.error = errorMessage;
        }
        state.isLoadingMerge = false;
      });
  },
});

export const {
  initialRegisterForm,
  setRegisterInfoRequest,
  setRegisterPinRequest,
  setPreviousProcess,
  clearRegisterInitiateRequest,
  clearError,
  clearIsAuthenticated,
  clearTokenMergeFromT1,
  clearMergeAccount,
} = registerSlice.actions;

export const isLoadingSelector = (store: RootState): boolean =>
  store.userRegister.isLoading;

export const errorCodeSelector = (store: RootState): string | undefined =>
  store.userRegister.error;

export const authenMethodSelector = (store: RootState): boolean | undefined =>
  store.userRegister.registerInitiateData?.authenticate ? true : false;

export const registerRequestIdSelector = (
  store: RootState,
): string | undefined => store.userRegister.registerRequestID;

export const nextProcessSelector = (store: RootState): string | undefined =>
  store.userRegister.nextProcess;

export const isAuthenticatedSelector = (store: RootState): boolean =>
  store.userRegister.isAuthenticated;

export const LastFourDigitMobileSelector = (
  store: RootState,
): string | undefined =>
  store.userRegister?.mobile?.substring(store.userRegister.mobile.length - 4);

export const userRegisterInfoSelector = (
  store: RootState,
): RegisterInfoModel | undefined => store.userRegister?.registerInfo;

export const mergeInfoSelector = (store: RootState): string | undefined =>
  store.userRegister.mergeInfo;

export const mergeTokenFromTheoneSelector = (
  store: RootState,
): string | undefined => store.userRegister.tokenMergeFromT1;

export const canMergeAccountSelector = (store: RootState): boolean =>
  store.userRegister.canMergeAccount && store.userRegister.canMergeAccount
    ? true
    : false;

export const isLoadingMergeSelector = (store: RootState): boolean =>
  store.userRegister.isLoadingMerge;

export default registerSlice.reducer;
