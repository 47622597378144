// import { RootState } from '@/store/store';
// import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { likeDislikeData } from '@/services/client/pdp/likeDislikeService';

// export interface likeDislikeState {
//   isSuccess: boolean;
//   isLoading: boolean;
//   error?: string;
// }

// const initialState: likeDislikeState = {
//   isSuccess: false,
//   isLoading: false,
// };

// export const likeDislikeSubmit = createAsyncThunk(
//   'pdp/likeDislike',
//   async ({
//     sku,
//     mode,
//     isLike,
//     comment,
//   }: {
//     sku: string;
//     mode: number;
//     isLike?: boolean;
//     comment?: string;
//   }) => {
//     const sendData = {
//       skcode: sku,
//       mode: mode,
//       is_like: isLike ? 1 : 0,
//       comment: comment ?? null,
//     };
//     const response = await likeDislikeData({ sendData });
//     if (!response || !response.dbCode) {
//       throw new Error(`${response.dbMessage ?? 'Error.'}`);
//     }
//     return response;
//   },
// );

// export const likeDislikeSlice = createSlice({
//   name: 'likeDislike',
//   initialState: initialState,
//   reducers: {
//     clearError: (state) => {
//       state.error = undefined;
//     },
//     clearSuccess: (state) => {
//       state.isSuccess = false;
//     },
//   },
//   extraReducers: (builder) => {
//     // pending, fulfilled, rejected
//     builder
//       .addCase(likeDislikeSubmit.pending, (state) => {
//         state.isLoading = true;
//         state.isSuccess = false;
//       })
//       .addCase(likeDislikeSubmit.fulfilled, (state) => {
//         state.isLoading = false;
//         state.isSuccess = true;
//       })
//       .addCase(likeDislikeSubmit.rejected, (state, action) => {
//         state.isLoading = false;
//         state.isSuccess = false;
//         state.error = action.error.message;
//       });
//   },
// });

// export const { clearError, clearSuccess } = likeDislikeSlice.actions;

// export const isLoadingLikeDislikeSelector = (store: RootState): boolean =>
//   store.requestLikeDislike.isLoading;

// export const isSuccessLikeDislikeSelector = (store: RootState): boolean =>
//   store.requestLikeDislike.isSuccess;

// export const errorLikeDislikeSelector = (
//   store: RootState,
// ): string | undefined => store.requestLikeDislike.error;

// export default likeDislikeSlice.reducer;
import { RootState } from '@/store/store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { likeDislikeData } from '@/services/client/pdp/likeDislikeService';

export interface likeDislikeState {
  isSuccess: boolean;
  isLoading: boolean;
  error?: string;
}

const initialState: likeDislikeState = {
  isSuccess: false,
  isLoading: false,
};

export const likeDislikeSubmit = createAsyncThunk(
  'pdp/likeDislike',
  async ({
    sku,
    mode,
    isLike,
    comment,
  }: {
    sku: string;
    mode: number;
    isLike?: boolean;
    comment?: string;
  }) => {
    const sendData = {
      skcode: sku,
      mode: mode,
      is_like: isLike ? 1 : 0,
      comment: comment ?? null,
    };
    const response = await likeDislikeData({ sendData });
    if (!response || !response.dbCode) {
      throw new Error(`${response.dbMessage ?? 'Error.'}`);
    }
    return response;
  },
);

export const likeDislikeSlice = createSlice({
  name: 'likeDislike',
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.error = undefined;
    },
    clearSuccess: (state) => {
      state.isSuccess = false;
    },
    resetState: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(likeDislikeSubmit.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.error = undefined;
      })
      .addCase(likeDislikeSubmit.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.error = undefined;
      })
      .addCase(likeDislikeSubmit.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.error = action.error.message;
      });
  },
});

export const { clearError, clearSuccess, resetState } =
  likeDislikeSlice.actions;

export const isLoadingLikeDislikeSelector = (store: RootState): boolean =>
  store.requestLikeDislike.isLoading;

export const isSuccessLikeDislikeSelector = (store: RootState): boolean =>
  store.requestLikeDislike.isSuccess;

export const errorLikeDislikeSelector = (
  store: RootState,
): string | undefined => store.requestLikeDislike.error;

export default likeDislikeSlice.reducer;
