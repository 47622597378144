import List from '@mui/material/List';
import Select from '@mui/material/Select';
import React from 'react';
interface Props {
  children: React.ReactNode;
  value: string;
}

export default function ProfileLayoutMenuResponsive({
  children,
  value,
}: Props) {
  return (
    <>
      <div className="hidden lg:block">
        <List className="py-0" sx={{ py: 0 }}>
          {children}
        </List>
      </div>
      <div className="block lg:hidden">
        <Select
          className="w-full py-0 text-sm"
          value={value}
          size="small"
          MenuProps={{
            PaperProps: {
              sx: {
                '@media (min-width: 976px)': {
                  display: 'none',
                },
                left: '8px !important',
              },
            },
          }}
          sx={{
            fontSize: '0.875rem',
            fontWeight: 'bolder',
            border: 'none',
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none !important',
            },
            '.MuiSelect-select': {
              p: '0 !important',
            },
            '.MuiPopover-paper': {
              display: 'none !important',
            },
          }}
        >
          {children}
        </Select>
      </div>
    </>
  );
}
