import { modelValue } from '@/utils/modelValue';

export interface AuthenInfoResponseModel {
  data?: AuthenInfoData;
  error?: AuthenInfoError;
}

export class AuthenInfoResponseModelConverter {
  public static fromJson(json: any): AuthenInfoResponseModel {
    const model: AuthenInfoResponseModel = {
      data: json['data']
        ? AuthenInfoDataConverter.fromJson(json['data'])
        : undefined,
      error: json['error']
        ? AuthenInfoErrorConverter.fromJson(json['error'])
        : undefined,
    };
    return modelValue(model);
  }
}

export interface AuthenInfoData {
  authenticate?: Authenticate;
  type?: string;
  status?: string;
  username?: Username;
  otp?: Otp;
  identity?: Identity;
}

export class AuthenInfoDataConverter {
  public static fromJson(json: any): AuthenInfoData {
    const model: AuthenInfoData = {
      authenticate: json['authenticate']
        ? AuthenticateConverter.fromJson(json['authenticate'])
        : undefined,
      type: json['type'],
      status: json['status'],
      username: json['username']
        ? UsernameConverter.fromJson(json['username'])
        : undefined,
      otp: json['otp'] ? OtpConverter.fromJson(json['otp']) : undefined,
      identity: json['identity']
        ? IdentityConverter.fromJson(json['identity'])
        : undefined,
    };
    return modelValue(model);
  }
}

export interface Authenticate {
  otp?: boolean;
  password?: boolean;
  pin?: boolean;
}

export class AuthenticateConverter {
  public static fromJson(json: any): Authenticate {
    const model: Authenticate = {
      otp: json['otp'],
      password: json['password'],
      pin: json['pin'],
    };
    return modelValue(model);
  }
}

export interface Otp {
  on?: On;
  type?: string;
}

export class OtpConverter {
  public static fromJson(json: any): Otp {
    const model: Otp = {
      on: json['on'] ? OnConverter.fromJson(json['on']) : undefined,
      type: json['type'],
    };
    return modelValue(model);
  }
}

export interface On {
  country?: string;
  value?: string;
}

export class OnConverter {
  public static fromJson(json: any): On {
    const model: On = {
      country: json['country'],
      value: json['value'],
    };
    return modelValue(model);
  }
}

export interface Username {
  type?: string;
  country?: string;
  value?: string;
}

export class UsernameConverter {
  public static fromJson(json: any): Username {
    const model: Username = {
      type: json['type'],
      country: json['country'],
      value: json['value'],
    };
    return modelValue(model);
  }
}

export interface AuthenInfoError {
  code?: string;
  description?: string;
  name?: string;
}

export class AuthenInfoErrorConverter {
  public static fromJson(json: any): AuthenInfoError {
    const model: AuthenInfoError = {
      code: json['code'],
      description: json['description'],
      name: json['name'],
    };
    return modelValue(model);
  }
}

export interface Identity {
  isNewDevice?: boolean;
}

export class IdentityConverter {
  public static fromJson(json: any): Identity {
    const model: Identity = {
      isNewDevice: json['isNewDevice'],
    };
    return modelValue(model);
  }
}
