import AppBar from '@mui/material/AppBar';
import CenterHeader from './CenterHeader';
import BottomHeader from './BottomHeader';
import TopBannerContext from '@/contexts/TopBannerContext';
import Image from 'next/image';
import { Box } from '@mui/material';
import AppLink from '@/components/Layout/handler/AppLink';
import { useRouter } from 'next/router';
import { Topbanner } from '@/models/VersionLoad.model';
import StaticPageHeader from './StaticPageHeader';

export default function Header() {
  const router = useRouter();

  return (
    <>
      {/* TopBanner From Version API */}
      <TopBannerContext.Consumer>
        {({ topBanner }: { topBanner?: Topbanner }) =>
          topBanner &&
          //  ถ้าจะให้แสดงทุกหน้า เอา router.asPath == '/' ออกได้เลย
          router.asPath == '/' && (
            <Box style={{ backgroundImage: topBanner.color }}>
              <AppLink href={topBanner.url ?? ''}>
                <Box className="max-width-container">
                  <Image
                    src={topBanner.img ?? ''}
                    alt={`TopBanner - ${topBanner.version?.toString() ?? ''}`}
                    width={1360}
                    height={113}
                    // className="hidden md:block"
                  />
                  {/* Comment ไว้ก่อนเพราะว่าจะได้ไม่ต้องโหลดภาพซ้ำปกติเค้า set size เดียว */}
                  {/* <Image
                    src={topBanner.img_mobile ?? topBanner.img}
                    alt={topBanner.version.toString()}
                    width={1360}
                    height={113}
                    className="md:hidden block"
                  /> */}
                </Box>
              </AppLink>
            </Box>
          )
        }
      </TopBannerContext.Consumer>
      <AppBar
        className="appbar"
        position="sticky"
        color="secondary"
        style={{ zIndex: '1000' }}
      >
        <CenterHeader></CenterHeader>
        <BottomHeader></BottomHeader>
      </AppBar>
      {/* <AppBar position="sticky" color="secondary">
      </AppBar> */}
      <StaticPageHeader />
    </>
  );
}
