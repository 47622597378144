import { Address, AddressConverter } from '@/models/Address.model';
import {
  GeoLocationResponse,
  GeoLocationResponseConverter,
} from '@/models/geoLocation.model';
import {
  AddressPageResponse,
  AddressPageResponseConverter,
} from '@/models/profile/AddressList.model';
import { saveClientCookieFromResponse } from '@/utils/cookie';
import { getDataFromAPI, postDataFromAPI } from '@/utils/httpClient';

export enum PostAddressStatus {
  // eslint-disable-next-line no-unused-vars
  add = 'N',
  // eslint-disable-next-line no-unused-vars
  edit = 'E',
  // eslint-disable-next-line no-unused-vars
  delete = 'D',
}

export const getAddress = async (
  name?: string,
): Promise<{
  data: Address[] | null | undefined;
  status: number | null;
}> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/getmultidlv',
      body: {
        anm: name,
      },
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: Address[] | null | undefined;
    status: number | null;
  } = { data: data, status: getData.status };

  if (data) {
    try {
      response.data = data.dbitems.map((item: any) =>
        AddressConverter.fromJson(item),
      );
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const postAddress = async (
  address: Address,
  status: PostAddressStatus,
  lang?: string,
): Promise<string | undefined> => {
  const getData = await postDataFromAPI({
    path: '/postmultidlv',
    lang,
    body: {
      nstr: JSON.stringify(AddressConverter.toJson(address)),
      mode: status,
    },
  });
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;
  if (data?.dbcode === true) {
    return undefined;
  } else {
    return data?.dbmessage && data?.dbmessage !== ''
      ? data?.dbmessage
      : 'Error.';
  }
};

export const getProfileAddressList = async ({
  lang,
}: {
  lang?: string;
}): Promise<{
  data: AddressPageResponse | null | undefined;
  status: number | null;
}> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/profile/addresslist',
      lang,
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: AddressPageResponse | null | undefined;
    status: number | null;
  } = { data: data, status: getData.status };

  if (data) {
    try {
      response.data = AddressPageResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  // ถ้าอนาคตมีคนเรียกใช้แต่ไม่อยาก save cookie ให้ย้ายไปใน Slice
  await saveClientCookieFromResponse(response.data?.cookies);
  return response;
};

export const geoLocation = async ({
  zipCode,
  lang,
}: {
  zipCode: string;
  lang?: string;
}): Promise<GeoLocationResponse[] | null | undefined> => {
  const getData = await getDataFromAPI({
    lang,
    path: `/webcall/zipcode/${zipCode}`,
  });
  const data =
    getData.status == 200
      ? getData.data
        ? getData.data
        : undefined
      : undefined;

  let response: GeoLocationResponse[] | null | undefined = data;
  if (data) {
    try {
      response = response?.map((data) =>
        GeoLocationResponseConverter.fromJson(data),
      );
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};
