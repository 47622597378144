import {
  CallPaymentResponse,
  CancelRedeemResponse,
  InitiateRedeemResponse,
  InstallmentPlan,
  NextPayResponse,
  OwnCard,
  PaymentMethod,
  PreProcResponse,
  RedeemedThe1,
  SelectedSlot,
  ShipmentResponse,
  ShippingType,
  Slot,
} from '@/models/Checkout.model';
import {
  callCancelRedeem,
  callDeleteCard,
  callInitiateRedeem,
  callKbVerify,
  callNextPay,
  callPayKasikorn,
  callPayment,
  callVerifyRedeem,
  getPreProc,
} from '@/services/client/checkoutService';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PaymentState } from '../../store';
import { convertToMarkPan } from '@/utils/format';
import { AddressConverter } from '@/models/Address.model';
import { CheckStockSkuResponse } from '@/models/Stock.model';
import { ShipmentType } from '@/services/server/shipmentPageService';

interface CheckoutState {
  ref: string;
  initialPaymentData?: ShipmentResponse | null;
  redeemedThe1?: RedeemedThe1;
  initialSelectedSlot?: SelectedSlot[];
  initialIsSelectedExpress?: boolean;
  initialOkTaxinvoice?: boolean;
  initialReceiveWith?: 'S' | 'E';
  initialMobileReceipt?: string;
  initialEmailReceipt?: string;
  // PmcrList
  pmcrInstListResult?: NextPayResponse | null;
  isLoadingPmcrInstList: boolean;
  errorPmcrInst?: string;
  // PmcrList
  // PaymentMethod
  currentPaymentMethod: PaymentMethod;
  currentInstsllmentPlan?: InstallmentPlan;
  // PaymentMethod
  // Card Input
  ownCardSelected?: OwnCard;
  cardNumber?: string;
  cardName?: string;
  cardExpire?: string;
  cardCvv?: string;
  // Card Input
  // Pre-Payment
  prePaymentResult?: PreProcResponse;
  isLoadingPrePayment: boolean;
  errorPrePayment?: string;
  isRefresh?: boolean;
  // Pre-Payment
  // The1Verify
  isLoadingThe1Verify: boolean;
  // The1Verify
  // Payment
  paymentResult?: CallPaymentResponse;
  isLoadingPayment: boolean;
  errorPayment?: CheckStockSkuResponse | null;
  // Payment
  // KbVerify
  isLoadingKbVerify: boolean;
  isSuccessKbVerify: boolean;
  // KbVerify
  // Delete Card
  isLoadingDeleteCard: boolean;
  errorDeleteCard?: string;
  // Delete Card
  creditCardValue: string;
}

const initialState: CheckoutState = {
  ref: '',
  isLoadingPmcrInstList: false,
  currentPaymentMethod: PaymentMethod.credit,
  isLoadingPayment: false,
  isLoadingKbVerify: false,
  isSuccessKbVerify: false,
  isLoadingPrePayment: false,
  isLoadingThe1Verify: false,
  isLoadingDeleteCard: false,
  creditCardValue: '',
};

export const verifyRedeem = createAsyncThunk<
  CancelRedeemResponse | null | undefined,
  {
    lang?: string;
    ref?: string;
    pin: string;
    requestID: string;
  },
  { state: PaymentState }
>(
  'payment/verifyRedeem',
  async (
    {
      lang,
      ref,
      pin,
      requestID,
    }: {
      lang?: string;
      ref?: string;
      pin: string;
      requestID: string;
    },
    { getState }: { getState: () => PaymentState },
  ): Promise<CancelRedeemResponse | null | undefined> => {
    const state = getState();
    const response = await callVerifyRedeem({
      lang,
      ref: ref,
      pin: pin,
      requestID: requestID,
      paymentMethod: state.payment.paymentResult?.paymentMethod,
    });

    if (response.data === null || response.status !== 200) {
      throw new Error(`${response?.status ?? 'Error.'}`);
    }

    if (response.data?.isError) {
      throw new Error(
        `${
          response.data?.message ??
          response.data?.status?.toString() ??
          'Error.'
        }`,
      );
    }

    return response.data;
  },
);

export const cancelRedeem = createAsyncThunk<
  CancelRedeemResponse | null | undefined,
  {
    lang?: string;
  },
  { state: PaymentState }
>(
  'payment/cancelRedeem',
  async (
    {
      lang,
    }: {
      lang?: string;
    },
    { getState }: { getState: () => PaymentState },
  ): Promise<CancelRedeemResponse | null | undefined> => {
    const state = getState();
    const response = await callCancelRedeem({
      lang,
      ref: state.payment.initialPaymentData?.ref,
    });

    if (response.data === null || response.status !== 200) {
      throw new Error(`${response?.status ?? 'Error.'}`);
    }

    if (response.data?.isError) {
      throw new Error(
        `${
          response.data?.message ??
          response.data?.status?.toString() ??
          'Error.'
        }`,
      );
    }

    return response.data;
  },
);

export const initiateRedeem = createAsyncThunk<
  InitiateRedeemResponse | null | undefined,
  {
    lang?: string;
    point: number;
    stcode?: string;
  },
  { state: PaymentState }
>(
  'payment/initiateRedeem',
  async (
    {
      lang,
      point,
      stcode,
    }: {
      lang?: string;
      point: number;
      stcode?: string;
    },
    { getState }: { getState: () => PaymentState },
  ): Promise<InitiateRedeemResponse | null | undefined> => {
    const state = getState();
    const response = await callInitiateRedeem({
      lang,
      point,
      stcode,
      ref: state.payment.initialPaymentData?.ref,
    });

    if (response.data === null || response.status !== 200) {
      throw new Error(`${response?.status ?? 'Error.'}`);
    }

    if (response.data?.isError) {
      throw new Error(
        `${
          response.data?.message ??
          response.data?.status?.toString() ??
          'Error.'
        }|${response.data?.isRefresh ?? false}`,
      );
    }

    return response.data;
  },
);

export const prePaymentQuery = createAsyncThunk<
  PreProcResponse | undefined,
  {
    cardNumber: string;
    cardSName?: string;
    bankSName?: string;
    paymentName?: string;
    inst?: number;
    plan?: number;
  },
  { state: PaymentState }
>(
  'payment/prePaymentQuery',
  async (
    {
      cardNumber,
      cardSName,
      bankSName,
      paymentName,
      inst,
      plan,
    }: {
      cardNumber: string;
      cardSName?: string;
      bankSName?: string;
      paymentName?: string;
      inst?: number;
      plan?: number;
    },
    { getState }: { getState: () => PaymentState },
  ): Promise<PreProcResponse | undefined> => {
    const state = getState();
    const response = await getPreProc({
      cardNumber,
      cardSName,
      bankSName,
      paymentName,
      paymentMethod: state.payment.currentPaymentMethod,
      inst,
      plan,
      oldInstallmentPlans: state.payment.prePaymentResult?.installmentPlans,
      ref: state.payment.initialPaymentData?.ref,
      type: state.payment.initialPaymentData?.type,
    });
    if (response.data === null || response.status !== 200) {
      if (response.status === 401) {
        throw new Error(`${response?.status ?? 'Error.'}`);
      } else {
        throw new Error('Error to fetching pre-payment data.');
      }
    }
    return response.data;
  },
);

export const verifyKbank = createAsyncThunk(
  'payment/verifyKbank',
  async (ref: string): Promise<string | null | undefined> => {
    const response = await callKbVerify(ref);
    return response;
  },
);
export const makeKasikornPayment = createAsyncThunk<
  CallPaymentResponse,
  { lang?: string },
  { state: PaymentState }
>(
  'payment/makeKasikornPayment',
  async (
    { lang }: { lang?: string },
    { getState }: { getState: () => PaymentState },
  ): Promise<CallPaymentResponse> => {
    const state = getState();
    const response = await callPayKasikorn({
      type: state.payment.initialPaymentData?.type,
      ref: state.payment.initialPaymentData?.ref,
      useStockStore:
        state.payment.initialPaymentData?.shippingType === ShippingType.delivery
          ? state.payment.initialPaymentData?.stcode
          : state.payment.initialPaymentData?.pickupStore,
      amount: Number(
        (state.payment.initialPaymentData?.totalAmountShow ?? '0').replaceAll(
          ',',
          '',
        ),
      ),
      lang,
    });
    if (response.status === 401) {
      throw new Error(`${response.status}`);
    }
    if (response.data.isError) {
      if (!response.data.products || response.data.products.length === 0) {
        throw new Error(`${response.data.messageKey ?? 'Error'}`);
      }
    }
    return response.data;
  },
);

export const makePayment = createAsyncThunk<
  CallPaymentResponse,
  { isFullRedeem?: boolean; lang?: string },
  { state: PaymentState }
>(
  'payment/makePayment',
  async (
    { isFullRedeem, lang }: { isFullRedeem?: boolean; lang?: string },
    { getState }: { getState: () => PaymentState },
  ): Promise<CallPaymentResponse> => {
    const state = getState();
    let cardDetail = {};
    let paymentMethod: PaymentMethod = isFullRedeem
      ? PaymentMethod.t1cp
      : state.payment.currentPaymentMethod;
    if (
      paymentMethod === PaymentMethod.credit ||
      paymentMethod === PaymentMethod.instllment
    ) {
      if (state.payment.ownCardSelected !== undefined) {
        cardDetail = {
          markPan: state.payment.ownCardSelected.markPan,
          markSName: state.payment.ownCardSelected.cardSName,
        };
      } else {
        if (
          state.payment.cardNumber?.replace(/[^0-9]/g, '').replaceAll('-', '')
            .length !== 16
        ) {
          throw new Error('incorrectcardnumber');
        }
        if (state.payment.cardName?.trim().length === 0) {
          throw new Error('incorrectcardholdername');
        }
        if (state.payment.cardExpire?.trim().length !== 5) {
          throw new Error('incorrectexpirydate');
        }
        if ((state.payment.cardCvv?.length ?? 0) < 3) {
          throw new Error('incorrectcvv');
        }
        cardDetail = {
          markPan: state.payment.cardNumber
            ? convertToMarkPan(state.payment.cardNumber)
            : undefined,
          markSName: state.payment.prePaymentResult?.cardInfo?.cardSName,
        };
      }
      if (paymentMethod === PaymentMethod.instllment) {
        if ((state.payment.cardCvv?.length ?? 0) < 3) {
          throw new Error('incorrectcvv');
        }
        if (
          !state.payment.currentInstsllmentPlan?.instRef &&
          !state.payment.currentInstsllmentPlan?.idPlan
        ) {
          throw new Error('nopromotionselected');
        }
        cardDetail = {
          ...cardDetail,
          inst: state.payment.currentInstsllmentPlan?.instRef,
          plan: state.payment.currentInstsllmentPlan?.idPlan,
        };
      }
    }
    if (
      state.payment.initialSelectedSlot?.length == 0 &&
      state.payment.initialPaymentData?.slots?.filter(
        (e) => !e.isLongtail && !e.isBackOrder && e.transportType !== 'KER',
      ).length === 0
    ) {
      throw new Error('unabletoselectthisde');
    }
    let deliveryInput = JSON.parse(
      state.payment.initialPaymentData?.customerJsonDelivery?.jsndlv ?? '{}',
    );
    const findPickRunningSlot = state.payment.initialPaymentData?.slots?.find(
      (sf) => sf.transportType == 'PICK',
    );
    const dlvall = state.payment.initialSelectedSlot?.find(
      (e) => e.running == findPickRunningSlot?.slotrunning,
    );
    let dlvd = '';
    let dlvt = '-';
    if (dlvall) {
      dlvd = dlvall.deliveryDate ?? '';
      dlvt = dlvall.timeSlotName ?? '-';
    }
    deliveryInput['Email'] =
      state.payment.initialPaymentData?.info?.email ?? '';
    deliveryInput['The 1'] = state.payment.initialPaymentData?.info?.t1c ?? '';
    deliveryInput['dlvdate'] = dlvd ?? '-';
    deliveryInput['dlvtime'] = dlvt ?? '-';
    deliveryInput['dlvtype'] =
      state.payment.initialPaymentData?.shippingType ?? '-';
    deliveryInput['dlvmulti'] = AddressConverter.toJson(
      state.payment.initialPaymentData?.addressList?.find(
        (e) => e.isDefaultShip,
      ) ?? {},
    );
    const response = await callPayment({
      lang,
      type: state.payment.initialPaymentData?.type,
      stcode: state.payment.initialPaymentData?.stcode,
      useStockStore:
        state.payment.initialPaymentData?.shippingType === ShippingType.delivery
          ? state.payment.initialPaymentData?.stcode
          : state.payment.initialPaymentData?.pickupStore,
      ref: state.payment.initialPaymentData?.ref,
      paymentMethod: paymentMethod,
      amount: Number(
        (state.payment.initialPaymentData?.totalAmountShow ?? '0').replaceAll(
          ',',
          '',
        ),
      ),
      deliveryInput: JSON.stringify(deliveryInput),
      isRequestTax: state.payment.initialOkTaxinvoice,
      receiveWith: state.payment.initialReceiveWith ?? 'E',
      receiveValue:
        (state.payment.initialReceiveWith === 'S'
          ? state.payment.initialMobileReceipt
          : state.payment.initialEmailReceipt) ?? '',
      deliveryType: state.payment.initialPaymentData?.shippingType,
      dcsAvailable: state.payment.initialPaymentData?.dcsAvailable,
      deliveryStore: state.payment.initialPaymentData?.deliveryStore,
      slotSelected:
        state.payment?.initialSelectedSlot?.map((e: SelectedSlot) => {
          const findSlot =
            state.payment.initialPaymentData?.requiredSlots?.find(
              (f) => f.running == e.running,
            );
          return {
            slotposition: e.running ?? 0,
            slotcode: e.slotCode,
            slotdlvdate: e.deliveryDate,
            slotname: e.timeSlotName,
            shiptype: findSlot?.shiptype ?? '',
            isLongtail: findSlot?.isLongtail,
            goWith: findSlot?.goWith,
            sku: findSlot?.shiptype === 'SER' ? findSlot?.sku : undefined,
            upcCode:
              findSlot?.shiptype === 'SER'
                ? findSlot?.upcCode ?? ''
                : undefined,
            vendor: findSlot?.vendor ?? '',
          };
        }) ?? [],
      ...cardDetail,
    });
    if (response.status === 401) {
      throw new Error(`${response.status}`);
    }
    if (response.data.isError) {
      if (!response.data.products || response.data.products.length === 0) {
        throw new Error(`${response.data.messageKey ?? 'Error'}`);
      }
    }
    return response.data;
  },
);

export const pmcrInstQuery = createAsyncThunk(
  'payment/pmcrInstQuery',
  async ({
    ref,
  }: {
    ref?: string;
  }): Promise<NextPayResponse | null | undefined> => {
    const response = await callNextPay({ ref });
    if (response.data === null || response.status !== 200) {
      throw new Error(`${response?.status ?? '500.'}`);
    }
    return response.data;
  },
);

export const deleteStoreCard = createAsyncThunk(
  'payment/deleteStoreCard',
  async ({
    lang,
    cardUuid,
  }: {
    lang: string;
    cardUuid: string;
  }): Promise<boolean> => {
    const response = await callDeleteCard({ lang, cardUuid });
    if (response.data === null || response.status !== 200) {
      throw new Error(`${response?.status ?? '500.'}`);
    }
    if (response.data.status === true) {
      return response.data.status;
    } else {
      throw new Error(`${response.data.message ?? '500.'}`);
    }
  },
);

const paymentSlice = createSlice({
  name: 'payment',
  initialState: initialState,
  reducers: {
    setInitialPaymentPage: (
      state,
      action: {
        payload: {
          initialPaymentData?: ShipmentResponse | null;
          initialIsSelectedExpress?: boolean;
          initialSelectedSlot?: SelectedSlot[];
          initialOkTaxinvoice?: boolean;
          initialReceiveWith?: 'S' | 'E';
          initialMobileReceipt?: string;
          initialEmailReceipt?: string;
        };
      },
    ) => {
      state.initialPaymentData = action.payload.initialPaymentData;
      if (
        action.payload.initialPaymentData?.checkStockResponse?.isError === true
      ) {
        state.errorPayment =
          action.payload.initialPaymentData?.checkStockResponse;
        return;
      }
      if (state.initialPaymentData?.redeemedThe1) {
        state.redeemedThe1 = state.initialPaymentData?.redeemedThe1;
      }
      state.initialIsSelectedExpress = action.payload.initialIsSelectedExpress;
      if (
        state.initialPaymentData?.type !== ShipmentType.repayment &&
        state.initialPaymentData?.type !== ShipmentType.paylater
      ) {
        const sameDaySlot: Slot | undefined =
          action.payload.initialPaymentData?.slots?.find(
            (e) =>
              e.sameDayObject?.status !== 0 && e.sameDayObject?.data !== null,
          );
        if (action.payload.initialIsSelectedExpress && sameDaySlot) {
          const slotSameDayInit: SelectedSlot = {
            running: sameDaySlot.slotrunning,
            slotCode: sameDaySlot.sameDayObject?.data?.timeSlotCode ?? '',
            timeSlotName: sameDaySlot.sameDayObject?.data?.timeSlotName ?? '',
            deliveryDate: sameDaySlot.sameDayObject?.data?.deliveryDate ?? '',
          };
          if (action.payload.initialSelectedSlot) {
            state.initialSelectedSlot = [
              ...action.payload.initialSelectedSlot,
              slotSameDayInit,
            ];
          } else {
            state.initialSelectedSlot = [slotSameDayInit];
          }
        } else {
          state.initialSelectedSlot = action.payload.initialSelectedSlot;
        }
      }
      state.initialOkTaxinvoice = action.payload.initialOkTaxinvoice;
      state.initialReceiveWith = action.payload.initialReceiveWith;
      state.initialMobileReceipt = action.payload.initialMobileReceipt;
      state.initialEmailReceipt = action.payload.initialEmailReceipt;
      state.ownCardSelected = action.payload.initialPaymentData?.ownCards?.[0];
    },
    clearError: (state) => {
      state.errorPmcrInst = undefined;
      state.errorPayment = undefined;
      state.errorPrePayment = undefined;
      state.errorDeleteCard = undefined;
      state.isRefresh = undefined;
    },
    // dispose() shipment
    clearResult: (state) => {
      state.paymentResult = undefined;
      state.pmcrInstListResult = undefined;
      state.ownCardSelected = undefined;
      state.cardNumber = undefined;
      state.cardName = undefined;
      state.cardExpire = undefined;
      state.cardCvv = undefined;
      state.prePaymentResult = undefined;
      state.currentInstsllmentPlan = undefined;
      state.redeemedThe1 = undefined;
    },
    clearPmcrInstResult: (state) => {
      state.pmcrInstListResult = undefined;
      state.currentPaymentMethod = PaymentMethod.credit;
    },
    clearPaymentResult: (state) => {
      state.paymentResult = undefined;
      state.isLoadingKbVerify = false;
      state.isSuccessKbVerify = false;
    },
    selectPaymentMethod: (state, action: { payload: PaymentMethod }) => {
      state.currentPaymentMethod = action.payload;
    },
    selectOwnCard: (state, action: { payload: string | undefined }) => {
      if (!state.isLoadingPayment) {
        if (action.payload) {
          state.ownCardSelected = state.initialPaymentData?.ownCards?.find(
            (e) => e.uuid === action.payload,
          );
        } else {
          state.ownCardSelected = undefined;
        }
      }
    },
    inputCardNumber: (state, action: { payload: string }) => {
      if (!state.isLoadingPayment) {
        state.cardNumber = action.payload;
      }
    },
    inputCardName: (state, action: { payload: string }) => {
      if (!state.isLoadingPayment) {
        state.cardName = action.payload;
      }
    },
    inputCardExpire: (state, action: { payload: string }) => {
      if (!state.isLoadingPayment) {
        state.cardExpire = action.payload;
      }
    },
    inputCardCvv: (state, action: { payload: string }) => {
      if (!state.isLoadingPayment) {
        state.cardCvv = action.payload;
      }
    },
    setCreditCardValue: (state, { payload }: { payload: string }) => {
      // Correctly update the state here
      state.creditCardValue = payload;
    },
    clearCreditCardValue: (state) => {
      state.creditCardValue = '';
    },
  },
  extraReducers: (builder) => {
    // pending, fulfilled, rejected
    builder
      .addCase(pmcrInstQuery.pending, (state) => {
        state.errorPmcrInst = undefined;
        state.isLoadingPmcrInstList = true;
      })
      .addCase(pmcrInstQuery.fulfilled, (state, action) => {
        state.errorPmcrInst = undefined;
        state.isLoadingPmcrInstList = false;
        state.pmcrInstListResult = action.payload;
      })
      .addCase(pmcrInstQuery.rejected, (state, action) => {
        state.errorPmcrInst = action.error.message;
        state.isLoadingPmcrInstList = false;
      });
    // pending, fulfilled, rejected
    builder
      .addCase(verifyRedeem.pending, (state) => {
        state.isLoadingThe1Verify = true;
      })
      .addCase(verifyRedeem.fulfilled, (state) => {
        state.isLoadingThe1Verify = false;
        state.paymentResult = {
          ...state.paymentResult,
          isVerifyPin: false,
        };
      })
      .addCase(verifyRedeem.rejected, (state, action) => {
        state.isLoadingThe1Verify = false;
        state.errorPayment = { message: action.error.message };
      });
    // pending, fulfilled, rejected
    builder
      .addCase(cancelRedeem.pending, (state) => {
        state.errorPmcrInst = undefined;
        state.isLoadingPmcrInstList = true;
      })
      .addCase(cancelRedeem.fulfilled, (state, action) => {
        state.errorPmcrInst = undefined;
        state.isLoadingPmcrInstList = false;
        state.pmcrInstListResult = action.payload?.nextPayResponse;
        state.redeemedThe1 = undefined;
        // set new t1c point to redeem from cancelredeeminfo(cancelRedeemInfo?: CancelRedeemedThe1) data
        if (state.initialPaymentData) {
          state.initialPaymentData = {
            ...state.initialPaymentData,
            the1Point: action.payload?.cancelRedeemInfo?.the1Point,
            the1PointShow: action.payload?.cancelRedeemInfo?.the1PointShow,
            availUseAmountShow:
              action.payload?.cancelRedeemInfo?.availUseAmountShow,
            availUsePointShow:
              action.payload?.cancelRedeemInfo?.availUsePointShow,
            remainAfterUsePointShow:
              action.payload?.cancelRedeemInfo?.remainAfterUsePointShow,
            redeemPromotion: action.payload?.cancelRedeemInfo?.redeemPromotion,
            isNotEnoughPoint:
              action.payload?.cancelRedeemInfo?.isNotEnoughPoint,
            isNotEnoughAmount:
              action.payload?.cancelRedeemInfo?.isNotEnoughAmount,
          };
        }
      })
      .addCase(cancelRedeem.rejected, (state, action) => {
        const splitError = action.error.message?.split('|');
        state.errorPmcrInst = splitError?.[0];
        state.isLoadingPmcrInstList = false;
        state.isRefresh = true;
      });
    // pending, fulfilled, rejected
    builder
      .addCase(initiateRedeem.pending, (state) => {
        state.errorPmcrInst = undefined;
        state.isLoadingPmcrInstList = true;
      })
      .addCase(initiateRedeem.fulfilled, (state, action) => {
        state.errorPmcrInst = undefined;
        state.isLoadingPmcrInstList = false;
        state.pmcrInstListResult = action.payload?.nextPayResponse;
        state.redeemedThe1 = action.payload?.redeemInfo;
      })
      .addCase(initiateRedeem.rejected, (state, action) => {
        const splitError = action.error.message?.split('|');
        state.errorPmcrInst = splitError?.[0];
        state.isLoadingPmcrInstList = false;
        state.isRefresh =
          (splitError?.length ?? 0) > 1
            ? splitError?.[1] === 'true'
            : undefined;
      });
    // pending, fulfilled, rejected
    builder
      .addCase(makePayment.pending, (state) => {
        state.errorPayment = undefined;
        state.isLoadingPayment = true;
      })
      .addCase(makePayment.fulfilled, (state, action) => {
        if (action.payload.products && action.payload.products.length > 0) {
          state.errorPayment = {
            message: action.payload.messageKey,
            products: action.payload.products,
          };
          state.isLoadingPayment = false;
        } else {
          state.errorPayment = undefined;
          state.isLoadingPayment = false;
          state.paymentResult = action.payload;
        }
      })
      .addCase(makePayment.rejected, (state, action) => {
        state.errorPayment = { message: action.error.message };
        state.isLoadingPayment = false;
      });

    // pending, fulfilled, rejected
    builder
      .addCase(makeKasikornPayment.pending, (state) => {
        state.errorPayment = undefined;
        state.isLoadingPayment = true;
      })
      .addCase(makeKasikornPayment.fulfilled, (state, action) => {
        state.errorPayment = undefined;
        state.isLoadingPayment = false;
        state.paymentResult = action.payload;
      })
      .addCase(makeKasikornPayment.rejected, (state, action) => {
        state.errorPayment = { message: action.error.message };
        state.isLoadingPayment = false;
      });
    // pending, fulfilled
    builder
      .addCase(verifyKbank.pending, (state) => {
        state.isLoadingKbVerify = true;
        state.isSuccessKbVerify = false;
      })
      .addCase(verifyKbank.fulfilled, (state, action) => {
        state.isLoadingKbVerify = false;
        if (action.payload?.includes('Status success')) {
          state.isSuccessKbVerify = true;
        }
      });
    // pending, fulfilled, rejected
    builder
      .addCase(prePaymentQuery.pending, (state) => {
        state.isLoadingPrePayment = true;
        state.errorPrePayment = undefined;
      })
      .addCase(prePaymentQuery.fulfilled, (state, action) => {
        state.isLoadingPrePayment = false;
        state.errorPrePayment = undefined;
        state.prePaymentResult = action.payload;
        state.currentInstsllmentPlan = action.payload?.currentPlan;
      })
      .addCase(prePaymentQuery.rejected, (state, action) => {
        state.isLoadingPrePayment = false;
        state.errorPrePayment = action.error.message;
        state.prePaymentResult = undefined;
        state.currentInstsllmentPlan = undefined;
      });

    // pending, fulfilled, rejected
    builder
      .addCase(deleteStoreCard.pending, (state) => {
        state.isLoadingDeleteCard = true;
        state.errorDeleteCard = undefined;
      })
      .addCase(deleteStoreCard.fulfilled, (state, action) => {
        state.isLoadingDeleteCard = false;
        state.errorDeleteCard = undefined;
        const paymentdata = state.initialPaymentData;
        if (paymentdata) {
          paymentdata.ownCards = paymentdata.ownCards?.filter(
            (e) => e.uuid !== action.meta.arg.cardUuid,
          );
          state.initialPaymentData = paymentdata;
        }
        state.ownCardSelected = state.initialPaymentData?.ownCards?.[0];
      })
      .addCase(deleteStoreCard.rejected, (state, action) => {
        state.isLoadingDeleteCard = false;
        state.errorDeleteCard = action.error.message;
      });
  },
});

export const {
  setInitialPaymentPage,
  clearError,
  clearResult,
  clearPmcrInstResult,
  clearPaymentResult,
  selectPaymentMethod,
  selectOwnCard,
  inputCardNumber,
  inputCardName,
  inputCardExpire,
  inputCardCvv,
  setCreditCardValue,
  clearCreditCardValue,
} = paymentSlice.actions;

export const initialPaymentDataSelector = (
  store: PaymentState,
): ShipmentResponse | undefined | null => store.payment.initialPaymentData;

export const redeemedThe1Selector = (
  store: PaymentState,
): RedeemedThe1 | undefined => store.payment.redeemedThe1;

export const initialIsSelectedExpressSelector = (
  store: PaymentState,
): boolean | undefined => store.payment.initialIsSelectedExpress;

export const initialOkTaxinvoiceSelector = (
  store: PaymentState,
): boolean | undefined => store.payment.initialOkTaxinvoice;

export const currentPaymentMethodSelector = (
  store: PaymentState,
): PaymentMethod => store.payment.currentPaymentMethod;

export const currentInstsllmentPlanSelector = (
  store: PaymentState,
): InstallmentPlan | undefined => store.payment.currentInstsllmentPlan;

export const isLoadingPmcrInstListSelector = (store: PaymentState): boolean =>
  store.payment.isLoadingPmcrInstList;

export const pmcrInstListResultSelector = (
  store: PaymentState,
): NextPayResponse | null | undefined => store.payment.pmcrInstListResult;

export const errorPmcrInstSelector = (
  store: PaymentState,
): string | undefined => store.payment.errorPmcrInst;

export const isLoadingPaymentSelector = (store: PaymentState): boolean =>
  store.payment.isLoadingPayment;

export const paymentResultSelector = (
  store: PaymentState,
): CallPaymentResponse | null | undefined => store.payment.paymentResult;

export const errorPaymentSelector = (
  store: PaymentState,
): CheckStockSkuResponse | null | undefined => store.payment.errorPayment;

export const isLoadingKbVerifySelector = (store: PaymentState): boolean =>
  store.payment.isLoadingKbVerify;

export const isSuccessKbVerifySelector = (store: PaymentState): boolean =>
  store.payment.isSuccessKbVerify;

export const ownCardSelectedSelector = (
  store: PaymentState,
): OwnCard | undefined => store.payment.ownCardSelected;

export const cardNumberSelector = (store: PaymentState): string | undefined =>
  store.payment.cardNumber;

export const cardNameSelector = (store: PaymentState): string | undefined =>
  store.payment.cardName;

export const cardExpireSelector = (store: PaymentState): string | undefined =>
  store.payment.cardExpire;

export const cardCvvSelector = (store: PaymentState): string | undefined =>
  store.payment.cardCvv;

export const prePaymentResultSelector = (
  store: PaymentState,
): PreProcResponse | undefined => store.payment.prePaymentResult;

export const isLoadingPrePaymentSelector = (store: PaymentState): boolean =>
  store.payment.isLoadingPrePayment;

export const isLoadingDeleteCardSelector = (store: PaymentState): boolean =>
  store.payment.isLoadingDeleteCard;

export const errorPrePaymentSelector = (
  store: PaymentState,
): string | undefined => store.payment.errorPrePayment;

export const errorDeleteCardSelector = (
  store: PaymentState,
): string | undefined => store.payment.errorDeleteCard;

export const isRefreshSelector = (store: PaymentState): boolean | undefined =>
  store.payment.isRefresh;

export const isLoadingThe1VerifySelector = (store: PaymentState): boolean =>
  store.payment.isLoadingThe1Verify;

export const creditCardValueSelector = (store: PaymentState): string =>
  store.payment.creditCardValue;

export default paymentSlice.reducer;
