import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { addFreeGift } from '@/services/client/addFreeGiftService';
import { AddFreeGiftModel } from '@/models/AddFreeGift.model';
interface AddFreeGiftState {
  result?: AddFreeGiftModel | null;
  isLoading: boolean;
}

const initialState: AddFreeGiftState = {
  isLoading: false,
};

export const addFreeGiftQuery = createAsyncThunk(
  'addFreeGift/add',
  async ({
    lang,
    skcode,
    ctlid,
    qnt,
    seq,
    usestock,
  }: {
    lang: string;
    skcode: number;
    ctlid: number;
    qnt: number;
    seq: number;
    usestock: string;
  }) => {
    const response = await addFreeGift({
      lang,
      skcode,
      ctlid,
      qnt,
      seq,
      usestock,
    });
    return response;
  },
);

const addFreeGiftSlice = createSlice({
  name: 'addFreeGift',
  initialState: initialState,
  reducers: {
    clearResultAddFreeGift: (state) => {
      state.result = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addFreeGiftQuery.pending, (state) => {
        state.result = undefined;
        state.isLoading = true;
      })
      .addCase(addFreeGiftQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.result = action.payload;
      })
      .addCase(addFreeGiftQuery.rejected, (state) => {
        state.isLoading = false;
        state.result = undefined;
      });
  },
});

export const { clearResultAddFreeGift } = addFreeGiftSlice.actions;

export const addFreeGiftResultsSelector = (
  store: RootState,
): AddFreeGiftModel | undefined => store.addFreeGift.result || undefined;

export const isLoadingAddFreeGiftResultsSelector = (
  store: RootState,
): boolean => store.addFreeGift.isLoading;

export default addFreeGiftSlice.reducer;
