import { MyReviewResponse } from '@/models/profile/MyReview.model';
import { getProfileMyReview } from '@/services/client/myReviewService';
import { RootState } from '@/store/store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ServerCookies from '@/services/client/cookieService';
import * as cookieKey from '@/constants/cookieKey.constant';
import { getProfileMyReviewEdit } from '@/services/client/myReviewEditService';
import { GetEditReviewModel } from '@/models/GetEditReview.model';

export interface profileMyReviewState {
  result1?: MyReviewResponse | null;
  result2?: MyReviewResponse | null;
  result3?: MyReviewResponse | null;
  resultEdit?: GetEditReviewModel | null;
  currentPage: number;
  limit: number;
  mode: number;
  isLoading: boolean;
  isLoadingEdit: boolean;
  error?: string;
}

const initialState: profileMyReviewState = {
  currentPage: 1,
  isLoading: false,
  isLoadingEdit: false,
  limit: 5,
  mode: 1,
};

export const profileMyReviewQuery = createAsyncThunk(
  'profileMyReview/profile/query',
  async ({
    lang,
    page,
    mode,
  }: {
    lang?: string;
    page?: number;
    mode?: number;
  }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await getProfileMyReview({ lang, page, mode });
    if (!response.data) {
      throw new Error(`${response.status ?? '500.'}`);
    }

    return response;
  },
);
export const profileMyReviewEdit = createAsyncThunk(
  'profileMyReview/profile/edit',
  async ({ lang, hrecid }: { lang: string; hrecid?: string }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await getProfileMyReviewEdit({ lang, hrecid });
    if (!response.data) {
      throw new Error(`${response.status ?? '500.'}`);
    }
    return response;
  },
);

export const profileMyReviewSlice = createSlice({
  name: 'profileMyReview',
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.error = undefined;
    },
    clearResult: (state) => {
      state.result1 = undefined;
      state.result2 = undefined;
      state.result3 = undefined;
      state.resultEdit = undefined;
    },
  },
  extraReducers: (builder) => {
    // pending, fulfilled, rejected
    builder
      .addCase(profileMyReviewQuery.pending, (state) => {
        state.isLoading = true;
        // state.result = undefined;
      })
      .addCase(profileMyReviewQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload === null) {
          state.result1 = {
            info: null,
          };
          state.result2 = {
            info: null,
          };
          state.result3 = {
            info: null,
          };
        } else {
          const mode = action.payload?.data?.mode;
          switch (Number(mode)) {
            case 1:
              state.result1 = action.payload?.data ?? null;
              break;
            case 2:
              state.result2 = action.payload?.data ?? null;
              break;
            case 3:
              state.result3 = action.payload?.data ?? null;
              break;
            default:
              state.result1 = null;
              state.result2 = null;
              state.result3 = null;
          }
        }
        state.limit = action.payload?.data?.limit ?? 5;
        state.currentPage = 1;
      })
      .addCase(profileMyReviewQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.result1 = null;
        state.result2 = null;
        state.result3 = null;
        state.error = action.error.message;
        state.currentPage = 1;
      });
    builder
      .addCase(profileMyReviewEdit.pending, (state) => {
        state.isLoadingEdit = true;
        state.resultEdit = undefined;
      })
      .addCase(profileMyReviewEdit.fulfilled, (state, action) => {
        state.resultEdit = action.payload?.data ?? null;
        state.isLoadingEdit = false;
      })
      .addCase(profileMyReviewEdit.rejected, (state) => {
        state.resultEdit = null;
        state.isLoadingEdit = false;
      });
  },
});

export const { clearError, clearResult } = profileMyReviewSlice.actions;

export const myReviewProfileResult1Selector = (
  store: RootState,
): MyReviewResponse | undefined | null => store.profileMyReview.result1;

export const myReviewProfileResult2Selector = (
  store: RootState,
): MyReviewResponse | undefined | null => store.profileMyReview.result2;

export const myReviewProfileResult3Selector = (
  store: RootState,
): MyReviewResponse | undefined | null => store.profileMyReview.result3;

export const myReviewProfileEditResultSelector = (
  store: RootState,
): GetEditReviewModel | undefined | null => store.profileMyReview.resultEdit;

export const isLoadingMyReviewProfileSelector = (store: RootState): boolean =>
  store.profileMyReview.isLoading;

export const errorMyReviewProfileSelector = (
  store: RootState,
): string | undefined => store.profileMyReview.error;

export const currentMyReviewProfilePageSelector = (store: RootState): number =>
  store.profileMyReview.currentPage;

export const limitMyReviewProfileSelector = (store: RootState): number =>
  store.profileMyReview.limit;

export const isLoadingMyReviewProfileEditSelector = (
  store: RootState,
): boolean => store.profileMyReview.isLoadingEdit;

export default profileMyReviewSlice.reducer;
