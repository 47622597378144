import { modelValue } from '@/utils/modelValue';

export interface RequestOtpResponseModel {
  data?: RequestOtpData;
  error?: RequestOtpError;
}

export class RequestOtpResponsConvert {
  public static fromJson(json: any): RequestOtpResponseModel {
    const model: RequestOtpResponseModel = {
      data: json['data']
        ? RequestOtpDataConverter.fromJson(json['data'])
        : undefined,
      error: json['error']
        ? RequestOtpErrorConverter.fromJson(json['error'])
        : undefined,
    };
    return modelValue(model);
  }
}

export interface RequestOtpData {
  requestID?: string;
  otpRefText?: string;
}

export class RequestOtpDataConverter {
  public static fromJson(json: any): RequestOtpData {
    const model: RequestOtpData = {
      requestID: json['requestID'],
      otpRefText: json['otpRefText'],
    };
    return modelValue(model);
  }
}

export interface RequestOtpError {
  code?: string;
  description?: string;
  name?: string;
}

export class RequestOtpErrorConverter {
  public static fromJson(json: any): RequestOtpError {
    const model: RequestOtpError = {
      code: json['code'],
      description: json['description'],
      name: json['name'],
    };
    return modelValue(model);
  }
}
