import {
  SearchSuggestResponse,
  SearchSuggestResponseConverter,
} from '@/models/SearchSuggest.model';
import { postDataFromAPI } from '@/utils/httpClient';

export const getSearchSuggestData = async ({
  text,
  lang,
}: {
  text: string;
  lang?: string;
}): Promise<{
  data: SearchSuggestResponse | null | undefined;
  status: number | null;
}> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/getsrclist',
      body: { condi: text },
      lang,
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: SearchSuggestResponse | null | undefined;
    status: number | null;
  } = { data: data, status: getData.status };

  if (data) {
    try {
      response.data = SearchSuggestResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};
