import {
  MyWarrantyResponse,
  MyWarrantyResponseConverter,
} from '@/models/profile/MyWarranty.model';
import { saveClientCookieFromResponse } from '@/utils/cookie';
import { postDataFromAPI } from '@/utils/httpClient';

export const getProfileMyWarranty = async ({
  lang,
  page,
}: {
  lang?: string;
  page?: number;
}): Promise<{
  data: MyWarrantyResponse | null | undefined;
  status: number | null;
}> => {
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/profile/mywarranty',
      lang,
      body: {
        page,
        lang,
      },
    }),
  ]);
  const data =
    getData.status == 200 ? (getData.data ? getData.data : null) : null;

  let response: {
    data: MyWarrantyResponse | null | undefined;
    status: number | null;
  } = { data: data, status: getData.status };
  if (data) {
    try {
      response.data = MyWarrantyResponseConverter.fromJson(data);
    } catch (error) {
      console.log(error);
    }
  }
  // ถ้าอนาคตมีคนเรียกใช้แต่ไม่อยาก save cookie ให้ย้ายไปใน Slice
  await saveClientCookieFromResponse(response.data?.cookies);
  return response;
};
