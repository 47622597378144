import { modelValue } from '@/utils/modelValue';
import { ProductModel, ProductModelConverter } from './Product.model';

export interface GetFreeGiftListModel {
  products?: ProductModel[];
}
export class GetFreeGiftListModelConverter {
  public static fromJson(json: any): GetFreeGiftListModel {
    const model: GetFreeGiftListModel = {
      products:
        json['products'] == null
          ? []
          : json['products'].map((x: any) => ProductModelConverter.fromJson(x)),
    };
    return modelValue(model);
  }
}
