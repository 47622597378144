import { modelValue } from '@/utils/modelValue';
import { CookieData } from './Authen.model';

export interface UsernameLoginResponseModel {
  isError?: boolean;
  errorType?: number;
  cookies?: CookieData[];
}

export class UsernameLoginResponseModelConverter {
  public static fromJson(json: any): UsernameLoginResponseModel {
    const model: UsernameLoginResponseModel = {
      isError: json['error'] == true ? true : false,
      cookies: json['cookies'],
      errorType: json['type'],
    };
    return modelValue(model);
  }
}
