import { MyWarrantyResponse } from '@/models/profile/MyWarranty.model';
import { MyWarrantyDetailResponse } from '@/models/profile/MyWarrantyDetail.model';
import { getProfileMyWarranty } from '@/services/client/myWarrantyService';
import { RootState } from '@/store/store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as ServerCookies from '@/services/client/cookieService';
import * as cookieKey from '@/constants/cookieKey.constant';
import { getProfileMyWarrantyDetail } from '@/services/client/myWarrantyDetailService';
// import { GetEditReviewModel } from '@/models/GetEditReview.model';

export interface profileMyWarrantyState {
  result?: MyWarrantyResponse | null;
  resultDetail?: MyWarrantyDetailResponse | null;
  currentPage: number;
  limit: number;
  isLoading: boolean;
  isLoadingDetail: boolean;
  error?: string;
  errorDetail?: string;
}

const initialState: profileMyWarrantyState = {
  currentPage: 1,
  isLoading: false,
  isLoadingDetail: false,
  limit: 5,
};

export const profileMyWarrantyQuery = createAsyncThunk(
  'profileMyWarranty/profile/query',
  async ({ lang, page }: { lang?: string; page?: number; mode?: number }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await getProfileMyWarranty({ lang, page });
    if (!response.data) {
      throw new Error(`${response.status ?? '500.'}`);
    }

    return response;
  },
);
export const profileMyWarrantyDetail = createAsyncThunk(
  'profileMyWarranty/profile/detail',
  async ({ lang, sku, ref }: { lang: string; sku?: number; ref: string }) => {
    const twdTokenCookie = await ServerCookies.get(cookieKey.twdToken);
    if (!twdTokenCookie) {
      return null;
    }
    const response = await getProfileMyWarrantyDetail({ lang, sku, ref });
    if (!response.data) {
      throw new Error(`${response.status ?? '500.'}`);
    }
    return response;
  },
);

export const profileMyWarrantySlice = createSlice({
  name: 'profileMyWarranty',
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.error = undefined;
    },
    clearResult: (state) => {
      state.result = undefined;
    },
    clearErrorDetail: (state) => {
      state.errorDetail = undefined;
    },
    clearResultDetail: (state) => {
      state.resultDetail = undefined;
    },
  },
  extraReducers: (builder) => {
    // pending, fulfilled, rejected
    builder
      .addCase(profileMyWarrantyQuery.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(profileMyWarrantyQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload === null) {
          state.result = {
            info: null,
          };
        } else {
          state.result = action.payload?.data ?? null;
        }
        state.limit = action.payload?.data?.limit ?? 5;
        state.currentPage = 1;
      })
      .addCase(profileMyWarrantyQuery.rejected, (state, action) => {
        state.isLoading = false;
        state.result = null;
        state.error = action.error.message;
        state.currentPage = 1;
      });
    builder
      .addCase(profileMyWarrantyDetail.pending, (state) => {
        state.isLoadingDetail = true;
        state.resultDetail = undefined;
      })
      .addCase(profileMyWarrantyDetail.fulfilled, (state, action) => {
        state.isLoadingDetail = false;
        if (action.payload === null) {
          state.resultDetail = {
            info: null,
          };
        } else {
          state.resultDetail = action.payload?.data ?? null;
        }
      })
      .addCase(profileMyWarrantyDetail.rejected, (state, action) => {
        state.resultDetail = null;
        state.isLoadingDetail = false;
        state.errorDetail = action.error.message;
      });
  },
});

export const { clearError, clearResult, clearResultDetail, clearErrorDetail } =
  profileMyWarrantySlice.actions;

export const myWarrantyProfileResultSelector = (
  store: RootState,
): MyWarrantyResponse | undefined | null => store.profileMyWarranty.result;

export const isLoadingMyWarrantyProfileSelector = (store: RootState): boolean =>
  store.profileMyWarranty.isLoading;

export const errorMyWarrantyProfileSelector = (
  store: RootState,
): string | undefined => store.profileMyWarranty.error;

export const currentMyWarrantyProfilePageSelector = (
  store: RootState,
): number => store.profileMyWarranty.currentPage;

export const limitMyWarrantyProfileSelector = (store: RootState): number =>
  store.profileMyWarranty.limit;

export const myWarrantyDetailProfileResultSelector = (
  store: RootState,
): MyWarrantyDetailResponse | undefined | null =>
  store.profileMyWarranty.resultDetail;

export const isLoadingMyWarrantyDetailProfileSelector = (
  store: RootState,
): boolean => store.profileMyWarranty.isLoadingDetail;

export const errorMyWarrantyDetailProfileSelector = (
  store: RootState,
): string | undefined => store.profileMyWarranty.errorDetail;

export default profileMyWarrantySlice.reducer;
