import { modelValue } from '@/utils/modelValue';

export interface Get1010CouponModel {
  iserror?: boolean;
  msg?: string;
  status?: number;
}
export class Get1010CouponModelConverter {
  public static fromJson(json: any): Get1010CouponModel {
    const model: Get1010CouponModel = {
      iserror: json['iserror'],
      msg: json['msg'],
      status: json['status'],
    };
    return modelValue(model);
  }
}
