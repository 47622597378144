import {
  clearHistoryResult,
  errorSuggestSelector,
  getHistoryResult,
  getSearchSuggest,
  resultLoadingSelector,
  searchCategoryResultSelector,
  searchHistoryResultSelector,
  searchProductResultSelector,
  searchTextCurrentResultSelector,
  searchTextSelector,
  setLoading,
} from '@/store/slices/searchSuggestSlice';
import { useAppDispatch } from '@/store/store';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import AppLink from '../../handler/AppLink';
import { useTranslation } from 'next-i18next';
import ProductImageBase64 from '@/components/common/ProductImageBase64';
import { useRouter } from 'next/router';
import TextMatching from '@/components/common/TextMatching';

export default function SearchSuggestBox() {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const searchText = useSelector(searchTextSelector);
  const searchTextCurrentResult = useSelector(searchTextCurrentResultSelector);
  const searchHistoryResult = useSelector(searchHistoryResultSelector);
  const searchCategoryResult = useSelector(searchCategoryResultSelector);
  const searchProductResult = useSelector(searchProductResultSelector);
  const resultLoading = useSelector(resultLoadingSelector);
  const errorSuggest = useSelector(errorSuggestSelector);
  const delay = 1500;
  const timeoutIdRef = useRef<number | null>(null);
  useEffect(() => {
    if (!searchHistoryResult) {
      dispatch(getHistoryResult());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchTextCurrentResult?.trim() !== searchText.trim()) {
      // if (searchText.trim().length > 2) {
      //   dispatch(setLoading(true));
      // }
      dispatch(setLoading(true));
      if (timeoutIdRef.current !== null) {
        clearTimeout(timeoutIdRef.current);
      }

      timeoutIdRef.current = window.setTimeout(() => {
        // if (searchText.trim().length > 2) {
        //   dispatch(
        //     getSearchSuggest({ text: searchText.trim(), lang: i18n.language }),
        //   );
        // }
        dispatch(
          getSearchSuggest({ text: searchText.trim(), lang: i18n.language }),
        );
      }, delay);
    }
    return () => {
      if (timeoutIdRef.current !== null) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [searchText, dispatch, i18n.language, searchTextCurrentResult]);

  const handleClearHistory = (e: any) => {
    e.preventDefault();
    dispatch(clearHistoryResult());
  };
  return (
    <div className="bg-white shadow-xl">
      {/* history */}
      {searchHistoryResult && searchHistoryResult.length > 0 && (
        <div className="px-2 py-1 border-t">
          <div className="flex leading-3 h-3">
            <div className="text-xs font-bold">{t('searchhistory')}</div>
            <div
              className="ms-auto text-xs font-bold text-grayDark cursor-pointer hover:text-black"
              onClick={handleClearHistory}
            >
              <span>{t('clearall')}</span>
              <i className="bi bi-trash3 ms-0.5"></i>
            </div>
          </div>
          <div className="flex flex-wrap">
            {[...searchHistoryResult].reverse().map((e, i) => (
              <AppLink key={i} href={`/search/${e}`}>
                <div
                  onClick={(event: any) => {
                    event.preventDefault();
                    if (router.query?.srct != e) {
                      router.push(`/search/${e}`);
                    }
                  }}
                  className="text-xs rounded-lg text-black bg-grayLight1 px-2 mr-1 mt-1 cursor-pointer hover:bg-grayDark1"
                >
                  {e}
                </div>
              </AppLink>
            ))}
          </div>
        </div>
      )}
      {searchText.trim() === '' ? (
        <></>
      ) : errorSuggest ? (
        <div className="border-t px-2 text-center text-sm py-2.5">
          {t('productnotfound')}{' '}
          {/* <a
            className="text-blue"
            href="https://bit.ly/3w6KET9"
            target="_blank"
          >
            {t('letushelp')}
          </a> */}
        </div>
      ) : resultLoading ? (
        <div className="border-t px-2 text-center">
          <div className="lds-ellipsis h-8">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <>
          {/* category */}
          {searchCategoryResult && searchCategoryResult.length > 0 && (
            <div className="border-t px-2 pb-1 max-h-11 overflow-hidden">
              <div className="flex flex-wrap">
                {[...searchCategoryResult].map((e, i) => (
                  <AppLink
                    key={i}
                    href={e.slugname && `/category/${e.slugname}`}
                  >
                    <div className="text-xs rounded-lg text-black bg-grayLight1 px-2 mr-1 mt-1 cursor-pointer hover:bg-grayDark1">
                      <TextMatching value={e.catname} matcher={searchText} />
                    </div>
                  </AppLink>
                ))}
              </div>
            </div>
          )}
          {/* product */}
          {/* {router.query?.srct != searchText && // หน้า search ไม่ต้องแสดง เพราะมีการแสดงผลที่ list แล้ว
            searchProductResult &&
            searchProductResult.length > 0 && (
              <>
                <div className="border-t pb-1 overflow-hidden">
                  {[...searchProductResult].map((e, i) => (
                    <AppLink
                      key={i}
                      className="py-1 flex items-center hover:bg-grayLight px-2 text-sm"
                      href={`/product/${e.slugname}`}
                    >
                      <div className="w-12">
                        <ProductImageBase64
                          pimImage={e.pimImage}
                          base64={e.base64}
                        />
                      </div>
                      <div className="ms-1 w-full leading-4">
                        <TextMatching value={e.name} matcher={searchText} />
                      </div>
                    </AppLink>
                  ))}
                </div>
                <AppLink href={`/search/${searchText}`}>
                  <div className="text-xs px-2 py-1 text-center hover:text-secondaryOrange">
                    {t('seeall')}
                  </div>
                </AppLink>
              </>
            )} */}
          {router.query?.srct != searchText && // หน้า search ไม่ต้องแสดง เพราะมีการแสดงผลที่ list แล้ว
            searchProductResult &&
            searchProductResult.length > 0 && (
              <>
                <div className="border-t pb-1 overflow-hidden">
                  {[...searchProductResult].slice(0, 5).map((e, i) => (
                    <AppLink
                      key={i}
                      className="py-1 flex items-center hover:bg-grayLight px-2 text-sm"
                      href={`/product/${e.slugname}`}
                    >
                      <div className="w-12">
                        <ProductImageBase64
                          pimImage={e.pimImage}
                          base64={e.base64}
                        />
                      </div>
                      <div className="ms-1 w-full leading-4">
                        <TextMatching value={e.name} matcher={searchText} />
                      </div>
                    </AppLink>
                  ))}
                </div>
                <AppLink href={`/search/${searchText}`}>
                  <div className="text-xs px-2 py-1 text-center hover:text-secondaryOrange">
                    {t('seeall')}
                  </div>
                </AppLink>
              </>
            )}
        </>
      )}
    </div>
  );
}
